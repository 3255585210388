import React from 'react';
import { LeftNav } from './LeftNav';
import { MainContent } from './MainContent';
import { Header } from './Header';
import { ErrorBoundary } from './ErrorBoundary';

export const SiteBody = ({ config, logo_url, children }) => {
    return (
        <div className={'site-body'}>
            {!config.hide_header && <Header logo_url={logo_url || '/valerian-logo.png'} />}
            <LeftNav />
            <ErrorBoundary>
                <MainContent config={config}>{children}</MainContent>
            </ErrorBoundary>
        </div>
    );
};
