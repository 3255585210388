import React from 'react';
import {useMutation, useQueryCache} from 'react-query';
import {useUpsert} from '../../../services/hooks/useUpsert';
import {InvoiceDataService} from './InvoiceDataService';
import {InvoiceProductVariants} from './InvoiceProductVariants';
import {ProductDropdown} from './ProductDropdown';

const invoiceProductHookContext = {
    entity: 'invoice_product_variants'
};

const invoiceDataService = new InvoiceDataService();
export function InvoiceProducts({invoice,valueChanged, parent_id,updateInvoice}){
    const [showSelect, setShowSelect] =          React.useState(false);
    const queryCache = useQueryCache();
    const [updateMutate] = useUpsert({...invoiceProductHookContext});
    const [deleteMutate] = useMutation(invoiceDataService.deleteInvoiceProductVariant,{
        onSuccess: async () => {
            await queryCache.invalidateQueries(['invoice_data',parent_id]);
        },
    });
    const addVariant = async (variant)=>{
        await updateMutate({...variant,invoice_id: invoice.id});
        setShowSelect(false);
        await queryCache.invalidateQueries(['invoice_data',parent_id]);
    };
    const deleteVariant = async (variant_id)=>{
        await deleteMutate(variant_id);
    };
    return <>
        <InvoiceProductVariants invoice_rows={invoice.invoice_product_variants}
            deleteVariant={deleteVariant}
            invoice={invoice}
            updateInvoice={updateInvoice}
            addVariant={addVariant}
            valueChanged={valueChanged}
            parent_id={parent_id}/>
        <tr>
            <td>
                <a className={'nav-link'} onClick={()=>setShowSelect(!showSelect)}><i className={'fa fa-plus'} /> Add</a>
            </td>
            <td >
                <ProductDropdown showSelect={showSelect} selectVariant={async (variant)=>await addVariant( variant)} />
            </td>
            <td colSpan={3}></td>
        </tr>
    </>;
}
