import classes from './VariantInventoryTable.module.scss';
import { useState, useEffect, useRef } from 'react';
import useTableData from '../../../services/hooks/useTableData';

import Button from '../../CommonUI/Button';
import ButtonType from '../../CommonUI/ButtonType';
import FormattedTable from '../../CommonUI/TitledTable/FormattedTable';
import Modal from '../../CommonUI/Modal';
import ConditionalBackgroundBlocker from '../../CommonUI/BackgroundBlocker';

import useAddInventoryAdjustments from '../../../services/hooks/useAddInventoryAdjustments';

const VariantInventoryTable = ({ selectedProduct, selectedVariant }) => {
    const headers = [
        { key: '1-Size', colTitle: 'Size', colKey: 'size', colStyles: { width: '50px' } },
        {
            key: '2-Available',
            colTitle: 'Qty Available',
            colKey: 'qty_inventory',
            colStyles: {
                textAlign: 'center',
                minWidth: '225px',
            },
            dataStyles: {
                textAlign: 'center',
                width: '75px',
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            inputType: 'number',
            onChange: ({ rowKey, newValue }) => {
                const newInventory = currentInventory.map((el) => {
                    const newEl = { ...el };
                    if (el.key === rowKey) newEl.qty_inventory = parseInt(newValue);
                    return newEl;
                });
                setCurrentInventory(orderInventory(newInventory));
            },
        },
        {
            key: '3-Pending',
            colTitle: 'Qty Pending',
            colKey: 'qty_open_sales',
            colStyles: {
                textAlign: 'center',
                minWidth: '225px',
            },
            dataStyles: {
                textAlign: 'center',
            },
        },
        {
            key: '4-Sku',
            colTitle: 'Variant Sku',
            colKey: 'sku_id',
        },
    ];

    const [isEditing, setEditing] = useState(false);
    const [isFormCommitted, setFormCommitted] = useState(false);

    const [sizes,sizeDataReturned] = useTableData({
        hookContext: {
            entity: 'sizes',
            filter: {
                size_range: { eq: selectedProduct.size_range },
            },
        },
    });

    const mapVariantInventory = (row) => {
        return {
            ...row,
            key: [row.id, row.size].join('-'),
            order_number: sizes?.find((size) => row.size === size.code)?.order_number,
        };
    };
    const [variantInventory, setVariantInventory] = useTableData({
        hookContext: {
            entity: 'inventory',
            filter_eq: { product_variant_id: selectedVariant.id },
        },
        effectCondition: sizeDataReturned,
        mapCallback: mapVariantInventory,
        dependencies: [sizeDataReturned],
    });

    const expandInventory = (invArray) => {
        if (sizes) {
            const missingSizes = sizes.filter((size) => {
                return !invArray.some((el) => el.size === size.code);
            });

            return invArray.concat(
                missingSizes.map((size, i) => {
                    return {
                        key: size.code,
                        order_number: sizes.find((s) => s === size).order_number,
                        product_id: selectedProduct.id,
                        product_variant_id: selectedVariant.id,
                        style_number: selectedProduct.style_number,
                        attr_2: selectedVariant.code,
                        size: size.code,
                        qty_inventory: 0,
                        qty_open_sales: 0,
                        qty_avail_sell: 0,
                        sku_id: [selectedProduct.style_number, selectedVariant.code, size.code].join('-'),
                        created_at: new Date(),
                        updated_at: new Date(),
                    };
                })
            );
        } else {
            return invArray;
        }
    };

    const orderInventory = (invArray) => {
        return invArray.sort((a, b) => {
            const orderA = a.order_number;
            const orderB = b.order_number;
            return orderA === orderB ? 0 : orderA < orderB ? -1 : 1;
        });
    };

    const [currentInventory, setCurrentInventory] = useState([]);
    useEffect(() => {
        if (variantInventory) {
            const newInventory = variantInventory.map((el) => {
                return { ...el };
            });
            setCurrentInventory(orderInventory(expandInventory(newInventory)));
        }
    }, [variantInventory]);

    const clickToEditDiv = (
        <Button
            label={
                <>
                    <i className={'fas fa-pencil-alt'} style={{ marginRight: '8px' }}></i>Click to manually edit
                    inventory availability
                </>
            }
            action={() => {
                setEditing(true);
                setFormCommitted(false);
            }}
            type={ButtonType.link}
        />
    );

    const [adjustmentDescription, setAdjustmentDescription] = useState('');
    const [showAdjustmentError, setShowAdjustmentError] = useState(false);

    const onCancelEdit = () => {
        setEditing(false);
        setCurrentInventory(orderInventory(variantInventory));
    };

    const addInventoryAdjustments = useAddInventoryAdjustments();
    const onCommitEdit = async () => {
        // Uncomment the following to make the adjustment description required.
        // if (adjustmentDescription === '') {
        //     setShowAdjustmentError(true);
        //     return;
        // }

        setEditing(false);
        const updatedInventoryArray = await addInventoryAdjustments({
            newInventoryArray: [...currentInventory],
            previousInventoryArray: [...variantInventory],
            adjustmentDescription: adjustmentDescription,
        });
        if (updatedInventoryArray) {
            setVariantInventory(
                updatedInventoryArray.map((row) => {
                    return mapVariantInventory(row);
                })
            );
            setFormCommitted(true);
        }

        setAdjustmentDescription('');
    };

    const commitEditDiv = (
        <>
            <input
                className={classes.input}
                type='text'
                name='adjustmentDescription'
                value={adjustmentDescription}
                onChange={(event) => {
                    setAdjustmentDescription(event.target.value);
                }}
                placeholder='(Optional) Enter a reason for the manual adjustment...'
                autoFocus
            />
            <Button label='Cancel' action={onCancelEdit} type={ButtonType.cancelButton} />
            <Button label='Save Changes' action={onCommitEdit} type={ButtonType.actionButton} />
        </>
    );

    return (
        <>
            <ConditionalBackgroundBlocker condition={isEditing}>
                {showAdjustmentError && (
                    <Modal
                        header='Description Required'
                        content='To make a manual adjustment, you must include a reason for the adjustment. Please click OK and then add a description to the input field at the top of the inventory table.'
                        buttonArray={[<Button label={'OK'} action={() => setShowAdjustmentError(false)} />] }
                    />
                )}
                <div className={classes.editWrapper}>{isEditing ? commitEditDiv : clickToEditDiv}</div>
                <FormattedTable
                    headers={headers}
                    data={currentInventory}
                    isFormEditing={isEditing}
                    isFormCommitted={isFormCommitted}
                />
            </ConditionalBackgroundBlocker>
        </>
    );
};

export default VariantInventoryTable;
