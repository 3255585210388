import React, {useEffect, useState} from 'react';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import {TabPanel, TabView} from 'primereact/tabview';

export const SeriesDef = ({show,seriesItem, updateSeriesItem,dataSource}) =>{
    const [currItem, setCurrItem] = useState(seriesItem || {});
    const {update} = useFieldUpdater({state:currItem,setState:setCurrItem});
    useEffect(()=>{
        if(seriesItem)
        {setCurrItem(seriesItem);}
    },[seriesItem]);

    const updateCurrItem = (field, value) =>{
        updateSeriesItem(update(field,value));
    };
    if(!show)
    {return <div />;}
    return <div>
        <div className={'row'}>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Name</label>
                    <input type="text" className={'form-control'}
                        value={currItem.series_name || ''}
                        onChange={(e)=>updateCurrItem('series_name', e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Data Source</label>
                    <select name="" id="" className={'form-control'} value={currItem.data_source_table || ''} onChange={(e)=>updateCurrItem('data_source_table', e.target.value)}>
                        <option value="">...Choose One</option>
                        {(dataSource?.schema_definition?.tables  || []).map((option, opt_index)=>{
                            return <option key={'data-source-table-option-' + opt_index} value={option.table_name}>{option.table_name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>

        <div className={'row'}>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Label</label>
                    <input type="text" className={'form-control'}
                        value={currItem.series_label || ''}
                        onChange={(e)=>updateCurrItem('series_label', e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Color</label>
                    <input type="text" className={'form-control'}
                        value={currItem.series_color || ''}
                        onChange={(e)=>updateCurrItem('series_color', e.target.value)}/>
                </div>
            </div>
        </div>
      
        <div className={'row'}>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Line Thickness</label>
                    <input type="text" className={'form-control'}
                        value={currItem.line_thickness || ''}
                        onChange={(e)=>updateCurrItem('line_thickness', e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Line Type</label>
                    <select name="" id="" className={'form-control'} value={currItem.line_type || ''}
                        onChange={(e)=>updateCurrItem('line_type', e.target.value)}>
                        <option value="">Default</option>
                        <option  value={'ShortDash'}>Short Dashed</option>
                        <option  value={'ShortDot'}>Short Dot</option>
                        <option  value={'ShortDashDot'}>Short Dash Dot</option>
                        <option  value={'ShortDashDotDot'}>Short Dash Dot Dot</option>
                        <option  value={'Dot'}>Dot</option>
                        <option  value={'Dash'}>Dash</option>
                        <option  value={'LongDash'}>Long Dash</option>
                        <option  value={'DashDot'}>Dash Dot</option>
                        <option  value={'LongDashDot'}>Long Dash Dot</option>
                        <option  value={'LongDashDotDot'}>Long Dash Dot Dot</option>
                    </select>
                </div>
            </div>
        </div>
    </div>;
};