import React, {useEffect, useState} from 'react';

export const ItemEntry = ({size, variant, invoice,updateInvoice}) =>{
    const [curInvoice, setCurInvoice] = useState(invoice || {});
    useEffect(()=>{
        if(invoice)
        {setCurInvoice(invoice);}
    },[invoice]);
    const getQty = (size)=>{
        return curInvoice.invoice_items?.find(i=>i.invoice_product_variant_id === variant.id && i.size === size)?.qty;
    };
    const updateQuantity = (qty,size)=>{
        let tmpInvoice = {...curInvoice};
        tmpInvoice.invoice_items = tmpInvoice.invoice_items || [];
        let ii = tmpInvoice.invoice_items
            .find(i=>i.invoice_product_variant_id === variant.id && i.size === size);
        if(ii){
            ii.qty = qty;
            ii.amount = variant.product?.price * ii.qty;
            ii.unit_price = variant.product?.price;
        }else{
            tmpInvoice.invoice_items.push({invoice_id: invoice.id,
                invoice_product_variant_id: variant.id,
                product_variant_id: variant.product_variant_id,
                product_id: variant.product_id,
                size:size,
                qty:qty,
                unit_price:variant.product?.price,
                amount:variant.product?.price * qty});
        }
        updateInvoice(tmpInvoice);
    };
    return <><label>{size.name}: </label>
        <input key={'invoice_item_entry_'+variant.product_id+'_item_' + variant.id + 'ii_item' + size.id}
            value={getQty(size.code)}
            onChange={(e)=>updateQuantity(e.target.value, size.code)}
            type="number" className={'qty_entry'}/>&nbsp;</>;
};
