import React, {useEffect, useState} from "react";
import {Checkbox} from "primereact/checkbox";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";

export const ParameterManager = ({updateSchema,schema}) =>{
    const [show, setShow] = useState(false);
    const [param, setParam] = useState(false);
    const [currSchema,setCurrSchema] = useState({});
    useEffect(()=>{
        if(schema)
            setCurrSchema(schema);
    },[schema])
    const saveObjects = () =>{
        let tmpCurSchema = {...currSchema};
        updateSchema(tmpCurSchema);
    };
    const updateField = (field, field_value) =>{
        let tmpParam = {...param};
        tmpParam[field] = field_value;
        setParam(tmpParam);
    }
    const addParameter = () =>{
        let tmpCurSchema = {...currSchema};
        tmpCurSchema.schema_definition = tmpCurSchema.schema_definition || {};
        tmpCurSchema.schema_definition.paramaters =tmpCurSchema.schema_definition.paramaters || [];
        tmpCurSchema.schema_definition.paramaters.push(param);
        setParam({});
        updateSchema(tmpCurSchema);
    }
    const removeParameter = (param) =>{
        let tmpCurSchema = {...currSchema};
        let item = tmpCurSchema.schema_definition.paramaters.find((i)=>i.parameter_name === param.parameter_name);
        tmpCurSchema.schema_definition.paramaters.splice(tmpCurSchema.schema_definition.paramaters.indexOf(item),1);
        setParam({});
        updateSchema(tmpCurSchema);
    }
    return <>
        <a className={'inline-nav-link'} onClick={()=>setShow(true)}>Parameters</a>
        <div>
            <Popup show={show} showSave={false} save={() => saveObjects()} title={"Parameters"} closeModal={() => setShow(false)}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="">New Parameter</label>
                                        <input type="text" className={"form-control"} onChange={(e)=>updateField('parameter_name',e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <a className={"inline-nav-link"} onClick={()=>addParameter()}>Add</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {currSchema.schema_definition?.paramaters?.map((item, item_index)=>{
                            return <div key={`parameter_${item_index}`}>{item.parameter_name} &nbsp;&nbsp;
                                <a className={"inline-nav-link"} onClick={()=>removeParameter(item)}><i className={"fas fa-trash"} /></a>
                            </div>;
                        })}
                    </div>
                </div>
            </Popup>
        </div>
    </>
}