import React, {Component} from "react";
import {ObjContainer} from "../../services/object_container";
import Modal from "react-bootstrap/Modal";

export class ImageUpload extends Component{
    constructor(props) {
        super(props);
        this.state={show:false,pictures:[],obj:{}};
        this.arrayBuffer = [];
        this.service_manager = ObjContainer.resolve("service_manager");
    }
    onDrop(picture){
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }
    preview(){
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer[this.arrayBuffer.length] = fileReader.result;
        };
        fileReader.readAsArrayBuffer(this.file);
    }
    async upload(){
        let data = Object.assign({}, this.state.obj, {parent_id:this.props.parentId, parent_type:this.props.parentType});
        await this.service_manager.resolve(this.props.parentType).upload_attachments(this.state.pictures,{},JSON.stringify(data));
        await this.service_manager.emit("data-updated",{});
        this.setState((s)=>{
            s.pictures = [];
            s.obj = {};
            s.show = false;
            return s;
        });
    }
    update(field, value){
        this.setState((s)=>{
            s.obj[field] = value;
            return s;
        })
    }
    render(){
        return(
            <div >
                <button className={"btn btn-primary"} onClick={()=>this.setState({show:!this.state.show})}>Add</button>
                <button className={"btn btn-primary"} onClick={async ()=>await this.service_manager.emit("data-updated",{}) }>Refresh</button>
                <div>
                    <Modal dialogClassName={"on-top"} show={this.state.show} onHide={()=>this.setState({show:false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Image Upload</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3"><label>Image Name</label></div>
                                    <div className="col-md-9">
                                        <input type={"text"} className={"form-control"} value={this.state.obj.file_name} onChange={(e)=>this.update('file_name',e.target.value)}/></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3"><label>Image Description</label></div>
                                    <div className="col-md-9">
                                            <textarea className={"form-control"} value={this.state.obj.file_description} onChange={(e)=>this.update('file_description',e.target.value)}>
                                            </textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3"><label>Image File</label></div>
                                    <div className="col-md-9">
                                        <input type={"file"}  onChange={(e) => this.onDrop(e.target.files[0])}/>
                                        {this.state.pictures.map((pic, i)=>{
                                            return <div key={"item_file_" + i}>{pic.name}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row">
                                <div className="col-md-3"><button className={"btn btn-primary"} onClick={()=> this.upload()}>Upload</button></div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}
