import React, {useState} from 'react';
import useNav from '../../../services/hooks/useNav';
 
export const SearchBlock = ({table, setSearch, search}) => {
    const [curSearch, setCurSearch] = useState(search);
    const {navTo} =useNav(); 
    return <>
        <div className="container-fluid valerian-search">
            <div className="row">
                <div className="col-md-8 pull-right" >
                    <button className={'btn btn-primary'} onClick={()=>{navTo(table.new_link);}}>Add</button>
                </div>
                <div className="col-md-4 pull-right">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <a onClick={()=>setSearch(curSearch)}><i className="fa fa-search"></i></a></div>
                        </div>
                        <input type="text" value={curSearch || ''} className={'form-control'} placeholder={'search'} onChange={(e)=>setCurSearch(e.target.value)}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
};