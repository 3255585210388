import React from "react";

export const TagDataViewer = ({rows, cols,onChange})=>{
    return <div>
        <table className={'table table-striped valerian-table'}>
            <thead>
            <tr><th></th>
                {cols.map((col, ind)=>{
                    return <th key={'col-header-' + ind}>{col.orig}</th>;
                })}
            </tr>
            </thead>
            <tbody>
            {rows.map((row, row_ind)=>{
                return <tr key={'data-row-' + row_ind}><td><input type="checkbox" onChange={(e)=>onChange(row, e.target.checked)}/></td>{cols.map((col, col_index)=>{
                    return <td key={'data-row-' + row_ind + '_col_' + col_index}>{row[col.field_code]}</td>;
                })}</tr>;
            })}
            </tbody>
        </table>
    </div>;
}