import React, {useState} from 'react';
import {SortIndicator} from './SortIndicator';
import {InlineFilterFields} from '../TableHeaderMenu';

export const DisplayCol = ({field, filter, setFilter, sorts, setSorts})=>{
    const [displayFilter, setDisplayFilter] = useState(false);
    const onFilter = (range_key, field, value)=>{
        let tmpFilter = {...filter};
        if(field.reference){
            tmpFilter[field.reference_field] = tmpFilter[field.reference_field] || {} ;
            tmpFilter[field.reference_field][range_key] = value;
        }else{
            tmpFilter[field.field_code] = tmpFilter[field.field_code] || {} ;
            tmpFilter[field.field_code][range_key] = value;
        }
        setFilter(tmpFilter);
    };
    return <th>
        <a onClick={()=>setDisplayFilter(!displayFilter)}><i className={'fas fa-filter'} /></a> {field.field_name}
        <SortIndicator field={field} ind={sorts[field.field_code]} sorts={sorts} setSorts={setSorts}/>
        {displayFilter && <InlineFilterFields
            field={field}
            filter={(range_key, field, value,e)=>onFilter(range_key, field, value,e)} /> }
    </th>;
};