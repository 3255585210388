import {useMutation, useQuery, useQueryCache} from 'react-query';
import React,{useEffect,useState} from 'react';
import {ProductDataService} from '../SalesOrders/ProductDataService';
import {PurchaseOrderVariants} from './PurchaseOrderVariants';
import useDelete from '../../../services/hooks/useDelete';
import {PurchaseOrderDataService} from "./PurchaseOrderDataService";
const salesOrderDataService = new ProductDataService();
const purchaseOrderDataService = new PurchaseOrderDataService();
const hookContext = {
    entity:'purchase_order_items',
    expands:{'products':'product_id',product_variations:'product_variant_id'}
};
export function PurchaseOrderProductTableItem({variant,purchaseOrder}){
    const {data:sizes} = useQuery(['sizes',variant.products.size_range],salesOrderDataService.getSizes);
    const [sizeList, setSizeList] = useState([]);
    const [curVariant, setCurVariant] = useState({});
    const queryCache = useQueryCache();
    const [mutateremovePurcahseOrderItems] = useMutation(purchaseOrderDataService.removePurchaseOrderItems,{
        onSuccess: async (res,params) => {
            await queryCache.invalidateQueries('search-purchase_order_items');
        },
    });
    useEffect(() => {
        if (variant.products.id !== null && variant) {
            setCurVariant(variant);
        }else{
            setCurVariant({});
        }
        setSizeList(sizes || []);
    }, [variant,sizes]);

    const deleteVariant = async(varItem)=>{
        await mutateremovePurcahseOrderItems({purchase_order_id: purchaseOrder.id, product_id:varItem.product_id, product_variant_id:varItem.product_variant_id});
    };
    return (<>
        <PurchaseOrderVariants variant={curVariant} purchaseOrder={purchaseOrder} sizes={sizeList}  product={variant.products}  deleteVariant={deleteVariant}/>
    </>);
}