import React, {useRef,useEffect,useState} from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {useRoutePost} from '../../../services/hooks/routes/useRoutePost';
/*
[{
                            caption: 'Region',
                            width: 120,
                            dataField: 'region',
                            area: 'row',
                            sortBySummaryField: 'Total'
                        }, {
                            caption: 'City',
                            dataField: 'city',
                            width: 150,
                            area: 'row'
                        }, {
                            dataField: 'date',
                            dataType: 'date',
                            area: 'column'
                        }, {
                            groupName: 'date',
                            groupInterval: 'month',
                            visible: false
                        }, {
                            caption: 'Total',
                            dataField: 'amount',
                            dataType: 'number',
                            summaryType: 'sum',
                            format: 'currency',
                            area: 'data'
                        }]
 */

export const PrebuildReportsList = ({report_url})=>{
    const [curReportUrl, setCurReportUrl] = useState(undefined);
    const [data, setData] = useState({rows:[],fields:[]});
    const [format, setFormat] = useState({});
    const [dataSource, setDataSource] = useState({});
    const ref = useRef(null);
    const {post} = useRoutePost({entity:'custom_reports',operation:'execute_report'});
    useEffect(()=>{
        if(report_url){
            setCurReportUrl(report_url);
        }
    },[report_url]);
    useEffect(()=>{
        if(curReportUrl){
            post({source_report:'reports/apparel/' + curReportUrl}).then((res)=>{
                if(res){
                    setData(res.result);
                    setFormat(res.report_format);
                }

            });
        }
    },[curReportUrl]);

    const exportData = ()=> {
        ref.current.exportCSV();
    };
    const headerTemplate = (data) =>{
        return <span style={{fontWeight:'700'}}>{data[format.header_field_name]}</span>;
    };

    const footerTemplate = (data, index) =>{
        let results = [];
        (format.footer_fields || []).map((row,i)=>{
            switch (row.field_type) {
                case 'field':
                    results.push( <td key={'footer_label_' + i} colSpan={row.colspan || 1} style={row.style}>{data[row.field_value]}</td>);
                    return;
                case 'text':
                    results.push(  <td key={'footer_label_' + i} colSpan={row.colspan || 1} style={row.style}>{row.field_value}</td>);
                    return;
                case 'calcsum':
                default:
                    results.push( <td key={'footer_label_' + i} colSpan={row.colspan || 1} style={row.style}>{calculateGroupTotal(data[row.group_name],row.group_name,row.field_value)}</td>);
                    
            }
 
        });
        return results;
    };
    const calculateGroupTotal = (ref_code,group_name, column) =>{
        let total = 0;
        for(let row of data.rows) {
            if(row[group_name] === ref_code) {
                total += +row[column];
            }
        }
        return total;
    };
    let header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={() => exportData()} /></div>;
    return <div>
        <DataTable value={data.rows}
            rowGroupMode="subheader"
            sortField={format.sort_field} sortOrder={1}
            groupField={format.group_field}
            rowGroupHeaderTemplate={(data) =>headerTemplate(data)}
            rowGroupFooterTemplate={(data, index)=>footerTemplate(data,index)}
            paginator={true} rows={20}
            header={header} ref={ref}
        >
            {data.fields.filter(i=>i.name !== format.header_field_name).map((field)=>{
                return <Column field={field.name} header={field.name} sortable={field.sortable} filter={field.filter} />;
            })}
        </DataTable>
    </div>;
};
