import {FilterService} from '../../../services/filter_service';
import {ObjContainer} from '../../../services/object_container';
import axios from "axios";

export class ProductDataService {
    async getSizes(key, size_range){
        let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('sizes'));
        let data = await filter_svc.context({size_range:size_range}).andWhere(i=> i.size_range === size_range).orderBy({order_number:'asc'}).toList();
        return data.data;
    }
    async saveSalesOrder(salesOrder){
        return ObjContainer.resolve('service_manager').resolve('orders').upsert(salesOrder.id, salesOrder);
    }
    async saveProductOrder(productOrder){
        return ObjContainer.resolve('service_manager').resolve('order_products').upsert(productOrder.id, productOrder);
    }
    async saveProductOrderItem(productOrderItem){
        return ObjContainer.resolve('service_manager').resolve('order_items').upsert(productOrderItem.id, productOrderItem);
    }
    async saveSalesOrderItems(salesOrderItems){
        return ObjContainer.resolve('service_manager').resolve('order_items').postMulti(salesOrderItems);
    }
    async deleteProductOrder(id){
        return ObjContainer.resolve('service_manager').resolve('order_products').delete(id);
    }

    async saveProductOrderVariant(productOrderVariant){
        return ObjContainer.resolve('service_manager').resolve('order_product_variations').upsert(productOrderVariant.id, productOrderVariant);
    }
    async deleteProductOrderVariant(id){
        return ObjContainer.resolve('service_manager').resolve('order_product_variations').delete(id);
    }

    async getOrder(key,orderId){
        if(orderId){
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('orders'));
            let data = await filter_svc.context({orderId:orderId}).include({'customers':'customer_id'}).andWhere(i=> i.id === orderId).first();
            return data;
        }
        return {};
    }
    async getOrderProducts(key, orderId){
        if(orderId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('order_products'));
            let data = await filter_svc.context({orderId: orderId}).andWhere(i => i.order_id === orderId).include({'products':'product_id'}).toList();
            return data.data;
        }
        return {};
    }
    async getSalesForPurchaseOrder(key, purchaseOrderId){
        if(purchaseOrderId){
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('orders'));
            let data = await filter_svc.context({purchaseOrderId:purchaseOrderId}).andWhere(i => i.purchase_order_id === purchaseOrderId).toList();
            return data.data;
        }
        return {};
    }
    async getProductOrderItems(key, orderVariantId,orderId){
        if(orderVariantId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('order_items'));
            let data = await filter_svc.context({orderVariantId:orderVariantId}).andWhere(i => i.order_variant_id === orderVariantId).andWhere(i => i.order_id === orderId).toList();
            return data.data;
        }
        return {};
    }

    async getOrderProductVariations(key, salesOrderId){
        if(salesOrderId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('order_product_variations'));
            let data = await filter_svc.context({salesOrderId: salesOrderId}).andWhere(i => i.order_id === salesOrderId)
                .include({'product_variations':'product_variant_id','products':'product_id'}).toList();
            return data.data;
        }
        return {};
    }
    async getProductOrderItemsByOrderId(key,orderId){
        if(orderId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('order_items'));
            let data = await filter_svc.context({orderId:orderId}).andWhere(i => i.order_id === orderId).toList();
            return data.data;
        }
        return {};
    }
    async getOrderProductVariationsByOrderId(key, orderId){
        if(orderId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('order_product_variations'));
            let data = await filter_svc.context({orderId: orderId}).andWhere(i => i.order_id === orderId).include({'product_variations':'product_variant_id'}).toList();
            return data.data;
        }
        return {};
    }
}
