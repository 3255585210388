import React, {useState} from 'react';
import {TagDesignList} from './TagDesignList';
import {TagDesignSurface} from './TagDesignSurface';
import {useUpsert} from "../../services/hooks/useUpsert";
const hookTagDesignContext = {
    entity:'tag_manager_tag_designs'
};
export const TagDesigner = () =>{
    const [showDesigner, setShowDesigner] = useState(false);
    const [curDesign, setCurDesign] = useState(false);
    const [upsert] = useUpsert({...hookTagDesignContext,onSave:(result)=>{
            setShowDesigner(false);
    }});
    const editDesign = (design) =>{
        setShowDesigner(true);
        setCurDesign({...design});

    }

    const save = async ()=>{
        await upsert({...curDesign});
        setShowDesigner(false);
    }

    return <div  className={'tag-list-internal-container'}>
        {!showDesigner && <TagDesignList setShowDesigner={setShowDesigner} editDesign={editDesign}/> }
        {showDesigner && <TagDesignSurface setShowDesigner={setShowDesigner} curDesignItem={curDesign} setCurDesignItem={setCurDesign} save={save}/> }
    </div>;
};