import React ,{useState} from 'react';
import {useSearchClear} from '../../services/hooks/useSearch';
import useFieldUpdater from '../../services/hooks/useFieldUpdater';
import {DataView} from '../Support/Import/DataView';
import TabbedView from '../CommonUI/TabbedView/TabbedView';
import './TagGenerator.scss';
import {ObjContainer} from '../../services/object_container';
import {TagListGeneral} from './TagListGeneral';
import {useUpsert} from '../../services/hooks/useUpsert';
const hookContext = {
    entity: 'import_files'
};
const tagDatabaseContext = {
    entity: 'tag_manager_tag_lists'
};
export const TagImporter = ({cancel}) =>{
    const [definition, setDefinition ] = useState({import_table_name:'',tag_list_name:'',tag_table_name:'', lines_to_skip:0});
    const [message, setMessage] = useState('');
    const [allCsvData, setAllCsvData] = useState('');
    const [cols, setCols] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [schemaDisabled, setSchemaDisabled] = useState(true);
    const [csvRows, setCsvRows] = useState([]);
    const [file, setFile] = useState('');
    const [ext, setExt] = useState('');
    const {update} = useFieldUpdater({state:definition, setState:setDefinition});
    const { clear } = useSearchClear({
        ...hookContext
    });
    const [upsert] = useUpsert({...tagDatabaseContext,onSave:async ()=>{
        await clear();
        cancel();
    }});

    const handleFiles = (e)=>{
        let newFile = e.target.files[0];
        let lastDot = newFile.name.lastIndexOf('.');
        let fileName = newFile.name.substring(0, lastDot);
        let cExt = newFile.name.substring(lastDot + 1);
        setExt(cExt);
        setFile(newFile);
        showSchema(newFile,cExt);
        setSchemaDisabled(false);
    };
    const upload = async ()=>{
        setUploadDisabled(true);
        let tmpDef = {...definition};
        let table_name= tmpDef.tag_list_name.toLowerCase().replace(/ +/g,'_');
        tmpDef.import_table_name =table_name;
        tmpDef.tag_table_name = table_name;
        await ObjContainer.resolve('import_service').import_file([file],tmpDef,tmpDef);
        await upsert(tmpDef);
        setMessage('Upload Finished Successfully');
    };
    const showSchema = (newFile,cExt)=>{
        if(cExt === 'csv'){
            let reader = new FileReader();
            reader.onload = ()=> {
                let data = reader.result.split('\n');
                let headers = data[+definition.lines_to_skip].split(',').map((i)=>{
                    return i.replace(/"/g,'');
                });
                setCsvRows(data.slice(1, 11).map((item)=>{
                    let itemObj = {};
                    let varItems = item.split(',');
                    headers.map((head, headIndex)=>{
                        itemObj[head] =  varItems[headIndex].replace(/"/g,'');
                    });
                    return itemObj;
                }));
                setCols(headers);
                let updated  = update('file_name',newFile.name);
                updated.fields =  headers;
                setDefinition(updated);
            };
            reader.readAsText(newFile);
        }
    };
    const tabs = () => {
        return [
            {
                title: 'General',
                view: (
                    <TagListGeneral handleFiles={handleFiles} uploadDisabled={uploadDisabled} update={update} cancel={cancel} upload={upload} definition={definition} />
                ),
                disabled: false,
            },
            {
                title: 'Preview',
                view: (
                    <DataView cols={cols} rows={csvRows} />
                ),
                disabled: schemaDisabled,
            }];
    };
    return (<div className={'container-fluid'}>
        { message && <><br/><div className="alert alert-success" role="alert">
            {message}
        </div></>}
        <div className="row">
            <div className="col-md-12">
                <div className={'tag-design-container'}>
                    <TabbedView tabs={tabs()} />
                </div>
            </div>
        </div>
    </div>);
};