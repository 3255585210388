import React, {useEffect, useState} from 'react';

import {MetaTable} from '../TableDriver/MetaTable/MetaTable';
export const ViewTableDisplay = ({table, parent})=>{
    const [filter, setFilter] = useState({});
    useEffect(()=>{
        let tmpFilter = {};
        tmpFilter[table.parent_field_name] ={eq:parent.id};
        setFilter(tmpFilter);
    },[parent]);
    return (
        <div>
            <MetaTable table={table} filter={filter} parent={parent} column_list={table.view_name} />
        </div>
    );

};
