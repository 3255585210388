import moment from 'moment';
import useBetweenDatesForm from '../../../services/hooks/useBetweenDatesForm';
import Report from '../Report';
import ByCustomerAndProductDetail from './ByCustomerAndProductDetail';

const queryFn = ({ source, fromDate = moment().dayOfYear(1), toDate = moment() }) => {
    return source === 'order'
        ? `select o.customer_id, c.customer_name, o.id id, o.apm_order_id, o.date, o.customer_po, p.style_number, p.id product_id, sum(oi.qty_open) qty, sum(oi.amount_open) amount
	from orders o
		join order_items oi on o.apm_order_id = oi.apm_order_id
        join products p on p.id = COALESCE(oi.product_id, (select id from products prd where prd.style_number = oi.style_number))
		join customers c on o.customer_id = c.id
	where oi.qty_open > 0 and o.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format('YYYY/MM/DD')}'
	group by o.customer_id, c.customer_name, o.id, o.apm_order_id, o.date, o.customer_po, p.style_number, p.id
    order by c.customer_name asc, o.apm_order_id asc, o.id asc;`
        : `select i.customer_id, c.customer_name, i.id id, i.date, i.customer_po, p.style_number, p.id product_id, sum(ii.qty) qty, sum(ii.amount) amount
        from invoices i
            join invoice_items ii on i.id = ii.invoice_id
            join products p on p.id = COALESCE(ii.product_id, (select id from products prd where prd.style_number = ii.style_number))
            join customers c on i.customer_id = c.id
        where ii.qty > 0 and i.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format('YYYY/MM/DD')}'
        group by i.customer_id, c.customer_name, i.id, i.date, i.customer_po, p.style_number, p.id
        order by c.customer_name asc, i.id asc;`;
};

const ByCustomerAndProduct = (source) => {
    const isOrderReport = source === 'order';
    const itemLabel = source.charAt(0).toUpperCase() + source.slice(1);
    return {
        title: isOrderReport ? 'Open Orders Report by Customer and Product' : 'Invoices by Customer and Product',
        queryFormHook: useBetweenDatesForm,
        queryFormText: `Show data from ${source} between`,
        queryFn: ({ fromDate, toDate }) => queryFn({ source, fromDate, toDate }),
        columns: [
            {
                dataField: 'customer_name',
                caption: 'Customer',
                dataType: 'string',
                groupIndex: 0,
                groupCellRender: (data) => {
                    const custItems = data.data.items ?? data.data.collapsedItems;
                    const orderItems = custItems && (custItems[0]?.items ?? custItems[0]?.collapsedItems);
                    const customerId = (orderItems && orderItems[0]?.customer_id) ?? 0;
                    return <a href={`/customers-Viewcustomers#${customerId}`}>{data.value}</a>;
                },
            },
            {
                dataField: isOrderReport ? 'apm_order_id' : 'id',
                caption: `${itemLabel} #`,
                dataType: 'number',
                groupIndex: 1,
                groupCellRender: (data) => {
                    const items = data.data.items ?? data.data.collapsedItems;
                    const rowData = (items && items[0]) ?? {};
                    const href = isOrderReport ? '/orders-Vieworders#' : '/invoices-Viewinvoices#';
                    return (
                        <div>
                            <div style={{ fontWeight: 'bold' }}>
                                <a href={`${href}${rowData.id}`}>
                                    {itemLabel} #{data.value}
                                </a>
                            </div>
                            <div style={{ fontWeight: 'normal' }}>
                                Date: {moment(rowData.date).format('MM/DD/YYYY')}
                            </div>
                            <div style={{ fontWeight: 'normal' }}>Customer P.O.: {rowData.customer_po}</div>
                        </div>
                    );
                },
            },
            {
                dataField: 'style_number',
                caption: 'Product Style',
                dataType: 'string',
                cellRender: (data) => {
                    return <a href={`/products-Viewproducts#${data.data.product_id}`}>{data.value}</a>;
                },
            },
            {
                dataField: 'qty',
                caption: 'Qty',
                dataType: 'number',
            },
            {
                dataField: 'amount',
                caption: 'Amount',
                dataType: 'number',
                format: 'currency',
            },
        ],
        isFilterableByRow: false,
        masterDetailComponent: ({
            data: {
                key: { id: id, apm_order_id: orderId = null, product_id: productId },
            },
        }) => {
            const props = { source, itemId: isOrderReport ? orderId : id, productId };
            return <Report report={ByCustomerAndProductDetail(props)} />;
        },
        masterDetailKeyExpr: isOrderReport ? ['id', 'apm_order_id', 'product_id'] : ['id', 'product_id'],
    };
};

export default ByCustomerAndProduct;
