import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import './Home.scss';
import 'primereact/resources/primereact.min.css';
import {useLoginNav} from '../../services/hooks/useLoginNav';
export const Home = (params)=>{
    const [homeData,setHomeData] = useLoginNav(params);
    return homeData.currentRouteItem;
};
