import React from 'react';

export const ReportAdvancedEdit = ({currReport,connections, updateField, dataSources}) =>{
    return <div className="container-fluid">
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Connection</label>
                    <select className={'form-control'} value={currReport.data_connection_id || ''}
                        onChange={(e)=>updateField('data_connection_id',e.target.value)}>
                        <option value="">--Choose One</option>
                        {connections.map(i=>{
                            return <option key={'connection_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                        })}
                    </select>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Datasource</label>
                    <select className={'form-control'} value={currReport.data_source_item_id || ''}
                        onChange={(e)=>updateField('data_source_item_id',e.target.value)}>
                        <option value="">--Choose One</option>
                        {dataSources.map(i=>{
                            return <option key={'data_source_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
    </div>;

};