import {InputTypes} from "../../CommonUI/FormBuilder/FormBuilderInputComponents";


export const CustomReportFormFieldNames = {
    categoryId: 'category_id',
    title: 'title',
    dataSourceConnectionId: 'data_source_connection_id',
    dataSourceItemId: 'data_source_item_id',
    customReportsTables: 'custom_reports_tables',
    customReportsTablesFields: 'custom_reports_tables_fields',
};

export const CustomReportForm = [
    {
        inputType: InputTypes.selectWithAdd,
        fieldName: CustomReportFormFieldNames.categoryId,
        label: 'Category',
        value: '',
        options: [
            { value: 'Customers' },
            { value: 'Inventory' },
            { value: 'Sales' },
        ],
        placeholder: 'Select a category for the report.',
    },
    {
        inputType: InputTypes.text,
        fieldName: CustomReportFormFieldNames.title,
        label: 'Title',
        value: '',
        placeholder: 'Enter a title for the report.',
    },
    {
        inputType: InputTypes.select,
        fieldName: CustomReportFormFieldNames.dataSourceConnectionId,
        label: 'Data Connection',
        value: '',
        options: [],
        placeholder: 'Select a data connection for the report.',
    },
    {
        inputType: InputTypes.select,
        fieldName: CustomReportFormFieldNames.dataSourceItemId,
        label: 'Data Set',
        value: '',
        options: [],
        placeholder: 'Select a data set for the report.'
    },
    {
        inputType: InputTypes.select,
        fieldName: CustomReportFormFieldNames.customReportsTables,
        label: 'Primary Table',
        value: '',
        options: [],
        placeholder: 'Select the primary table for the report.'
    },
    {
        inputType: InputTypes.checkboxGroup,
        fieldName: CustomReportFormFieldNames.customReportsTablesFields,
        label: 'Columns',
        value: '',
        options: [],
    },
];
