import React, { Component, useState } from 'react';
import { Button } from 'primereact/button';
import { ObjContainer } from '../../services/object_container';
import useNav from '../../services/hooks/useNav';

export const ChangePassword = () => {
    const [loginData, setLoginData] = useState({ user_obj: {}, message: '', success_message: '' });
    const authenticator = ObjContainer.resolve('authentication_service');
    const { getHash } = useNav();
    const _onChange = (e) => {
        let tmpLoginData = { ...loginData };
        tmpLoginData.user_obj[e.target.name] = e.target.value;
        setLoginData(tmpLoginData);
    };
    const reset = async () => {
        let key = getHash();
        if (!key) {
            let tmpLoginData = { ...loginData };
            tmpLoginData.message = 'Cannot complete request.';
            setLoginData(tmpLoginData);
            return;
        }
        let tmpLoginData = { ...loginData };
        tmpLoginData.key = key;
        let result = await authenticator.resetPassword(tmpLoginData);
        console.log('results', result);
        if (result.data.message === 'success') {
            tmpLoginData.success_message = 'Password Updated';
            setLoginData(tmpLoginData);
        } else {
            tmpLoginData.message = 'Update failed';
            setLoginData(tmpLoginData);
        }
    };
    return (
        <div className='login-body'>
            <div className='card login-panel p-fluid'>
                <div className='login-panel-content'>
                    <div className='p-grid'>
                        <div className='p-col-12' style={{ textAlign: 'center' }}>
                            <img src={'/valerian-logo.png'} style={{ width: '150px' }} />
                        </div>
                        <div className='p-col-12' style={{ textAlign: 'center' }}>
                            <p>Please create a new password below.</p>
                        </div>
                        {loginData.message && (
                            <div className='p-col-12 alert alert-danger' role='alert'>
                                {loginData.message}
                            </div>
                        )}
                        {loginData.success_message && (
                            <div className='p-col-12 alert alert-success' role='alert'>
                                {loginData.success_message} <br />
                                <a href={'/'}>Login</a>
                            </div>
                        )}
                        <div className='p-col-12' style={{ textAlign: 'left' }}>
                            <label className='login-label'>Password</label>
                            <div className='login-input'>
                                <input
                                    className={'form-control'}
                                    type={'password'}
                                    placeholder={'Password'}
                                    name={'password'}
                                    onChange={(e) => _onChange(e)}
                                />
                            </div>
                        </div>
                        <div className='p-col-12' style={{ textAlign: 'left' }}>
                            <label className='login-label'>Confirm</label>
                            <div className='login-input'>
                                <input
                                    className={'form-control'}
                                    type={'password'}
                                    placeholder={'Confirm Password'}
                                    name={'confirm_password'}
                                    onChange={(e) => _onChange(e)}
                                />
                            </div>
                        </div>
                        <div className='p-col-12 button-pane'>
                            <Button label='Reset' onClick={async () => await reset()} />
                            &nbsp;
                        </div>
                        <div className='p-col-12 link-pane' style={{ textAlign: 'center' }}>
                            <a href='/'>Login</a> | <a href='/ResetPassword'>Forget Password?</a>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
