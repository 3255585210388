import React, { Component, PureComponent } from 'react';
import * as ReactHighcharts from 'react-highcharts';

function getColors(dataset) {
    var colorPalette = [];
    switch(dataset)
    {
        case "purple":
            colorPalette = ["#3c1072","#987EBF","#6a3da0","#49138b","#220940","#8B68B5","#592895","#7A53AA","#43127f"];
            break;

        case "blue":
            colorPalette = ["#1E6583","#87B7CB","#3B93B1","#217092","#113849","#73ABC2","#3787A8","#5F9FB9","#247BA0"];
            break;
        case "red":
            colorPalette = ["#BA4D58","#FF9FA9","#FF7684","#D15663","#743037","#FF919C","#FF6978","#FF8490","#E8606E"];
            break;
        default:
            colorPalette = ["#49138B", "#ff6978","#247BA0","#b2ede8","#F79256","#93ACB5","#C0F5FA","#F7B267","#aaaaaa"];

    }
    return colorPalette;
}
const RADIAN = Math.PI / 180;

export  const RechartLine =({data,value_field, label_field, dom_min, dom_max})=>{

        if(!value_field) return <div>Value Field Not Set</div>;
        if(!label_field) return <div>Label Field Not Set</div>;

        let l_data = (data || []).map((item,i)=>{
            return {name:item[label_field.field_code],value:(+item[value_field.field_code]) }
        }).sort((a,b)=>{
            if(a.value  < b.value) return -1;
            if(a.value > b.value) return 1;
            return 0;
        });
        let max = 0;
        let min = 0;
        if (data.length > 0){
            max = dom_max || l_data.reduce( (a, b) =>{
                return Math.max(a.value, b.value);
            });
            min = dom_min || l_data.reduce( (a, b)  =>{
                return Math.min(a.value, b.value);
            });
        }
        let valData = (data || []).map((item,i)=>{
            return {name:item[label_field.field_code],y:+(item[value_field.field_code]) };
        }).slice(0,10);
        let config = {
            chart:{height:275},
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: valData.map(i => i.name)
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                },
                point: {
                    valueSuffix: ''
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}'
                    }
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span >{point.name}</span>: <b>{point.y}</b><br/>'
            },

            series: [
                {
                    name: "Browsers",
                    data: valData}]};
        return (
            <>
                <ReactHighcharts config={config}  />
            </>);
}
export class LabelAsPoint extends React.Component {
    onClick = () => {
        const { index, key, payload } = this.props;
        this.props.onClick(index);
    };
    render() {
        const { x, y } = this.props;

        return (
            <circle
                className={`dot`}
                onClick={this.onClick}
                cx={x || 0}
                cy={y || 0}
                r={8}
                fill="transparent"/>
        );
    }
}
export const RechartBar  = (props)=> {
    if (!props.value_field) return <div>Value Field Not Set</div>;
    if (!props.label_field) return <div>Label Field Not Set</div>;

    let data = (props.data || []).map((item, i) => {
        return {name: item[props.label_field.field_code], value: (+item[props.value_field.field_code])}
    });
    let max = 0;
    let min = 0;
    if (data.length > 0){
        max = props.dom_max || data.reduce( (a, b) => {
                return Math.max(a.value, b.value);
            });
        min = props.dom_min || data.reduce( (a, b)=>  {
            return Math.min(a.value, b.value);
        });
    }
    let valData = (data || []).map((item,i)=>{
        return {name:item[props.label_field.field_code],y:+(item[props.value_field.field_code]) };
    }).slice(0,10);
    let config = {chart:
            {height:275,type: 'column' },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: data.map(i => i.name)
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: ''
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
        },

        series: [
            {
                name: "Browsers",
                colorByPoint: true,
                data: data.map(i=>i.value)
            }]
    };
    return <ReactHighcharts config={config}  />;
}

export function RechartsPie({onClick,data,data_type,color, label_field, value_field}){

        if(!label_field){
            return <div>Set Label</div>;
        }
        if(!value_field){
            return <div>Set Values</div>;
        }

        let valData = (data || []).map((item,i)=>{
            return {name:item[label_field.field_code],y:+(item[value_field.field_code]) }}).slice(0,10);

        let config = {chart: {height:275,type: 'pie' },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                },
                point: {
                    valueSuffix: ''
                }
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}'
                    }
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
            },

            series: [
                {
                    name: "Browsers",
                    colorByPoint: true,
                    data: valData}]};
        return <ReactHighcharts config={config}  />;
}

export class ReportDataFormatter{
    static  getFormatter(type){
        let formatter = (value, name, props) =>{ return value };

        if(type === 'string_money' || type === 'decimal_money' ){
            formatter = (value, name, props) =>{
                if(isNaN(value)) return value.toString();
                return '$' + (value * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
        }
        return formatter;
    }
}
