import AxiosClient from './AxiosClient';
import {useAuth} from './authTokenContext';
import {useQuery} from 'react-query';
import constants from './constants';
const { api } = constants;
const { basePath, options } = api;

const GetData = async (queryKey,context, params) => {

    if(!params.report_name)
    {return {};}
    const getParams = (param_data)=>{
        return Object.keys(param_data).map((param)=>{
            return `${param}=${param_data[param]}`;
        }).join('&');
    };
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity,
    } = context;
    try {
        const pathname = `${basePath}report_view/${params.environment}/${params.report_name}?${getParams(params.params)}`;
        const results = await AxiosClient.get(
            pathname,
            config
        );
        const { data } = results;
        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble searching ${entity}.`);
    }
};
export const useReportConfig = (context) =>{
    const auth = useAuth();
    return useQuery(
        [
            `report-config-${context.entity}`, 
            {
                ...auth,
                ...context
            }
        ],
        (args, item) => GetData(args,item, context)
    );
};