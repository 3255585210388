import React from 'react';
import { TableMasterComponent } from '../../components/TableDriver/TableMasterComponent';
import EventEmitter from 'events';
import { FormMasterComponent } from '../../components/FormDriver/FormMasterComponent';
import { IntegrationForm } from '../../components/ApiManager/ApiEditor/IntegrationForm';
import { HomePageComponent } from '../../components/HomePage/HomePageComponent';
import { DashboardManagerComponent, Dashboards } from '../../components/Dashboards/DashboardManagerComponent';
import { ApiForm } from '../../components/ApiManager/ApiEditor/ApiForm';
import { ImportManager } from '../../components/Support/Import/ImportManager';
import { PrebuildReportsList } from '../../components/Reports/PrebuiltReports/PrebuildReportsList';
import { QueryBuilder } from '../../components/Support/Import/QueryBuilder';
import { SystemConfigComponent } from '../../components/SystemConfig/SystemConfigComponent';
import { ProductVariationManager } from '../../components/Products/ProductVariationManager';
import { PurchaseOrderManager } from '../../components/Products/PurchaseOrderManager';
import { Diagram } from '../../components/Database/Diagram';
import { DataSources } from '../../components/Dashboards/DataSourceBuilder';
import { AnalysisManager } from '../../components/Dashboards/AnalysisManager';
import { DataConnectionManager } from '../../components/Dashboards/DataConnectionManager';
import { DataConnectionSchemaImport } from '../../components/Dashboards/DataConnectionSchemaImport';
import { DataSetList } from '../../components/Dashboards/DataSetList';
import { Users } from '../../components/SystemConfig/UserManagement/Users';
import { EditUser } from '../../components/SystemConfig/UserManagement/EditUser';
import { ChangePassword } from '../../components/Login/ChangePassword';
import { ResetPassword } from '../../components/Login/ResetPassword';
import { LoginPage } from '../../components/Login/LoginPage';
import { SiteBody } from '../../components/HomePage/SiteBody';
import { EmbeddedReports } from '../../components/Dashboards/Embedded/EmbeddedReports';
import { EmbeddedReportsList } from '../../components/Dashboards/Embedded/EmbeddedReportsList';
import { MetaTable } from '../../components/TableDriver/MetaTable/MetaTable';
import { InboundIntegrationForm } from '../../components/SystemConfig/InboundIntegrations/InboundIntegrationForm';
import { CurrentInventoryLevels } from '../../components/Reports/InventoryReports/CurrentInventoryLevels';
import { InvoiceSummaryReport } from '../../components/Reports/SalesReports/InvoiceSummaryReport';
import { SimplifiedSiteBody } from '../../components/HomePage/SimplifiedSiteBody';
import { DashboardReportList } from '../../components/Dashboards/ReportDesigner/DashboardReportList';
import { DashboardReportDesigner } from '../../components/Dashboards/ReportDesigner/DashboardReportDesigner';
import ReportsRouter from '../../components/Reports/ReportsRouter';
import {ReportDashboard} from '../../components/Reports/ReportDashboard';

import {TagGenerator} from '../../components/TagManager/TagGenerator';

export class NavRouter extends EventEmitter {
    constructor(config = {}) {
        super();
        this.config = config;
        this.routes = {
            Login: { component: <LoginPage />, isPublic: true },
            Home: { component: <HomePageComponent /> },
            ChangePassword: { component: <ChangePassword />, isPublic: true },
            ResetPassword: { component: <ResetPassword />, isPublic: true },
            CurrentInventoryLevel: { component: <CurrentInventoryLevels />, isPublic: true },
            InvoiceSummaryReport: { component: <InvoiceSummaryReport />, isPublic: true },
            product_variations_manager: { component: <ProductVariationManager /> },
            diagram: { component: <Diagram /> },
            purchase_order_manager: { component: <PurchaseOrderManager /> },
            DashboardManager: { component: <DashboardManagerComponent /> },
            DashboardReportList: { component: <DashboardReportList /> },
            DashboardReportDesigner: { component: <DashboardReportDesigner /> },
            EmbeddedReports: { component: <EmbeddedReports /> },
            EmbeddedReportsList: { component: <EmbeddedReportsList /> },
            ReportsList: { component: <PrebuildReportsList /> },
            Reports: {simplified:true, component: <ReportDashboard /> },
            TagGenerator: { component: <TagGenerator /> },
            reports: {
                componentFn: (paramsArray) => <ReportsRouter category={paramsArray[0]} />,
            },
            InventoryReports: { component: <PrebuildReportsList /> },
            Dashboards: { component: <Dashboards /> },
            Analysis: { component: <AnalysisManager /> },
            DataConnections: { component: <DataConnectionManager /> },
            DataConnectionSchemaImport: { component: <DataConnectionSchemaImport /> },
            DataSources: { component: <DataSetList /> },
            PgDataSources: { component: <DataSources /> },
            'System-Tables': {
                component: (
                    <div key={'system-tables'}>
                        <TableMasterComponent
                            config={{
                                table_name: 'tables',
                                title: 'Tables',
                                routes: [{ route_key: 'add', route_dest: 'System-NewTable' }],
                            }}
                            table_def={{}}
                        />
                    </div>
                ),
            },
            'System-NewTable': {
                component: (
                    <div key={'system-tables-new'}>
                        <FormMasterComponent
                            config={{
                                table_name: 'tables',
                                title: 'New Table',
                                routes: [{ route_key: 'list', route_dest: 'System-Tables' }],
                            }}
                        />
                    </div>
                ),
            },
            'System-Config': {
                component: (
                    <div key={'system-fields-new'}>
                        <SystemConfigComponent />
                    </div>
                ),
            },
            'System-Fields': {
                component: (
                    <div key={'system-fields'}>
                        <TableMasterComponent
                            config={{
                                table_name: 'fields',
                                title: 'Fields',
                                routes: [{ route_key: 'add', route_dest: 'System-NewField' }],
                            }}
                            table_def={{}}
                        />
                    </div>
                ),
            },
            'System-NewField': {
                component: (
                    <div key={'system-fields-new'}>
                        <FormMasterComponent
                            config={{
                                table_name: 'fields',
                                title: 'New Field',
                                routes: [{ route_key: 'list', route_dest: 'System-Fields' }],
                            }}
                        />
                    </div>
                ),
            },
            'fields-Newfields': {
                component: (
                    <div key={'system-fields-new'}>
                        <FormMasterComponent
                            config={{
                                table_name: 'fields',
                                title: 'New Field',
                                routes: [{ route_key: 'list', route_dest: 'System-Fields' }],
                            }}
                        />
                    </div>
                ),
            },
            'Query-System': {
                component: (
                    <div key={'system-external-api'}>
                        <QueryBuilder />
                    </div>
                ),
            },
            'Import-Manager': {
                component: (
                    <div key={'system-external-api'}>
                        <ImportManager />
                    </div>
                ),
            },
            'System-Apis': {
                component: (
                    <div key={'system-external-api'}>
                        <TableMasterComponent
                            config={{
                                table_name: 'external_apis',
                                title: 'External APIs',
                                routes: [{ route_key: 'add', route_dest: 'System-NewApi' }],
                            }}
                            table_def={{}}
                        />
                    </div>
                ),
            },
            'System-NewApi': {
                component: (
                    <div key={'system-external-api-new'}>
                        <ApiForm
                            config={{
                                table_name: 'fields',
                                title: 'New Field',
                                routes: [{ route_key: 'list', route_dest: 'System-Fields' }],
                            }}
                        />
                    </div>
                ),
            },
            'System-Integrations': {
                component: (
                    <div key={'system-external-integration'}>
                        <TableMasterComponent
                            config={{
                                table_name: 'integration_apis',
                                title: 'Integrations',
                                routes: [{ route_key: 'add', route_dest: 'System-NewIntegration' }],
                            }}
                            table_def={{}}
                        />
                    </div>
                ),
            },
            'System-NewIntegration': {
                component: (
                    <div key={'system-external-integration-new'}>
                        <IntegrationForm
                            config={{
                                table_name: 'integration_apis',
                                title: 'New Integration',
                                routes: [{ route_key: 'list', route_dest: 'System-Integration' }],
                            }}
                        />
                    </div>
                ),
            },
            'System-Users': { component: <Users /> },
            'inbound-integrations': { component: <MetaTable table={{ table_name: 'inbound_integrations' }} /> },
            InboundIntegrationForm: { component: <InboundIntegrationForm /> },
            'System-AddUsers': { component: <EditUser /> },
        };
    }

    addComponents(components) {
        for (let component in components) {
            this.routes[component] = components[component];
        }
        return this;
    }
    addReports(reports) {
        let reportNav = this.navMenu.find((i) => i.link === 'ReportsList');
        reports.forEach((report) => {
            reportNav.children.push({ display: report.report_title, link: report.report_name });
        });
        return this;
    }
    navTo(route, state, e) {
        if (e) {
            e.stopPropagation();
        }
        if (state && state.route_params && state.route_params.filter && state.route_params.filter.id) {
            window.history.pushState(
                { ...state, path: route + '#' + state.route_params.filter.id },
                route + '#' + state.route_params.filter.id,
                '/' + route + '#' + state.route_params.filter.id
            );
        } else {
            window.history.pushState({ ...state, path: window.location.pathname }, route, `/${route}`);
        }
        this.emit('nav', route);
        return false;
    }
    navLocal(route, state, e) {
        if (e) {
            e.stopPropagation();
        }
        if (state && (state.id || state.key)) {
            const hash = state.id ?? state.key;
            window.history.pushState({ ...state, path: `${route}#${hash}` }, `${route}#${hash}`, `/${route}#${hash}`);
        } else {
            window.history.pushState({ ...state, path: window.location.pathname }, route, `/${route}`);
        }
        this.emit('nav', route);
        return false;
    }
    getBackItem(event) {
        let my_state = this.getState();
        if (my_state) {
            let routeData = this.routes[my_state.path.replace('/', '')];
            if (routeData.simplified) {
                return this.simplifiedWrapper(routeData?.component, this.routeDate || {});
            }
            return this.loggedInWrapper(routeData?.component, this.routeDate || {});
        }
        return null;
    }
    getState() {
        let hash = window.location.hash;
        const idOrKey = window.location.pathname.includes('reports')
            ? { key: hash.replace('#', '') }
            : { id: hash.replace('#', '') };
        let params = Object.assign(
            {},
            window.history.state,
            {
                parent_id: hash.replace('#', ''),
                path: (window.location.pathname || '').replace('/', '') || '/',
            } || { path: '/' },
            idOrKey
        );
        if (hash) {
            params = { ...params, route_params: { filter: { ...idOrKey } } };
        }
        return params;
    }
    getMenuList() {
        return this.navMenu;
    }
    publicWrapper(component) {
        return <div className='layout-wrapper menu-layout-static'>{component}</div>;
    }
    simplifiedWrapper(component, data) {
        return (
            <div className='layout-wrapper menu-layout-static logged-in'>
                <SimplifiedSiteBody {...data}>{component}</SimplifiedSiteBody>
            </div>
        );
    }
    loggedInWrapper(component, data) {
        return (
            <div className='layout-wrapper menu-layout-static logged-in'>
                <SiteBody {...data}>{component}</SiteBody>
            </div>
        );
    }
    getRouteContent(route, authenticated, data) {
        const urlParams = route.split('/');
        const primaryRoute = urlParams[0];

        this.routeDate = data || {};
        const routeData = this.routes[primaryRoute];

        if (!routeData) {
            if (authenticated) {
                return this.simplifiedWrapper(<HomePageComponent />, data);
            }
            return this.publicWrapper(<LoginPage />);
        }
        if (!authenticated && !routeData.isPublic) {
            return this.publicWrapper(<LoginPage />);
        }

        const routeContent = routeData.component ?? routeData.componentFn(urlParams.slice(1));
        if (!authenticated && routeData.isPublic) {
            return this.publicWrapper(routeContent);
        }
        if (routeData.simplified) {
            return this.simplifiedWrapper(routeContent, data);
        }
        return this.loggedInWrapper(routeContent, data);
    }
    getApiRoute() {}
}
