import React, {useState} from "react";

export const TableDataViewer = ({table, data,schema, updateParams})=>{

    if(!data || !data.column_data )
        return <div>Select an Object</div>;


    return <div style={{height:"500px", overflow:"auto"}}>

        <table className={"table"}>
            <thead>
            <tr>{data.column_data.filter(i=>i.enabled).map((col, index)=>{
                return <th>{col.name}</th>
            })}</tr>
            </thead>
            <tbody>
            {!data || data.result.length === 0 && <div>No Data</div>}
            {(data && data.result) && data.result.map((dat,dat_index)=>{
                return <tr key={"data_row_" + dat_index}>{data.column_data.map((col, col_index)=>{
                    return <td key={"data_row_" + dat_index + "_dat_item_" + col_index}>{dat[col.name]}</td>
                })}</tr>
            })}
            </tbody>
        </table>
    </div>
}