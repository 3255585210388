import { useState, useEffect } from 'react';
import useNav from '../../services/hooks/useNav';
import useTableData from '../../services/hooks/useTableData';
import useLoginData from '../../services/hooks/useLoginData';
import useRawQuery from '../../services/hooks/useRawQuery';

import classes from './ReportDashboard.module.scss';

import ReportData from './ReportData';
import Button from '../CommonUI/Button';
import ActionRow from '../CommonUI/ActionRow';
import Loader from '../CommonUI/Loader';

export const ReportDashboard = () => {
    const [ categories, , , isCustomReportDataFetched ] = useTableData({
        hookContext: {
            entity: 'custom_reports_categories'
        },
        mapCallback: row => row.name
    })

    const loggedInUserId = useLoginData().user.id
    const { rows: allowedCustomReportIds } = useRawQuery(`select cr.id from custom_reports cr left outer join custom_reports_users cru on cru.report_id = cr.id where cru.user_id = ${loggedInUserId} or cru.user_id IS NULL`);
    useEffect(() => {
    }, [ allowedCustomReportIds ])
    const [ customReports, _, customReportsReturned, clear] = useTableData({
        hookContext: { 
            entity: 'custom_reports',
            filter_in: { id: allowedCustomReportIds?.map(row => row.id) }
         },
        mapCallback: (row) => {
            return {
                id: row.id,
                category: row.custom_reports_categories?.name ?? 'Uncategorized',
                name: row.title,
                nav: `reports/custom#${row.id}`
            }
        }
    });

    const { navTo } = useNav();

    const [ categoryBlocks, setCategoryBlocks ] = useState(null);
    useEffect(() => {
        if(categories && customReportsReturned) {
            const blockCategories = [
                ...categories,
                'Uncategorized'
            ]
            const blocks = blockCategories.map(key => {
                const embeddedReports = ('Uncategorized' === key
                    ? Object.keys(ReportData).filter(objKey => !categories.map(cat => cat.toLowerCase()).includes(objKey.toLowerCase()))
                    : [key.toLowerCase()]
                ).flatMap(rptKey => {
                    return Object.keys(ReportData).includes(rptKey) ? ReportData[rptKey].reports : null
                }).filter(rpt => rpt !== null)
                const reports = [
                    ...embeddedReports,
                    ...customReports.filter(report => {
                        return key === 'Uncategorized' 
                            ? !categories.map(cat => cat.toLowerCase()).includes(report.category.toLowerCase())
                            : report.category.toLowerCase() === key.toLowerCase()
                    }).map(report => {
                        return {
                            ...report,
                            isEditable: true
                        }
                    })
                ].sort((a, b) => {
                    const aName = a.name?.toLowerCase();
                    const bName = b.name?.toLowerCase();
                    return aName > bName ? 1 : aName < bName ? -1 : 0;
                });

                if(reports.length === 0) return null
        
                const reportLinks = reports.map((report, idx) => {
                    return (
                        <div key={report.name}>
                            <div className={classes.reportRowWrapper}>
                                <a className={'inline-nav-link'} onClick={() => navTo(report.nav)}>
                                    {report.name}
                                </a>
                                {report.isEditable && <i className={['fas fa-pencil-alt', classes.editIcon].join(' ')} onClick={() => navTo(`reports/edit/${report.id}`)}></i>}
                            </div>
                            {idx !== reports.length && <hr />}
                        </div>
                    );
                });
        
                const categoryDescription = key === 'Uncategorized' 
                    ? 'The following reports do not belong to a known category.' 
                    : Object.keys(ReportData).includes(key.toLowerCase())
                        ? ReportData[key.toLowerCase()].description
                        : ''
                return (
                    <div key={key} className={classes.reportsListContainer}>
                        <div className={classes.reportListHeader}>{key[0].toUpperCase() + key.slice(1)}</div>
                        <div className={classes.reportListDescription}>{categoryDescription}</div>
                        <hr />
                        <div className={classes.reportListBody}>
                            <div className={classes.reportsTitle}>Reports</div>
                            {reportLinks}
                        </div>
                    </div>
                );
            }).filter(block => block !== null)    

            setCategoryBlocks(blocks);
        } else {
            setCategoryBlocks(null);
        }
    }, [categories, customReports]);

    return <>
        <ActionRow rightButtons={ [ <Button key={'add'} label={'Add Custom Report'} action={() => navTo('reports/add')}/> ] } />
        { isCustomReportDataFetched
            ? <div className={classes.reportDashboardContent}>{categoryBlocks}</div>
            : <Loader />
        }
    </>
};
