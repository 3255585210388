import {useState, useRef, useEffect} from 'react';
import classes from './FormBuilder.module.scss';

import FormBuilderInput from './FormBuilderInputComponents';
import { InputTypes } from './FormBuilderInputComponents';

const FormBuilder = ({ formArray, onChange, isEditing = true, removeUpperBorder = false }) => {

    const [curFormArray, setCurFormArray] = useState(formArray);

    useEffect(()=>{
        setCurFormArray(formArray);
    },[formArray]);


    const tableBody = curFormArray?.filter((obj) => obj.fieldName !== 'key')
        .map((obj, idx, arr) => {
            const isCheckbox = obj.inputType === InputTypes.checkbox;
            return (
                <div className="col-md-6" key={obj.fieldName}>
                    <div className="form-group">
                        <label className={"form-label " + classes.formLabel}>{obj.label}</label>
                        <div className={isEditing ? classes.tableDataEditMode : classes.tableData}>
                            {isEditing ? (
                                <FormBuilderInput
                                    props={{
                                        ...obj,
                                        onChange:(name, value) =>{
                                            onChange(name, value)
                                        }
                                    }}
                                />
                            ) : isCheckbox ? (
                                obj.value ? (
                                    <i className={['fas fa-check-circle', classes.check].join(' ')}></i>
                                ) : (
                                    <i className={['fas fa-times-circle', classes.times].join(' ')}></i>
                                )
                            ) : (
                                obj.value
                            )}
                        </div>
                    </div>
                </div>
            );
        });

    return <div className={removeUpperBorder ? classes.detailTableNoBorder : classes.detailTable}>
        <div className={"row"}>{tableBody}</div>
    </div>
};

export default FormBuilder;