import {ProductDataService} from './ProductDataService';
import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import {ProductSizeEntry} from './ProductSizeEntry';
import {ObjContainer} from '../../../services/object_container';

const productDataService = new ProductDataService();
export const VariantItem = ({variant, deleteItem})=>{
    const [curSizes, setCurSizes] = useState([]);
    const [curVariant, setCurVariant] = useState({});
    const {data:sizes} = useQuery(['sizes_'+curVariant?.products?.size_range,curVariant?.products?.size_range],productDataService.getSizes, {});
    const queryCache = useQueryCache();
    let [orderItems, setOrderItems] = useState([]);
    const {data:order_items}  =  useQuery(['order_items',curVariant.id],productDataService.getProductOrderItems);

    useEffect(()=>{
        if(variant)
        {setCurVariant(variant);}
        if(sizes && order_items)
        {
            setCurSizes(sizes);
            let oItems =sizes.map((size)=>{
                if(!order_items.find(i=>i.size === size.code))
                {return {order_id: curVariant.order_id, size:size.code, order_variant_id: curVariant.id,qty:0,product_id:curVariant.product_id,
                    product_variant_id:curVariant.product_variant_id} ;}
                return order_items.find(i=>i.size === size.code);
            });
            setOrderItems(oItems);
        }
    },[variant,sizes,order_items]);


    const [addMutate] = useMutation(productDataService.saveProductOrderItem,{
        onSuccess: async (res,params) => {
            await queryCache.invalidateQueries(['order_items',curVariant.id,curVariant.order_id]);
        },
    });

    const updateOrderItem  = async (orderItem)=>{
        let order_i = orderItems.find(i=>i.size === orderItem.size && i.order_variant_id === curVariant.id);
        order_i = Object.assign(order_i, orderItem);
        setOrderItems(i=>[...orderItems]);
        //await addMutate(orderItem);
    };
    return(<tr>
        <td style={{width:'50px'}}>

            <a className={'inline-nav-link'} onClick={()=>deleteItem(curVariant)}><i className={'fa fa-trash-alt'} /></a>
        </td>
        <td >
            {curVariant.products?.style_number} {curVariant.products?.description} - {curVariant.product_variations?.variation_code} {curVariant.product_variations?.variation_name}
        </td>
        <td>
            {(orderItems || []).map((orderItem,ind)=>{
                return (<ProductSizeEntry key={'variation-' + curVariant.product_variations?.variation_code + '-size-entry-' + orderItem.size + '-' + ind}
                    orderItem={orderItem} variant={curVariant} updateOrderItem={updateOrderItem}/>);
            })}
        </td>

    </tr>); 
};
