import React, {Component} from 'react';
import {FormInput} from "../FormDriver/FormComponents";
import {ObjContainer} from "../../services/object_container";
import {Popup} from "../FormDriver/FormBuilderSupportComponent";


export class InvoiceItem extends Component{
    constructor(props={}){
        super();
        this.state = {
            obj:  props.item_base || {attr_2:'', style_number:''},
            invoice_items: (props.invoice_items || []),
            styles:[],
            prints:[],
            sizes:[],
            ops:{
                onChange: async (field, value)=>{
                    this.setState(s=>{
                        s.obj[field.field_code] = value;
                        return s;
                    });
                    await this.refresh();
                },
                onClick:async (field, value)=>{
                    console.log("onclick", field, value);
                    this.setState(s=>{
                        s.obj[field.field_code] = value;
                        return s;
                    });
                    await this.refresh_prints();
                },
                onDefine: ()=>{
                }},
            show:props.show,
        };
        this.service_manager = ObjContainer.resolve("service_manager");
    }
    async componentDidMount() {
        await this.refresh();
    }
    async refresh_prints(){
        let print_numbers = await this.service_manager.resolve("print_numbers").search({filter:{code:{eq:this.state.obj['attr_2']}, style_code:{eq:this.state.obj['style_number']}}});
        this.setState({ prints:print_numbers.data.data});
    }
    async refresh(){

        let style_codes =   await this.service_manager.resolve("style_codes").search({filter:{code:{eq:this.state.obj['style_number']}}});
        let sizes =         await this.service_manager.resolve("sizes").search({filter:{}});
        let res = sizes.data.data;
        res.sort((a, b) =>{
            if (a.order_number > b.order_number) return 1;
            if (b.order_number > a.order_number) return -1;
            return 0;
        });
        this.setState({styles:style_codes.data.data, sizes:res});
    }
    async save(){
        await this.service_manager.resolve("invoice_items").postMulti(this.state.invoice_items);
        this.setState({show:false});
        if(this.props.refresh)
            await this.props.refresh();
    }
    mapMulti(size, value){
        let invoice_items = this.state.invoice_items || [];
        if(value > 0){
            let invoice_item = invoice_items.find(i=>i.size === size.code);
            if(invoice_item){
                invoice_item.qty = value;
            }else{
                invoice_items.push(Object.assign({}, this.state.obj, {size:size.code, qty:value, invoice_id:this.props.parentId}))
            }
        }else{
            let invoice_item = invoice_items.find(i=>i.size === size.code);
            invoice_items.splice(invoice_items.indexOf(invoice_item), 1)
        }
        this.setState({invoice_items});
    }
    render(){
        return <div style={{marginBottom:"3px"}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <button className={"btn btn-primary"}
                                onClick={()=>{this.setState({show:true})}}>New</button>
                    </div>
                </div>
                <Popup show={this.state.show}
                       title={"Invoice Item"}
                       closeModal={()=>{this.setState({show:false})}}
                       showSave={false}
                       save={() =>{} }>
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Style Number</label>
                                <FormInput  field={this.props.schema_def.fields.find(i=>i.field_code === 'style_number')}
                                            value={this.state.obj['style_number']}
                                            title={"Style Code"}
                                            items={this.state.styles.map(i=>{
                                                return {description:`${i.code} - ${i.description}`, code:i.code}
                                            })}
                                            {...this.state.ops}/>
                            </div>
                            <div className="col-md-6">
                                <label>Print Code</label>
                                <FormInput  field={this.props.schema_def.fields.find(i=>i.field_code === 'attr_2')}
                                            value={ this.state.obj['attr_2']}
                                            title={"Print Number"}
                                            items={this.state.prints.map(i=>{
                                                return {description:`${i.code} ${i.description ? ' -  ' + i.description :""}`, code:i.code}
                                            })}
                                            {...this.state.ops}/>
                            </div>
                        </div>
                        {(this.state.obj['style_number'] && this.state.obj['attr_2'] ) &&
                            <div>
                                <div className={"row"}>
                                    <div className="col-md-12">
                                        <div style={{display:"flex", flexDirection:"row"}}>
                                            {this.state.sizes.map((size, index)=>{
                                                return <div style={{flex:"1 75px"}} key={"invoice_item_" + index}>
                                                    <label>{size.name}</label><br/>
                                                    <input type={"number"} className={"form-control"}
                                                           onChange={(e)=>this.mapMulti(size, e.target.value)} value={(this.state.invoice_items.find(i=>i.size === size.code) || {qty:0}).qty}/>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <button className={"btn btn-primary"} onClick={async ()=> await this.save()}>Save</button>
                                    </div>
                                </div>
                            </div> }
                    </div>
                </Popup>
            </div>
        </div>;
    }
}


