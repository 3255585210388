import {ObjContainer} from '../../services/object_container';
import FaCog from 'react-icons/lib/fa/cog';
import React, { Component } from 'react';
import {Reports, ReportView} from './ReportBuilder';
import {Popup,FlexPopup} from '../FormDriver/FormBuilderSupportComponent';
import {Tree} from 'primereact/tree';
import {ContextMenu} from 'primereact/contextmenu';
import {TabPanel, TabView} from 'primereact/tabview';


import Editor, {monaco} from '@monaco-editor/react';

export class DataSources extends React.Component{
    constructor(props={}){
        super();
        this.service_manager = ObjContainer.resolve('service_manager');
        this.data_manager = ObjContainer.resolve('data_manager');
        this.state = {...props, data:[],data_sources:[],collections: [],tables:[], data_source: this.initialDataSource(),
            update_query: (query)=>{
                this.setState(s=>{
                    s.data_source.query = query;
                });
            },
            refresh:async ()=>{
                let result = await this.executeFilter(this.state.data_source);
                this.setState((s)=>{
                    s.data_source.source_table = 'query';

                    return s;
                });
            },
            reset: ()=>{
                this.setState(s=>{
                    s.data_source = this.initialDataSource();
                    s.data = [];
                    s.added_columns =[];
                    return s;
                });
            },
            updateList:async (field, item, table)=>{

                this.setState(s=>{
                    s.data_source.source_table = table.table_name;
                    s.data_source.schema_key = table.schema_key;
                    s.data_source.query = undefined;
                    s.data_source[field] = s.data_source[field] || [];
                    s.data_source[field].push(item);
                    return s;
                });
                await this.executeFilter(this.state.data_source);
            },update: async (item_param, state_field, field_param, value_param)=>{
                await this.update(item_param, state_field, field_param, value_param);
            },
            changeBase:(field, value)=>{
                this.setState(i=>{
                    i.data_source[field] = value;
                    return i;
                });
            },
            save:async()=>await this._save(this.state.data_source),
            selectCollection: (schema)=> this.setState((s)=>{
                s.schema = schema;
                s.data_source.source_table = schema.table_name;
                s.data_source.schema_key = schema.schema_key;
                s.data_source.table_id = schema._id;
                s.step1 = false;
                s.step2 = true;
                return s;
            }),
            cancel: ()=>{
                this.setState({showPivotWizard:false});
            },
            goForward:()=>{
                this.setState({step1:false,step2:true});
            },
            goBackward:()=>{
                this.setState({step1:true,step2:false});
            }};
    }
    initialDataSource(){
        return {columns:[],data_source_name:'', filter_values:[],rows:[],source_table:'',values:''};
    }
    async componentDidMount(){
        await this.loadData();
        let tables = await this.service_manager.resolve('tables').get({filter:{}});
        let data = await this.data_manager.getData('/schema_router/db_schema',{});
        let db_data = data.tables.rows.reduce((arr, table, index)=>{
            let found_table = tables.data.data.find(i=>i.table_name === table.tablename);
            if(found_table){
                arr.push({
                    'key': index,
                    'label': `${ found_table ? (found_table.table_title || table.tablename) : table.tablename}`,
                    'data': found_table,
                    'icon': 'pi pi-fw pi-table',
                    'children': data.columns.rows.filter(i=>i.table_name === table.tablename).map((col, col_index)=> {
                        let found_column = `${col.column_name} (${col.data_type} ${col.character_maximum_length ? ('(' + col.character_maximum_length + ')') : ''})`;
                        let found_column_data= {field_code:col.column_name, field_name:col.column_name, data_type:this.mapType(col.data_type)};
                        if(found_table){
                            let search_column = found_table.fields.find(i=>i.field_code === col.column_name);
                            if(search_column){
                                found_column = search_column.field_name;
                                found_column_data = search_column;
                            }

                        }
                        return {
                            'key': index + '-' + col_index,
                            'label': found_column,
                            'column':true,
                            'data': {found_table, col: found_column_data},
                            'icon': 'pi pi-fw pi-cog'
                        };

                    })});
            }else{
                let found_table = {table_name: table.tablename};
                arr.push({
                    'key': index,
                    'label': `${table.tablename}`,
                    'data': found_table,
                    'icon': 'pi pi-fw pi-table',
                    'children': data.columns.rows.filter(i=>i.table_name === table.tablename).map((col, col_index)=> {
                        let found_column = `${col.column_name} (${col.data_type} ${col.character_maximum_length ? ('(' + col.character_maximum_length + ')') : ''})`;
                        let found_column_data= {field_code:col.column_name, field_name:col.column_name, data_type:this.mapType(col.data_type)};

                        return {
                            'key': index + '-' + col_index,
                            'label': found_column,
                            'column':true,
                            'data': {found_table, col: found_column_data},
                            'icon': 'pi pi-fw pi-cog'
                        };

                    })});
            }
            return  arr;
        },[]);
        this.setState({db_data,tables:tables.data.data});
    }
    mapType(db_data_type){
        switch (db_data_type) {
            case 'timestamp with timezone':
                return 'datetime';
            case 'varchar':
                return 'string';
        }
    }
    async update(item_param, state_field, field_param, value_param){
        this.setState((s)=>{
            s.data_source[state_field].find(i=>i.field_name === item_param.field_name)[field_param] = value_param;
            return s;
        });
        await this.executeFilter(this.state.data_source);
    }
    async executeFilter(data_source){
        let temp_data = await this.data_manager.postData('/analytics/test_dataset',data_source);
        this.setState(s=>{
            s.data = temp_data.data;
            s.added_columns = temp_data.added_columns;
            s.data_source.fields = data_source.added_columns;
            s.data_source.added_columns = data_source.added_columns;
            return s;
        });
        return temp_data.raw_result;
    }
    async loadData(){
        let data_sources = await this.data_manager.getData('/data-api/data_sources',{});
        let data = await this.data_manager.getWithSearch('/api/tables',{expand:['fields'],page_size:500});
        this.setState({data_sources:data_sources.data,collections: data.data.data, showPivotWizard:false});
    }
    async _showDataSource(data_source){
        let schema = this.state.collections.find(i => data_source.schema_key === i.schema_key || data_source.table_id === i._id);
        await this.executeFilter(data_source);
        this.setState({schema:schema,data_source:data_source, showPivotWizard:true, step2:true, step1:false});
    }
    async deleteDataSource(data_source){
        await this.data_manager.deleteData(`/data-api/data_sources/${data_source._id}`);
        await this.loadData();
    }
    addSource(){
        this.setState({data:[],showPivotWizard: !this.state.showPivotWizard,data_source:this.initialDataSource(),step1:true, step2:false});
    }
    async _save(data_source){
        if(data_source._id){
            await this.data_manager.putData('/data-api/data_sources/' + data_source._id,data_source);
        }else{
            await this.data_manager.postData('/data-api/data_sources', data_source);
        }
        await this.loadData();
    }
    render(){
        return (<div>

            {!this.state.showPivotWizard &&
            <div>
                <button className={'btn btn-primary'} onClick={() => this.addSource()}>New Dataset</button>
                <table className={'table'}>
                    <thead>
                        <tr><th style={{width:'150px'}} /><th>Dataset Name</th><th>Table Name</th></tr>
                    </thead>
                    <tbody>
                        {this.state.data_sources.map((source,i)=>{
                            return <tr key={'data-sources-' + i}>
                                <td><a  href={'#'} onClick={async  ()=> await this._showDataSource(source)}>Edit</a> | <a  href={'#'} onClick={()=>this.deleteDataSource(source)} >Delete</a></td>
                                <td>{source.data_source_name}</td>
                                <td>{source.source_table}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>}
            {this.state.showPivotWizard && <AddDataSource {...this.state} complete={async ()=>await this.loadData()}/>}
        </div>);
    }
}

export class AddDataSource extends Component{
    constructor(){
        super();
        this.service_manager = ObjContainer.resolve('service_manager');
        this.data_manager = ObjContainer.resolve('data_manager');
        this.state ={expandedKeys:{},
            droppedNodes:[],
            nodes:[],
            selected_node:{},
            menu: [
                {
                    label: 'Select Data',
                    icon: 'pi pi-search',
                    command: () => {
                        //this.setState({query_text:`select * from ${this.state.db_data.find(i=>i.key === this.state.selectedNodeKey).data} limit 10;`})
                    }
                }]
        };
        this.dragItem = {};
    }
    onDrop(e, field){
        let item = Object.assign({},this.dragItem.data);
        this.props.updateList(field,item.col, item.found_table);
        this.setState(s=>{
            return s;
        });

    }
    onDragOver(e){
        e.preventDefault();
    }
    onDragStart(e,node){

        this.dragItem = node; 
    }
    nodeTemplate(node) {
        if (node.column) {
            return (
                <div draggable onClick={(e)=>this.onDragStart(e,node)} onMouseOver={(e)=>this.onDragStart(e,node)} onDragStart={(e)=>this.onDragStart(e,node)}>{node.label}</div>
            );
        }
        
        return (
            <b>{node.label}</b>
        );
        
    }
    render(){
        return (<div>
            <h3>Dataset Builder</h3>
            <button className={'btn btn-primary'} onClick={async ()=> await this.props.save()}>Save</button>&nbsp;
            <button className={'btn btn-primary'} onClick={async ()=> await this.props.reset()}>Reset</button>&nbsp;
            <button className={'btn btn-primary'} onClick={async ()=> await this.props.refresh()}>Refresh</button>&nbsp;
            <button className={'btn btn-primary'} onClick={async ()=> await this.props.cancel()}>Cancel</button>&nbsp;

            <div style={{display:'flex',height:'75vh', flexDirection:'row'}}>
                <div style={{display:'flex',width:'305px'}}>
                    <ContextMenu model={this.state.menu} ref={el => this.cm = el} />
                    <Tree value={this.props.db_data}
                        expandedKeys={this.state.expandedKeys}
                        onToggle={e => this.setState({expandedKeys: e.value})}
                        onContextMenuSelectionChange={event => this.setState({selectedNodeKey: event.value})}
                        onContextMenu={event => this.cm.show(event.originalEvent)}
                        style={{overflowY:'auto', width:'100%'}}
                        nodeTemplate={(node) => this.nodeTemplate(node)}
                        onSelect={(e)=>this.dragItem = e.node}
                    />
                </div>
                <div style={{display:'flex', flexDirection:'column',flexGrow:'1'}}>
                    <div style={{ padding:'5px'}}>
                        <div>
                            <div className="col-md-2"><label>Dataset Name</label></div>
                            <div className="col-md-4">
                                <input type={'text'} className={'form-control'}
                                    onChange={(e)=>this.props.changeBase('data_source_name', e.target.value)} value={this.props.data_source.data_source_name}/>
                            </div>
                        </div>
                        <hr/>
                        <div style={{display:'flex'}}>
                            <div style={{flexGrow:'1'}}>
                                <TabView >
                                    <TabPanel header={'Builder'}>
                                        <div style={{height:'24vh', overflowY:'hidden'}}>
                                            <div>
                                                <p>Rows</p>
                                                <div className={'dropable'} style={{minHeight:'25px', border:'1px solid silver', display:'flex', flexDirection:'column'}}  onDrop={(e)=>this.onDrop(e,'rows')}  onDragOver={(e) => this.onDragOver(e)}>
                                                    {(this.props.data_source.rows || []).map((item, index)=>{
                                                        return (
                                                            <div key={'dropped_node_' + index}>
                                                                <FieldSettings  data={item}
                                                                    onChange={async (item_param, field_param, value_param)=> await this.props.update(item_param,'rows',  field_param, value_param)}/>
                                                            </div>);
                                                    })}
                                                </div>
                                                <p>Values</p>
                                                <div className={'dropable'} style={{minHeight:'25px', border:'1px solid silver', display:'flex', flexDirection:'column'}}  onDrop={(e)=>this.onDrop(e,'values')}  onDragOver={(e) => this.onDragOver(e)}>
                                                    {(this.props.data_source.values || []).map((item, index)=>{
                                                        return (
                                                            <div key={'dropped_node_' + index}>
                                                                <FieldSettings  data={item}
                                                                    onChange={async (item_param, field_param, value_param)=> await this.props.update(item_param,'values',  field_param, value_param)}/>
                                                            </div>);
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header={'Raw'}>
                                        <div style={{height:'24vh', overflowY:'auto'}}>
                                            <QuerySystem update_query={(query)=>this.props.update_query(query)} tables={this.props.tables} query={this.props.data_source.query}/>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ReportView report={{visual_type:'table',
                            rows:this.props.rows || [],
                            data:this.props.data|| [],
                            values:this.props.values || [],
                            added_columns:this.props.added_columns|| [],
                            filters:this.props.filters|| []}}
                        added_columns={this.props.added_columns || []}
                        data={this.props.data|| []}
                        />
                    </div>
                </div>
            </div>
        </div>);
    }
}
let monaco_init = null;
export function QuerySystem({tables,query, height,update_query}){
    let editorRef = null;
    const [queryText,setQueryText] = React.useState(query||'');
    const editorMounted = (_, editor) =>{
        editorRef = editor;
        editorRef.onDidChangeModelContent(ev => {
            update_query(editorRef.getValue());
            setQueryText(editorRef.getValue());
        });
    };
    if(!monaco_init){
        monaco.init().then(monaco => {
            monaco.languages.registerCompletionItemProvider('sql', {
                provideCompletionItems: (model, position) =>{
                    let word = model.getWordUntilPosition(position);
                    let range = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endColumn: word.endColumn
                    };
                    let options = (tables || []).reduce((arr,table)=>{
                        if(!arr.find(i=>i.label === table.table_name)){
                            arr.push({
                                label: table.table_name,
                                kind: monaco.languages.CompletionItemKind.Function,
                                documentation:  table.table_name,
                                insertText:  table.table_name,
                                range: range
                            });
                        }
                        return arr;
                    },[]);
                    return {
                        suggestions: options
                    };
                }
            });
        });
        monaco_init = true;
    }
    return (<div>
        <Editor height={height || '25vh'} language="sql" value={queryText} editorDidMount={(_,editor) => editorMounted(_,editor)}/>
    </div>
    );
}
export function FieldSettings({onChange,data}){
    const [show, setShow] = React.useState(false);
    const getIcon = () =>{
        switch(data.data_type){
            case 'number':
                return 'fa fa-hashtag';
            case 'money':
            case 'decimal_money':
                return 'fa fa-dollar-sign';
            case 'int':
                return 'fa fa-hashtag';
            case 'string':
                return 'fa fa-tag';
            default:
                return 'fa fa-tag';
        }
    };
    return (<div style={{display:'flex'}}>
        <div className="col-md-10">
            <i className={getIcon()} /> &nbsp;&nbsp;
            {data.field_name}</div>
        <div className="col-md-2">
            <a href={'#'} onClick={()=> setShow(true)}><FaCog/></a>
        </div>
        <FlexPopup show={show} title={'Settings'} closeModal={()=>setShow(false)}>
            <div className={'container'} style={{width: '500px', height:'500px'}}>
                <div className={'row'}>
                    <div className="col-md-3">
                        <label>Data Type</label>
                    </div>
                    <div className="col-md-9">
                        <select className={'form-control'} onChange={async (e)=> await onChange(data, 'format_type', e.target.value)} value={data.format_type}>
                            <option>--Select One</option>
                            <option value={'number'}>Number</option>
                            <option value={'decimal_money'}>Money</option>
                            <option value={'string_money'}>Money with $0.00</option>
                            <option value={'text'}>Text</option>
                        </select>
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col-md-3">
                        <label>Aggregate</label>
                    </div>
                    <div className="col-md-9">
                        <select className={'form-control'} onChange={async (e)=> await onChange(data, 'agg_type', e.target.value)} value={data.agg_type}>
                            <option>--Select One</option>
                            <option value={'sum'}>Sum</option>
                            <option value={'avg'}>Average</option>
                            <option value={'max'}>Max</option>
                            <option value={'min'}>Min</option>
                            <option value={'value_by_year'}>Year</option>
                            <option value={'value_by_quarter'}>Quarter</option>
                            <option value={'value_by_month'}>Month</option>
                            <option value={'value_by_day'}>Day</option>
                        </select>
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col-md-3">
                        <label>Order By</label>
                    </div>
                    <div className="col-md-9">
                        <select className={'form-control'} onChange={async (e)=> await onChange(data, 'order_by', e.target.value)} value={data.order_by}>
                            <option>--Select One</option>
                            <option value={'count_asc'}>Count Ascending</option>
                            <option value={'count_desc'}>Count Descending</option>
                            <option value={'value_asc'}>Value Ascending</option>
                            <option value={'value_desc'}>Value Descending</option>
                        </select>
                    </div>
                </div>
            </div>
        </FlexPopup>
    </div>);
}
