import {useMutation, useQuery} from 'react-query';
import React from 'react';
import {ProductDataService} from './ProductDataService';
import {VariantItem} from './VariantItem';
const productDataService = new ProductDataService();
export function ProductVariants({variants,setVariants}){
    const [deleteMutate] = useMutation(productDataService.deleteProductOrderVariant);

    let deleteItem = async (variant)=>{
        variants.splice(variants.indexOf(variant),1);
        await deleteMutate(variant.id);
        setVariants(variants);
    };
    return <>{variants.map((variant, variant_i)=>{
        return (<VariantItem  key={'variant_' + variant_i} deleteItem={deleteItem} variant={variant} />);
    })}</>;
}
