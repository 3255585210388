import React, {useEffect, useState} from 'react';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import useNav from '../../../services/hooks/useNav';
import useAdd from '../../../services/hooks/useAdd';
import {Popup} from '../../FormDriver/FormBuilderSupportComponent';
const datasetHookContext = {
    entity: 'data_source_items',
};
const hookContext = {
    entity: 'data_source_connections'
};
export const NewDataset = ({show,connection})=>{
    const [showNew, setShowNew] = useState(false);
    const [dataset,setDataset] = useState({data_source_name:''});
    const {update} = useFieldUpdater({state:dataset,setState:setDataset});
    const {navTo} = useNav(hookContext);
    const [addMutate]  = useAdd({...datasetHookContext,onSave: (data)=>{
        navTo('DataConnectionSchemaImport',{id:data.id});
    }}); 
    useEffect(()=>{
        setShowNew(show);
    },[show]);
    const saveDataset = async ()=>{
        let aset = {...dataset};
        aset.data_connection_id = connection.id;
        await addMutate(aset);
    };
    return <Popup title={'New Dataset'} show={showNew} save={()=>saveDataset()} closeModal={()=>setShowNew(false)} showSave={true}>
        <div className="col-md-12">
            <div className="form-group">
                <label>Dataset Name</label>
                <input className={'form-control'} type="text" value={dataset.data_source_name} onChange={(e)=>update('data_source_name', e.target.value)}/>
            </div>
        </div>
    </Popup>;
};