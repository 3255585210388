import {ObjContainer, ScopedObjContainer} from './object_container';
import {TableService} from './table_service';
import {NavRouter} from './core/nav_router';
import {GenericTableService} from './generic_table_service';
import EventEmitter from 'events';
import React from 'react';
import {TableMasterComponent} from '../components/TableDriver/TableMasterComponent';
import {FormMasterComponent} from '../components/FormDriver/FormMasterComponent';
import {CalendarMasterComponent} from '../components/CalendarDriver/CalendarMasterComponent';
import {AnalyticsManager} from '../components/Analytics/AnalyticsManager';
import {ViewComponent} from '../components/ViewDriver/ViewComponent';
import {DataManager} from './data_manager';
import {DashboardViewer} from '../components/Dashboards/DashboardManagerComponent';
import {PrebuildReportsList} from '../components/Reports/PrebuiltReports/PrebuildReportsList';

import {InvoiceSearch} from '../components/TableDriver/InvoiceSearch';
import {SalesOrderManager} from '../components/Products/SalesOrderManager';
import {PurchaseOrderManager} from '../components/Products/PurchaseOrderManager';
import {InvoiceManager} from '../components/Products/Invoices/InvoiceManager';
import {InvoiceViewer} from '../components/Products/Invoices/InvoiceViewer';
import {NavWrapper} from './core/nav_wrapper';
import {MetaTable} from '../components/TableDriver/MetaTable/MetaTable';
import {ProductList} from '../components/Products/Products/ProductList';
import {CustomerList} from '../components/Customers/CustomerList/CustomerList';
import {InventoryManager} from '../components/Products/InventoryManager/InventoryManager';
import {InventoryTable} from '../components/TableDriver/InventoryTable';
 

export class FormEmitter extends EventEmitter {}
export class AnalyticsEmitter extends EventEmitter {}
export class TableSchemaService {
    constructor() {
        this.tables = [];
    }
    setTables(tables) {
        this.tables = tables;
    }
    getSchema(schema_name) {
        return this.tables.find((i) => i.table_name === schema_name);
    }
}
export class ServiceManager extends EventEmitter{
    constructor(config={}){
        super();
        this.config = config;
        this.config.root_url = this.config.root_url || '';
        this.service_obj_container = new ScopedObjContainer(this.config);
        this.service_obj_container.register('tables', new TableService(this.config));
        this.service_obj_container.register('aggregates', new GenericTableService(Object.assign({},{table_name:'aggregates'},this.config)));
        this.service_obj_container.register('fields', new GenericTableService(Object.assign({},{table_name:'fields'},this.config)));
        this.service_obj_container.register('users', new GenericTableService(Object.assign({},{table_name:'users'},this.config)));
        this.service_obj_container.register('external_apis', new GenericTableService(Object.assign({},{table_name:'external_apis'},this.config)));
        this.service_obj_container.register('system_config', new GenericTableService(Object.assign({},{table_name:'system_config'},this.config)));
        this.service_obj_container.register('integration_apis', new GenericTableService(Object.assign({},{table_name:'integration_apis'},this.config)));
        this.service_obj_container.register('reports', new GenericTableService(Object.assign({},{table_name:'reports'},this.config)));
        this.service_obj_container.register('roles', new GenericTableService(Object.assign({},{table_name:'roles'},this.config)));
        this.service_obj_container.register('permissions', new GenericTableService(Object.assign({},{table_name:'permissions'},this.config)));
        this.service_obj_container.register('generic', new GenericTableService(Object.assign({},{table_name:'generic'},this.config)));
        this.service_obj_container.register('scoped_nav', new NavWrapper(Object.assign({},{table_name:'generic'},this.config)));
        this.service_obj_container.register('form_emitter', new FormEmitter());
        this.service_obj_container.register('analytics', new AnalyticsEmitter());
        this.data_manager = new DataManager(this.config);
        this.nav_router = new NavRouter(this.config);

    }
    async initialize(){
        let tables_data = await this.service_obj_container.resolve('tables').get({});
        let dashboards = await this.data_manager.getData('/api/v_dashboards',{});
        let reports = await this.data_manager.getData('/api/custom_reports/report_list',{}) || {};
        this.tables = tables_data.data.data || [];
        this.tables.forEach((table)=>{
            this.service_obj_container.register(table.table_name, new GenericTableService({table_name:table.table_name,...this.config}));
            (table.child_collection || []).forEach((child)=>{
                this.service_obj_container.register(child.table_name, new GenericTableService({table_name:child.table_name,...this.config}));
            });
        });
        let retComponent = {};
        this.tables.forEach((table)=>{
            retComponent[`${table.table_name}-${table.table_name}`] =  {component:(
                <div >
                    {(!table.display_type || (table.display_type  && table.display_type.value === 'table_display')) &&
                    <TableMasterComponent config={{table_name:`${table.table_name}`,title:`${table.system_label || table.table_name}`,routes:[{route_key:'add',route_dest:`${table.table_name}-New${table.table_name}`}] }}
                        sort={table.sort}
                        table_def={table}
                    />}
                    {(table.display_type  && table.display_type.value === 'calendar_display') &&
                    <CalendarMasterComponent/>}
                    {(table.display_type  && table.display_type.value === 'inventory') &&
                    <InventoryTable/>}
                    {(table.display_type  && table.display_type.value === 'invoice') &&
                    <InvoiceSearch table_def={table}/>}
                    {(table.display_type  && table.display_type.value === 'meta') &&
                    <MetaTable table={table}/>}
                    {(table.display_type  && table.display_type.value === 'product_list') &&
                    <ProductList table={table}/>}
                    {(table.display_type  && table.display_type.value === 'customer') &&
                    <CustomerList />}

                </div>)};
            (table.views || []).forEach((view)=>{
                retComponent[`${view.view_name}-${view.view_name}`] =  {component:(
                    <div>
                        {(!view.view_type || view.view_type  === 'table_display') &&
                        <TableMasterComponent
                            config={{table_name:`${table.table_name}`,title:`${view.view_label}`,
                                routes:[{route_key:'add',route_dest:`${table.table_name}-New${table.table_name}`}] }}
                            filter={view.filter}
                            sort={view.sort} table_def={Object.assign({},table,view)}  />}

                        {view.view_type && view.view_type === 'inventory' && (
                            <InventoryManager />
                        )}
                    </div>
                ),
                };
            });
            retComponent[`${table.table_name}-analytics`] = {
                component: (
                    <div>
                        <AnalyticsManager table={table} />
                    </div>
                ),
            };

            retComponent[`${table.table_name}-New${table.table_name}`] = {
                component: (
                    <div>
                        {table.edit_type === 'sales_order' && <SalesOrderManager />}
                        {table.edit_type === 'purchase_order' && <PurchaseOrderManager />}
                        {table.view_type === 'invoices' && <InvoiceManager />}
                        {!table.edit_type && (
                            <FormMasterComponent
                                config={{
                                    table_name: `${table.table_name}`,
                                    title: `New ${table.system_label || table.table_name}`,
                                    routes: [
                                        {
                                            route_key: 'list',
                                            route_dest: `${table.table_name}-${table.table_name}`,
                                        },
                                        {
                                            route_key: 'view',
                                            route_dest: `${table.table_name}-View${table.table_name}`,
                                        },
                                    ],
                                }}
                            />
                        )}
                    </div>
                ),
            };

            retComponent[`${table.table_name}-View${table.table_name}`] = {
                component: (
                    <div >
                        {table.view_type === 'sales_order' && <SalesOrderManager />}
                        {table.view_type === 'purchase_order' && <PurchaseOrderManager />}
                        {table.view_type === 'invoices' && <InvoiceViewer />}
                        {!table.view_type && (
                            <ViewComponent
                                config={{
                                    table_name: `${table.table_name}`,
                                    title: `New ${table.system_label || table.table_name}`,
                                    routes: [
                                        {
                                            route_key: 'list',
                                            route_dest: `${table.table_name}-${table.table_name}`,
                                        },
                                        {
                                            route_key: 'new',
                                            route_dest: `${table.table_name}-New${table.table_name}`,
                                        },
                                    ],
                                }}
                            />
                        )}
                    </div>
                ),
            };
        });

        dashboards.data.data.forEach((dashboard) => {
            retComponent[`${dashboard.dashboard_title}`] = {
                component: (
                    <div className={'h-100'}>
                        <DashboardViewer dashboard={dashboard} />
                    </div>
                ),
            };
        });
        reports.reports.forEach((report) => {
            retComponent[`${report.report_name}`] = {
                component: (
                    <div className={'h-100'}>
                        <PrebuildReportsList report_url={report.report_url} />
                    </div>
                ),
            };
        });

        ObjContainer.resolve('nav').addComponents(retComponent);
    }
    schema(type_name) {
        return this.tables.find((i) => i.table_name === type_name);
    }
    resolve(type_name) {
        return this.service_obj_container.resolve(type_name);
    }
    hasType(type_name) {
        return this.service_obj_container.resolve(type_name) !== undefined;
    }
    register(event, handler) {
        this.addListener(event, handler);
    }
    remove(event) {
        this.removeAllListeners(event);
    }
}
