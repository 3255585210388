import React, { useEffect, useState} from 'react';
import { Dropdown } from 'primereact/dropdown';
import useGetById from '../../../services/hooks/useGetById';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import useNav from '../../../services/hooks/useNav';
import {Checkbox} from 'primereact/checkbox';
import {useUpsert} from '../../../services/hooks/useUpsert';

const integrations = [
    {label:'Athena',value:'athena'},
    {label:'Epic',value:'epic'},
    {label:'Apparel Magic',value:'apparel_magic'},
    {label:'Square',value:'square'},
    {label:'Shopify',value:'shopify'},
    {label:'Yelp',value:'yelp'},
    {label:'Facebook',value:'facebook'}];
const dataSourceHookContext = {
    entity: 'integration_apis'
};
export const IntegrationForm =()=>{
    const [curInt, setCurInt] = useState({});
    const {update} = useFieldUpdater({state:curInt,setState:setCurInt});
    const {getState} = useNav();
    const {id:curIntId} =getState();
    const {data:integrationData} = useGetById({...dataSourceHookContext,id:curIntId});
    const [savedMsg, setSavedMsg] = useState('');
    const [upsertIntegration] = useUpsert({...dataSourceHookContext,onSave:(result)=>{
        setSavedMsg('Integration Saved');
        setTimeout(()=>{
            setSavedMsg('');
        },2000);
    }});
    useEffect(()=>{
        if(integrationData){
            setCurInt(integrationData.data);
        }
    },[integrationData]);

    const onSave = async ()=>{
        await upsertIntegration(curInt);
    };
    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <h2>API Integration</h2>
                </div>
            </div>
            { savedMsg && <><br/><div className="alert alert-success" role="alert">
                {savedMsg}
            </div></>}
            <div className="row">
                <div className="col-md-4">
                    <label>Last Ran</label> &nbsp;
                    <div style={{fontWeight:'700'}}>{curInt['last_run']}</div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col md-6">
                    <label >Integration Type</label><br/>
                    <Dropdown value={curInt['integration_type'] || ''}
                        filterBy="label"
                        options={integrations} onChange={(e) => update('integration_type',e.value)} placeholder="Select an Integration Type"/>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className={'control-label'}>Integration Tag</label>
                        <input type={'text'} className={'form-control'} onChange={(e) => update('integrationTag',e.target.value)} value={curInt['integrationTag']}/>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <div className="form-group">
                        <Checkbox onChange={e => update('paused',e.checked)} checked={curInt['paused']} />
                        <label htmlFor="cb2" className="p-checkbox-label">Paused</label>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className={'control-label'}>API Url</label>
                        <input type={'text'} className={'form-control'} name={'baseUrl'} onChange={(e) => update('baseUrl', e.target.value)} value={curInt['baseUrl']}/>
                    </div>
                </div>

            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <label className={'control-label'}>API Token</label>
                    <input type={'text'} className={'form-control'} name={'token'} onChange={(e) => update('token', e.target.value)}  value={curInt['token']}/>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className={'control-label'}>API Key</label>
                        <input type={'text'} className={'form-control'} name={'key'} onChange={(e) => update('key', e.target.value)} value={curInt['key']}/>
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className={'control-label'}>API Secret</label>
                        <input type={'text'} className={'form-control'} name={'secret'} onChange={(e) => update('secret', e.target.value)} value={curInt['secret']}/>
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className="col-md-3">
                    <button className={'btn btn-primary'} onClick={() => onSave()}>Save</button>&nbsp;
                </div>
            </div>
        </div>);
};
