import React,{useState,useEffect} from 'react';
import useSearch from "../../../services/hooks/useSearch";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import useNav from "../../../services/hooks/useNav";
import useDelete from "../../../services/hooks/useDelete";
import {useMutation, useQuery, useQueryCache} from "react-query";
import {PurchaseOrderDataService} from "./PurchaseOrderDataService";
const salesOrderContext = {
  entity:"orders"
};
const salesOrderItemsContext = {
    entity:"order_items",
    expands:{'products':'product_id','product_variations':'product_variant_id'}
};
const purchaseOrderDataService = new PurchaseOrderDataService();
export const SalesOrderTab = ({purchaseOrder})=>{
    const [salesOrderItems,setSalesOrderItems] = React.useState([]);
    const {navTo} = useNav();
    const queryCache = useQueryCache();
    const {status,data:order_items_data,error}  =  useQuery(['open_sales_order_items'],purchaseOrderDataService.getSalesOrderItemData);
    const [mutateSalesOrderItems] = useMutation(purchaseOrderDataService.assignSalesOrderItem,{
        onSuccess: async (res,params) => {
            await queryCache.invalidateQueries('open_sales_order_items');
        },
    });
    const [mutateremoveSalesOrderItems] = useMutation(purchaseOrderDataService.removeSalesOrderItem,{
        onSuccess: async (res,params) => {
            await queryCache.invalidateQueries('open_sales_order_items');
        },
    });
    useEffect(() => {
        if (order_items_data  && order_items_data.data) {
            let items = order_items_data.data.reduce((arr,item)=>{
              let src = arr.find(i=>i.product_id === item.product_id && i.product_variant_id === item.product_variant_id)
              if(!src)
                  arr.push({...item, sizes:[item]})
              else
                  src.sizes.push(item);
              return arr;
            },[]);
            setSalesOrderItems(items);
        } else {
            setSalesOrderItems([]);
        }
    }, [order_items_data]);
    const {data:order_data} = useSearch({...salesOrderContext})
    const product_field = (entry) => {
        return `${entry.style_number} - ${entry.description}`;
    };
    const variant_field = (entry) => {
        return `${entry.variation_code} - ${entry.variation_name}`;
    };
    const addToPO = async (entry) =>{
        await mutateSalesOrderItems({order_entry: entry, purchase_order_id:purchaseOrder.id});
    }
    const removeFromPO = async (entry) =>{
        await mutateremoveSalesOrderItems({order_entry: entry, purchase_order_id:purchaseOrder.id});
    }
    const actions_field = (entry)  =>{
        if(entry.included ==='Y'){
            return <div><i className={"fa fa-check text-success"} /> &nbsp;&nbsp;<a onClick={ async ()=> await removeFromPO(entry)}>Remove</a></div>
        }
        return <div><a onClick={ async ()=> await addToPO(entry)}>Add</a></div>
    }
    const qtys= (entry) =>{
        return <span>{entry.sizes.map((size,ind)=>{
            return <span key={"sizes "+ entry.id + "_" + ind }>{size.size}:{size.qty} &nbsp;&nbsp;</span>;
        })}</span>;
    }

    return(<><div>
        <h2>Ordered Items</h2>
        <div>
            <DataTable  className="p-datatable-customers" value={salesOrderItems}
                        header={''}  dataKey="id" rowHover
                        var="item"
                        paginator rows={10} emptyMessage="No reports found"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10,25,50]}>
                <Column field="product" header="Product" body={product_field} sortable filter  />
                <Column field="variant" header="Variant" body={variant_field}  sortable filter filterPlaceholder="Search by key" />
                <Column field="qty" header="Quantity" body={qtys} sortable filter filterPlaceholder="Search Connection" />
                <Column field="id" header="Actions" body={actions_field}  />
            </DataTable>
        </div>
    </div>
    </>);
};