import { useEffect, useState } from "react"
import useAdd from "../../../services/hooks/useAdd"
import useTableData from "../../../services/hooks/useTableData"

import classes from './useAddCategory.module.scss'

import Modal from "../../CommonUI/Modal"
import Button from "../../CommonUI/Button"
import ButtonType from "../../CommonUI/ButtonType"
import FormBuilder from "../../CommonUI/FormBuilder/FormBuilder"
import { InputTypes } from "../../CommonUI/FormBuilder/FormBuilderInputComponents"
import FormattedTable from "../../CommonUI/TitledTable/FormattedTable"

export const useAvailableCategories = () => {
    const [ availableCategories ] = useTableData({
        hookContext: { entity: 'custom_reports_categories' }
    })

    return availableCategories
}

const useAddCategory = () => {
    const [ isModalShowing, setModalShowing ] = useState(false)
    const [ isCurrentCategoriesShowing, setCurrentCategoriesShowing ] = useState(false);
    const [ newCategoryName, setNewCategoryName ] = useState('')
    const [ newCategoryDescription, setNewCategoryDescription ] = useState('')
    const [ currentCategoriesArray, setCurrentCategoriesArray ] = useState([])

    const availableCategories = useAvailableCategories()
    useEffect(() => {
        if(availableCategories) {
            setCurrentCategoriesArray(availableCategories.sort().map(row => {
                    return {
                        key: row.id,
                        name: row.name
                    }
                }))
        }
    }, [availableCategories])

    const [ addCategory ] = useAdd({ entity: 'custom_reports_categories'})

    const newCategoryField = [
        {
            inputType: InputTypes.text,
            fieldName: 'category',
            label: 'New Category Name',
            value: newCategoryName,
            placeholder: 'Enter a unique name for the new category.',
        },
        {
            inputType: InputTypes.text,
            fieldName: 'description',
            label: 'Category Description',
            value: newCategoryDescription,
            placeholder: '(optional) Enter a short description for the new category.',
        }
    ]

    const newCategoryForm = <>
        <FormBuilder 
            formArray={newCategoryField}
            setFormArray={(arr) => {
                arr.forEach(field => {
                    switch(field.fieldName) {
                        case('category'):
                            setNewCategoryName(field.value);
                            break;
                        case('description'):
                            setNewCategoryDescription(field.value);
                            break;
                        default:
                            return;
                    }
                })
            }}
            removeUpperBorder={true}
        />
        <div>
            <a className={classes.clickable} onClick={() => setCurrentCategoriesShowing(!isCurrentCategoriesShowing)} style={{fontSize: '.8em'}}>
                {isCurrentCategoriesShowing ? 'Hide' : 'Show'} Current Category Names
            </a>
            {isCurrentCategoriesShowing && <FormattedTable
                headers={[{
                    key: 'name',
                    colTitle: '',
                    colKey: 'name',
                }]}
                data={currentCategoriesArray}
            />}
        </div>
    </>

    const onCategorySave = async () => {
        await addCategory({
            name: newCategoryName,
            description: newCategoryDescription
        }).then(res => {
            setNewCategoryName('')
            setNewCategoryDescription('')
            setModalShowing(false)
        })
    }
    
    return {
        showModal: () => setModalShowing(true),
        component: <>
            {isModalShowing && <Modal
                header={'Add Category'}
                content={newCategoryForm}
                buttonArray={[
                    <Button
                        label={'Cancel'}
                        action={() => setModalShowing(false)}
                        type={ButtonType.cancelButton}
                    />,
                    <Button
                        label={'Save New Category'}
                        action={onCategorySave}
                    />
                ]}
            />}
        </>
    }
}

export default useAddCategory