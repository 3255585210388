import React, {Component} from "react";
import {ObjContainer} from "../../services/object_container";
import {TableImportComponent} from "./TableImportComponent";

export class TableFeatures extends Component{
    constructor(props){
        super();
        this.service_manager = ObjContainer.resolve("service_manager");
        this.data_manager = ObjContainer.resolve("data_manager");
        this.state={showImport: false,definition: props.definition, parent_id: props.parent_id, parent_collection: props.parent_collection};

    }
    _onImport(){
        this.setState({showImport: true})
    }
    _onAnalytics(){
        ObjContainer.resolve("nav").navTo(this.state.definition.table_name + "-analytics",
            {parent_data: Object.assign({},this.state.definition || {},{back_state:ObjContainer.resolve("nav").getState() })} );
    }
    async exec(op){
        await this.data_manager.getData(op.op_url,{});
    }
    render(){
        return (
            <div className={""} style={{marginLeft:"3px"}}>
                <DropDownFieldSelector definition={this.props.definition}
                                       ops={{
                                            onImport:() => this.setState({showImport:!this.state.showImport}),
                                            onAnalytics:this._onAnalytics.bind(this),
                                            onDelete: this.props.onDelete.bind(this),
                                            exec: async (op)=> await this.exec(op)
                                        }} />
                {this.state.showImport &&
                <div style={{position:"absolute", zIndex: 100, backgroundColor: "white", width:"800px", padding: "20px", border:"1px solid silver"}}>
                    <TableImportComponent definition={this.props.definition} parent_id={this.props.partent_id} parent_collection={this.props.parent_collection}
                                          name={this.state.definition.table_name}
                                          cancel={() =>this.setState({showImport:false}) }/>
                </div>
                }
            </div>);
    }
}
class DropDownFieldSelector extends Component{
    render(){
        return (
            <div className="dropdown" >
                <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    Table Actions <span className="caret" />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li><a onClick={this.props.ops.onImport.bind(this)}>Import</a></li>
                    <li><a onClick={this.props.ops.onDelete.bind(this)}>Delete Selected</a></li>
                    {(this.props.definition?.ops || []).map((op, index)=>{
                        return <li key={"op_command_" + index}><a onClick={async () => await this.props.ops.exec(op) }>{op.op_name}</a></li>
                    })}
                </ul>
            </div>
        );
    }

};
