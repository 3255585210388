import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import useNav from "../../../services/hooks/useNav";
import useSearch from "../../../services/hooks/useSearch";
import useDelete from "../../../services/hooks/useDelete";
const usersHookContext = {
    entity: 'users'
};
export const Users = ()=>{
    const [show, setShow] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const {navTo} = useNav(usersHookContext);
    const { data:user_data, isLoading, error: apiError } = useSearch({
        ...usersHookContext
    });
    const [deleteUser] = useDelete(usersHookContext);
    const addUser = ()=>{
        navTo("System-AddUsers",{});
    };
    React.useEffect(() => {
        if (user_data && user_data.data) {
            setUsers(user_data.data);
        } else {
            setUsers([]);
        }
    }, [user_data]);

    const removeUser = async (user) =>{
        await deleteUser({id:user.id});
    };
    const actionsBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await removeUser(rowData)}>Delete</a>
            </>
        );
    };
    const emailBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await navTo("System-AddUsers",{id:rowData.id})}>{rowData.username}</a>
            </>
        );
    };
    return <div>
        <div>
            <button className={"btn btn-primary"} onClick={() =>addUser()}>Add User</button>
            <div >
                <div className="card">
                    <DataTable  className="p-datatable-customers" value={users}
                                header={""}  dataKey="id" rowHover
                                paginator rows={10} emptyMessage="No users found"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                        <Column field="username" header="Email/Username"   body={emailBodyTemplate} sortable filter  />
                        <Column field="first_name" header="First Name"   sortable filter filterPlaceholder="First Name" />
                        <Column field="last_name" header="Last Name"   sortable filter filterPlaceholder="Last Name" />
                        <Column field="id" header="Actions"  body={actionsBodyTemplate}  />
                    </DataTable>
                </div>
            </div>
        </div>
    </div>;
}