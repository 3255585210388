import {SeriesDef} from './SeriesDef';
import React from 'react';

export const EditSeries = ({currReport, addNewSeries, selectSeries, report,seriesItem,showNewSeries,updateSeriesItem,dataSource}) =>{
    return <div className={'container-fluid'}>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Edit Series</label> <a className={'inline-nav-link'} onClick={()=>addNewSeries()}>Add</a>
                    <select className={'form-control'} onChange={(e)=>selectSeries(e.target.value)}>
                        <option value="">...Select One</option>
                        {currReport.report_definition?.series?.map((series,series_index)=>{
                            return <option key={'series-item-' + series_index} value={series.series_index}>{series.series_name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
        <hr/>
        <SeriesDef report={report} seriesItem={seriesItem} show={showNewSeries} updateSeriesItem={updateSeriesItem} dataSource={dataSource} />
    </div>;
};