import React, {
    Component,
    PureComponent,
    useImperativeHandle,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
    useCallback
} from 'react';
import { Chart, Series } from 'devextreme-react/chart';
import * as ReactHighcharts from 'react-highcharts';
import {ChartViewer} from './ChartViewer';
import DragAndDrop from './DragAndDrop';



export const ReportDesignItem = ({report, selected, setSelected}) =>{
    const [reportDesign, setReportDesign] = useState({...report});
    const [curSize, setCurSize] = useState( {...report.size});
    const notSelected = <div className={'centered-status-message'}>
        Choose report type<br/>
        Start by dragging fields to the design surface.
    </div>;
    const div = useRef();
    useEffect(()=>{
        let tmp = {...report};
        if(!tmp.size.width )
            tmp.size.width = 400;
        if(!tmp.size.height )
            tmp.size.height= 400;
        setReportDesign(tmp);
        setCurSize(tmp.size);
        let element = div.current;
        let timeout = undefined;
        element.addEventListener('resize', (event) => {
            let tmp = {...curSize};
            tmp.width = event.detail.width;
            tmp.height = event.detail.height;
            setCurSize( {...tmp});
            if(!timeout){
                timeout = setTimeout(()=>{
                    let tmpReport = {...report};
                    tmpReport.size = {...tmp};
                    setSelected(tmpReport);
                },1000);
            }else{
                clearTimeout(timeout);
                timeout = setTimeout(()=>{
                    let tmpReport = {...report};
                    tmpReport.size = {...tmp};
                    setSelected(tmpReport);
                },1000);
            }

        });
        function checkResize(mutations){
            const el = mutations[0].target;
            const w = el.clientWidth;
            const h = el.clientHeight;
            const isChange = mutations
                .map((m) => `${m.oldValue}`)
                .some((prev) => prev.indexOf(`width: ${w}px`) === -1 || prev.indexOf(`height: ${h}px`) === -1);
            if (!isChange) { return; }
            const event = new CustomEvent('resize', { detail: { width: w -10, height: h -10 } });
            el.dispatchEvent(event);
        }
        let observer = new MutationObserver(checkResize);
        observer.observe(element, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });
        return () => {
            observer.disconnect();
            clearTimeout(timeout);
        }
    },[report,selected,div]);
    const getItem = (item) =>{
        let tmpReport = {...reportDesign};
        tmpReport.fields = tmpReport.fields || [];
        tmpReport.fields.push(item);
        setReportDesign(tmpReport);
        setSelected(tmpReport);
    };
    const getIsSelected= ()=>{
        return report.report_key === selected.report_key;
    }
    return <DragAndDrop onDrop={(data)=>getItem(data)}>
        <div className={"resizable-area" + (getIsSelected() ? ' selected' :'')}
                style={{width:(report?.size?.width + 10+ 'px'),height:((report?.size?.height +10) + 'px')}}
                ref={div}>
            <div className={'report-item-b-instance droppable ' }>
                {reportDesign.fields && <ChartViewer reportDesign={reportDesign}/> }
                {!reportDesign.fields && notSelected}
            </div>

        </div>
    </DragAndDrop>;
};

