import { useState, useEffect } from 'react';
import useNav from '../../../services/hooks/useNav';
import { useUpsert } from '../../../services/hooks/useUpsert';
import useTableData from '../../../services/hooks/useTableData';
import useGetById from '../../../services/hooks/useGetById';
import useDelete from '../../../services/hooks/useDelete';
import useAddCategory, { useAvailableCategories } from './useAddCategory';
import _ from 'lodash';
import classes from './CustomReportEdit.module.scss';
import ActionRow from "../../CommonUI/ActionRow";
import Button from '../../CommonUI/Button';
import Modal from '../../CommonUI/Modal';
import PageTitle from "../../CommonUI/PageTitle"
import FormBuilder from "../../CommonUI/FormBuilder/FormBuilder";
import CustomReportColumnDef from './CustomReportColumnDef';
import CustomReportsEditUsers from './CustomReportsEditUsers';
import {CustomReportForm, CustomReportFormFieldNames} from "./CustomReportEditFormDef";
import {InputTypes} from "../../CommonUI/FormBuilder/FormBuilderInputComponents";

const CustomReportEdit = ({ reportId = null }) => {
    // State
    const [ reportData, setReportData ] = useState(null);
    const [ changeableFormArray, setChangeableFormArray ] = useState([]);
    const [ availableCategories, setAvailableCategories ] = useState([]);
    const [ dataSourceConnectionId, setDataSourceConnectionId ] = useState(null);
    const [ dataSourceItemId, setDataSourceItemId ] = useState(null);
    const [ dataSourceTableNames, setDataSourceTableNames ] = useState(null);
    const [ selectedTableNames, setSelectedTableNames ] = useState([]);
    const [ availableFields, setAvailableFields ] = useState([]);
    const [ selectedFields, setSelectedFields ] = useState([]);
    const [ currentUsers, setCurrentUsers ] = useState([]);

    const categoryData = useAvailableCategories()
    const { isFetchedAfterMount: isReportDataFetchedAfterMount, data: reportQueryResults } = useGetById({
        entity: 'custom_reports',
        id: reportId,
        includeChildren: true
    });
    const [ dataSourceItems ] = useTableData({
        hookContext: {
            entity: 'data_source_items',
            filter_eq: { data_connection_id: dataSourceConnectionId }
        },
        dependencies: [ dataSourceConnectionId ]
    });
    const [ dataSourceConnections ] = useTableData({
        hookContext: {
            entity: 'data_source_connections'
        },
        mapCallback: (row) => {
            return {
                value: row.id,
                label: row.data_source_name
            }
        }
    });
    const { data: dataSourceSchema } = useGetById({
        entity: 'data_source_items',
        id: dataSourceItemId,
    });
    const { showModal, component: addCategoryModal } = useAddCategory()

    useEffect(() => {
        if(categoryData) {
            setAvailableCategories(categoryData.map(row => {
                return {
                    value: row.id,
                    label: row.name
                }
            }))
        }
        if(!reportId && dataSourceConnections) {
            let formArray = CustomReportForm.map(field => {
                switch(field.fieldName) {
                    case CustomReportFormFieldNames.categoryId:
                        return {
                            ...field,
                            options: [
                                ...availableCategories,
                                {
                                    value: 0,
                                    label: 'Uncategorized'
                                }
                            ],
                            onAdd: showModal
                        }
                    case CustomReportFormFieldNames.title:
                        return field
                    case CustomReportFormFieldNames.dataSourceConnectionId:
                        return {
                            ...field,
                            options: getDataSourceConnectionOptions(),
                            onFieldChange: (name, value) => onDataSourceConnectionIdChange(name, value)
                        }
                    default:
                        return null;
                }
            });
            setChangeableFormArray(formArray);
        }
    }, [reportId,dataSourceConnections,categoryData])

    useEffect(() => {
        if(reportQueryResults && reportQueryResults.data) {
            let reportDataVal = reportQueryResults.data;
            setReportData( reportDataVal);
            setCurrentUsers(reportDataVal.custom_reports_users.map(user => {
                return {
                    key: user.user_id,
                    id: user.id,
                    userId: user.user_id,
                    username: user.users.username,
                    firstName: user.users.first_name,
                    lastName: user.users.last_name
                }
            }) || [])
            setDataSourceConnectionId(reportDataVal.data_source_items?.data_connection_id)
            setDataSourceItemId(reportDataVal?.data_source_item_id);
            let selectedTables = reportDataVal[CustomReportFormFieldNames.customReportsTables];
            let selectedTableNamesVar = selectedTables?.map(table => table.table_name);
            setSelectedTableNames([...selectedTableNamesVar])
            let availableFieldVals = [];
            if(selectedTableNamesVar.length > 0){
                let selectedFieldVals = mapFieldData(selectedTables.flatMap(table => {
                    return table[CustomReportFormFieldNames.customReportsTablesFields];
                }),selectedTables[0].table_name);
                setSelectedFields(selectedFieldVals)
            }

            let dataSourceTableNames = [];
            if(dataSourceSchema && dataSourceSchema.data){
                dataSourceTableNames = dataSourceSchema.data.schema_definition.tables.map(table => {
                    return { value: table.table_name }
                });
                setDataSourceTableNames(dataSourceTableNames);
                if(selectedTableNamesVar.length > 0){
                    let tableName = selectedTables[0].table_name;
                    let columns = dataSourceSchema.data.schema_definition.tables.find(table => table.table_name === tableName).column_data;
                    availableFieldVals = mapFieldData(columns,tableName);
                    setAvailableFields([...availableFieldVals]);
                }

            }
            setChangeableFormArray(getCustomForm(reportDataVal, availableFieldVals, dataSourceTableNames));
        }
    }, [reportQueryResults,
        dataSourceConnections,
        dataSourceItems,
        dataSourceSchema]);
    const getCustomForm = (reportDataVal,availableFields, tableNameOptions) =>{
        return CustomReportForm.map(field => {
            switch(field.fieldName) {
                case CustomReportFormFieldNames.categoryId:
                    return {
                        ...field,
                        value: reportDataVal[field.fieldName],
                        options: [
                            ...availableCategories,
                            {
                                value: 0,
                                label: 'Uncategorized'
                            }
                        ],
                        onAdd: showModal
                    }
                case CustomReportFormFieldNames.title:
                    return {
                        ...field,
                        value: reportDataVal[field.fieldName]
                    }
                case CustomReportFormFieldNames.dataSourceConnectionId:
                    return {
                        ...field,
                        value: reportDataVal.data_source_items?.data_connection_id,
                        options: getDataSourceConnectionOptions(),
                        onFieldChange: (name, value)  => onDataSourceConnectionIdChange(name, value)
                    }
                case CustomReportFormFieldNames.dataSourceItemId:
                    return {
                        ...field,
                        value: reportDataVal[field.fieldName],
                        options: getDataSourceItemOptions(),
                        onFieldChange: (name, value)  => onDataSourceItemIdChange(name, value)
                    }
                case CustomReportFormFieldNames.customReportsTables:
                    return {
                        ...field,
                        value: reportDataVal[field.fieldName].find(table => _.isNull(table.join_type))?.table_name,
                        options: tableNameOptions,
                        onFieldChange: (name, value)  => onPrimaryTableChange(name, value)
                    }
                case CustomReportFormFieldNames.customReportsTablesFields:
                    return {
                        ...field,
                        value: selectedFields.map(field => field.fullyQualifiedName),
                        options: getAvailableFieldsForForm(availableFields),
                        onFieldChange:(name, value)  => onFieldSelected(name, value)
                    }
                default:
                    return null;
            }
        });
    }
    const getDataSourceConnectionOptions = () => {
        return [
            ...dataSourceConnections
        ]
    }

    // Load potential dataSource Items
    const getDataSourceItemOptions = () => {
        return dataSourceItems.map(item => {
            return {
                value: item.id,
                label: item.data_source_name
            }
        })
    }

    // Map field data to internal format
    const mapFieldData = (fields, primaryTable) => {
        return fields.map(field => {
            const fieldName = field.field_name ?? field.name
            return {
                position: field.position ?? 0,
                fullyQualifiedName: `${primaryTable}.${fieldName}`,
                name: fieldName,
                table_name: primaryTable,
                caption: field.caption ?? field.name,
                dataType: field.dataType ?? null,
                format: field.format ?? null,
                alignment: field.alignment ?? 'left',
                width: field.width ?? null,
                visible: field.visible ?? true,
                allowFiltering: field.allowFiltering ?? true,
                allowHeaderFiltering: field.allowHeaderFiltering ?? false,
                groupIndex: field.groupIndex ?? null
            }
        })
    }
    const getAvailableFieldsForForm = (availableFieldsVal) => {
        return (availableFieldsVal || []).map(field => {
            return {
                value: field.fullyQualifiedName,
                label: field.name,
                sublabel: selectedTableNames[0] || ""
            }
        })
    }

    const onDataSourceConnectionIdChange = (name, value) => {
        setDataSourceConnectionId(parseInt(value));
    }
    
    const onDataSourceItemIdChange = (name, value) => {
        setDataSourceItemId(value);
        setSelectedTableNames([])
        setSelectedFields([])
    }
    
    const onPrimaryTableChange = (name, value) => {
        console.log("primary changed", name, value);
        let tmpReportData = {...reportData};
        tmpReportData[CustomReportFormFieldNames.customReportsTables] = [{[CustomReportFormFieldNames.customReportsTablesFields]:[],table_name:value}];
        setSelectedTableNames([value]);
        setReportData(tmpReportData);
        if(value){
            let columns = dataSourceSchema.data.schema_definition.tables.find(table => table.table_name === value).column_data;
            let availableFieldItems = mapFieldData(columns,value);
            setAvailableFields([...availableFieldItems]);
            setChangeableFormArray(getCustomForm(tmpReportData,availableFieldItems, dataSourceTableNames));
        }else{
            setChangeableFormArray(getCustomForm(tmpReportData,[],dataSourceTableNames));
            setAvailableFields([]);
        }
    }

    const onFieldChange = (name, value) => {
        let calculatedFields = changeableFormArray.filter((obj) => Object.keys(obj).includes('calculateValue'));
        let calculatedFieldKeys = calculatedFields.map((field) => field.key);
        let tmpArray = [...changeableFormArray];
        let newArray = tmpArray.map((obj) => {
            if (obj.inputType === 'checkbox') {
                if (obj.fieldName === name) {
                    obj.value = value ? 'true' : 'false';
                }
            } else if (obj.fieldName === name) {
                obj.value = value;
            }
            return obj;
        });
        if (calculatedFieldKeys.length > 0) {
            newArray = newArray.map((obj) => {
                if (calculatedFieldKeys.includes(obj.fieldName)) {
                    obj.value = obj.calculateValue.elements
                        .map((el) => {
                            switch (el.type) {
                                case 'lookup':
                                    return newArray.find((row) => row.key === el.key).value;
                                case 'exact':
                                default:
                                    return el.value;
                            }
                        })
                        .join(obj.calculateValue.delimiter);
                    console.log("deafult",obj.value);
                }
                return obj;
            });
        }
        setChangeableFormArray(newArray);
        if(newArray.find(i=>i.fieldName===name))
            onFieldChangeDispatch(newArray.find(i=>i.fieldName===name), value);
    };
    const onFieldChangeDispatch = (field, value) =>{
        switch(field.fieldName){
            case CustomReportFormFieldNames.dataSourceConnectionId:
                onDataSourceConnectionIdChange(field.fieldName, value);
                break;
            case CustomReportFormFieldNames.dataSourceItemId:
               onDataSourceItemIdChange(field.fieldName, value)
                break;
            case CustomReportFormFieldNames.customReportsTables:
                onPrimaryTableChange(field.fieldName, value)
                break;
            case CustomReportFormFieldNames.customReportsTablesFields:
               onFieldSelected(value, value)
                break;
            default:
        }
    }
    const onFieldSelected = (name, fieldName) => {

        let fields = [...selectedFields];
        console.log("name, fieldName", name, fieldName,availableFields,fields);
        let existing = fields.find(field => field.fullyQualifiedName === name );
        if(existing){
            fields.splice(fields.indexOf(existing), 1);
        }else{
            fields.push({...availableFields.find(field => field.fullyQualifiedName === name)})
        }
        fields.map((item, index)=>{
            item.position = index + 1;
        })
        setSelectedFields(fields);
    }

    const { navTo } = useNav();
    const [upsertReport] = useUpsert({entity: 'custom_reports'});
    const [upsertTable] = useUpsert({entity: 'custom_reports_tables'});
    const [upsertField] = useUpsert({entity: 'custom_reports_tables_fields'});
    const [upsertUser] = useUpsert({entity: 'custom_reports_users'});
    const [deleteField] = useDelete({entity: 'custom_reports_tables_fields'});
    const [deleteUser] = useDelete({entity: 'custom_reports_users'});
    const saveData = async () => {
        const reportObj = {}
        
        if(reportId) {
            reportObj.id = parseInt(reportId)
        }
        
        changeableFormArray.forEach(field => {
            switch(field.fieldName) {
                case CustomReportFormFieldNames.categoryId:
                case CustomReportFormFieldNames.title:
                case CustomReportFormFieldNames.dataSourceItemId:
                    reportObj[field.fieldName] = field.value
                    break;
                default:
                    break;
            }
        });

        let res = await upsertReport(reportObj);
        const reportId = res.result[0].id
        await Promise.all(selectedTableNames.map(async tableName => {
            const tableObj = {
                report_id: reportId,
                table_name: tableName
            };
            if(reportData) tableObj.id = reportData[CustomReportFormFieldNames.customReportsTables][0].id

            return upsertTable(tableObj).then(async res => {
                const promiseArray = []

                const tableId = res.result[0].id
                const tableName = res.result[0].table_name
                console.log("value",reportData[CustomReportFormFieldNames.customReportsTables][0]);
                // Currently only calls the fields from one (the first) table in the tables array - Will need to be refactored when multiple tables are allowed
                const prevFields = reportData ?
                    reportData[CustomReportFormFieldNames.customReportsTables][0][CustomReportFormFieldNames.customReportsTablesFields] : []
                const selectedFieldsFQNames = selectedFields.map(field => field.fullyQualifiedName)
                const fieldsToDelete = prevFields.filter(field => !selectedFieldsFQNames.includes(`${tableName}.${field.field_name}`))

                fieldsToDelete.map(field => {
                    promiseArray.push(deleteField({ id: field.id }))
                })

                selectedFields.map(async field => {
                    const fieldObj = {
                        table_id: tableId,
                        position: field.position,
                        field_name: field.name,
                        caption: _.isEmpty(field.caption) ? field.name : field.caption,
                        dataType: _.isEmpty(field.dataType) ? null : field.dataType,
                        format: _.isEmpty(field.format) ? null : field.format,
                        alignment: _.isEmpty(field.alignment) ? null : field.alignment,
                        width: _.isEmpty(field.width) ? null : field.width,
                        visible: _.isEmpty(field.visible) ? true : field.visible,
                        allowFiltering: _.isEmpty(field.allowFiltering) ? true : field.allowFiltering,
                        allowHeaderFiltering: _.isEmpty(field.allowHeaderFiltering) ? false : field.allowHeaderFiltering,
                        groupIndex: _.isEmpty(field.groupIndex) ? null : field.groupIndex,
                    }

                    const prevField = prevFields.find(pf => field.fullyQualifiedName === `${tableName}.${pf.field_name}`)
                    if(prevField) fieldObj.id = prevField.id

                    promiseArray.push(upsertField(fieldObj))
                })

                // Process current users
                const prevUsers = reportData ? reportData.custom_reports_users : []
                const usersToDelete = prevUsers.filter(user => !currentUsers.map(u => u.userId).includes(user.user_id))
                usersToDelete.map(user => {
                    promiseArray.push(deleteUser({ id: user.id }))
                })

                const usersToAdd = currentUsers.filter(user => !prevUsers.map(u => u.user_id).includes(user.userId))
                usersToAdd.map(async user => {
                    const userObj = {
                        user_id: user.userId,
                        report_id: reportId,
                        updated_at: new Date()
                    }
                    promiseArray.push(upsertUser(userObj))
                })
                return promiseArray;
            })
        }));
        navTo('Reports');
    }
    
    // ***** Cancellation Modal Buttons *****
    const [ isModalShowing, setModalShowing ] = useState(false);
    const modalButtons = [
        <Button key='yes' label={'Yes, Cancel'} action={() => navTo('Reports')} />,
        <Button key='no' label={'No. Continue Editing'} action={() => setModalShowing(false)} />
    ]

    // ***** Edit Allowed Users Modal *****
    const [ isEditingUsers, setEditingUsers ] = useState(false);
    const editingUsersButtons = [
        <Button key='close' label={'Done Editing'} action={() => setEditingUsers(false)} />
    ]
    
    // ***** Action Buttons (Cancel, Save) *****
    const actionButtons = [
        <Button key='save' label={'Save Custom Report'} action={saveData} />,
        <Button key='cancel' label={'Cancel'} action={() => setModalShowing(true)} />,
    ]

    // ***** Lower Action Buttons *****
    const lowerActionButtons = [
        <Button key='users' label={'Edit User Permissions'} action={() => setEditingUsers(true)} />
    ]
    
    return <>
        { addCategoryModal }
        <> 
            { isModalShowing && 
                <Modal 
                    header={'Cancel Custom Report'}
                    content={'Are you sure you want to cancel making this custom report? You will lose any information that you have entered into the form.'}
                    buttonArray={modalButtons}
                /> 
            }
            { isEditingUsers &&
                <Modal
                    header={'Edit Allowed Users'}
                    content={<CustomReportsEditUsers currentUsers={currentUsers} setCurrentUsers={setCurrentUsers}/>}
                    buttonArray={editingUsersButtons}
                />
            }
            <div className={classes.pageWrapper}>
                <div className={classes.titleWrapper}>
                    <PageTitle>{reportId ? 'Edit' : 'Add'} Custom Report</PageTitle>
                </div>
                <div className={classes.formWrapper}>
                    <FormBuilder 
                        formArray={changeableFormArray}
                        onChange={onFieldChange}
                        isEditing={true}
                        removeUpperBorder={true}
                    /> 
                    <CustomReportColumnDef 
                        selectedFields={selectedFields.sort((a, b) => {
                            return a.position > b.position ? 1
                                : a.position < b.position ? -1
                                : a.name > b.name ? 1
                                : a.name < b.name ? -1
                                : 0
                        })} 
                        setSelectedFields={setSelectedFields}
                    />
                </div>
                <div className={classes.lowerActionWrapper}>
                    <ActionRow 
                        leftButtons={lowerActionButtons}
                        rightButtons={actionButtons}
                    />
                </div>
            </div>
        </>
    </>
}
    
export default CustomReportEdit;