import classes from './ActionRow.module.scss';

import Button from './Button';

const ActionRow = ({ leftButtons = [], centerButtons = [], rightButtons = [] }) => {
    return <div className={classes.rowWrapper}>
        {[leftButtons, centerButtons, rightButtons].map((buttons, idx) => {
            return <div key={`actionButtonSegment-${idx}`}>
                {buttons.map(button => {
                    const newButton = <Button
                        {...button.props}
                        key={button.props.label}
                    />
                    return newButton
                    })}
            </div>
        })}
        
    </div>
}

export default ActionRow;