import React, { useEffect, useState } from 'react';
import { ObjContainer } from '../object_container';
import { useQueryCache } from 'react-query';
import { useAuthOps } from './authTokenContext';
import { useGetOne } from './useGetOne';
const systemHookContext = {
    entity: 'system_config',
};
export const useLoginNav = (params) => {
    const queryCache = useQueryCache();
    const [authenticated, setAuthenticated] = useState(
        (ObjContainer.resolve('user_manager') || { isLoggedIn: () => false }).isLoggedIn()
    );
    const [systemData, setSystemData] = useState({});
    const { setToken } = useAuthOps();
    const [homeData, setHomeData] = useState({
        currentRouteItem: ObjContainer.resolve('nav').getRouteContent(
            ObjContainer.resolve('nav').getState().path,
            authenticated,
            params
        ) || <div>No Route</div>,
        logo: '',
        loggedIn: authenticated,
        message: '',
        logo_url: '',
        message_success: false,
        ...params,
    });
    const { data: systemInfo_data } = useGetOne(systemHookContext);
    useEffect(() => {
        if (systemInfo_data) {
            setSystemData(systemInfo_data);
            let tmpHomeData = { ...homeData };
            tmpHomeData.logo_url = systemInfo_data.logo_url;
            tmpHomeData.currentRouteItem = ObjContainer.resolve('nav').getRouteContent(
                ObjContainer.resolve('nav').getState().path,
                authenticated,
                tmpHomeData
            ) || <div>No Route</div>;

            setHomeData(tmpHomeData);
        }
    }, [systemInfo_data]);
    useEffect(() => {
        ObjContainer.resolve('nav').addListener('try_login', async (loginState) => {
            if (!loginState) {
                setAuthenticated(false);
                setHomeData({
                    ...homeData,
                    loggedIn: false,
                    logo_url: undefined,
                    currentRouteItem: ObjContainer.resolve('nav').getRouteContent('Login', false, {}),
                });
            }
            if (loginState.authenticated) {
                setToken(loginState.jwt);
                setAuthenticated(true);
                await ObjContainer.initializeTables();
                let tmpHomData = { ...homeData };
                tmpHomData.loggedIn = true;
                tmpHomData.currentRouteItem = ObjContainer.resolve('nav').getRouteContent(
                    ObjContainer.resolve('nav').getState().path,
                    true,
                    homeData
                );
                setHomeData(tmpHomData);

                await queryCache.invalidateQueries('search-system_config');
            }
        });
        ObjContainer.resolve('nav').addListener('nav', (item) => {
            let tmpHomeData = { ...homeData };
            tmpHomeData.loggedIn = (ObjContainer.resolve('user_manager') || { isLoggedIn: () => false }).isLoggedIn();
            tmpHomeData.logo_url = systemData.logo_url;
            tmpHomeData.currentRouteItem = ObjContainer.resolve('nav').getRouteContent(
                ObjContainer.resolve('nav').getState().path,
                tmpHomeData.loggedIn,
                tmpHomeData
            );
            setHomeData(tmpHomeData);
        });
        window.addEventListener('popstate', (e) => {
            let component = ObjContainer.resolve('nav').getBackItem(e);
            let tmpHomData = { ...homeData };
            if (component) {
                tmpHomData.currentRouteItem = component;
                tmpHomData.loggedIn = (
                    ObjContainer.resolve('user_manager') || { isLoggedIn: () => false }
                ).isLoggedIn();
                setHomeData(tmpHomData);
            }
        });
        return () => {
            window.removeEventListener('popstate', (e) => {
                let component = ObjContainer.resolve('nav').getBackItem(e);
                let tmpHomData = { ...homeData };
                if (component) {
                    tmpHomData.currentRouteItem = component;
                    tmpHomData.loggedIn = (
                        ObjContainer.resolve('user_manager') || { isLoggedIn: () => false }
                    ).isLoggedIn();
                    setHomeData(tmpHomData);
                }
            });
            ObjContainer.resolve('nav').removeAllListeners('table_updated');
            ObjContainer.resolve('nav').removeAllListeners('nav');
            ObjContainer.resolve('nav').removeAllListeners('try_login');
        };
    }, [homeData]);

    return [homeData, setHomeData];
};
