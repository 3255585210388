import React, {useEffect, useState} from "react";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";
import {FilterConfigurationItem} from "./FilterConfigurationItem";

export const FiltersConfiguration = ({show,setShow,table,updateTable, all_tables,schema}) =>{
    const [showImport, setShowImport] =useState(false);
    const [currTable, setCurrTable] =useState(table || {});
    useEffect(()=>{
        setShowImport(show);
    },[show]);
    useEffect(()=>{
        if(table)
            setCurrTable(table);
    },[table]);
    const saveJoins = ()=>{
        setShow(false);
    }
    return <Popup show={showImport} showSave={true} save={() => saveJoins()} title={currTable.table_name + " Filters"} closeModal={() => setShow(false)}>
        <div className="row">
            <div className="col-md-12">
                <div className="p-col-12">
                    <div style={{height:"400px",overflow:"auto"}}>
                        {(!currTable.filter_list || currTable.filter_list.length === 0)&& <>
                            <FilterConfigurationItem schema={schema} table={currTable} filterItem={{}} all_tables={all_tables} updateTable={updateTable} />
                        </>}
                        {(currTable.filter_list && currTable.filter_list.length > 0) && (
                            <>
                                {currTable.filter_list.map((i, ind)=>{
                                    return <FilterConfigurationItem key={"join_item_" + ind}  schema={schema} filterItem={i} table={currTable} all_tables={all_tables} updateTable={updateTable} />;
                                })}
                            </>)}
                    </div>
                </div>
            </div>
        </div>
    </Popup>;
}