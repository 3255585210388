const InvoiceSummaryDetails = ({ invoiceId }) => {
    return {
        isDetail: true,
        title: null,
        query: `select ii.sku, p.style_number, p.id product_id, p.description, pv.variation_code, pv.variation_name, ii.size, ii.qty, ii.amount
        from invoice_items ii
            join products p on p.id = COALESCE(ii.product_id, (select id from products prd where prd.style_number = ii.style_number))
            join product_variations pv on pv.id = ii.product_variant_id
        where ii.qty > 0 and ii.invoice_id = '${invoiceId}';`,
        columns: [
            {
                dataField: 'sku',
                caption: 'SKU',
                dataType: 'string',
            },
            {
                dataField: 'description',
                caption: 'Product Description',
                dataType: 'string',
            },
            {
                dataField: 'variation_name',
                caption: 'Product Variation',
                dataType: 'string',
            },
            {
                dataField: 'size',
                caption: 'Size',
                dataType: 'string',
            },
            {
                dataField: 'qty',
                caption: 'Qty',
                dataType: 'number',
                alignment: 'right',
            },
            {
                dataField: 'amount',
                caption: 'Amount',
                dataType: 'number',
                format: {
                    type: 'currency',
                    precision: 2,
                },
                alignment: 'right',
            },
        ],
        isGroupable: false,
        isSearchable: false,
        isFilterableByRow: false,
        isFilterableByPanel: false,
        isExportable: false,
    };
};

export default InvoiceSummaryDetails;
