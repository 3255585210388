import { useQuery } from 'react-query';
import AxiosClient from './AxiosClient';
import constants from './constants';
import { useAuth } from './authTokenContext';

const { api } = constants;
const { basePath, options } = api;

const GetById = async (queryKey,context, params) => {
    if(!params.id || params.id < 0)
    {return {};}
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity,
        expands,
        expands_multi,
        includeChildren = false
    } = params;
    const buildParams = ()=>{
        let params_vals = [];
        if(expands){
            for(let i in expands){
                params_vals.push(`expands[${i}]=${expands[i]}`);
            }
        }
        if(expands_multi){
            for(let i in expands_multi){
                params_vals.push(`expands_multi[${i}]=${expands_multi[i]}`);
            }
        }
        if(includeChildren) {
            //params_vals.push('includeChildren=true');
        }
        return '?' + params_vals.join('&');
    };
    try {
        const pathname = `${basePath}${entity}/${params.id}${buildParams()}`;
        const results = await AxiosClient.get(
            pathname,
            config
        );
        const { data } = results;
        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble searching ${entity}.`);
    }
};

const useGetById = (context) => {
    const auth = useAuth();
    return useQuery( 
        [
            `by-id-${context.entity}`,
            {
                ...auth,
                ...context
            }
        ],
        (args, item) => GetById(args,item, context)
    );
};

export default useGetById;
