import classes from "./TriangleIcon.module.scss";

const TriangleIcon = ({ size }) => {
  const styles = {
    borderLeftWidth: `${size / 2}px`,
    borderRightWidth: `${size / 2}px`,
    borderBottomWidth: `${size * 0.8660254}px`,
  };
  return (
    <div className={classes.triangle} style={styles}>
      &nbsp;
    </div>
  );
};

export default TriangleIcon;
