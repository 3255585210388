import React, {useEffect, useRef, useState} from 'react';
import {useRoutePost} from '../../../services/hooks/routes/useRoutePost';

import DataGrid,{
    Export,
    Grouping,
    GroupPanel,
    Column,
    SearchPanel,
    Paging,
    Summary,
    GroupItem
} from 'devextreme-react/data-grid';
import {Workbook} from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
export const CurrentInventoryLevels = () =>{
    const [data, setData] = useState({rows:[],fields:[]});
    const {post} = useRoutePost({entity:'custom_reports',operation:'execute_report'});
    useEffect(()=>{
        post({source_report:'reports/apparel/inventory_current_report'}).then((res)=>{
            if(res){
                setData(res.result);
            }
        });
    },[]);

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    };
    return <div>
        <DataGrid
            id="gridContainer"
            dataSource={data.rows}
            showBorders={true}
            onExporting={onExporting}>
            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={true} />
            <Paging defaultPageSize={10} />


            <Column dataField={'style_code'} header={'Style'} dataType={'string'} sortable={true} filter={true} />
            <Column dataField={'print_number'} header={'Print'}  dataType={'string'} sortable={true} filter={true} />
            <Column dataField={'variation_name'} header={'Print Code'} dataType={'string'} sortable={true} filter={true} />
            <Column caption="Sizes">
                <Column dataField={'XXS'} header={'XXS'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'XS'} header={'XS'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'S'} header={'S'}  dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'M'} header={'M'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'L'} header={'L'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'XL'} header={'XL'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'2XL'} header={'2XL'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'U'} header={'U'} dataType={'number'} sortable={true} filter={true} />
                <Column dataField={'F'} header={'F'} dataType={'number'} sortable={true} filter={true} />
            </Column>
            <Column dataField={'total'} dataType={'number'} header={'Total'} sortable={true} filter={true} />
            <Column dataField={'description'} header={'Description'} dataType={'string'} sortable={true} filter={true} groupIndex={0} />
            <Export enabled={true} allowExportSelectedData={true} />
            <Summary>
                <GroupItem
                    column="print_number"
                    summaryType="count"
                    displayFormat="{0} variants" />
                <GroupItem
                    column="XXS"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="XS"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="S"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="M"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="L"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="XL"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="2XL"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="F"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="U"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="total"
                    summaryType="sum"
                    displayFormat="{0}"
                    showInGroupFooter={false}
                    alignByColumn={true} />
                <GroupItem
                    column="total"
                    summaryType="sum"
                    displayFormat="Total: {0}"
                    showInGroupFooter={true} />
            </Summary>
        </DataGrid>
    </div>;
};