import React, {Component} from 'react';
import {ObjContainer} from '../../services/object_container';
import {Popup} from '../FormDriver/FormBuilderSupportComponent'; 
export class ProductVariationManager extends Component{
    constructor(){
        super();
        this.state = { param_state: ObjContainer.resolve('nav').getState(),sizes:[],product:{},current_variant:{},purchases:[]};
        this.service_manager = ObjContainer.resolve('service_manager');
    }
    async componentDidMount() {
        if(this.state.param_state.parent_data){
            await this.getInventory(this.state.param_state.parent_data);
        }
    }
    async getInventory(variant){
        let product_filter = await this.service_manager.resolve('products').search({filter: {id:{eq:variant.product_id}}});
        let product = product_filter.data.data[0];
        let filter =        {filter: {style_number:{eq:product.style_number}, attr_2:{eq:variant.variation_code}}, sort: {attr_2:'asc'},pageSize:20000 };
        let data =      await this.service_manager.resolve('inventory').search(filter);
        let size_filter =   {filter: {size_range:{eq:product.size_range}}, sort: {order_number:'asc'} };
        let sizes =     await this.service_manager.resolve('sizes').search(size_filter);
        //let purchase_order_filter =   {filter: {product_variation_id:{eq:variant.id}}, sort: {create_date:"asc"} };
        //let purchases =     await this.service_manager.resolve("purchase_orders").search(purchase_order_filter);
        this.setState({current_variant: variant,product:product,
            inventory_data:data.data.data, sizes: sizes.data.data, edit:false,purchases:[]});
    }
    navToEdit(){
        let local_state = {
            route_params:{filter:{id:this.state.product.id}},parent_id:this.state.current_variant.id, parent_data: Object.assign({},
                this.state.current_variant,{back_state:ObjContainer.resolve('nav').getState()})};
        ObjContainer.resolve('nav').navTo('products-Viewproducts', local_state);
    }
    navToPO(){
        let local_state = {
            route_params:{filter:{id:-1}},parent_id:this.state.current_variant.id, parent_data: Object.assign({},
                this.state.current_variant,{back_state:ObjContainer.resolve('nav').getState()})};
        ObjContainer.resolve('nav').navTo('purchase_order_manager', local_state);
    }
    async saveChanges(){
        for(let item in this.state.inventory_data){
            await this.service_manager.resolve('inventory').upsert( this.state.inventory_data[item].id, this.state.inventory_data[item]);
        }
        this.setState({show:false});
    }
    increment(inventory,size){
        if(inventory){
            this.setState((s)=>{
                let inventory_item = s.inventory_data[s.inventory_data.indexOf(inventory)];
                inventory_item.qty_inventory =inventory.qty_inventory+1;
                return s;
            });
        }else{
            this.setState((s)=>{
                s.inventory_data.push({attr_2:s.current_variant.variation_code,style_number:s.product.style_number,size:size.code,qty_inventory:1,qty_open_sales:0});
                return s;
            });
        }
    }
    decrement(inventory,size){
        if(!inventory || inventory.qty_inventory === 0)
        {return;}
        this.setState((s)=>{
            let inventory_item = s.inventory_data[s.inventory_data.indexOf(inventory)];
            inventory_item.qty_inventory =inventory.qty_inventory-1;
            return s;
        });
    }
    updateValue(inventory,size,value){
        if(inventory){
            this.setState((s)=>{
                let inventory_item = s.inventory_data[s.inventory_data.indexOf(inventory)];
                inventory_item.qty_inventory = value;
                return s;
            });
        }else{
            this.setState((s)=>{
                s.inventory_data.push({attr_2:s.current_variant.variation_code,style_number:s.product.style_number,size:size.code,qty_inventory:value,qty_open_sales:0});
                return s;
            });
        }

    }
    render(){
        return <div className={'container-fluid'}>
            <div>
                <a>{this.state.product.category}</a> > <a>{this.state.product.description}</a> > <a>{this.state.current_variant.variation_name}</a>
            </div>
            <div>
                <button className={'btn btn-primary'} onClick={()=> this.navToEdit()}>Edit Properties</button>
            </div>
            <div>
                <h3>Name / Description</h3>
                <div className="row">
                    <div className="col-md-12">
                        {`${this.state.product.style_number} - ${this.state.product.description}`}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        Print: {`${this.state.current_variant.variation_code} - ${this.state.current_variant.variation_name}`}
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <h3>Current Inventory</h3>
                    <button className={'btn btn-primary'} onClick={()=>this.setState({show:true})}>Manual Adjustment</button>
                    <div className="row">
                        <div className="col-md-2">Size</div><div className="col-md-2">Current</div><div className="col-md-2">Pending</div>
                    </div>
                    {this.state.sizes.map((size, index_f)=>{
                        let i_item = (this.state.inventory_data || []).find(it=>it.size === size.code);
                        return (
                            <div className="row" key={'var_item_' + 0 + '_field_' + index_f}>
                                <div className={'col-md-2'} style={{textAlign:'left'}} >{size.code}</div>
                                <div className={'col-md-2'} style={{textAlign:'right'}} >{i_item ? i_item.qty_inventory : 0}</div>
                                <div className={'col-md-2'} style={{textAlign:'right'}} >{i_item ? i_item.qty_open_sales : 0}</div>
                            </div>);
                    })}
                </div>
                <div className="col-md-6">
                    <h3>Purchase History</h3>
                    <button className={'btn btn-primary'} onClick={()=>this.navToPO()}>Create PO</button>
                </div>
            </div>
            <Popup show={this.state.show} save={() => this.saveChanges()} showSave={true} closeModal={()=>this.setState({show:false})} title={'Manual Adjustment'}>
                {this.state.sizes.map((size, index_f)=>{
                    let i_item = (this.state.inventory_data || []).find(it=>it.size === size.code);
                    return (
                        <div className="row" key={'var_item_' + 0 + '_field_' + index_f}>
                            <div className={'col-md-2'} style={{textAlign:'left'}} >{size.code}</div>
                            <div className={'col-md-2'} style={{textAlign:'right'}} >
                                <a onClick={()=>this.decrement(i_item,size)}><i className={'fa fa-minus'} /></a>&nbsp;&nbsp;
                                <input type={'text'} style={{width:'50px'}} onChange={(e)=>this.updateValue(i_item,size, e.target.value) } value={i_item ? i_item.qty_inventory : 0}/>
                                &nbsp;&nbsp;<a onClick={()=>this.increment(i_item,size)}><i className={'fa fa-plus'} /></a>
                            </div>

                        </div>);
                })}

            </Popup>
        </div>;
    }
}
