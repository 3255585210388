import EventEmitter from 'events';
let isLoggedIn = false;

export class UserManagementService extends EventEmitter{
    constructor(config={}){
        super();
    }
    login(data){
        localStorage.setItem('login_data',JSON.stringify(data));
        isLoggedIn = true;
    }

    loginData(){
        try{
            return JSON.parse(localStorage.getItem('login_data') );
        }catch(e){
            localStorage.setItem('login_data',undefined);
        }
        return JSON.parse(localStorage.getItem('login_data') );
    }
    logout(){
        localStorage.setItem('login_data',JSON.stringify({authenticated:false}));
        isLoggedIn = false;
        window.location.href = "/";
    }
    hasPermission(perm){
        let user =  JSON.parse(localStorage.getItem('login_data') );
        console.log("user", user);
        return true;
    }
    isLoggedIn(){
        let loginData = this.loginData() || {};
        if( loginData.authenticated)
            isLoggedIn = true;
        return isLoggedIn;
    }
}
