import {Popup} from '../../FormDriver/FormBuilderSupportComponent';
import React, {useEffect, useState} from 'react';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import useNav from '../../../services/hooks/useNav';
import useGetById from '../../../services/hooks/useGetById';
import useSearch from '../../../services/hooks/useSearch';
import {useUpsert} from '../../../services/hooks/useUpdate';
const embeddedReportHookContext = {
    entity: 'embedded_reports',
    expands:{'data_source_connections':'data_connection_id','data_source_items':'data_source_item_id'}
};
const connectionHookContext = {
    entity: 'data_source_connections'
};
const dataSourceHookContext = {
    entity: 'data_source_items'
};
export const NewEmbeddedReport = ({commit,show,setShow})=>{
    const [currReport,setCurrReport] = useState({});
    const [connections,setConnections] = useState([]);
    const [dataSources,setDataSources] = useState([]);
    const [currentConnectionId,setCurrentConnectionId] = useState();
    const {update} = useFieldUpdater({state:currReport,setState:setCurrReport});
    const {data:dataConnections} = useSearch({...connectionHookContext});
    const {data:dataSourceData} = useSearch({...dataSourceHookContext,filter_eq:{data_connection_id:currentConnectionId}});
    const {getState,navTo} = useNav();
    useEffect(()=>{
        if(dataConnections && dataConnections.data){
            setConnections(dataConnections.data);
        }
    },[dataConnections]);
    useEffect(()=>{
        if(dataSourceData && dataSourceData.data){
            setDataSources(dataSourceData.data);
        }
    },[dataSourceData]);

    const commitNew = async () =>{
        let tmpReport = {...currReport}; 
        tmpReport.data_connection_id = currentConnectionId;
        await commit(tmpReport);
    };
    return <Popup show={show} closeModal={()=>navTo('EmbeddedReportsList')} title={'New Embedded Report'} showSave={true} save={async (new_def)=> await commitNew()}>
        <div style={{maxHeight:'400px',overflow:'auto'}}>
            <div className="container-fluid">
                <div className="row">
                    <div className={'col-md-12'}>
                        <div className="form-group">
                            <input type={'text'} name="" id="" className={'form-control'} onChange={(e)=>update('report_title', e.target.value)}/>
                            <label htmlFor="">Report Title</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={'col-md-12'}>
                        <div className="form-group">
                            <select name="" id="" className={'form-control'} onChange={(e)=>setCurrentConnectionId(e.target.value)}>
                                <option value="">--Choose One</option>
                                {connections.map(i=>{
                                    return <option key={'connection_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                                })}
                            </select>
                            <label htmlFor="">Data Connection</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={'col-md-12'}>
                        <div className="form-group">
                            <select name="" id="" className={'form-control'} onChange={(e)=>update('data_source_item_id',e.target.value)}>
                                <option value="">--Choose One</option>
                                {dataSources.map(i=>{
                                    return <option key={'data_source_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                                })}
                            </select>
                            <label htmlFor="">Data Source</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Popup>;
};