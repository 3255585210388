import React, {Component} from 'react';
import {ObjContainer} from '../../services/object_container';
import {FieldDisplay, GroupedFormDefinition} from '../FormDriver/FormDefinition';
import './ViewLayer.scss';
export class ViewLayer extends Component{
    constructor(props={}){
        super();
        this.state ={collapsed:{},editing:{},obj:(props.obj || {})};
        this.service_manager = ObjContainer.resolve('service_manager');
        this.data_manager = ObjContainer.resolve('data_manager');
        this.ref_list = []; 
    }
    collectGroups(group){
        let group_data = this.props.schema_def.fields.filter((i)=>group.group_key === i.group_key);
        let res = (group_data || []).reduce((arr, item)=>{
            let row = arr.find(i => i.row_num === (item.layout ? item.layout.row : 100) );
            if(!row){
                if(item.layout)
                {arr.push({row_num: item.layout.row, cols:[{col_num: item.layout.column , width:item.layout.width, height: item.layout.height, field: item}]});}
                else
                {arr.push({row_num: 100, cols:[{col_num: 1, width:3, height: 1, field: item}]});}
            }else{
                if(item.layout)
                {row.cols.push({col_num: item.layout.column, width:item.layout.width, height: item.layout.height, field: item});}
                else
                {row.cols.push({col_num: 1, width:3, height: 1, field: item});}
                row.cols.sort((a,b)=>{
                    if(a.col_num < b.col_num) {return -1;}
                    if(a.col_num === b.col_num) {return 0;}
                    if(a.col_num > b.col_num) {return 1;}
                });
            }
            return arr;
        },[]);
        res.sort((a, b)=>{
            if(a.row_num < b.row_num) {return -1;}
            if(a.row_num === b.row_num) {return 0;}
            if(a.row_num > b.row_num) {return 1;}
        });
        return res || [];
    }
    editing(group){
        this.setState((s) =>{
            s.editing[group] = !s.editing[group];
            return s;
        });
    }
    onCancel(group){
        this.setState((s) =>{
            s.editing[group.group_title] = false;
            return s;
        });
    }
    async saveData(group){
        if(this.props.ops.onSave){
            await this.props.ops.onSave(group);
        }
        this.setState((s) =>{
            s.editing[group.group_title] = false;
            return s;
        });
    }
    expand(group){
        this.setState((s)=>{
            s.collapsed[group] =false;
            return s;
        });
    }
    collapse(group){
        this.setState((s)=>{
            s.collapsed[group] =true;
            return s;
        });
    }
    render(){
        return (<div>
            {(this.props.schema_def.layout_groups || []).map((group, i)=>{
                let group_layout = this.collectGroups(group);
                return (
                    <div key={'group_def_' + i}>
                        <div>
                            <div className={'section-header'}>
                                <h3 className={'section-title'}>{group.group_title} </h3>
                                <div className={'section-ops'}>
                                    {this.state.collapsed[group.group_title] && <a onClick={()=>this.expand(group.group_title)}><i className={'pi pi-angle-down'}/></a> }
                                    {!this.state.collapsed[group.group_title] && <a onClick={()=>this.collapse(group.group_title)}><i className={'pi pi-angle-right'}/></a> }
                                    <a onClick={()=>{this.editing(group.group_title);}}><i className={'pi pi-pencil'}/></a></div>
                            </div>
                            <div className={'clear'} />
                        </div>
                        <div>
                            {!this.state.collapsed[group.group_title] && <div>
                                <GroupedFormDefinition definition={group_layout}
                                    edit_mode={this.props.edit_mode || this.state.editing[group.group_title]}
                                    obj={this.props.obj}
                                    ops={Object.assign({},this.props.ops,
                                        {onSave: async (e)=> {
                                            e.preventDefault();
                                            return await this.saveData(group);
                                        },
                                        onCancel:     ()=> this.onCancel(group)   })
                                    }
                                    buttons={[]}
                                />
                            </div>}
                        </div>
                        <hr/>
                    </div>
                );
            })}

            {!this.props.schema_def.layout_groups &&
            <div className={'row'} >
                {this.props.schema_def.fields.map((field,i) =>{
                    return [<div key={'item_1_' + i}  className={'col-md-2'}><label>{field.field_name}</label></div>,
                        <div key={'item_2_' + i} className={'col-md-4'}><FieldDisplay data={this.props.obj} field={field} ops={this.props.ops}/></div>];
                })}
            </div>}
        </div>);
    }
}
