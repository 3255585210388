import React, { Component } from 'react';
import {utils, read} from 'xlsx';
import {ObjContainer} from '../../services/object_container';
import {VDropdown} from '../FormDriver/VDropdown';

export class TableImportComponent extends Component{

    constructor(props) {
        super(props);
        this.file = undefined;
        this.state = {
            definition:props.definition,
            parent_id: props.parent_id,
            parent_collection:props.parent_collection,
            cols:[],
            worksheets:[],
            config:{skip_lines:0,worksheets:''},
            current_map:[],
            current_open: -1,
            reference_sheet:'',
            upload_worksheets:[]
        };
        this.service_manager = ObjContainer.resolve('service_manager');
        this.arrayBuffer = undefined;
    }

    async handleFiles(e){
        this.file= e.target.files[0];
        if(!this.arrayBuffer){
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                this.readErrors = 0;
                this.arrayBuffer = fileReader.result;
                this.readAll();
            };
            fileReader.readAsArrayBuffer(this.file);
        }else{
            this.readAll();
        }
    }
    test(){
        this.readFile();
    }

    readFile(){
        if(this.file){
            if(!this.arrayBuffer){
                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.readErrors = 0;
                    this.arrayBuffer = fileReader.result;
                    this.readAll();
                };
                fileReader.readAsArrayBuffer(this.file);
            }else{
                this.readAll();
            }
        }
    }
    readAll(){
        let data = new Uint8Array(this.arrayBuffer);
        let arr = new Array();
        for(let i = 0; i != data.length; ++i)
        {arr[i] = String.fromCharCode(data[i]);}
        let bstr = arr.join('');
        let workbook = read(bstr, {type:'binary',cellDates:true, cellNF: false, cellText:false});
        let worksheet = workbook.Sheets[this.state.reference_sheet || workbook.SheetNames[0]];
        this.filetoupload =utils.sheet_to_json(worksheet,{raw:false,dateNF:'MM/DD/YYYY',range:+this.state.config.skip_lines});
        let objcols = this.get_header_row(worksheet,(+this.state.config.skip_lines));
        this.setState({cols:objcols, worksheets:workbook.SheetNames});
    } 
    processAll(cb){
        let data = new Uint8Array(this.arrayBuffer);
        let arr = new Array();
        for(let i = 0; i != data.length; ++i)
        {arr[i] = String.fromCharCode(data[i]);}
        let bstr = arr.join('');
        let workbook = read(bstr, {type:'binary',cellDates:true, cellNF: false, cellText:false});
        for(let sheet_i = 0 ; sheet_i <  this.state.upload_worksheets.length; sheet_i++){
            let worksheet = workbook.Sheets[this.state.upload_worksheets[sheet_i]];
            cb(utils.sheet_to_json(worksheet,{raw:false,dateNF:'MM/DD/YYYY',range:+this.state.config.skip_lines}));
        }
    }
    changeConfig(field, value){
        this.setState((s)=>{
            s.config[field] = value;
            return s;
        });
    }
    get_header_row(sheet, header_offset=0) {
        let headers = [];
        let range = utils.decode_range(sheet['!ref']);
        let C; let R = range.s.r ;
        for(C = range.s.c; C <= range.e.c; ++C) {
            let cell = sheet[utils.encode_cell({c:C, r:R+ header_offset})]; /* find the cell in the first row */
            let hdr = 'UNKNOWN ' + (C || ''); // <-- replace with your desired default
            if(cell && cell.t) {hdr = utils.format_cell(cell) || '';}
            headers.push(hdr);
        }
        return headers;
    }
    setMappedItem(item,index, mapped_name){

        this.setState((s)=>{
            s.current_map[index] = {mapped_name:mapped_name, field: item};
            return s;
        });
    }
    changeReference(reference){
        this.setState({reference_sheet:reference});
        this.readAll();
    }
    setOptions(index, option, value){
        this.setState((s)=>{
            s.current_map[index][option] = value;
            return s;
        });
    }
    toggleWorksheet(sheet_name){
        this.setState((s)=>{
            let index = s.upload_worksheets.indexOf(sheet_name);
            if(index > -1){
                s.upload_worksheets.splice(index, 1);
            }else{
                s.upload_worksheets.push(sheet_name);
            }
            return s;
        });
    }
    async upload(){
        if(!this.file) {return;}
        let schema = [];
        let all = [];
        this.processAll((data)=>{
            let parsed = data.reduce((arr, data )=>{
                let new_item = {};
                this.state.current_map.filter(i => i !== undefined && !i.pivot).forEach((map_item)=>{
                    new_item[map_item.field.field_code] = data[map_item.mapped_name];
                });
                let pivots = this.state.current_map.filter(i => i !== undefined && i.pivot);
                if(pivots.length > 0){
                    pivots.forEach((pivot_item)=>{
                        let cpy = Object.assign({}, new_item);
                        cpy[pivot_item.field.field_code] = pivot_item.mapped_name;
                        cpy[pivot_item.piv_value_field.field_code] = data[pivot_item.mapped_name];
                        arr.push(cpy);
                    });
                }else{
                    arr.push(new_item);
                }
                return arr;
            },[]);
            all = all.concat(parsed);
        });
        this.state.current_map.filter(i => i !== undefined).forEach((map_item)=>{
            map_item.field.field_type = map_item.primary ? 'primary': undefined;
            schema.push(map_item.field);
            if(map_item.piv_value_field)
            {schema.push(map_item.piv_value_field);}
        });
        let distinct = schema.filter((value, index, self)=>{
            return self.indexOf(value) === index;
        });
        await this.service_manager.resolve(this.state.definition.table_name)
            .upload_upsert(JSON.stringify(all),{parent_id: this.state.parent_id, parent_collection: this.state.parent_collection},JSON.stringify({import_map:distinct}));
        if(this.props.close)
        {this.props.close();}
    }
    render(){
        return (<div className={'container'}>
            <div className="row">
                <div className={'col-md-6'}><label>File:</label></div><div className="col-md-6">
                    <input className={'form-control'} type={'file'} name={this.state.definition.table_name} onChange={(e) => this.handleFiles(e)}/></div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label>Header Lines to Skip:</label>
                </div>
                <div className="col-md-6">
                    <input  className={'form-control'} type={'number'} name={'skip_lines'} value={this.state.config.skip_lines} onChange={(e) => this.changeConfig('skip_lines',e.target.value)}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label>Reference Sheet:</label>
                </div>
                <div className="col-md-6">
                    <VDropdown
                        title={this.state.reference_sheet ?   this.state.reference_sheet  : 'Choose Worksheet'}
                        onClick={(item)=>this.changeReference(item)}
                        simple={true}
                        items={this.state.worksheets}
                        display_field={'reference_sheet'}
                    />
                </div>
            </div>
            <button className='btn btn-primary' onClick={ ()=>  this.test()}>Describe</button>&nbsp;
            <button className='btn btn-primary' onClick={async ()=> await this.upload()}>Import</button>&nbsp;
            <button className='btn btn-default' onClick={async ()=> await this.props.cancel()}>Cancel</button>
            <div >
                <div >
                    <div>Headers</div>
                    <div style={{ height:'225px', overflow:'auto', border: '1px solid silver'}}>
                        <table className={'table'}>
                            <thead><tr><th>Excel Column</th><th>Mapped To</th></tr></thead>
                            <tbody>
                                {(this.state.cols || []).map((col, index)=>{
                                    return <tr key={'header-column-' + index}>
                                        <td >{col}</td>
                                        <td>
                                            <div>
                                                <VDropdown
                                                    title={this.state.current_map[index] ?   this.state.current_map[index].field.field_name  : 'Map To'}
                                                    onClick={(item)=>this.setMappedItem(item, index,col)}
                                                    items={this.state.definition.fields}
                                                    display_field={'field_name'}
                                                />
                                                {this.state.current_map[index] &&
                                                    <div >
                                                        <input type={'checkbox'} onClick={(e) => this.setOptions(index, 'pivot', e.target.checked)}/> <label>Pivot</label>&nbsp;
                                                        <input type={'checkbox'} onClick={(e) => this.setOptions(index, 'primary', e.target.checked)} /> <label>Primary</label>

                                                        {this.state.current_map[index].pivot &&
                                                        <VDropdown
                                                            title={this.state.current_map[index].piv_value_field ?   this.state.current_map[index].piv_value_field.field_name  : 'Map To'}
                                                            onClick={(item)=>this.setOptions(index, 'piv_value_field', item)}
                                                            items={this.state.definition.fields}
                                                            display_field={'field_name'}
                                                        />}
                                                    </div>}
                                            </div>
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div >
                    <div>Worksheets</div>
                    <div style={{   height:'225px', overflow:'auto', border: '1px solid silver'}}>
                        {(this.state.worksheets || []).map((worksheet, index)=>{
                            return (
                                <div key={'header-worksheet-' + index}>
                                    <input type={'checkbox'} checked={this.state.upload_worksheets.indexOf(worksheet) > -1} onClick={()=> this.toggleWorksheet(worksheet)}/> {worksheet}
                                </div>);
                        })}
                    </div>
                </div>

            </div>
        </div>);
    }

}

