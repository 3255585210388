import useBetweenDatesForm from '../../../services/hooks/useBetweenDatesForm';
import moment from 'moment';

const queryFn = ({ source, fromDate = moment().dayOfYear(1), toDate = moment() }) => {
    return source === 'order'
        ? `select c.id, c.customer_name, c.phone, c.email, c.city, c.state, c.country, c.customer_name || COALESCE(' | ' || c.city || ', ' || c.state || COALESCE(', ' || c.country, ''), '') || COALESCE(' | ' || c.phone, '') || COALESCE (' | ' || c.email, '') as concat_customer, p.id product_id, COALESCE(p.style_number, oi.style_number) || ' ' || COALESCE(pv.variation_code, oi.attr_2) as style_description, sum(oi.qty) qty, sum(oi.amount) amount 
                from customers c 
                    join orders o on o.customer_id = c.id 
                    join order_items oi on o.apm_order_id = oi.apm_order_id 
                    join products p on p.id = COALESCE(oi.product_id, (select id from products prd where prd.style_number = oi.style_number))
                    left join product_variations pv on pv.id = COALESCE(oi.product_variant_id, (select id from product_variations prodvar where prodvar.variation_code = oi.attr_2 and prodvar.product_id = p.id))
            where o.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format('YYYY/MM/DD')}' 
                group by c.id, c.customer_name, c.phone, c.email, c.city, c.state, c.country, p.id, style_description order by c.customer_name;`
        : `select c.id, c.customer_name, c.phone, c.email, c.city, c.state, c.country, c.customer_name || COALESCE(' | ' || c.city || ', ' || c.state || COALESCE(', ' || c.country, ''), '') || COALESCE(' | ' || c.phone, '') || COALESCE (' | ' || c.email, '') as concat_customer, p.id product_id, COALESCE(p.style_number, ii.style_number) || ' ' || COALESCE(pv.variation_code, ii.attr_2) as style_description, sum(ii.qty) qty, sum(ii.amount) amount 
                from customers c 
                    join invoices i on i.customer_id = c.id 
                    join invoice_items ii on i.id = ii.invoice_id 
                    join products p on p.id = COALESCE(ii.product_id, (select id from products prd where prd.style_number = ii.style_number))
                    left join product_variations pv on pv.id = COALESCE(ii.product_variant_id, (select id from product_variations prodvar where prodvar.variation_code = ii.attr_2 and prodvar.product_id = p.id))
                where i.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format('YYYY/MM/DD')}' 
                group by c.id, c.customer_name, c.phone, c.email, c.city, c.state, c.country, p.id, style_description order by c.customer_name;`;
};

const ItemsByCustomer = (source) => {
    return {
        title: `${source.charAt(0).toUpperCase() + source.slice(1)} Items by Customer`,
        queryFormHook: useBetweenDatesForm,
        queryFormText: 'Show data from orders between',
        queryFn: ({ fromDate, toDate }) => queryFn({ source, fromDate, toDate }),
        columns: [
            {
                dataField: 'concat_customer',
                caption: 'Customer',
                dataType: 'string',
                groupIndex: 0,
                groupCellRender: (data) => {
                    const items = data.data?.items ?? data.data.collapsedItems;
                    const companyInfo = (items && items[0]) ?? {};
                    const href = `/customers-Viewcustomers#${companyInfo?.id}`;
                    return (
                        <>
                            <div>
                                <a href={href}>{companyInfo?.customer_name}</a>
                            </div>
                            <div style={{ fontWeight: 'normal' }}>
                                {companyInfo?.city}, {companyInfo?.state}
                                {companyInfo?.country && `, ${companyInfo?.country}`}
                            </div>
                            <div style={{ fontWeight: 'normal' }}>
                                {[companyInfo?.phone, companyInfo?.email].filter((el) => el !== null).join(' | ')}
                            </div>
                        </>
                    );
                },
            },
            {
                dataField: 'style_description',
                caption: 'Style',
                dataType: 'string',
                cellRender: (data) => {
                    return (
                        <a href={`http://localhost:3000/products-Viewproducts#${data.data.product_id}`}>{data.value}</a>
                    );
                },
            },
            {
                dataField: 'qty',
                caption: 'Qty',
                dataType: 'number',
            },
            {
                dataField: 'amount',
                caption: 'Amount',
                dataType: 'number',
                format: 'currency',
            },
        ],
        summaryItems: [
            {
                column: 'qty',
                summaryType: 'sum',
                displayFormat: 'Total Items:  {0}',
                alignByColumn: true,
                showInGroupFooter: true,
            },
            {
                column: 'amount',
                summaryType: 'sum',
                valueFormat: 'currency',
                displayFormat: 'Total:  {0}',
                alignByColumn: true,
                showInGroupFooter: true,
            },
        ],
    };
};

export default ItemsByCustomer;
