import {DataTypeIcon} from '../SchemaBuilder/DataTypeIcon';
import React, {useEffect, useState} from 'react';
import useSearch from '../../../services/hooks/useSearch';
const connectionHookContext = {
    entity: 'data_source_connections'
};
const dataSourceHookContext = {
    entity: 'data_source_items'
};
export const ConnectionInfo = ({currReport, update,setCurDataSource}) =>{
    const [connections,setConnections] = useState([]);
    const [dataSources,setDataSources] = useState([]);
    const {data:dataConnections} = useSearch({...connectionHookContext});
    const {data:dataSourceData} = useSearch({...dataSourceHookContext,filter_eq:{data_connection_id:currReport.data_connection_id}});
    useEffect(()=>{
        if(dataConnections && dataConnections.data){
            setConnections(dataConnections.data);
        }
    },[dataConnections]);
    useEffect(()=>{
        if(dataSourceData && dataSourceData.data){
            setDataSources(dataSourceData.data);
        }
    },[dataSourceData]);
    const updateCurDataSource = (value)=>{
        if(value){
            update('data_source_item_id', value);
            setCurDataSource(dataSources.find(i=>+i.id === +value));
        }
    };
    return <div >
        <div className={'connection-selector'}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">
                            Report Name
                        </label>
                        <input className={'form-control'} type="text" value={currReport.report_name || ''} onChange={(e)=>update('report_name',e.target.value)}/>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Connection</label>
                        <select className={'form-control'} name="" id="" value={currReport.data_connection_id || ''}
                            onChange={(e)=>update('data_connection_id',e.target.value)}>
                            <option value="">--Choose One</option>
                            {connections.map(i=>{
                                return <option key={'connection_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className={'dataset-selector'}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Datasource</label>
                        <select className={'form-control'} value={currReport.data_source_item_id || ''}
                            onChange={(e)=>updateCurDataSource(e.target.value)}>
                            <option value="">--Choose One</option>
                            {dataSources.map(i=>{
                                return <option key={'data_source_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};