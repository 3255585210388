import React, {useEffect, useState} from "react";

export const GroupConfigurationItem = ({table,groupItem,updateTable, all_tables})=>{
    const [curGroup, setCurGroup] = useState(groupItem || {});
    const [currTable, setCurrTable] = useState(table);
    useEffect(()=>{
        setCurGroup(groupItem);
    },[groupItem]);
    useEffect(()=>{
        setCurrTable(table);
    },[table]);

    const saveObjects = ()=>{
        updateTable(currTable);
    }
    const update = (field, value)=>{
        let tmpCurGroup = {...groupItem};
        let tmpCurTable = {...currTable};
        tmpCurGroup[field] = value;
        setCurGroup(tmpCurGroup);
        tmpCurTable.group_list = tmpCurTable.group_list || [];
        let ind = tmpCurTable.group_list.indexOf(groupItem);
        if(ind < 0)
            tmpCurTable.group_list.push(tmpCurGroup);
        else
            tmpCurTable.group_list[ind] = tmpCurGroup;
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    }
    const removeRow = ()=>{
        let tmpCurTable = {...currTable};
        tmpCurTable.group_list = tmpCurTable.group_list || [];
        tmpCurTable.group_list.splice(tmpCurTable.group_list.indexOf(groupItem),1);
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    }
    const addRow = ()=>{
        let tmpCurTable = {...currTable};
        tmpCurTable.group_list = tmpCurTable.group_list || [];
        tmpCurTable.group_list.push({});
        updateTable(tmpCurTable)
        setCurrTable(tmpCurTable);
    }
    return <>
        <div className="row">
            <div className="col-md-3">
                <select name="" id="" placeholder={"Source Field"} value={curGroup.source_field} onChange={(e)=>
                    update('source_field', e.target.value)} className={"form-control"}>
                    <option>--Choose Source Column</option>
                    {table.column_data.map((item,index)=>{
                        return <option key={"table_join_source_column_" + index} value={item.name}>{item.name}</option>;
                    })}
                </select>
            </div>
            <div className="col-md-3">
                <select name="" id="" placeholder={"Direction"} value={curGroup.group_direction} onChange={(e)=>
                    update('group_direction', e.target.value)} className={"form-control"}>
                    <option value={"asc"}>Ascending</option>
                    <option value={"desc"}>Descending</option>
                </select>
            </div>
            <div className="col-md-1">
                <a className={"inline-nav-link"}  onClick={()=>addRow()}><i className={"fa fa-plus"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={()=>saveObjects()}><i className={"fa fa-save"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={()=>removeRow()}><i className={"fa fa-trash"} /></a>
            </div>
        </div>
    </>;
}