import { useState, useEffect } from 'react';
import useRawQuery from '../../../services/hooks/useRawQuery';

const getQueryFnAndColumns = (source, itemId, productId) => {
    const isOrder = source === 'order';

    const [sizes, setSizes] = useState([]);
    const [sizeSQL, setSizeSQL] = useState('');
    const [sizeColumns, setSizeColumns] = useState([]);
    const { rows: sizeData } = useRawQuery(`select s.code code, s.size_range size_range
        from size_ranges sr
            join sizes s on sr.code = s.size_range
            join products p on p.size_range = sr.code
            where p.id = ${productId}
            order by s.order_number;`);

    useEffect(() => {
        if (sizeData.length > 0) {
            setSizes(
                sizeData.map((row) => {
                    return { code: row.code, sizeRange: row.size_range };
                })
            );
        } else if (sizes.length !== 0) {
            setSizes([]);
        }
    }, [sizeData]);

    useEffect(() => {
        if (sizes.length > 0) {
            setSizeSQL(
                sizes
                    .map(
                        (size) =>
                            `sum(case when size IN ('${size.code}', '${size.sizeRange}') then ${
                                isOrder ? 'qty_open' : 'qty'
                            } else 0 end) ${size.code}`
                    )
                    .join(',') + ','
            );

            setSizeColumns(
                sizes.map(({ code }) => {
                    return {
                        dataField: code.toLowerCase(),
                        caption: code.toUpperCase(),
                        dataType: 'string',
                        width: 'auto',
                    };
                })
            );
        } else {
            sizeSQL !== '' && setSizeSQL('');
            sizeColumns.length > 0 && setSizeColumns([]);
        }
    }, [sizes]);

    const queryFn = isOrder
        ? `select  p.style_number, pv.variation_code, ${sizeSQL}
                sum(qty_open) qty_open, sum(amount_open) amount_open
            from order_items oi
                join products p on p.id = COALESCE(oi.product_id, (select id from products prd where prd.style_number = oi.style_number))
                left join product_variations pv on pv.id = COALESCE(oi.product_variant_id, (select id from product_variations prodvar where prodvar.variation_code = oi.attr_2 and prodvar.product_id = p.id))
            where apm_order_id = ${itemId} and p.id = ${productId} and qty_open > 0
            group by p.style_number, pv.variation_code
            order by variation_code;`
        : `select p.style_number, pv.variation_code, ${sizeSQL}
                sum(qty) qty, sum(amount) amount
            from invoice_items ii
                join products p on p.id = COALESCE(ii.product_id, (select id from products prd where prd.style_number = ii.style_number))
                left join product_variations pv on pv.id = COALESCE(ii.product_variant_id, (select id from product_variations prodvar where prodvar.variation_code = ii.attr_2 and prodvar.product_id = p.id))
            where invoice_id = ${itemId} and p.id = ${productId} and qty > 0
            group by p.style_number, pv.variation_code
            order by variation_code;`;

    const columns = [
        {
            dataField: 'style_number',
            caption: 'Style',
            dataType: 'string',
            width: 75,
        },
        {
            dataField: 'variation_code',
            caption: 'Variation',
            dataType: 'string',
            width: 75,
        },
        ...sizeColumns,
        {
            dataField: isOrder ? 'qty_open' : 'qty',
            caption: 'Qty',
            dataType: 'number',
            width: 50,
        },
        {
            dataField: isOrder ? 'amount_open' : 'amount',
            caption: 'Amount',
            dataType: 'number',
            format: 'currency',
            width: 100,
        },
    ];

    return { queryFn, columns };
};

const ByCustomerAndProductDetail = ({ source, itemId, productId }) => {
    const { queryFn, columns } = getQueryFnAndColumns(source, itemId, productId);
    return {
        isDetail: true,
        title: null,
        queryFn: () => queryFn,
        columns: columns,
        isGroupable: false,
        isSearchable: false,
        isFilterableByRow: false,
        isFilterableByPanel: false,
        isExportable: false,
    };
};

export default ByCustomerAndProductDetail;
