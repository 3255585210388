import React, {useEffect, useState} from "react";
import {DataTypeIcon} from "./DataTypeIcon";
import {TableDetailsConfig} from "./TableDetailsConfig";
import {JoinConfiguration} from "./JoinConfiguration";
import {ColumnSettingsLink} from "./ColumnSettingsLink";
import {JoinConfigurationItem} from "./JoinConfigurationItem";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";
import {GroupsConfiguration} from "./GroupsConfigurataion";
import {FiltersConfiguration} from "./FiltersConfiguration";
import {QueryBuilder} from "./QueryBuilder";
import {StoredProcBuilder} from "./StoredProcBuilder";

export const TableDetailView = ({table,removeTable,selectTable, updateTable,schema, updateSchema})=>{
    const [showCols, setShowCols] = useState(false)
    const [showConfig, setShowConfig] = useState(false);
    const [showJoins, setShowJoins] = useState(false);
    const [showGroups, setShowGroups] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [curTable, setCurTable] = useState(table);
    const [allTables, setAllTables] = useState([]);
    useEffect(()=>{
        if(table){
            setCurTable(table );
        }
    },[table]);
    useEffect(()=>{
        if(schema && schema.schema_definition.tables){
            setAllTables(schema.schema_definition.tables || []);
        }
    },[schema])

    const colUpdated= (col)=>{
        let tmpCurTable = {...curTable};
        let tmpCol = tmpCurTable.column_data.find(i=>i.name === col.name);
        tmpCurTable.column_data[tmpCurTable.column_data.indexOf(tmpCol)] = col;
        updateTable(tmpCurTable)
        setCurTable(tmpCurTable);
    }
    if(!table)
        return <div></div>;
    return <div  className={"sel-table-item"}>
        <div className="sel-table-header">
            <a className={"inline-nav-link"} onClick={() => selectTable(curTable)}>{table.table_name}</a>
        </div>
        <div className="sel-table-body">
            {showCols && <div className={"sel-table-body-cols"}>
                {curTable.column_data.filter(i=>i.enabled).map((col, ind)=>{
                    return <div className="row">
                        <div className="col-md-2">
                            <ColumnSettingsLink col={col} all_tables={allTables} colUpdated={colUpdated}/>
                        </div>
                        <div className="col-md-8">
                            <div className={"col-data"}>{col.name}</div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <DataTypeIcon id={col.user_type_id} />
                            </div>
                        </div>
                    </div>

                })}
            </div>}
            {!showCols && <div>{curTable.column_data?.filter(i=>i.enabled).length} Column(s)</div>}
        </div>
        {(curTable.table_type === 'table' || curTable.table_type === 'V' || !curTable.table_type) &&
        <>
            <div className="sel-table-footer">
                <a className={"inline-nav-link"}  onClick={()=>setShowGroups(true)}><i className={"fas fa-cubes"} /></a> &nbsp;
                <a className={"inline-nav-link"}  onClick={()=>setShowFilters(true)}><i className={"fas fa-filter"} /></a> &nbsp;
                <a className={"inline-nav-link"}  onClick={()=>setShowJoins(true)}><i className={"fas fa-infinity"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={() =>{setShowCols(!showCols)}}><i className={"fas fa-list-ul"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={()=>removeTable(curTable)}><i className={"fas fa-trash"} /></a> &nbsp;
                <a className={"inline-nav-link"}  onClick={()=>setShowConfig(true)}><i className={"fas fa-cog"} /></a>
            </div>
            <TableDetailsConfig table={curTable} updateTable={updateTable} showConfig={showConfig} setShowConfig={setShowConfig}/>
            <JoinConfiguration table={curTable} all_tables={allTables} updateTable={updateTable} show={showJoins} setShow={setShowJoins}/>
            <GroupsConfiguration table={curTable} all_tables={allTables} updateTable={updateTable} show={showGroups} setShow={setShowGroups}/>
            <FiltersConfiguration schema={schema} table={curTable} all_tables={allTables} updateTable={updateTable} show={showFilters} setShow={setShowFilters}/>
        </>}
        {curTable.table_type === 'query' &&
        <>
            <div className="sel-table-footer">
                <a className={"inline-nav-link"} onClick={() =>{setShowCols(!showCols)}}><i className={"fas fa-list-ul"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={()=>removeTable(curTable)}><i className={"fas fa-trash"} /></a> &nbsp;
                <a className={"inline-nav-link"}  onClick={()=>setShowConfig(true)}><i className={"fas fa-cog"} /></a>
            </div>
            <QueryBuilder show={showConfig} table={curTable} dataset={schema} setShow={setShowConfig} updateDataset={updateSchema}/>
        </>}
        {curTable.table_type === 'sp' &&
            <>
                <div className="sel-table-footer">
                    <a className={"inline-nav-link"} onClick={() =>{setShowCols(!showCols)}}><i className={"fas fa-list-ul"} /></a> &nbsp;
                    <a className={"inline-nav-link"} onClick={()=>removeTable(curTable)}><i className={"fas fa-trash"} /></a> &nbsp;
                    <a className={"inline-nav-link"}  onClick={()=>setShowConfig(true)}><i className={"fas fa-cog"} /></a>
                </div>
                <StoredProcBuilder show={showConfig} table={curTable} dataset={schema} setShow={setShowConfig} updateDataset={updateSchema}/>
            </>}

    </div>
}


