import React, {useEffect, useState} from 'react';
import {useRouteData} from '../../../services/hooks/routes/useRouteData';
const permissionHookContext = {
    entity: 'permissions',
    operation: 'permission_list'
};

export const Permissions = ({user,setUser}) =>{
    const [permissions, setPermissions] = useState([]);
    const {data:permission_data} = useRouteData({...permissionHookContext});
    useEffect(()=>{
        if(permission_data){
            setPermissions(permission_data.permissions);
        }
    },[permission_data]);
    const updateCapability = (cap, checked)=>{
        let tmpUser = {...user};
        tmpUser.permissions = tmpUser.permissions || {assigned:[]};
        if(checked){
            tmpUser.permissions.assigned.push(cap);
        }
        else{
            let perm = tmpUser.permissions.assigned.find(item => cap.value === item.value);
            if(perm){
                tmpUser.permissions.assigned.splice(tmpUser.permissions.assigned.indexOf(perm),1);
            }
        }
        setUser(tmpUser);
    };
    return <div>
        <h4>Permissions</h4>
        {permissions.map((permission_area,i)=>{
            return <div key={'permission_area_' + i}>
                <div className={'permission_title'}>{permission_area.title}</div>
                {permission_area.capabilities.map((cap,j)=>{
                    return (<div className="form-check" key={'permission-cb-' + i + '-of-' + j}>
                        <input className="form-check-input" type="checkbox" value={cap.value} checked={user.permissions?.assigned?.find(m=>m.value === cap.value)}
                            id="defaultCheck1"
                            onChange={(e)=>{updateCapability(cap,e.target.checked);}}/>
                        <label className="form-check-label" htmlFor="defaultCheck1">
                            {cap.display}
                        </label>
                    </div>);
                })}
            </div>;
        })}
    </div>;
};