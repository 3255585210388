import React from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import useSearch from '../../services/hooks/useSearch';
import useNav from '../../services/hooks/useNav';
import useDelete from "../../services/hooks/useDelete";


const datasetHookContext = {
    entity: 'data_source_items',
    expands:{'data_source_connections':'data_connection_id'},
    paging:{pageSize:5000}
}; 


export const DataSetList = ()=>{

    const [datasets, setDatasets] = React.useState([]);
    const {navTo} = useNav(datasetHookContext);
    const { data:dataset_data } = useSearch({
        ...datasetHookContext
    });
    const [deleteDataset] = useDelete({...datasetHookContext});
    React.useEffect(() => {
        if (dataset_data && dataset_data.data) {
            setDatasets(dataset_data.data);
        } else {
            setDatasets([]);
        }
    }, [dataset_data]);
    const addDashboard = async()=>{
        navTo('DataConnectionSchemaImport',{});

    };
    const deleteDashboard = async (dashboard) =>{
        if(confirm("are you sure?")){
            await deleteDataset(dashboard);
        }
    };
    const nameBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => navTo('DataConnectionSchemaImport',rowData)}>{rowData.data_source_name}</a>
            </>
        );
    };
    const actionsBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await deleteDashboard(rowData)}>Delete</a>
            </>
        );
    };
    const connectionBodyTemplate =(rowData) =>{
        return (
            <>
                {rowData.data_source_connections?.data_source_name}
            </>
        );
    };
    return (<div>
        <h2>Datasets</h2>
        <div>
            <div  className={'valerian-search'}>
                <button className={'btn btn-primary'} onClick={() =>addDashboard()}>Add Data Source</button>
            </div>
            <DataTable  className="p-datatable-customers" value={datasets}
                header={''}  dataKey="id" rowHover
                paginator rows={10} emptyMessage="No reports found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                <Column field="data_source_name" header="Name"  body={nameBodyTemplate} sortable filter  />
                <Column field="api_key" header="Key"   sortable filter filterPlaceholder="Search by key" />
                <Column field="connection" header="connection" body={connectionBodyTemplate}   sortable filter filterPlaceholder="Search Connection" />
                <Column field="id" header="Actions"  body={actionsBodyTemplate}  />
            </DataTable>
        </div>
    </div>);
};