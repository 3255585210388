import React, {Component, PureComponent, useEffect} from 'react';
import {ObjContainer} from '../../services/object_container';
import {TabPanel, TabView} from 'primereact/tabview';
import {ChooseReportTypes, ManagedReportRunner, ReportRunner, Reports} from './ReportBuilder';
import {DataSources} from './DataSourceBuilder';
import GridLayout from 'react-grid-layout';
import {LayoutBuilder} from './LayoutBuilder';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import {DashboardDataService} from './shared/DashboardDataService';
import {ReportDataService} from './shared/ReportDataService';
import {Popup} from '../FormDriver/FormBuilderSupportComponent';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
const dashboardService = new DashboardDataService();
const reportService = new ReportDataService();
import './datatable.css'; 
export function DashboardManagerComponent(){
    return (<div >
        <h3>Dashboard Manager</h3>
        <TabView >
            <TabPanel header={'Analyses'}>
                <div style={{height:'82vh', overflowY:'auto'}}>
                    <Dashboards/>
                </div>
            </TabPanel>
            <TabPanel header={'Datasets'}>
                <div style={{height:'82vh', overflowY:'auto'}}>
                    <DataSources />
                </div>
            </TabPanel>
            <TabPanel header={'Layouts'}>
                <div style={{height:'82vh', overflowY:'auto'}}>
                    <LayoutBuilder />
                </div>
            </TabPanel>
        </TabView>
        <br/>
    </div>);
}
export function Dashboards(){
    const [show, setShow] = React.useState(false);
    const [dashboards, setDashboards] = React.useState([]);
    const [dashboard, setDashboard] = React.useState({});
    let {data:dashboard_data}  =  useQuery(['v_dashboards'],dashboardService.getDashboards);
    const queryCache = useQueryCache();
    const [mutateDeleteDashboard] = useMutation(dashboardService.deleteDashboard,{
        onSuccess: async (salesOrder, mutationVariables) => {
            await queryCache.invalidateQueries(['v_dashboards']);
        },
    });
    React.useEffect(() => {
        if (dashboard_data) {
            setDashboards(dashboard_data);
        } else {
            setDashboards([]);
        }
    }, [dashboard_data]);
    const addDashboard = async()=>{
        setDashboard({reports:[]});
        setShow(true);
    };
    const deleteDashboard = async (dashboard) =>{
        await mutateDeleteDashboard(dashboard);
    };
    const refresh = async () =>{
        await queryCache.invalidateQueries(['dashboards']);
    };
    const showDashboard = (dashboard) =>{
        setDashboard({...dashboard});
        setShow(true);
    };
    const nameBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await showDashboard(rowData)}>{rowData.dashboard_title}</a>
            </>
        );
    };
    const actionsBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await deleteDashboard(rowData)}>Delete</a>
            </>
        );
    };
    return (<div>
        <h2>Dashboards</h2>
        {!show && <div>
            <div  className={'valerian-search'}>
                <button className={'btn btn-primary'} onClick={() =>addDashboard()}>Add Analysis</button>
            </div>

            <DataTable  className="p-datatable-customers" value={dashboards}
                header={''}  dataKey="id" rowHover
                paginator rows={10} emptyMessage="No reports found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                <Column field="report_name" header="Actions"  body={actionsBodyTemplate} style={{width:'95px'}} />
                <Column field="report_name" header="Name"  body={nameBodyTemplate} sortable filter filterPlaceholder="Search by name" />
                <Column field="created_by" header="Created By"  sortable filter filterPlaceholder="Created By" />
                <Column field="created_on" header="Created On"  sortable filter filterPlaceholder="Created On" />
            </DataTable>

        </div>}
        {show &&
                    <DashboardBuilder dashboard={dashboard} complete={async ()=> await refresh()} setShow={setShow}/>
        }
    </div>);
}
export function DashboardBuilder({dashboard,complete, setShow}){
    const [theDashboard, setTheDashboard] = React.useState(dashboard || {});
    const [dashboardReports, setDashboardReports] = React.useState(dashboard.reports || []);
    const queryCache = useQueryCache();
    const [mutateDashboard] = useMutation(dashboardService.saveDashboard,{
        onSuccess: async (salesOrder, mutationVariables) => {
            await queryCache.invalidateQueries(['dashboards']);
        },
    });
    const removeReport = (report) =>{
        let index = theDashboard.reports.indexOf(report);
        theDashboard.reports.splice(index, 1);
        setDashboardReports([...theDashboard.reports]);
        return theDashboard.reports;
    };
    const upsertReportForDashboard = (report)=>{
        if(!report){return;}
        if(!report._id || !theDashboard.reports.find(i=>i._id === report._id)){
            theDashboard.reports.push(report);
        }else{
            let index = theDashboard.reports.indexOf(theDashboard.reports.find(i=>i._id === report._id));
            theDashboard.reports[index] = {...report};
        }
        setDashboardReports([...theDashboard.reports]);
        return theDashboard.reports;
    };
    const _save = async () => {
        await mutateDashboard(theDashboard);
        await complete();
        setShow(false);
        setTheDashboard({...theDashboard});
        setDashboardReports([...theDashboard.reports]);
    };
    const onChange = (field, value)=>{
        theDashboard[field] = value;
        setTheDashboard({...theDashboard});
        setDashboardReports([...theDashboard.reports]);
    };
    return (<div className={'container-fluid'}>
        <div className={'analysis-builder-container'}>

            <div className={'analysis-builder-nav'}>
                <a href=""><i className={'fa fa-chart-pie'}/></a>

            </div>
            <div className={'analysis-builder-body'}>
                <div className="row">
                    <div className="col-md-3">
                        <button className={'btn btn-primary'} onClick={async ()=> await _save()}>Save</button> &nbsp;
                        <button className={'btn btn-primary'} onClick={async ()=> setShow(false)}>Cancel</button>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-3">
                        <label>Dashboard Title</label>
                    </div>
                    <div className="col-md-3">
                        <input type={'text'} className={'form-control'} onChange={(e) => onChange('dashboard_title', e.target.value)} value={theDashboard['dashboard_title']}/>
                    </div>
                </div>
                <div>
                    <DashboardBuilderView reports={dashboardReports} upsertReportForDashboard={upsertReportForDashboard} removeReport={removeReport}/>
                </div>
            </div>
        </div>
    </div>);
}
export function DashboardBuilderView({reports,upsertReportForDashboard, removeReport}){
    let [editReport, setEditReport] = React.useState({});
    let [dashboardReports, setDashboardReports] = React.useState([...reports] || []);
    const [show, setShow] = React.useState(false);
    useEffect(()=>{
        if(reports)
        {setDashboardReports(reports);}
    },[reports]);
    let editReportData = (report)=>{
        setShow(true);
        setEditReport({...report});
        upsertReportForDashboard(report);
    };
    let deleteReportData = (report) =>{
        removeReport(report);
        setShow(false);
        setEditReport({});
    };
    const updateReport =  (report)=>{
        upsertReportForDashboard(report);
        setShow(false);
        setEditReport({});
    };
    const showNewReport = ()=>{
        setShow(true);
    };
    let reportsInter = dashboardReports.map((report,i) =>{
        return <ReportEditItem key={'dashboards-' + i} editReport={editReport} report={report}
            editReportData={editReportData} deleteReportData={deleteReportData} setEditReport={setEditReport} />;
    });

    reportsInter = reportsInter.concat(new Array(Math.ceil(3- (dashboardReports.length % 3)))
        .fill()
        .map((n,i) =>{
            return <div key={'dashboards-' + dashboardReports.length+ i} className={'dashboard-report-container'}>
                <div className={'add-new-report'}>
                    <h3>Click Add to begin adding analytics.</h3><br/>
                    <h3><a className={'inline-nav-link'} onClick={()=>showNewReport()}><i className={'fa fa-plus'}/> Add</a></h3>
                </div>
            </div>;
        }));

    const n = 3;
    return (<div>
        <div className="dashboard-layout-master">
            {new Array(Math.ceil(reportsInter.length / n))
                .fill()
                .map((g,i) => <div key={'dashrow_' + i} className={'dashboard-row'}>{reportsInter.splice(0, n)}</div>)}
        </div>
        <Popup show={show} title={'Analytics'}>
            <ChooseReportTypes report={editReport} complete={async (report)=> {
                await updateReport(report);
            }}/>
        </Popup>
    </div>);
}
export const ReportEditItem = ({editReport, report, editReportData,deleteReportData, setEditReport})=>{
    return <div  className={'dashboard-report-container ' + (editReport === report ? ' dashboard-report-container-active' : '')} onClick={()=>setEditReport(report)}>
        {editReport === report &&
        <div className={'report-item-instance'}>
            <div className={'report-item-body selected'}>
                <ManagedDashboardReport report={report} />
                <div className={'report-right-nav'}>
                    <a onClick={async ()=> await editReportData(report)}><i className={'fa fa-cog'}/></a><br/>
                    <a onClick={()=> deleteReportData(report)}><i className={'fa fa-trash'}/></a>
                </div>
            </div>
        </div>}
        {editReport !== report &&
        <div className={'report-item-instance'}>
            <div className={'report-item-body'}>
                <ManagedDashboardReport report={{...report}} />
            </div>
        </div>}
    </div>;
};
export function DashboardViewer({dashboard}){
    const reports = dashboard.reports.map((report,i) =>{
        return <div key={'dashboards-' + i} className={'dashboard-report-container'} >
            <div className={'report-item-instance'}>
                <div className={'report-item-body'}>
                    <DashboardReport report={report} />
                </div>
            </div>
        </div>;
    });
    const n = 3;
    return (<>
        <div  className={'dashboard-title'}>
            {dashboard.dashboard_title}
        </div>
        <div className="container-fluid dashboard-layout-master">
            {new Array(Math.ceil(reports.length / n))
                .fill()
                .map((_,i) => <div key={'container_item_' + i} className={'dashboard-row'}>{reports.splice(0, n)}</div>)}
        </div>
    </>);

}
export const ManagedDashboardReport = ({report}) =>{
    const [data,setData] = React.useState([]);
    const [reportInfo,setReportInfo] = React.useState(report||{});
    const [addedColumns,setAddedColumns] = React.useState([]);
    let {data:report_data}  =  useQuery(['report', report],reportService.runReport);
    React.useEffect(() => {
        if (report_data) {
            setData(report_data.data);
            setAddedColumns(report.added_columns);
            setReportInfo({...reportInfo});
        } else {
            setData([]);
        }
    }, [report_data]);
    return <>
        {data.length > 0 && <ReportRunner report={reportInfo} data={data} added_columns={addedColumns} />}
    </>;
};
export function DashboardReport({report}){
    const [data,setData] = React.useState([]);
    const [addedColumns,setAddedColumns] = React.useState([]);
    let {data:report_data}  =  useQuery(['report', report],reportService.runReport);
    React.useEffect(() => {
        if (report_data) {
            setData(report_data.data);
            setAddedColumns(report.added_columns);
        } else {
            setData([]);
        }
    }, [report_data]);
    return <>
        {data.length > 0 && <ReportRunner report={report} data={data} added_columns={addedColumns} />}
    </>;
}
