import { transform } from "lodash";
import classes from "./TabRow.module.scss";

const TabRow = ({ children }) => {
  return (
    <div className={classes.tabRowWrapper}>
      {children}
      {/* {children.map((child) => {
        const x = 160 * children.indexOf(child);
        const styles = {
          transform: `translate(${x}px, 0)`,
        };
        return (
          <div key={x} style={styles}>
            {child}
          </div>
        );
      })} */}
    </div>
  );
};

export default TabRow;
