import { useState, useEffect } from "react";
import useGetById from "./useGetById";
import useDataServices from '../../services/hooks/useDataServices';
import { set } from "lodash";


const useCustomReport = ({ customReportId }) => {
    const [ reportObj, setReportObj ] = useState({})
    const [ reportData, setReportData ] = useState(null)
    const [ dataSourceItem, setDataSourceItem ] = useState(null)
    const { getData: getCustomReportData } = useDataServices({ id: dataSourceItem?.id })

    const { data: customReportData } = useGetById({ 
        entity: 'custom_reports', 
        id: customReportId,
        includeChildren: true
    })

    useEffect(() => {
        if(customReportData && customReportData.data) {
            const report = customReportData.data
            setDataSourceItem(report?.data_source_items)

            const mappedReport = {
                title: report.title,
                primaryTableName: (report?.custom_reports_tables || [])[0]?.table_name,
                columns: (report?.custom_reports_tables || [])[0]?.custom_reports_tables_fields.map(field => {
                    return {
                        ...field,
                        dataField: field.field_name
                    }
                }).sort((a, b) => {
                    return a.position > b.position ? 1
                        : a.position < b.position ? -1
                        : a.name > b.name ? 1
                        : a.name < b.name ? -1
                        : 0
                }) || []
            }

            setReportObj(mappedReport)
        }
    }, [customReportData]);

    useEffect(async () => {
        if(dataSourceItem && reportObj) {
            const data_set = {
                ...dataSourceItem,
                core_table_name: reportObj.primaryTableName,
                schema_definition: {
                    ...dataSourceItem.schema_definition,
                    tables: [
                        ...dataSourceItem.schema_definition.tables.map(table => {
                            return {
                                ...table,
                                filter_list: null
                            }
                        })
                    ]
                }
            }
            const reportDataResults = await getCustomReportData({ data_set })
            setReportData(reportDataResults.data.result)
        }
    }, [dataSourceItem, reportObj])

    return { structure: reportObj, data: reportData }
}

export default useCustomReport