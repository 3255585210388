
import React, {useEffect, useState} from 'react';
import {Popup} from '../../FormDriver/FormBuilderSupportComponent';
import Editor, {monaco} from '@monaco-editor/react';
export const StoredProcBuilder = ({show, setShow,table, dataset, updateDataset}) =>{
    let editorRef = null;
    const [showQuery, setShowQuery] =useState(false);
    const [queryText, setQueryText] =useState(false);
    const [currTable, setCurrTable] =useState(table || {table_type: 'sp'});

    useEffect(()=>{
        setShowQuery(show);
    },[show]);

    useEffect(()=>{
        setCurrTable(table|| {table_type: 'sp'});
    },[table]);

    const saveQuery = ()=>{

        let tmpData = {...dataset};
        tmpData.schema_definition  =  tmpData.schema_definition || {tables:[]};
        tmpData.schema_definition.tables =tmpData.schema_definition.tables || [];
        let table = tmpData.schema_definition.tables.find(i=>i.table_name === currTable.table_name);
        if(table){
            tmpData.schema_definition.tables[tmpData.schema_definition.tables.indexOf(table)] = {...currTable};
        }else{
            tmpData.schema_definition.tables.push( {...currTable});
        }
        updateDataset(tmpData);
        setShow(false);
    };

    const updateVal = (value, property)=>{
        const tempCur = {...currTable};
        tempCur[property] = value;
        setCurrTable(tempCur);
        return tempCur;
    };

    return <div><Popup  show={showQuery} showSave={true} save={() => saveQuery()} title={'Stored Proc Builder'} closeModal={() => setShow(false)}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Query Name</label>
                        <input className="form-control" type="text" value={currTable.table_name || ''} onChange={(e)=>{updateVal(e.target.value, 'table_name');}}/>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className={"col-md-12"}>
                        <div className="form-group">
                            <label htmlFor="">Stored Proc</label>
                            <input className="form-control" type="text" value={currTable.query_text || ''} onChange={(e)=>{updateVal(e.target.value, 'query_text');}}/>
                        </div>
                </div>
            </div>
        </div>

    </Popup></div>;
};