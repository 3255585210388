import classes from './PageTitle.module.scss';

const PageTitle = ({ children, titleNote = null }) => {
    return (
        <div className={classes.pageTitleWrapper}>
            <h2 className={classes.pageTitle}>{children}</h2>
            {titleNote && <p className={classes.titleNote}>{titleNote}</p>}
        </div>
    );
};

export default PageTitle;
