import axios from "axios";
import {UserManagementService} from "./user_management_service";

export class DataConnectionService{
    constructor(config={}){
        this.user_manager = new UserManagementService();
        this.config = config;
        this.config.root_url = this.config.root_url || "";
    }
    async test_connection(data_set){
        return axios.post( this.config.root_url+ '/data_api/data_connection/test_connection',data_set, this.getHeaders())
    }
    async get_schema(data_set){
        return axios.post( this.config.root_url+ '/data_api/data_connection/get_schema',data_set, this.getHeaders())
    }
    async get_data(data_set){
        return axios.post( this.config.root_url+ '/data_api/data_connection/get_data',data_set, this.getHeaders())
    }

    getHeaders(){
        return {headers :{Authorization:"Bearer " + (this.user_manager.loginData() || {}).jwt}};
    }
}