import React from 'react';
import {useQueryCache} from 'react-query';

export function ProductSizeEntry({variant, orderItem, updateOrderItem}){
    const [sizeCount, setSizeCount] = React.useState(orderItem.qty);
    const queryCache = useQueryCache();
    React.useEffect(() => {
        setSizeCount(orderItem.qty);
    },[orderItem.qty]);
    const updateQuantity = async(e)=>{
        orderItem.qty = +e.target.value;
        queryCache.setQueryData('OrderItems',(data)=>{
            let item = data.find(di=> (di.size === orderItem.size && di.order_variant_id === variant.id));
            if(item){
                item = Object.assign(item,orderItem);
            }else{
                data.push(orderItem);
            }
            return data;
        });
        await updateOrderItem(orderItem);
    };
    return(
        <><label htmlFor="">{orderItem.size}:</label>
            <input type={'number'} style={{width:'45px', border:'1px solid #EFEFEF', textAlign:'center'}}
                value={sizeCount} onChange={async (e)=> await updateQuantity(e)}/>&nbsp;&nbsp;</>
    );
}
