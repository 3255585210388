enum ButtonType {
    button,
    actionButton,
    miniActionButton,
    cancelButton,
    link,
    linkInverted,
    symbol
}

export default ButtonType;
