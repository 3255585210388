import React, { Component } from 'react';
import {ObjContainer} from '../../services/object_container';
import {ChildTableActions} from './ChildTableActions';
import {ViewTableDisplay} from './ViewTableDisplay';
import TabbedView from '../CommonUI/TabbedView/TabbedView';
import {ViewDetailsView} from './ViewDetailsView';

export class ViewComponent extends Component{
    constructor(params){
        super();
        this.state={config:params.config,obj:{},schema_def:{fields:[]},activeIndex:0,search:'',
            qs_results: [],collapsed:{},editing:{}};
        this.service_manager = ObjContainer.resolve('service_manager');
        this.data_manager = ObjContainer.resolve('data_manager');
        this.ref_list = [];
    }
    async componentDidMount(){
        await this.getObjData(this.props.config);
    }
    async getObjData(config){
        let param_state = ObjContainer.resolve('nav').getState();
        this.data_def = await this.service_manager.resolve(config.table_name).schema({});
        let obj = {};
        if(param_state.route_params && param_state.route_params.filter){
            let filter = Object.assign({},{filter_eq: param_state.route_params.filter},{expand:this.data_def.data.expand});
            obj = await this.service_manager.resolve(config.table_name).getOneData(filter,this.data_def.data.primary_key || '_id');
        }
        obj = Object.assign({},obj,(this.state.param_state || {}).parent_data || {});
        (this.data_def.data.child_collection || []).map((child, i)=>{
            this.ref_list.push(React.createRef())  ;
        });
        this.setState({schema_def: this.data_def.data,obj:obj,param_state: param_state});
    }
    _onCancel(){
        ObjContainer.resolve('nav').navTo(this.props.config.routes.find(route => route.route_key ==='list').route_dest);
    }
    _edit(){ 
        let local_state = {route_params:{filter:{}},
            parent_data: Object.assign({},this.props.parent_data || {},this.props.config,{back_state:ObjContainer.resolve('nav').getState()})};
        local_state.route_params.filter[this.data_def.data.primary_key || '_id'] = this.state.obj[this.data_def.data.primary_key || '_id'];
        ObjContainer.resolve('nav').navTo(this.props.config.routes.find(route => route.route_key ==='new').route_dest, local_state);
        this.service_manager.emit('form_setup',this.props.config);
    }
    async setFilter(filter_str){
        if(!filter_str){
            this.setState({qs_results:[]});
            return;
        }
        let filter = {filter_or:{}};
        let qf_fields = this.state.schema_def.fields.filter((f)=>f.quick_search);
        qf_fields.forEach((f)=>{
            filter.filter_or[f.field_code] = {eq:filter_str};
        });
        let result  = await this.service_manager.resolve(this.state.config.table_name).search(filter);
        this.setState({qs_results:result.data.data.slice(0,5)});
    }
    async navToRow(field, row){

        let filter = {filter_eq:{},expand:this.state.schema_def.expand};
        filter.filter_eq[this.data_def.data.primary_key || '_id']  = row[this.data_def.data.primary_key || '_id'];

        let obj = await this.service_manager.resolve(this.state.config.table_name).getOneData(filter,this.data_def.data.primary_key || '_id');
        this.setState({obj:obj});
        (this.state.schema_def.child_collection || []).map((child, i)=>{
            if(this.ref_list[i].current)
            {this.ref_list[i].current.update(obj);}
        });
    }
    editing(group){
        this.setState((s) =>{
            s.editing[group] = !s.editing[group];
            return s;
        });
    }
    async _saveData(group){

        await this.service_manager.resolve(this.state.config.table_name). upsert(this.state.obj.id,this.state.obj);
        this.setState((s) =>{
            s.editing[group] = false;
            return s;
        });
        await this.getObjData(this.props.config);
        return false;
    }
    async _refreshChild(){

        (this.state.schema_def.child_collection || []).map((child, i)=>{
            if(this.ref_list[i].current)
            {this.ref_list[i].current.update(this.state.obj);}
        });
    }
    _changeData(field_code, value){
        this.setState((s) =>{
            s.obj[field_code] = value;
            return s;
        });
    }
    _cancelEdit(group){
        this.setState((s) =>{
            s.editing[group] = false;
            return s;
        });
    }

    render(){
        let tabs = [
            {
                title: 'Details',
                view: (
                    <ViewDetailsView obj={this.state.obj}
                        onButtonPress={(button) => this._onButtonPress(button)}
                        saveData={async (group)=> await this._saveData(group.group_title)}
                        cancelEdit={()=> (group)=>{ this._cancelEdit(group.group_title);}}
                        changeData={(field_code, value,data)=>{this._changeData(field_code, value);}}
                        schema={this.state.schema_def}
                    />
                ),
                disabled: false,
            }
        ];
        tabs = tabs.concat((this.state.schema_def.child_collection || []).map((child,i) =>{
            return {
                title: child.table_title,
                view: (
                    <><ChildTableActions child={child}
                        parentType={this.state.schema_def.table_name}
                        parentId={this.state.obj.id}
                        parentObj={this.state.obj}
                        finished={async ()=> await this.getObjData(this.props.config)}
                        ops={{onSave: async (group) => this._refreshChild()} }
                    />
                    <div className={'container-fluid'} style={{overflow:'auto'}}>
                        <ViewTableDisplay parent={this.state.obj} table={child} />
                    </div></>), disabled: false};
        }));
        return <div className={'container-fluid'}>
            <div className={'row'} >
                <div className={'col-md-12'} style={{overflow:'auto'}}>
                    <TabbedView tabs={tabs} />
                </div>
            </div>
        </div>;
    }
}
export class QuickSearch extends Component{
    render(){
        return (<div className={'button-bar'}>
            <div className={'header-title'}>
                <button onClick={()=>this.props.onCancel()} className={'btn btn-primary'}>List</button>
            </div>
            <div className={'view-quick-search'}>
                <div className={'input-group'}>
                    <input type={'text'} className={'form-control'} placeholder={'Search'} name={'form-quick-search'} onChange={async (e) => await this.props.setFilter(e.target.value)}/>
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className={'pi pi-search'} /></span>
                    </div>
                </div>
                {(this.props.qs_results && this.props.qs_results.length > 0)  && <div className={'search-results'}>
                    <div >
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    {this.props.qs_fields.map((f,i)=>{
                                        return <th key={'quick_search_head'+i} style={{whiteSpace:'nowrap'}} >{f.field_name}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.qs_results.map((r,r_i)=>{
                                    return (
                                        <tr>
                                            {this.props.qs_fields.map((f,f_i)=>{
                                                if(f.link)
                                                {return (<td key={'table-quick_search_result_-' + f_i + '-' + f_i} style={{whiteSpace:'nowrap'}}>
                                                    <a href={'#'} onClick={(e) => this.props.navToRow(f, r)} >{r[f.field_code]}</a>
                                                </td>);}
                                                return <td key={'quick_search_result_' + r_i + '_' + f_i}>{r[f.field_code]}</td>;
                                            })}
                                        </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
        </div>);
    }
}
