import React from 'react';

export const YAxis = ({currReport,updateReportDef}) =>{
    return <div  className={'container-fluid'}>
        <div className={'row'}>
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Title</label>
                    <input type="text" className={'form-control'}
                           value={currReport.report_definition?.y_axis_title || ''}
                           onChange={(e)=>updateReportDef('y_axis_title', e.target.value)}/>
                </div>
            </div>
        </div>
    </div>;
};