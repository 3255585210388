import { useState, useCallback, useEffect } from "react";

const useElementRect = (dependencyArray) => {
  const [elementRect, setElementRect] = useState(null);
  const elementRef = useCallback((el) => {
    if (el !== null) {
      setElementRect(el.getBoundingClientRect());
    }
  }, dependencyArray);

  const elementData = {
    ref: elementRef,
    rect: elementRect,
  };

  return elementData;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }, []);

  return windowSize;
};

export default useElementRect;
