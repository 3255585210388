import React, { Component } from 'react';
import {ObjContainer} from "../../services/object_container";
import {TableMasterComponent} from "../TableDriver/TableMasterComponent";
import * as dot from 'dot';
import {
    AnalyticsDefine,
    ChildCollectionDefine,
    NotesDefine,
    Popup, ReferenceDefine,
    SurveyResultsDefine
} from "./FormBuilderSupportComponent";
import {FormDefinition} from "./FormDefinition";


export class FormMasterComponent extends Component{
    constructor(){
        super();
        this.state = {schema_def:{child_obj:{},fields:[]},obj:{}, child_schema:{},param_state: ObjContainer.resolve("nav").getState(),dropins:[],show_relationship:{} };
        this.service_manager = ObjContainer.resolve("service_manager");
        this.data_manager = ObjContainer.resolve("data_manager");
    }
    async componentDidMount(){

        await this.performNavSetup(this.props.config);
        this.service_manager.addListener("form_setup",async (config)=>{
            await this.performNavSetup(config);
        });
        this.service_manager.resolve("fields").addListener("field_update",(field)=>{
            let field_index = this.state.schema_def.fields.indexOf(field);
            if(field_index > -1){
                this.state.schema_def.fields[field_index] = field;
                this.setState({schema_def: this.state.schema_def});
            }
        });
    }
    async performNavSetup(config){
        let param_state = ObjContainer.resolve("nav").getState();
        this.data_def = await this.service_manager.resolve(config.table_name).schema({});
        (this.data_def.data.child_collection || []).map(async (collection) =>{
           if(collection.relation_type === 'attached_item'){
               this.data_def.data.child_schema = this.data_def.child_schema || {};
               this.data_def.data.child_schema[collection.table_name] = (await this.service_manager.resolve(collection.table_name).schema({})).data;
           }
        });
        let obj = {};
        if(param_state.route_params && param_state.route_params.filter){
            let filter = Object.assign({},param_state.route_params,{expand:this.data_def.data.expand});
            obj = await this.service_manager.resolve(config.table_name).getById(filter,this.data_def.data.primary_key || '_id');
        }
        obj = Object.assign({},obj,this.state.param_state.parent_data || {});
        let dropins = [];
        (this.data_def.data.fields || []).forEach((field)=>{
            if((field.data_type === 'pick_list' || field.data_type ==='checkbox') && obj[field.field_code] && g_dropins[obj[field.field_code].value]){
                let dropin = g_dropins[obj[field.field_code].value];
                let dropinBase = Object.assign({},dropin , {content:
                    React.createElement(dropin.contentObj,
                        Object.assign({}, {schema_def: this.data_def.data},{obj:obj},dropin,{onChange:this._changeChild.bind(this), field_code: field.field_code, value:obj[dropin.name]}),null)
                });
                dropins.push(dropinBase);
            }
        });
        this.setState({schema_def: this.data_def.data,obj:obj,param_state: param_state, dropins:dropins});
    }
    _changeChild(dropin_name, field_code, value){
        this.state.obj[dropin_name] = value;
        let dropins = [];
        let dropin = g_dropins[this.state.obj[field_code].value];
        let dropinBase = Object.assign({},dropin , {content:
            React.createElement(dropin.contentObj,Object.assign({},dropin,
                {onChange:this._changeChild.bind(this), field_code:field_code, value: this.state.obj[dropin.name]}, this.state),null)
        });
        dropins.push(dropinBase);
        this.setState({obj: this.state.obj,dropins:dropins});
    }
    componentWillUnmount(){
        this.service_manager.removeAllListeners("form_setup");
        this.service_manager.resolve(this.props.config.table_name).removeAllListeners("refresh-data");
        this.service_manager.resolve(this.props.config.table_name).removeAllListeners("update-filter");
        this.service_manager.resolve("fields").removeAllListeners("field_update")
    }
    _onChange(field_code,value, e){

        let old_obj = this.state.obj || {};
        old_obj[field_code] = value || (e ? e.target.value : '');
        let dropins = this._getDropins();
        this.setState({obj:old_obj,dropins});
        this.service_manager.resolve("form_emitter").emit("updated");
    }
    _assignChildData(child_name, data){
        this.setState((s)=>{
            s.obj["child_" + child_name] = data;
            return s;
        })
    }
    _getDropins(){
        let dropins = [];
        let old_obj = this.state.obj;
        (this.state.schema_def.fields || []).forEach((field)=>{
            if((field.data_type === 'pick_list' || field.data_type ==='checkbox') &&
                this.state.obj[field.field_code] &&
                this.state.obj[field.field_code].value &&
                g_dropins[this.state.obj[field.field_code].value]) {

                let dropin = g_dropins[this.state.obj[field.field_code].value];
                let dropinBase = Object.assign({},dropin , {
                    content:
                        React.createElement(dropin.contentObj,
                            Object.assign({}, this.state,{
                                onChange: this._changeChild.bind(this),
                                field_code: field.field_code,
                                value:this.state.obj[dropin.name]}),null)
                },{title:field.title});
                dropins.push(dropinBase);
            }else if((field.data_type === 'pick_list' || field.data_type ==='checkbox')  && old_obj[field.field_code] && g_dropins[old_obj[field.field_code].value]){
                let dropin = g_dropins[old_obj[field.field_code].value];
                delete this.state.obj[dropin.name];
            }
        });
        return dropins;
    }
    _onDefine(){
    }
    async _onSave(){
        if(this.state.schema_def.display_field)
            this.state.obj["display"] = this.state.obj[this.state.schema_def.display_field];
        if(!this.isValid()){
            this.setState(this.state);
            return;
        }
        let res = await this.service_manager.resolve(this.props.config.table_name).upsert(this.state.obj[this.state.schema_def.primary_key || "_id"],this.state.obj);
        let local_state = {route_params:{filter:{}},
            parent_data: Object.assign({},this.props.parent_data || {},{back_state:ObjContainer.resolve("nav").getState()})};
        local_state.route_params.filter[this.state.schema_def.primary_key || "_id"] = res.result[0][this.state.schema_def.primary_key || "_id"];
        ObjContainer.resolve("nav").navTo(this.props.config.routes.find(route => route.route_key ==="view").route_dest, local_state);
    }
    isValid(){
        this.state.schema_def.fields.forEach((field)=>{
            (field.validations || []).forEach((validation)=>{
                switch(validation.validation_type){
                    case "required":
                        let value = this.state.obj[field.field_code];
                        if(value === '' || value === undefined || value === null){
                            field.error_state = true;
                            field.error_message = `${field.field_name} is required`;
                        }
                        break;
                }
            })
        });
        return true;
    }
    _onCancel(){
        let local_state = {route_params:{filter:{}},
            parent_data: Object.assign({},this.props.parent_data || {},{back_state:ObjContainer.resolve("nav").getState()})};
        local_state.route_params.filter[this.data_def.data.primary_key || '_id'] = this.state.obj[this.data_def.data.primary_key || '_id'];

        if(this.state.param_state.parent_data){
            ObjContainer.resolve("nav").navTo(this.state.param_state.parent_data.back_state.path, this.state.param_state);
        }else{
            ObjContainer.resolve("nav").navTo(this.props.config.routes.find(route => route.route_key ==="view").route_dest,this.state.param_state);
        }

    }
    _onDataChange(option, e){
    }
    showLookup(table_name){
        this.state.show_relationship[table_name] = !this.state.show_relationship[table_name];
        this.setState(this.state);
    }
    //ToDo: This needs to change because the collection list naming is different for "Expands"
    async saveChild(table_name, data,id){
        this.state.show_relationship[table_name] = false;
        this.state.obj[table_name+ "_ref"] =this.state.obj[table_name+ "_ref"] || [];
        this.state.obj[table_name+ "_ref"].push(id[this.state.schema_def.primary_key || "_id"]);
        if(this.state.schema_def.display_field)
            this.state.obj["display"] = this.state.obj[this.state.schema_def.display_field];
        await this.service_manager.resolve(this.props.config.table_name).upsert(this.state.obj[this.state.schema_def.primary_key || "_id"],this.state.obj);
        this.setState(this.state);
    }
    closeModal(table_name){
        this.state.show_relationship[table_name] = false;
        this.setState(this.state);
    }
    _upsertAttachedChild(child_obj_name){
        this.setState((s) => {
            s.obj[child_obj_name]= s.obj[child_obj_name] || [];
            s.obj[child_obj_name].push(s.child_obj);
            s.child_obj = {};
            return s;
        });
        this.closeModal(child_obj_name);
    }
    _onChangeAttachedChild(field_code,value, e){
        let old_obj = this.state.child_obj || {};
        old_obj[field_code] = value || e.target.value;
        this.setState({child_obj:old_obj});
    }
    _removeAttachedItem(row,child_obj_name){
        let old_obj = this.state.obj;
        old_obj[child_obj_name].splice(old_obj[child_obj_name].indexOf(row),1);
        this.setState({obj:old_obj});
    }
    _onDefineAttachedChild(data){

    }
    async _onButtonPress(button){
        let tempFn = dot.template(button.url);
        let src = Object.assign({},this.state.schema_def,this.state.obj);
        let resultText = tempFn(src);
        await this.data_manager.postData(resultText,this.state.obj)

    }
    buildChildren(child_collection, show_relationship, schema){
        let tab_def =[];
        child_collection.forEach((child)=>{
            if(child.relation_type === "lookup_search"){
                tab_def.push({
                    name: child.table_name,
                    title: (child.table_title || child.table_name),
                    content: (<div>
                        <Popup show={show_relationship[child.table_name]} title={child.table_name}
                               closeModal={this.closeModal.bind(this,child.table_name)} showSave={false}  save={() =>{} }>
                            <TableMasterComponent
                                table_def={child || {fields:[]} }
                                config={{table_name:child.table_name}}
                                rowClick={this.saveChild.bind(this,child.table_name)} />
                        </Popup>
                        <TableMasterComponent
                            table_def={child || {fields:[]} }
                            config={Object.assign({},child,{fields:[]})}
                                              rows={this.state.obj[child.table_name]}
                                              table_type={'assign'}
                                              onAddClick={this.showLookup.bind(this,child.table_name)} />
                    </div>)});
            }else if (child.relation_type === "attached_item"){
                tab_def.push({
                    name: child.table_name,
                    title: (child.table_title || child.table_name),
                    content: (<div>
                        <Popup show={show_relationship[child.table_name]} title={child.table_title}
                               closeModal={this.closeModal.bind(this,child.table_name)} showSave={false}  save={() =>{} }>
                            <FormDefinition
                                definition={child }
                                obj={this.state.child_obj || {}}
                                buttons={(schema || {buttons:[]}).buttons}
                                ops={{onSave: () => this._upsertAttachedChild(child.table_name),
                                    onChange: (field_code,value, e) => this._onChangeAttachedChild(field_code,value, e),
                                    onCancel: this.closeModal.bind(this),
                                    onDefine: this._onDefineAttachedChild.bind(this),
                                    onButtonPress: (button) => this._onButtonPress(button)  }}
                            />
                        </Popup>
                        <TableMasterComponent
                                table_def={child || {fields:[]}}
                                config={Object.assign({},child,{fields:[]})}
                                rows={this.state.obj[child.table_name] || []}
                                onDelete={(row)=> this._removeAttachedItem(row,child.table_name)}
                                table_type={'assign'}
                                onAddClick={this.showLookup.bind(this,child.table_name)} />
                    </div>)});
            }else{
                tab_def.push({
                    name: child.table_name,
                    title: (child.title || child.table_name),
                    content: (<TableMasterComponent
                        table_def={child || {fields:[]} }
                        config={Object.assign({},child,
                        {routes:[{route_key:"add",route_dest:`${child.table_name}-New${child.table_name}`}]})}
                                                    parent_data={Object.assign({},this.state.obj,this.state.param_state,{
                                                        parent_collection: schema.table_name,
                                                        back_state: {path:`${ schema.table_name}-New${ schema.table_name}`,param_state:this.state.param_state},
                                                        parent_id: this.state.param_state.route_params.filter[schema.primary_key || "_id"] })}
                                                    onData={(data) => this._assignChildData(child.table_name, data)} />)});
            }
        });
        return tab_def;
    }
    getTabs(){
        let tab_def = [];
        if(this.state.schema_def.child_collection && this.state.param_state && this.state.param_state.route_params && this.state.schema_def.child_collection instanceof Array){
            tab_def  = tab_def.concat(this.buildChildren(this.state.schema_def.child_collection,this.state.show_relationship,this.state.schema_def));
        }
        tab_def = tab_def.concat(this.state.dropins);
        return tab_def;
    }
    render(){

        return (
            <div className={"h-100"}>
                {this.state.obj[this.state.schema_def.primary_key || "_id"] && <div style={{marginTop:"5px"}}>
                    <ul className="nav nav-tabs" role="tablist">
                        <li  className={"nav-item-general_1 active"}>
                            <a className={"nav-link active"} data-toggle="tab" href="#general" role="tab">General</a>
                        </li>
                        <li  className={"nav-item-general_4"}>
                            <a className={"nav-link"} data-toggle="tab" href="#notes" role="tab">Notes</a>
                        </li>
                    </ul>
                </div>}
                {!this.state.obj[this.state.schema_def.primary_key || "_id"] &&
                    <div className="tab-pane active h-100"  id={"general"}>
                        <h2>New</h2>
                        <FormDefinition
                            definition={this.state.schema_def}
                            obj={this.state.obj}
                            ops={{onSave: this._onSave.bind(this),
                            onChange: this._onChange.bind(this),
                             onCancel: this._onCancel.bind(this),
                             onDefine:this._onDefine.bind(this) }}

                        />
                        {this.state.obj[this.state.schema_def.primary_key || "_id"] &&
                            <SetupTabs tab_def={this.getTabs()} onChange={this._onDataChange.bind(this)} table_name={this.state.schema_def.table_name} _id={this.state.obj[this.state.schema_def.primary_key || "_id"]}/>
                        }
                    </div>
                }
                {this.state.obj[this.state.schema_def.primary_key || "_id"] &&
                    <div className="tab-content  h-100">
                        <div className="tab-pane active h-100"  id={"general"}>
                            <h2>Edit {this.state.obj[this.state.schema_def.display_field]}</h2>
                            <div style={{height:"44vh",overflow:"auto"}}>
                                <FormDefinition
                                    definition={this.state.schema_def}
                                        obj={this.state.obj}
                                    ops={{onSave: this._onSave.bind(this),
                                          onChange: this._onChange.bind(this),
                                          onCancel: this._onCancel.bind(this),
                                          onDefine:this._onDefine.bind(this),
                                          onButtonPress: (button) => this._onButtonPress(button) }}
                                          buttons={(this.state.schema_def || {buttons:[]}).buttons}/>
                                </div>
                            <SetupTabs tab_def={this.getTabs()} onChange={this._onDataChange.bind(this)} table_name={this.state.schema_def.table_name} _id={this.state.obj[this.state.schema_def.primary_key || "_id"]}/>
                        </div>
                        <div  className="tab-pane h-100" id={"survey_results"}>
                            <SurveyResultsDefine obj={this.state.obj} schema_def={this.state.schema_def}/>
                        </div>
                        <div  className="tab-pane h-100" id={"notes"}>
                            <NotesDefine/>
                        </div>
                        <div  className="tab-pane h-100" id={"scheduler"}>

                        </div>
                    </div>}

            </div>);
    }
}
let SetupTabs = (params) =>{
    let first_1 = true;
    let first_2 = true;
    return (
        <div style={{marginTop: "10px"}}>
            <ul className="nav nav-tabs" role="tablist">
                {params.tab_def.map((tab,index)=>{
                    let ret =(
                        <li className={"nav-item " + (first_1 ? "active": "" )} key={"tab-def-" + index}>
                            <a className={"nav-link " + (first_1 ? "active": "" )} data-toggle="tab" href={"#" + tab.name} role="tab">{tab.title}</a>
                        </li>);
                    first_1 = false;
                    return ret;
                })}
                <li  className={"nav-item " + (first_1 ? "active": "" )}>
                    <a className={"nav-link " + (first_1 ? "active": "" )} data-toggle="tab" href="#settings" role="tab">Settings</a>
                </li>
            </ul>
            <div className="tab-content">
                {params.tab_def.map((tab,index)=>{
                    let ret =(<div className={"tab-pane " + (first_2 ? "active": "" )} id={tab.name} role="tabpanel" key={"tab-act-" + index}>{tab.content}</div>);
                    first_2 = false;
                    return ret;
                })}
                <div className={"tab-pane " + (first_2 ? "active": "" )} id="settings" role="tabpanel"><SettingsArea _id={params._id} table_name={params.table_name} /></div>
            </div>
        </div>);
};
let DropDownList = (params)=>{
    return (
        <span className="dropdown">
            <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                {params.value.display || "Selct One..."}
                &nbsp;<span className="caret"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                {(params.options|| []).map((option,index) =>{
                    return <li key={"relation_option_value_" + index}>
                        <a className="nav-link" onClick={params.onChange.bind(this,option)} name={params.name} >{option.display}</a>
                    </li>;
                })}
            </ul>
        </span>
    )
};

const g_dropins = {
    "reference": {title:"Reference" ,name:"reference" ,           contentObj: ReferenceDefine},
    "child":     {title:"Child" ,    name:"child_collection" ,    contentObj: ChildCollectionDefine},
    "analytics": {title:"Analytics" ,name:"analytics" ,           contentObj: AnalyticsDefine},
    "survey":    {title:"Survey" ,   name:"survey_collection" ,   contentObj: SurveyResultsDefine},
    "notes":     {title:"Notes" ,    name:"notes_collection" ,    contentObj: NotesDefine},
};
