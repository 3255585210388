import React, {useEffect, useState} from "react";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";

export const ColumnSettingsLink = ({col,all_tables, colUpdated}) =>{
    const [showConfig, setShowConfig] = useState();

    const [colData, setColData] = useState(col);
    useEffect(()=>{
        setColData(col);
    },[col]);
    const commit = ()=>{
        setShowConfig(false);
    };
    const setFormat = (format_type)=>{
        let tmpColData = {...colData};
        tmpColData.format_type = format_type;
        setColData(tmpColData);
        colUpdated(tmpColData);
    }
    const setOrder = (field,checked)=>{
        let tmpColData = {...colData};
        tmpColData[field] = checked;
        setColData(tmpColData);
        colUpdated(tmpColData);
    }
    return <>
        <a  className={"inline-nav-link"} onClick={()=>setShowConfig(true)}><i className={"fas fa-cog"} /></a>
        <Popup show={showConfig} closeModal={()=>setShowConfig(false)} title={"Column:" + col.name} showSave={true} save={(new_def)=>commit()}>
            <div className={"row"}>
                <div className="col-md-12">
                    <div className="form-check">
                        <input type="checkbox" className={"form-check-input"}
                               checked={colData.order_by}
                               onChange={(e)=>{setOrder('order_by', e.target.checked)}}/> <label htmlFor="" className={"form-check-label"}>Order By</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" checked={colData.order_by_desc} className={"form-check-input"}
                               onChange={(e)=>{setOrder('order_by_desc', e.target.checked)}}/> <label htmlFor="" className={"form-check-label"}>Descending</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Order Priority</label>
                        <input type="text" className={"form-control"} value={colData.order_priority || 0} onChange={(e)=>{setOrder('order_priority', e.target.checked)}}/>
                        <small id="emailHelp" className="form-text text-muted">Relative to other columns.</small>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Format</label>
                        <select className={"form-control"} value={colData.format_type} onChange={(e)=>setFormat(e.target.value)}>
                            <option>--Choose One</option>
                            {colData.user_type_id === 42 && <>
                                <option value="date-mm-dd-yyyy">Date (MM/DD/YYYY)</option>
                                <option value="date-mm-yy">Date (MM/YY)</option>
                                <option value="date-mon">Date (Month)</option>
                                <option value="date-dow">Date (Day of week)</option>
                                <option value="date-yyyy">Date (Year)</option>
                                <option value="date-doy">Date (Day of Year)</option>
                                <option value="date-dom">Date (Day of Month)</option>
                                <option value="date-q">Date (Quater)</option>
                                <option value="date-qy">Date (Quater/Year)</option>
                            </>}
                            <option value="money">Money ($1,234.00)</option>
                            <option value="money-00">Money ($1,234)</option>
                            <option value="decimal">Decimal (0.00)</option>
                            <option value="number">Number (0,000,000)</option>
                            <option value="string">String</option>

                        </select>
                    </div>

                </div>
            </div>
        </Popup>
    </>
}
