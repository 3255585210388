import {useAuth} from "./authTokenContext";
import {ObjContainer} from "../object_container";
import useAdd from "./useAdd";
import useUpdate from "./useUpdate";
const hookContext = {
    entity: 'data_source_items'
};
const useDataServices = (context) => {
    const {data}  = {data:{}}; //useGetById(context);
    const [addMutate]  = useAdd(hookContext);
    const [editMutate]  = useUpdate(hookContext);

    return {
        testConnection: async (connection)=>{
            return ObjContainer.resolve("data_connection_service").test_connection(connection);
        },getSchema: async (connection)=>{
            return  ObjContainer.resolve("data_connection_service").get_schema(connection);
        },getData: async (data_def)=>{
            return  ObjContainer.resolve("data_connection_service").get_data(data_def);
        },saveDataset: async (dataset)=>{
            if(dataset.id)
                editMutate(dataset);
            else
                addMutate(dataset);
        },getDataset: async ()=>{
            return  data;
        }};
};

export default useDataServices;
