import classes from './Tab.module.scss';

const Tab = ({
    isSelected = false,
    title,
    clickHandler = null,
    isDisabled = false,
}) => {
    const tabWrapperClasses = new Array(
        classes.tabWrapper,
        isSelected ? classes.isSelected : null,
        isDisabled ? classes.isDisabled : null
    ).join(' ');
    return (
        <div className={tabWrapperClasses} onClick={clickHandler}>
            <p className={classes.tabText}>{title}</p>
        </div>
    ); 
};

export default Tab;
