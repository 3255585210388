import {useAuth} from '../authTokenContext';
import constants from '../constants';
import AxiosClient from '../AxiosClient';
const { api } = constants;
const { basePath, options } = api;
const PostData = async (request,context) =>{
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity,
        operation,
        tenantId
    } = context;
    try {
        const pathname = `${basePath}${entity}/${operation}`;
        const results = await AxiosClient.post(
            pathname,
            request,
            config
        );
        const { data } = results;
        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble searching ${entity}.`);
    }
};
export const useRoutePost = (context) =>{
    const auth = useAuth();
    return {post:async (data)=>{
        return await PostData(data, {...auth,...context});
    }};
};