import {ObjContainer} from "./object_container";
import {utils, write} from "xlsx";
import * as fileDownload from "js-file-download";

export class TableOperations{
    constructor(config){
         this.service_manager = ObjContainer.resolve("service_manager");
         this.pageConfig = {count:20, page:1};
         this.filter = {};
         this.schema = config.schema;
         this.routes = config.routes || [];
         this.parent_data = config.parent_data || {};
         this.rowClick = config.rowClick;
         this.selectedRows = [];
         this.source = null;
    }
    operations(source){
        this.source = source;
        return {
            onAdd: ()=>                this._onAdd(),
            onPage: (page_params) =>   this._updatePaging(page_params),
            rowClick: (row, field,e) =>  {

                return this._click(row, field,e);
            },
            onDelete: async ()=>   await this._deleteRows(),
            onExport: async ()=>   await this._export(),
            onSelect: (checked, row)=>{
                let id_index = this.selectedRows.indexOf(row[this.schema.primary_key || "id"]);
                if(!checked && id_index > -1){
                    this.selectedRows.splice(id_index,1);
                }else{
                    this.selectedRows.push(row[this.schema.primary_key || "id"]);
                }
                source.setState({selected:this.selectedRows});
            }
        }
    }
    _updatePaging(paging){
        console.log("update paging", paging)
        if(paging === this.pageConfig.page) return;
        if(paging < 1) return;
        if(paging > Math.ceil(this.pageConfig.count / this.pageConfig.page_size)) return;
        this.filter.page = paging;
        this.service_manager.resolve(this.schema.table_name).emit("filter-updated", this.filter);
    }
    _onAdd(){
        let route = this.routes.find(route => route.route_key === "add");
        ObjContainer.resolve("nav").navTo(route.route_dest,
            {parent_data: Object.assign({},this.parent_data,{back_state: ObjContainer.resolve("nav").getState()})});
        this.service_manager.emit("form_setup",this.schema);
    }
    _click(row, field,e){
        e.stopPropagation();
        try{
            if(this.rowClick){
                this.rowClick(row, field,e);
            }else{
                this._tableNav(row, field,e)
            }
        }catch(e){
            console.log("error", e.toString());
            return false
        }

        return false;
    }
    _tableNav(row, field){
        let local_state = {id:row.id,route_params:{filter:{id:row.id}},
            parent_id:row.id,
            parent_data: {...this.parent_data,back_state:ObjContainer.resolve("nav").getState()}};
        local_state.route_params.filter[field.link_field] = row[field.link_field];
        ObjContainer.resolve("nav").navTo(field.link, local_state);
    }
    async _deleteRows(){
        for(let row_i = 0;row_i< this.selectedRows.length; row_i++){
            let row = this.selectedRows[row_i];
            await this.service_manager.resolve(this.schema.table_name).delete(row);
        }
        this.selectedRows = [];
        this.source.setState({selected:[]});
        await this.service_manager.resolve(this.schema.table_name).emit("update-grid");

    }

    async _export(){
        let data_def = await this.service_manager.resolve(this.schema.table_name).get(Object.assign({},this.filter,{pageSize:100000}));
        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExtension = '.xlsx';
        let ws = utils.json_to_sheet(data_def.data.data);
        let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        let excelBuffer =  write(wb, { bookType: 'xlsx', type: 'array' });
        let data = new Blob([excelBuffer], {type: fileType});
        fileDownload(data, 'export.' + fileExtension);
    }

}
