import React, { useRef } from "react";
import useElementRect, {
  useWindowSize,
} from "../../../services/hooks/useElementRect";
import FormattedTable from "./FormattedTable";
import classes from "./TitledTable.module.scss";

const TitledTable = ({
  title = null,
  headers,
  data,
  selectedItem,
  onItemSelected,
}) => {
  const windowSize = useWindowSize();
  const tableWrapperEl = useElementRect([windowSize.width, windowSize.height]);
  const tableRef = useRef();
  const onScroll = (event) => {
    tableRef.current.onScroll(event);
  };

  return (
    <div className={classes.outerWrapper}>
      <div className={classes.innerWrapper}>
        {title && <div className={classes.tableTitle}>{title}</div>}
        <div
          className={classes.tableWrapper}
          onScroll={onScroll}
          ref={tableWrapperEl.ref}
        >
          <FormattedTable
            headers={headers}
            data={data}
            selectedItem={selectedItem}
            onItemSelected={onItemSelected}
            ref={tableRef}
            wrapperEl={tableWrapperEl}
          />
        </div>
      </div>
    </div>
  );
};

export default TitledTable;
