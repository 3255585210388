import React,{useEffect,useState} from 'react';
import {ObjContainer} from '../../services/object_container';

import {PurchaseOrderTab} from './PurchaseOrders/PurcahseOrderTab';
import {SalesOrderTab} from './PurchaseOrders/SalesOrderTab';
import useGetById from '../../services/hooks/useGetById';
import {TabView} from "primereact/tabview";
import TabbedView from "../CommonUI/TabbedView/TabbedView";


const hookContext = {
    entity:'purchase_orders',
    expands_multi:{'purchase_order_items':'purchase_order_id'}
};
export function PurchaseOrderManager(){

    const requestState  =  ObjContainer.resolve('nav').getState();
    let {data:purchase_order}  = useGetById({...hookContext,id:requestState.id});
    const [purchaseOrder,  setPurchaseOrder] = useState({});
    let tabs = [
        {
            title: 'Details',
            view: (<PurchaseOrderTab purchaseOrder={purchaseOrder} setPurchaseOrder={setPurchaseOrder}/>),
            disabled: false,
        },{
            title: 'Ordered Items',
            view: (<SalesOrderTab purchaseOrder={purchaseOrder} setPurchaseOrder={setPurchaseOrder}/>),
            disabled: false
        }
        ,{
            title: 'Sales Orders',
            view: (<SalesOrderTab purchaseOrder={purchaseOrder} setPurchaseOrder={setPurchaseOrder}/>),
            disabled: false
        },{
            title: 'Invoices',
            view: (<SalesOrderTab purchaseOrder={purchaseOrder} setPurchaseOrder={setPurchaseOrder}/>),
            disabled: false
        }
    ];
    useEffect(() => {
        if (purchase_order && purchase_order.data) {
            setPurchaseOrder(purchase_order.data);
        } else {
            setPurchaseOrder({});
        }
    }, [purchase_order, requestState.id]);
    return <div className={'container-fluid'}>
        <div className="row">
            <div className="col-md-12">
                <h2>Purchase Order</h2>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12"  style={{overflow:'auto'}}>
                <TabbedView  tabs={tabs}/>
            </div>
        </div>
    </div>;
}

