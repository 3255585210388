import React, {useEffect, useState} from "react";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";
import {GroupConfigurationItem} from "./GroupConfigurationItem";
export const GroupsConfiguration = ({show,setShow,table,updateTable, all_tables,dataset}) =>{
    const [showImport, setShowImport] =useState(false);
    const [currTable, setCurrTable] =useState(table || {});
    useEffect(()=>{
        setShowImport(show);
    },[show]);
    useEffect(()=>{
        if(table)
            setCurrTable(table);
    },[table]);
    const saveJoins = ()=>{

        setShow(false);
    }
    return <Popup show={showImport} showSave={true} save={() => saveJoins()} title={currTable.table_name + " Groups"} closeModal={() => setShow(false)}>
        <div className="row">
            <div className="col-md-12">
                <div className="p-col-12">
                    <div style={{height:"400px",overflow:"auto"}}>
                        {(!currTable.group_list || currTable.group_list.length === 0)&& <>
                            <GroupConfigurationItem table={currTable} groupItem={{}} all_tables={all_tables} updateTable={updateTable} />
                        </>}
                        {(currTable.group_list && currTable.group_list.length > 0) && (
                            <>
                                {currTable.group_list.map((i, ind)=>{
                                    return <GroupConfigurationItem key={"join_item_" + ind} groupItem={i} table={currTable} all_tables={all_tables} updateTable={updateTable} />;
                                })}
                            </>)}
                    </div>
                </div>
            </div>
        </div>
    </Popup>;
}