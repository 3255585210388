import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {ObjContainer} from '../object_container';

export const useSchema = (ctx) =>{
    const {data} =useQuery(['schema-config',ctx], async ()=>{
        let res = await ObjContainer.resolve('service_manager').resolve(ctx.table_name).schema({});
        return res.data;
    }); 
    return {data};
};