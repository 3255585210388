import { useState, useEffect } from 'react';
import useAdd from './useAdd';
import useTableData from './useTableData';
import { useUpsert } from './useUpsert';

const useAddInventoryAdjustments = () => {
    const [updateInventory] = useUpsert({ entity: 'inventory' });
    const [addAdjustment] = useAdd({ entity: 'adjustments' });
    const [addAdjustmentItem] = useAdd({ entity: 'adjustment_items' });

    const addInventoryAdjustments = async ({ newInventoryArray, previousInventoryArray, adjustmentDescription }) => {
        const updatedInventoryArray = await Promise.all(
            newInventoryArray.map(async (row) => {
                return updateInventory(row).then((res) => {
                    if (res.success) {
                        return res.result[0];
                    } else return res.success;
                });
            })
        );

        if (updatedInventoryArray.some((res) => res === false)) {
            console.log('Error updating inventory database');
            return Promise.resolve(false);
        }

        const loginData = JSON.parse(localStorage.login_data);
        const adjustmentEl = {
            date: new Date(),
            reason: adjustmentDescription,
            authorized_person: loginData.user.id,
            created_at: new Date(),
            updated_at: new Date(),
        };
        const addAdjustmentResult = await addAdjustment(adjustmentEl);
        if (!addAdjustmentResult.success) {
            console.log('Error inserting new adjustment description into database.');
            return Promise.resolve(false);
        }

        const adjustmentRecord = addAdjustmentResult.result[0];
        const adjustmentId = adjustmentRecord.id;
        const insertAdjustmentsSuccess = await Promise.all(
            updatedInventoryArray.map(async (row) => {
                const prevInventory =
                    previousInventoryArray.length > 0
                        ? previousInventoryArray.find((el) => el.id === row.id)?.qty_inventory ?? 0
                        : 0;
                const inventoryDiff = row.qty_inventory - prevInventory;
                if (inventoryDiff !== 0) {
                    const adjLine = {
                        adjustment_id: adjustmentId,
                        inventory_id: row.id,
                        qty_inventory: row.qty_inventory - prevInventory,
                    };
                    return addAdjustmentItem(adjLine).then((res) => res.success);
                }
                return Promise.resolve(true);
            })
        ).then((res) => {
            return res.every((res) => res);
        });

        if (!insertAdjustmentsSuccess) {
            console.log('Error inserting adjustment data into database.');
            return Promise.resolve(false);
        }

        return Promise.resolve(updatedInventoryArray);
    };

    return addInventoryAdjustments;
};

export const useAddMissingSizes = () => {
    const [variantId, setVariantId] = useState(null);

    const [variantArray, , variantDataReturned] = useTableData({
        hookContext: variantId
            ? {
                  entity: 'product_variations',
                  filter_eq: { id: variantId },
              }
            : {
                  entity: 'product_variations',
              },
        effectCondition: variantId >= 1,
    });

    const [sizeRange, setSizeRange] = useState(null);
    useEffect(() => {
        if (variantDataReturned) setSizeRange(variantArray[0].products.size_range);
        else setSizeRange(null);
    }, [variantDataReturned]);
    const [sizes, , sizesDataReturned] = useTableData({
        hookContext: sizeRange
            ? {
                  entity: 'sizes',
                  filter_eq: { size_range: sizeRange },
              }
            : {
                  entity: 'sizes',
              },
        effectCondition: sizeRange !== null,
        mapCallback: (el) => el.code,
    });

    const [inventory, , inventoryDataReturned] = useTableData({
        hookContext: variantId
            ? {
                  entity: 'inventory',
                  filter_eq: { product_variant_id: variantId },
              }
            : {
                  entity: 'inventory',
              },
        effectCondition: variantId >= 1,
    });

    const [addInventory] = useAdd({ entity: 'inventory' });
    const insertMissingInventory = async (variant, missingSizeCodesArray) => {
        missingSizeCodesArray.map(async (sizeCode) => {
            const newSize = {
                product_id: variant.products.id,
                product_variant_id: variant.id,
                style_number: variant.products.style_number,
                attr_2: variant.variation_code,
                size: sizeCode,
                qty_inventory: 0,
                qty_open_sales: 0,
                qty_avail_sell: 0,
                sku_id: [variant.products.style_number, variant.variation_code, sizeCode].join('-'),
                created_at: new Date(),
                updated_at: new Date(),
            };
            await addInventory(newSize);
        });
    };

    const getMissingSizes = () => {
        const invSizes = inventory.map((row) => row.size);
        return sizes.filter((code) => !invSizes.includes(code));
    };

    const [sizesAdded, setSizesAdded] = useState(false);
    useEffect(async () => {
        if (variantDataReturned && inventoryDataReturned && sizesDataReturned) {
            const missingSizes = getMissingSizes();
            await insertMissingInventory(variantArray[0], missingSizes);
            setSizesAdded(true);
        }
    }, [variantDataReturned, inventoryDataReturned, sizesDataReturned]);

    const addMissingSizes = (selectedVariantId) => {
        setSizesAdded(false);
        setVariantId(selectedVariantId);
    };

    return [addMissingSizes, sizesAdded];
};

export default useAddInventoryAdjustments;
