import React, {Component} from 'react';
import {ObjContainer} from '../../services/object_container';
import {Popup} from '../FormDriver/FormBuilderSupportComponent';
import Modal from 'react-bootstrap/Modal';
 
import {ImageUpload} from './ImageUpload';
import {InvoiceItem} from './InvoiceItem';
import {ViewLayer} from './ViewLayer';

export class ChildTableActions extends Component{
    constructor(props) {
        super(props);
        this.state={show:false, obj:{},editing:{}};
        this.arrayBuffer = [];
        this.service_manager = ObjContainer.resolve('service_manager');
    }
    async _saveData(group){
        let obj = this.state.obj;
        let primaries = this.props.child.fields.filter( i=>i.field_type === 'primary');
        primaries.forEach((primary)=>{
            obj[primary.field_code] = this.props.parentObj[primary.parent_field_name];
        });
        await this.service_manager.resolve(this.props.child.table_name).upsert(this.state.obj.id,obj);
        this.setState((s) =>{
            s.show = false;
            return s;
        });
        if(this.props.ops.onSave){
            await this.props.ops.onSave(group);
        }
    }
    _changeData(field_code, value){
        this.setState((s) =>{
            s.obj[field_code] = value;
            return s;
        });
    }
    _cancelEdit(group){
        this.setState((s) =>{
            s.editing[group] = false;
            return s;
        });
    }
    async _refresh(){
        if(this.props.ops.onSave) {
            await this.props.ops.onSave();
        }
    }
    _onButtonPress(){

    }
    render() {
        if(this.props.child.edit_type === 'add_popup'){
            return (
                <div>
                    <button className={'btn btn-primary'} onClick={() => this.setState({show:!this.state.show})}>Add</button>
                    <Popup show={this.state.show} closeModal={()=>this.setState({show:false})}>
                        <ViewLayer schema_def={this.props.child} obj={this.state.obj}
                            edit_mode={true}
                            ops={{
                                onSave:        async (group)=>{await this._saveData(group.group_title);},
                                onChange:      (field_code, value)=>{this._changeData(field_code, value);},
                                onCancel:      (group)=>{this._cancelEdit(group.group_title);},
                                onDefine:      ()=>{},
                                onButtonPress: (button) => this._onButtonPress(button) }}/>
                    </Popup>
                </div>);
        }
        else if(this.props.child.edit_type === 'image_upload') {
            return ( <ImageUpload parentType={this.props.parentType} parentId={this.props.parentId}/> );
        }
        else if(this.props.child.edit_type === 'invoice_item') {
            return ( <InvoiceItem parentType={this.props.parentType} schema_def={this.props.child} parentId={this.props.parentId} refresh={async ()=> await this._refresh() }/> );
        }
        
        return <div></div>;
        
    }
}


