import classes from './FormattedTableCell.module.scss';
import { useState, useEffect, useRef } from 'react';

import TriangleIcon from '../TriangleIcon';

const FormattedTableCell = ({
    header: { key, colKey, dataStyles, inputType = null, onChange = null },
    dataFields: { key: dataKey, ...fields },
    isSelected = false,
    iconElRef = null,
    iconPosition: { x: positionX, y: positionY } = null,
    iconRotation = 90,
    isFormEditing = false,
    isFormCommitted = false,
}) => {
    const [originalValue, setOriginalValue] = useState(fields[colKey]);
    useEffect(() => {
        if (isFormCommitted) setOriginalValue(fields[colKey]);
    }, [isFormCommitted]);

    const onValueChange = (event) => {
        onChange({
            rowKey: dataKey,
            newValue: event.target.value,
        });
    };

    const onValueCancel = () => {
        onChange({
            rowKey: dataKey,
            newValue: originalValue,
        });
    };

    const iconStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        transform: `translate(${positionX}px, ${positionY}px) rotate(${iconRotation}deg)`,
    };

    const bullet = (
        <div ref={iconElRef} style={iconStyles}>
            <TriangleIcon size={13} />
        </div>
    );

    const editingElClasses = isFormEditing
        ? {
              originalValEl: [classes.originalValueWrapper, classes.isVisible],
              revertEl: [classes.revertWrapper, classes.isVisible],
          }
        : {
              originalValEl: [classes.originalValueWrapper, classes.isHidden],
              revertEl: [classes.revertWrapper, classes.isHidden],
          };

    const originalValueEl = useRef(null);
    const [originalValElWidth, setOriginalValElWidth] = useState(0);
    useEffect(() => {
        setOriginalValElWidth(originalValueEl.current?.offsetWidth);
    }, [originalValueEl.current?.offsetWidth]);

    const dataDiv = onChange ? (
        <div className={classes.tableDataWrapper}>
            {isSelected && key === 1 ? bullet : ''}
            <div className={editingElClasses.originalValEl.join(' ')} ref={originalValueEl}>
                <div className={classes.originalValueHeader}>Original</div>
                <div className={classes.originalValueBody}>{originalValue}</div>
            </div>
            <input
                className={isFormEditing ? '' : classes.nonEditableInput}
                disabled={!isFormEditing}
                type={inputType ?? 'text'}
                value={fields[colKey]}
                onChange={onChange ? onValueChange : null}
                style={dataStyles}
            />
            <div className={editingElClasses.revertEl.join(' ')} style={{ width: `${originalValElWidth}px` }}>
                <i className={['fas fa-undo-alt', classes.revertIcon].join(' ')} onClick={onValueCancel}></i>
            </div>
        </div>
    ) : (
        <div style={dataStyles}>
            {isSelected && key === 1 ? bullet : ''}
            {fields[colKey]}
        </div>
    );

    const wrapperStyles = { ...dataStyles };
    if (onChange) wrapperStyles['height'] = '52px';

    return (
        <td key={key} style={wrapperStyles}>
            {dataDiv}
        </td>
    );
};

export default FormattedTableCell;
