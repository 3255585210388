import React, {useState} from 'react';
import {EditSeries} from './EditSeries';
import TabbedView from '../../CommonUI/TabbedView/TabbedView';
import {XAxis} from './XAxis';
import {YAxis} from './YAxis';

export const ReportBasicEdit = ({dataSource, currReport, updateField,updateReportDef,addNewSeries,selectSeries,report,seriesItem,updateSeriesItem,showNewSeries}) =>{

    return <div className="container-fluid">
        <div className={'row'}>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Title</label>
                    <input type="text" className={'form-control'}
                        value={currReport.report_title || ''}
                        onChange={(e)=>updateField('report_title', e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Chart Type</label>
                    <select className={'form-control'} value={currReport.report_definition?.report_type || ''}
                        onChange={(e)=>updateReportDef('report_type',e.target.value)}>
                        <option value="">...Select One</option>
                        <option value="line">Line</option>
                        <option value="pie">Pie</option>
                        <option value="bar">Bar</option>
                        <option value="table">Table</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">API Key</label>
                    <input type="text" value={currReport.api_key || ''} className={'form-control'} onChange={(e)=>updateField('api_key',e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Environment</label>
                    <input type="text" value={currReport.environment || ''} className={'form-control'} onChange={(e)=>updateField('environment',e.target.value)}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Dynamic Properties Data</label>
                    <select className={'form-control'} value={currReport.report_definition?.report_properties_data || ''}
                            onChange={(e)=>updateReportDef('report_properties_data',e.target.value)}>
                        <option value="">...Select One</option>
                        {(dataSource?.schema_definition?.tables  || []).map((option, opt_index)=>{
                            return <option key={'report-def-table-option-' + opt_index} value={option.table_name}>{option.table_name}</option>;
                        })}
                    </select>
                </div>
            </div>
            <div className={"col-md-6"}>
                <div className="form-group">
                    <label htmlFor="">Font Family</label>
                    <input type="text" value={currReport.report_definition?.font_family || ''} className={'form-control'} onChange={(e)=>updateReportDef('font_family',e.target.value)}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Font Size</label>
                    <input type="text" value={currReport.report_definition?.font_size || ''} className={'form-control'} onChange={(e)=>updateReportDef('font_size',e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Timezone Offset from UTC</label>
                    <input type="text" value={currReport.report_definition?.timezone_offset || ''} className={'form-control'} onChange={(e)=>updateReportDef('timezone_offset',e.target.value)}/>
                </div>
            </div>
        </div>
    </div>;
};