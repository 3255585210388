import React, {useEffect, useState} from 'react';
import {Popup} from '../../FormDriver/FormBuilderSupportComponent';
import Editor, {monaco} from '@monaco-editor/react';
export const QueryBuilder = ({show, setShow,table, dataset, updateDataset}) =>{
    let editorRef = null;
    const [showQuery, setShowQuery] =useState(false);
    const [queryText, setQueryText] =useState('');
    const [currTable, setCurrTable] =useState(table || {table_type: 'query'});

    useEffect(()=>{
        setShowQuery(show);
        if(show){
            monaco.init().then(monaco => {
                monaco.languages.registerCompletionItemProvider('sql', {});
            });
        }
    },[show]);

    useEffect(()=>{
        setCurrTable(table|| {table_type: 'query'});
    },[table]);
    const saveQuery = ()=>{
        let newTable = updateVal(queryText,'query_text');
        let tmpData = {...dataset};
        tmpData.schema_definition  =  tmpData.schema_definition || {tables:[]};
        tmpData.schema_definition.tables =tmpData.schema_definition.tables || [];
        let table = tmpData.schema_definition.tables.find(i=>i.table_name === currTable.table_name);
        if(table){
            tmpData.schema_definition.tables[tmpData.schema_definition.tables.indexOf(table)] = {...newTable};
        }else{
            tmpData.schema_definition.tables.push({...newTable});
        }
        updateDataset(tmpData);
        setShow(false); 
    };
    const updateVal = (value, property)=>{
        const tempCur = {...currTable};
        tempCur[property] = value;
        setCurrTable(tempCur);
        return tempCur;
    };
    const editorMounted = (_, editor) =>{
        editorRef = editor;
        editor.onDidChangeModelContent(ev => {
            setQueryText(editor.getValue());
        });
    };
    return <div><Popup  show={showQuery} showSave={true} save={() => saveQuery()} title={'Query Builder'} closeModal={() => setShow(false)}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Query Name</label>
                        <input className="form-control" type="text" value={currTable.table_name || ''} onChange={(e)=>{updateVal(e.target.value, 'table_name');}}/>

                    </div>
                </div>
            </div>
        </div>
        {showQuery && <Editor height={'25vh'} language="sql" value={currTable.query_text || ''} editorDidMount={(_,editor) => editorMounted(_,editor)}/>}
    </Popup></div>;
};