import React, {useState} from 'react';
import useNav from '../../../services/hooks/useNav';
import useGetById from '../../../services/hooks/useGetById';
import {useUpsert} from '../../../services/hooks/useUpsert';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import {useRoutePost} from '../../../services/hooks/routes/useRoutePost';
const hookContext = {
    entity: 'inbound_integrations'
};
const managementHookContext = {
    entity: 'api_security',
    operation: 'generate_key'
};
export const InboundIntegrationForm = ()=>{
    const {getState,navTo} = useNav();
    const [integration,setIntegration] = useState({});
    const [updateMutate]  = useUpsert({...hookContext,onSave:()=>{
        navTo('inbound-integrations');
    }});
    const {update} = useFieldUpdater({state:integration,setState: setIntegration});
    const {post} = useRoutePost({...managementHookContext});
    const saveData = async () =>{
        await updateMutate(integration);

    };
    const generateKey = async () =>{
        let result = await post();
        let tmpIntegration ={...integration};
        tmpIntegration['api_key'] = result.apiKey;
        tmpIntegration['api_guid'] = result.uuid;
        tmpIntegration['api_secret'] = result.secret;
        setIntegration(tmpIntegration);
    };
    return <div className={'container-fluid'}>
        <h2>Inbound Integration</h2>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Integration Name</label>
                    <input type="text" className={'form-control'} onChange={(e)=>update('integration_name', e.target.value)}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <a className={'inline-nav-link'} onClick={async ()=>await generateKey()}>Generate</a>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">API Key</label>
                    <input type="text" className={'form-control'} value={integration.api_key ||''} disabled={true}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Secret</label>
                    <input type="text" className={'form-control'} value={integration.api_secret ||''} disabled={true}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <button className={'btn btn-primary'} onClick={async ()=>await saveData()}>Save</button>
                </div>
            </div>
        </div>
    </div>;
};