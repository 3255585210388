import React, {Component}from 'react';
import Editor, {monaco} from '@monaco-editor/react';
import {ObjContainer} from '../../../services/object_container';
import {ContextMenu} from 'primereact/contextmenu';
import {Tree} from 'primereact/tree';
import {Growl} from 'primereact/growl';

export class QueryBuilder extends Component{
    constructor(){
        super();
        this.service_manager = ObjContainer.resolve('service_manager');
        this.data_manager = ObjContainer.resolve('data_manager');
        this.state = {
            query_text:'',
            query_fields: [],
            rows:[],
            db_data:[],
            nodes: null,
            expandedKeys: {}, 
            selectedNodeKey: null,
            menu: [
                {
                    label: 'Select Data',
                    icon: 'pi pi-search',
                    command: () => {
                        this.setState({query_text:`select * from ${this.state.db_data.find(i=>i.key === this.state.selectedNodeKey).data} limit 10;`});
                    }
                }]
        };
    }
    async componentDidMount() {
        let tables = await this.service_manager.resolve('tables').get({filter:{}});
        let data = await this.data_manager.getData('/schema_router/db_schema',{});
        let db_data = data.tables.rows.map((table, index)=>{
            return  {
                'key': index,
                'label': `${table.tablename}`,
                'data': table.tablename,
                'icon': 'pi pi-fw pi-table',
                'children': data.columns.rows.filter(i=>i.table_name === table.tablename).map((col, col_index)=> {
                    return {
                        'key': index + '-' + col_index,
                        'label': `${col.column_name} (${col.data_type} ${col.character_maximum_length ? ('(' + col.character_maximum_length + ')') : ''})`,
                        'data': 'Work Folder',
                        'icon': 'pi pi-fw pi-cog'
                    };
                })};});
        this.setState({db_data});
        monaco.init()
            .then(monaco => {
                monaco.languages.registerCompletionItemProvider('sql', {
                    provideCompletionItems: (model, position) =>{
                        let word = model.getWordUntilPosition(position);
                        let range = {
                            startLineNumber: position.lineNumber,
                            endLineNumber: position.lineNumber,
                            startColumn: word.startColumn,
                            endColumn: word.endColumn
                        };
                        let options = tables.data.data.reduce((arr,table)=>{
                            if(!arr.find(i=>i.label === table.table_name)){
                                arr.push({
                                    label: table.table_name,
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    documentation:  table.table_name,
                                    insertText:  table.table_name,
                                    range: range
                                });
                            }
                            return arr;
                        },[]);
                        return {
                            suggestions: options
                        };
                    }
                });
            });
    }
    async executeQuery(){
        let data = await this.data_manager.postData('/import_file/query',{query:this.state.query_text});
        if(data.fields && data.fields.length > 0 ){
            this.setState({failed_message:'',query_fields:data.fields, rows:data.rows,message:'success'});
        }else if(data.message){
            this.setState({failed_message:'Failed: ' + data.message, message:'',query_fields:[], rows:[]});
        }
        else{
            this.setState({failed_message:'',message:'execution complete',query_fields:[], rows:[]});
        }

    }
    editorMounted(_, editor) {
        this.editorRef  = editor;
        this.editorRef.onDidChangeModelContent(ev => {
            this.setState({query_text: this.editorRef.getValue()});
        });
    }
    getSqlCompletionProvider(monaco) {
        return {
            providerCompletionItems: (model, position) =>{
                return [{
                    label:'test',
                    detail: 'details',
                    documentation: 'Documentation'
                }];
            }
        };
    }
    providerCompletionItems(model, position){
        return [];
    }
    render(){
        return (
            <div>
                <div style={{marginBottom:'5px'}}>
                    <span style={{fontSize:'1.5em'}}>SQL Editor &nbsp;</span><button className={'btn btn-primary'} onClick={()=>this.executeQuery()}>Send</button> <button className={'btn btn-primary'} onClick={()=>this.setState({query:undefined})}>Cancel</button>
                </div>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-3" >
                            <ContextMenu model={this.state.menu} ref={el => this.cm = el} />
                            <Tree value={this.state.db_data} expandedKeys={this.state.expandedKeys} onToggle={e => this.setState({expandedKeys: e.value})}
                                onContextMenuSelectionChange={event => this.setState({selectedNodeKey: event.value})}
                                onContextMenu={event => this.cm.show(event.originalEvent)}  style={{height:'40vh', overflowY:'auto', width:'100%'}}/>
                        </div>
                        <div className="col-md-9" style={{padding: '5px', borderTop: '1px solid silver', marginTop:'5px'}}>
                            <Editor height="40vh" language="sql" value={this.state.query_text} editorDidMount={(_,editor) => this.editorMounted(_,editor)}/>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" style={{height:'43vh' ,overflow:'auto'}}>
                    <div style={{color:'green'}}>{this.state.message}</div><div style={{color:'red'}}>{this.state.failed_message}</div>
                    {this.state.rows && <ReportResultView rows={this.state.rows} fields={this.state.query_fields} />}
                    {!this.state.rows ||  this.state.rows.length === 0 && (<div>
                        <p>Result will display here.</p>
                    </div>)}
                </div>
            </div>);
    }
}
export class ReportResultView extends Component{
    render(){
        return (
            <table className={'table sticky'}>
                <thead><tr>
                    {this.props.fields.map((field, i)=>{
                        return (<th key={'result_head_' + i}>{field.name}</th>);
                    })}
                </tr></thead>
                <tbody>
                    {this.props.rows.map((row,row_i)=>{
                        return (<tr key={'row_num_' + row_i}>
                            {this.props.fields.map((field, i)=>{
                                return (<td key={'result_field_row_' + row_i + '_field_' + i}>{row[field.name]}</td>);
                            })}
                        </tr>);
                    })}
                </tbody>
            </table>
        );
    }
}
