import React, {useEffect, useState} from "react";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";
import {Checkbox} from "primereact/checkbox";
import {DataTypeIcon} from "./DataTypeIcon";

export const TableDetailsConfig = ({table, updateTable,showConfig,setShowConfig}) =>{
    const [curTable, setCurTable] = useState(table);
    const [enableAll, setEnableAll] = useState(true);
    useEffect(()=>{
        if(table)
            setCurTable(table);
    },[table]);
    const toggleColumn = (col, enabled) =>{
        let tableVar = {...curTable};
        let colDat = tableVar.column_data.find(i=>i.name === col.name);
        colDat.enabled = enabled;
        updateTable(tableVar);
    }
    const toggleAll = (enabled)=>{
        let tableVar = {...curTable};
        tableVar.column_data.forEach(col=>{
            col.enabled = enabled;
        });
        updateTable(tableVar);
        setEnableAll(!enableAll);
    }
    const commit = () =>{
        setShowConfig(false);
        updateTable(table);
    };
    return  <Popup show={showConfig} closeModal={()=>setShowConfig(false)} title={table.table_name} showSave={true} save={(new_def)=>commit()}>
        <div style={{maxHeight:"400px",overflow:"auto"}}>
            <div className="row">
                <div className="col-md-2">
                    <Checkbox inputId="cb1"  onChange={(e)=> toggleAll(e.checked)} checked={enableAll} />
                </div>
                <div className="col-md-8">
                    <div className={"col-data"}>All</div>
                </div>
                <div className="col-md-2">

                </div>
            </div>
            {curTable.column_data.map((col, ind)=>{
                return <div className="row" key={"table_details_config_" + ind}>
                    <div className="col-md-2">
                        <Checkbox inputId="cb1"  onChange={(e)=> toggleColumn(col, e.checked)} checked={col.enabled} />
                    </div>
                    <div className="col-md-8">
                        <div className={"col-data"}>{col.name}</div>
                    </div>
                    <div className="col-md-2">
                        <DataTypeIcon id={col.user_type_id} />
                    </div>
                </div>
            })}
        </div>
    </Popup>
}