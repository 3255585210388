import EventEmitter from 'events';
import axios from 'axios';
import {UserManagementService} from './user_management_service';

export class DataManager extends EventEmitter{
    constructor(config={root_url:''}){
        super();
        this.user_manager = new UserManagementService();
        this.config = config;
        this.config.root_url = this.config.root_url || '';
    }
    async getData(url,query){
        let ret = await axios.get(`${this.config.root_url+url}?${this.formatQuery(query)}`, this.getHeaders());
        return ret.data;
    }
    async deleteData(url){
        let ret = await axios.delete(`${this.config.root_url}${url}`, this.getHeaders());
        return ret.data;
    }
    async getWithSearch(url,query){
        let ret = await axios.get(`${this.config.root_url + url}?${this._prepareFilter(query)}`, this.getHeaders());
        return ret.data;
    }
    async searchData(url,query){
        let ret = await axios.get(`${this.config.root_url + url}?${this.formatQuery(query)}`, this.getHeaders());
        return ret.data;
    }
    async postData(url,data){
        let ret = await axios.post(`${this.config.root_url + url}`, data,this.getHeaders());
        return ret.data;
    }
    async putData(url,data){
        let ret = await axios.put(`${this.config.root_url + url}`, data,this.getHeaders());
        return ret.data;
    } 
    _prepareFilter(filter){
        let ret_array = [];

        if(filter.filter && Object.getPrototypeOf(filter.filter) === String.prototype){

            return 'filter=' + filter.filter.toString();
        }
        Object.keys(filter.filter || {}).forEach((key)=>{
            if(filter.filter[key] !== null && filter.filter[key] !== '')
            {ret_array.push(`filter[${key}]=${filter.filter[key]}`);}
        });

        if(filter.expand){

            let exp_str = filter.expand.join(',');
            ret_array.push(`expand=${exp_str}`);
        }
        if(filter.sort)
        {ret_array.push(`sort[${filter.sort.field_code}]=${filter.sort.sort_direction}`);}
        if(filter.page){
            ret_array.push(`page=${filter.page}`);
        }
        if(filter.pager) {
            Object.keys(filter.pager || {}).forEach((key) => {
                if (filter.pager[key] !== null && filter.pager[key] !== '')
                {ret_array.push(`pager[${key}]=${filter.pager[key]}`);}
            });
        }
        return ret_array.join('&');
    }
    formatQuery(query){
        let keyList = [];
        Object.keys(query || {}).forEach((key)=>{
            keyList.push(`${key}=${query[key]}`);
        });
        return keyList.join('&');
    }
    getHeaders(){
        return {headers :{Authorization:'Bearer ' + (this.user_manager.loginData() || {}).jwt}};
    }
}
