import React, { useEffect, useState } from 'react';
import { useRoutePost } from '../../../services/hooks/routes/useRoutePost';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';

import classes from '../Report.module.scss';
import PageTitle from '../../CommonUI/PageTitle';

import { saveAs } from 'file-saver';
import DataGrid, {
    Column,
    Export,
    Grouping,
    GroupItem,
    GroupPanel,
    Paging,
    SearchPanel,
    Summary,
    TotalItem,
} from 'devextreme-react/data-grid';
import * as moment from 'moment';

export const InvoiceSummaryReport = () => {
    const [data, setData] = useState({ rows: [], fields: [] });
    const [filter, setFilter] = useState({
        start_date: { source: 'date', exp: 'gte', value: moment().add(-30, 'days').format('YYYY-MM-DD') },
    });
    const { post } = useRoutePost({ entity: 'custom_reports', operation: 'execute_report' });
    const updateData = async (tmpFilter) => {
        let res = await post({
            source_report: 'reports/apparel/grouped_sales_report_by_product',
            parameters: tmpFilter,
        });
        setData(res.result);
        return res;
    };
    useEffect(() => {
        updateData(filter).then((res) => {});
    }, []);
    const update = async (field, exp, value) => {
        let tmpFilter = { ...filter };
        if (!value) {
            delete tmpFilter[field];
        } else {
            tmpFilter[field] = { source: 'date', exp: exp, value: value };
        }
        setFilter(tmpFilter);
        await updateData(tmpFilter);
    };
    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sales Report');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'InvoiceSummary.xlsx');
            });
        });
        e.cancel = true;
    };
    return (
        <div className={'container-fluid'}>
            <div className={classes.reportWrapper}>
                <PageTitle>Invoice Summary</PageTitle>
                <form>
                    Show invoices between&nbsp;
                    <input
                        type='date'
                        value={filter.start_date?.value}
                        onChange={async (e) => await update('start_date', 'gte', e.target.value)}
                    />
                    &nbsp;&nbsp;and&nbsp;&nbsp;
                    <input
                        type='date'
                        value={filter.end_date?.value}
                        onChange={async (e) => await update('end_date', 'lte', e.target.value)}
                    />
                    &nbsp;
                </form>
            </div>
            <DataGrid id='gridContainer' dataSource={data.rows} showBorders={true} onExporting={onExporting}>
                <GroupPanel visible={true} />
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={10} />

                <Column dataField={'style_number'} header={'Style'} dataType={'string'} sortable={true} filter={true} />
                <Column
                    dataField={'variation_code'}
                    header={'Print'}
                    dataType={'string'}
                    sortable={true}
                    filter={true}
                />
                <Column
                    dataField={'variation_name'}
                    header={'Print Code'}
                    dataType={'string'}
                    sortable={true}
                    filter={true}
                />
                <Column dataField={'qty'} dataType={'number'} header={'Qty'} sortable={true} filter={true} />
                <Column
                    dataField={'total'}
                    dataType={'number'}
                    format='currency'
                    header={'Total'}
                    sortable={true}
                    filter={true}
                />
                <Column
                    dataField={'description'}
                    header={'Description'}
                    dataType={'string'}
                    sortable={true}
                    filter={true}
                    groupIndex={0}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Summary>
                    <GroupItem column='variation_code' summaryType='count' displayFormat='{0} variants' />

                    <GroupItem
                        column='qty'
                        summaryType='sum'
                        showInGroupFooter={false}
                        displayFormat='{0}'
                        alignByColumn={true}
                    />
                    <GroupItem column='qty' summaryType='sum' displayFormat='Count: {0}' showInGroupFooter={true} />

                    <GroupItem
                        column='total'
                        summaryType='sum'
                        displayFormat='{0}'
                        showInGroupFooter={false}
                        valueFormat='currency'
                        alignByColumn={true}
                    />
                    <GroupItem
                        column='total'
                        summaryType='sum'
                        displayFormat='Total: {0}'
                        valueFormat='currency'
                        showInGroupFooter={true}
                    />

                    <TotalItem column='qty' summaryType='sum' />

                    <TotalItem column='total' summaryType='sum' valueFormat='currency' />
                </Summary>
            </DataGrid>
        </div>
    );
};
