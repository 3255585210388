import { useUpsert } from '../../../services/hooks/useUpsert';
import useTableData from '../../../services/hooks/useTableData';

import classes from './InventoryManager.module.scss';

import DetailsTable from '../../CommonUI/DetailsTable';

const VariantDetails = ({ selectedProduct, selectedVariant, onUpdate, isInAddMode }) => {
    const productDataObjectArray = [
        {
            key: 'key',
            value: selectedProduct.id,
        },
        {
            key: 'Product Name',
            value: selectedProduct.description,
        },
        {
            key: 'Product Code',
            value: selectedProduct.style_number,
        },
        {
            key: 'Price',
            value: selectedProduct.price,
        },
        {
            key: 'Cost',
            value: selectedProduct.cost,
        },
        {
            key: 'Category',
            value: selectedProduct.category,
        },
        {
            key: 'Notes',
            value: selectedProduct.notes,
        },
    ];

    const productTable = () => {
        return (
            selectedProduct.id && <DetailsTable title={'Product Details'} dataObjectArray={productDataObjectArray} />
        );
    };

    const variantDataObjectArray = (isInAddMode || selectedVariant) && [
        {
            key: 'key',
            value: isInAddMode ? 0 : selectedVariant.id,
            fieldName: 'id',
        },
        {
            key: 'Variant Name',
            value: isInAddMode ? '' : selectedVariant.variant_description,
            isEditable: true,
            type: 'text',
            fieldName: 'variation_name',
        },
        {
            key: 'Variant Code',
            value: isInAddMode ? '' : selectedVariant.code,
            isEditable: true,
            type: 'text',
            fieldName: 'variation_code',
        },
        {
            key: 'Sku',
            value: isInAddMode ? '' : selectedVariant.sku,
            fieldName: 'sku',
            calculateValue: {
                elements: [
                    { type: 'exact', value: selectedProduct.style_number },
                    { type: 'lookup', key: 'Variant Code' },
                ],
                delimiter: '-',
            },
        },
        {
            key: 'Is Archived?',
            value: isInAddMode ? false : selectedVariant.archived,
            isEditable: true,
            inputType: 'checkbox',
            fieldName: 'archived',
        },
    ];

    const [sizes, setSizes] = useTableData({
        hookContext: {
            entity: 'sizes',
            filter: {
                size_range: { eq: selectedProduct.size_range },
            },
        },
    });

    const hookContext = {
        entity: 'product_variations',
        id: selectedVariant?.id,
    };
    const [upsertMutate] = useUpsert({
        ...hookContext,
    });

    const onCommit = async (variantObj) => {
        const updatedObj = {
            ...variantObj,
            sku: [selectedProduct.style_number, variantObj.variation_code].join('-'),
            product_id: selectedProduct.id,
        };

        if (isInAddMode) {
            delete updatedObj.id;
        }
        await upsertMutate(updatedObj).then((res) => {
            const result = Array.isArray(res.result) ? res.result[0] : res.result;
            onUpdate(result);
        });
    };

    const onCancel = () => {
        if (isInAddMode) onUpdate(null);
        else onUpdate();
    };

    const variantTable = () => {
        return (
            (selectedVariant || isInAddMode) && (
                <DetailsTable
                    title={'Variant Details'}
                    dataObjectArray={variantDataObjectArray}
                    onSave={onCommit}
                    onCancel={onCancel}
                    isEditing={isInAddMode}
                />
            )
        );
    };

    return (
        <div className={classes.detailsTable}>
            {productTable()}
            {variantTable()}
        </div>
    );
};

export default VariantDetails;
