import React, {useState} from 'react';

export const XAxis = ({currReport, updateReportDef,dataSource}) =>{
    const [newPhaseGroup, setNewPhaseGroup] = useState({});
    const [showAddNew, setShowAddNew] = useState(false);
    const updateNewPhaseGroup = (field, value) =>{
        let tmpPhaseGroup = {...newPhaseGroup};
        tmpPhaseGroup[field] = value;
        setNewPhaseGroup(tmpPhaseGroup);
    }
    const addCommentLineGroup = () =>{
        let tmp = {...currReport};
        tmp.x_axis_vertial_groups =tmp.x_axis_vertial_groups || [];
        tmp.x_axis_vertial_groups.push({...newPhaseGroup});
        updateReportDef('x_axis_vertial_groups',tmp.x_axis_vertial_groups);
        setNewPhaseGroup({});
        setShowAddNew(false);
    }
    const deleteGroup = (group) =>{
        let tmp = {...currReport};
        tmp.x_axis_vertial_groups =tmp.x_axis_vertial_groups || [];
        tmp.x_axis_vertial_groups.splice(tmp.x_axis_vertial_groups.indexOf(group),1);
        updateReportDef('x_axis_vertial_groups',tmp.x_axis_vertial_groups);
        setNewPhaseGroup({});
        setShowAddNew(false);
    }
    return <div  className={'container-fluid'}>
        <div className={'row'}>
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Title</label>
                    <input type="text" className={'form-control'}
                        value={currReport.report_definition?.x_axis_title || ''}
                        onChange={(e)=>updateReportDef('x_axis_title', e.target.value)}/>
                </div>
            </div>
        </div>
        <div className={'row'}>
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Axis Datatype</label>
                    <select  className={'form-control'}
                        value={currReport.report_definition?.x_axis_data_type || ''}
                        onChange={(e)=>updateReportDef('x_axis_data_type', e.target.value)}>
                        <option value="">--Choose One</option>
                        <option value="datetime">Date/Time</option>
                    </select>

                </div>
            </div>
        </div>
        <div className={'row'}>
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Phase Lines Data</label>
                    <select name="" id="" className={'form-control'} value={currReport.report_definition?.x_axis_phase_lines || ''} onChange={(e)=>updateReportDef('x_axis_phase_lines', e.target.value)}>
                        <option value="">...Choose One</option>
                        {(dataSource?.schema_definition?.tables  || []).map((option, opt_index)=>{
                            return <option key={'data-source-table-option-' + opt_index} value={option.table_name}>{option.table_name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
        <div>
            <label htmlFor="">Horizontal Line Groups</label>
        </div>
        <div className="row">
            <div className="col-md-12">
                <a className={"inline-link"} onClick={()=>setShowAddNew(!showAddNew)}><i className={"fas fa-plus"} />Add</a>
                <table className={"table valerian-table"}>
                    <thead>
                        <tr><th>Data Source</th><th>Color Scheme</th><th>Text Limit</th><th></th></tr>
                    </thead>
                    <tbody>
                    {(currReport.report_definition?.x_axis_vertial_groups || []).map((vertical_group, ind)=>{
                        return <tr key={"added-comment_row_" + ind}>
                            <td>{vertical_group.data_source}</td>
                            <td>{vertical_group.color}</td>
                            <td>{vertical_group.text_limit}</td>
                            <td><a href="" onClick={()=>deleteGroup(vertical_group)}><i className={"fas fa-trash"}></i></a></td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>

        </div>

        {showAddNew && <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Vertical Data</label>
                        <select name="" id="" className={'form-control'} value={newPhaseGroup?.data_source || ''}
                                onChange={(e)=>updateNewPhaseGroup('data_source', e.target.value)}>
                            <option value="">...Choose One</option>
                            {(dataSource?.schema_definition?.tables  || []).map((option, opt_index)=>{
                                return <option key={'data-source-table-option-' + opt_index} value={option.table_name}>{option.table_name}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Color</label>
                        <input name="" id="" className={'form-control'} value={newPhaseGroup?.color || ''}
                                onChange={(e)=>updateNewPhaseGroup('color', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Text Limit</label>
                        <input name="" id="" className={'form-control'} value={newPhaseGroup?.text_limit || ''}
                               onChange={(e)=>updateNewPhaseGroup('text_limit', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <button className={"btn btn-primary"} onClick={()=>addCommentLineGroup()}>Commit</button>
                    </div>
                </div>
            </div>
        </div>}

    </div>;
};