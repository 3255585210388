import classes from './CustomReportColumnDef.module.scss';

import FormBuilder from '../../CommonUI/FormBuilder/FormBuilder';
import { InputTypes } from '../../CommonUI/FormBuilder/FormBuilderInputComponents';

const getCardinal = num => {
    const lastDigit = `${num}`.charAt(`${num}`.length - 1)
    switch(lastDigit) {
        case "1":
            return `${num}st`
        case "2":
            return `${num}nd`
        case "3":
            return `${num}rd`
        default:
            return `${num}th`
    }
}

const dataTypeOptions = ['string', 'number', 'date', 'boolean', 'object', 'datetime'];
const formatOptions = ['billions', 'currency', 'day', 'decimal', 'exponential', 'fixedPoint', 'largeNumber', 'longDate', 'longTime', 'millions', 'millisecond', 'month', 'monthAndDay', 'monthAndYear', 'percent', 'quarter', 'quarterAndYear', 'shortDate', 'shortTime', 'thousands', 'trillions', 'year', 'dayOfWeek', 'hour', 'longDateLongTime', 'minute', 'second', 'shortDateShortTime'];
const alignmentOptions = ['left', 'center', 'right'];
const mapOptions = options => {
    return options.map(option => {
        return {
            value: option
        }
    })
}

const CustomReportColumnDef = ({ selectedFields, setSelectedFields }) => {    
    return <div className={classes.columnsWrapper}>
        {selectedFields.map(field => {
            const fieldArray = [
                {
                    inputType: InputTypes.number,
                    fieldName: 'position',
                    label: 'Column Position',
                    value: field.position ?? 0,
                },
                {
                    inputType: InputTypes.text,
                    fieldName: 'caption',
                    label: 'Caption',
                    value: field.caption ?? '',
                    help:'Enter a label for this field.',
                    placeholder: 'Choose one.'
                },
                {
                    inputType: InputTypes.select,
                    fieldName: 'dataType',
                    label: 'Data Type',
                    value: field.dataType ?? '',
                    options: mapOptions(dataTypeOptions),
                    help:'Choose the appropriate data type.',
                    placeholder: 'Choose one.'
                },
                {
                    inputType: InputTypes.select,
                    fieldName: 'format',
                    label: 'Data Format',
                    value: field.format ?? '',
                    options: mapOptions(formatOptions),
                    help:'Choose an optional format.',
                    placeholder: 'Choose one.'
                },
                {
                    inputType: InputTypes.select,
                    fieldName: 'alignment',
                    label: 'Alignment',
                    value: field.alignment ?? '',
                    options: mapOptions(alignmentOptions),
                    help:'Choose an optional alignment.',
                    placeholder: 'Choose one.'
                },
                {
                    inputType: InputTypes.checkbox,
                    fieldName: 'allowFiltering',
                    label: 'Allow Filtering',
                    value: field.allowFiltering,
                },
                {
                    inputType: InputTypes.select,
                    fieldName: 'groupIndex',
                    label: 'Group Index',
                    value: field.groupIndex ?? '',
                    options: [
                        ...(selectedFields.map((field, idx) => {
                            return {
                                value: idx + 1,
                                label: getCardinal(idx + 1)
                            }
                        }))
                    ],
                    help:'Choose an index to group on this field.',
                    placeholder: 'Choose one.'
                }
            ]
            const onParameterChange = newParameterArray => {
                setSelectedFields(prevArray => {
                    const updatedParameters = { ...field }
                    newParameterArray.forEach(parameter => {
                        updatedParameters[parameter.fieldName] = parameter.value
                    })
                    return [
                        ...prevArray.filter(f => f.name !== field.name),
                        { ...updatedParameters }
                    ]
                })
            }
            return <div key={field.fullyQualifiedName} className={classes.columnCard}>
                <p className={classes.tableName}>{field.table_name}</p>
                <p className={classes.fieldName}>{field.name}</p>
                <div className={classes.fieldForm}>
                    <FormBuilder 
                        formArray={fieldArray}
                        setFormArray={arr => onParameterChange(arr)}
                        isEditing={true}
                    />
                </div>
            </div>
        })}
    </div>
}

export default CustomReportColumnDef