import {useQueryCache} from 'react-query';
import React,{useState,useEffect} from 'react';

export function PurchaseOrderAdjustmentItemInput({ variant, updateCounts,purchase_order_item}){
    const [curPurchaseOrderItem, setCurPurchaseOrderItem] = useState({adjusted_qty:0});
    useEffect(()=>{
        setCurPurchaseOrderItem(purchase_order_item);
    },[purchase_order_item]);
    const queryCache = useQueryCache();

    const onChange = (value)=>{
        let tmpItem = {...curPurchaseOrderItem};
        tmpItem.adjusted_qty = +value;
        tmpItem.qty = tmpItem.adjusted_qty + tmpItem.so_qty;
        updateCounts(tmpItem);
        queryCache.setQueryData('PurchaseOrderItems',(data)=>{
            let l_data = [...data];
            let item = l_data.find(di=> (di.size === tmpItem.size && di.product_variant_id === variant.id));
            if(item){
                item.adjusted_qty = +value;
                item.qty = tmpItem.adjusted_qty + tmpItem.so_qty;
            }else{
                l_data.push(tmpItem);
            }
            return l_data;
        });
        setCurPurchaseOrderItem(tmpItem);
    };
    return <>
        <input type={'number'} className={'qty_entry'}
            onChange={(e)=>onChange(e.target.value)}
            value={+curPurchaseOrderItem.adjusted_qty}/>
    </>;
}
