import React from 'react';
 
export const PagerItem = ({total,curPager,setCurPager})=>{
    const getPages = () =>{
        let pageCount = total / curPager.pageSize;
        let pages = [];
        for(let i = 0;i < pageCount;i++){
            pages.push(i);
        }if(curPager.page > 5)
        {return  pages.slice(curPager.page - 2 ,5);}
        return  pages.slice(0,5);
    };
    return <div className={'container-fluid  valerian-pager'}>
        <div className="form-row">
            <div className="col-md-1">
                Count: {total}
            </div>
            <div className="col-md-1">
                <select className={'form-control form-control-sm'} value={curPager.pageSize} onChange={(e)=>setCurPager({...curPager,pageSize:e.target.value})}>
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div className={'col-md-10'}> 
                {curPager.page === 0 &&
                <>
                    <span className={'disabled'}><i className={'fas fa-angle-double-left'} /></span> &nbsp;
                    <span className={'disabled'}><i className={'fas fa-angle-left'} /></span> &nbsp; &nbsp;
                </>}
                {curPager.page > 0 &&
                <>
                    <a onClick={()=>{setCurPager({...curPager,page:0});}}><i className={'fas fa-angle-double-left'} /></a> &nbsp;
                    <a onClick={()=>{setCurPager({...curPager,page:curPager.page - 1});}}><i className={'fas fa-angle-left'} /></a> &nbsp;
                </>}
                {getPages().map((page, index)=>{
                    if(page === curPager.page){
                        return <span key={'page_item_'+index} className={'disabled'}>{+page + 1} &nbsp;</span>;
                    }
                    return <span key={'page_item_'+index}><a  className={'inline-nav-link'} onClick={()=>setCurPager({...curPager,page:page})}>{+page + 1}</a>&nbsp;</span>;
                })}&nbsp;&nbsp;
                {((curPager.page + 1) * curPager.pageSize )  >= total &&
                <>
                    <span className={'disabled'}><i className={'fas fa-angle-double-right'} /></span> &nbsp;
                    <span className={'disabled'}><i className={'fas fa-angle-right'} /></span> &nbsp;
                </>}
                {((curPager.page + 1)* curPager.pageSize )  < total &&
                <>
                    <a onClick={()=>{setCurPager({...curPager,page:curPager.page + 1});}}><i className={'fas fa-angle-right'} /></a> &nbsp;
                    <a onClick={()=>{setCurPager({...curPager,page:(total/curPager.pageSize)});}}><i className={'fas fa-angle-double-right'} /></a> &nbsp;
                </>}
            </div>
        </div>
    </div>;
};