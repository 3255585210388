import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useQuery } from 'react-query';
import { ObjContainer } from '../../../services/object_container';
import { InvoiceDataService } from './InvoiceDataService';
import useNav from '../../../services/hooks/useNav';
import { useDownloadDocument } from '../../../services/hooks/useDownloadDocument';
import { useRoutePost } from '../../../services/hooks/routes/useRoutePost';
import useGetById from '../../../services/hooks/useGetById';
const invoiceDataService = new InvoiceDataService();
const getPdfContext = {
    entity: 'inventory_operations',
    operation: 'download',
    file_name: 'invoice.pdf',
};
const invoiceHookContext = {
    entity: 'inventory_operations',
    operation: 'send_invoice',
};
const invoiceCompleteHookContext = {
    entity: 'inventory_operations',
    operation: 'complete_invoice',
};
const embeddedReportHookContext = {
    entity: 'invoice',
};
export function InvoiceViewer() {
    const [invoice, setInvoice] = useState({ date: '11/01/2020' });
    const [systemInfo, setSystemInfo] = useState({ logo_url: 'valerian_logo.png' });
    const [isDisabled, setIsDisabled] = useState(false);
    let { data: system_data } = useQuery(
        ['system_config'],
        ObjContainer.resolve('service_manager').resolve('system_config').getOneData
    );
    const requestState = ObjContainer.resolve('nav').getState();

    //const {data:invoice_data} = useGetById({...embeddedReportHookContext,id:requestState.parent_id});

    let { data: invoice_data } = useQuery(['invoice_data', requestState.parent_id], invoiceDataService.getInvoiceData);
    let { download } = useDownloadDocument(getPdfContext);
    let { post: sendEmail } = useRoutePost({ ...invoiceHookContext });
    let { post: complete } = useRoutePost({ ...invoiceCompleteHookContext });

    const { navTo } = useNav();
    useEffect(() => {
        if (invoice_data) {
            console.log(invoice_data);
            setInvoice(invoice_data.data.invoice || {});
        } else {
            setInvoice({ date: '11/01/2020' });
        }
    }, [invoice_data]);

    useEffect(() => {
        if (system_data) {
            setSystemInfo(system_data);
        } else {
            setSystemInfo({ logo_url: 'valerian_logo.png' });
        }
    }, [system_data]);
    const completeInvoice = async () => {
        setIsDisabled(true);
        await complete({ invoice_id: invoice.id });
    };
    const getTotalVariantPrice = (variant, product) => {
        return +invoice.invoice_items?.reduce((val, item) => {
            if (+variant.id === +item.invoice_product_variant_id) {
                val = val + +item.qty * +product.price;
            }
            return val;
        }, 0);
    };
    const getTotalPrice = () => {
        if (!invoice || !invoice.invoice_items) {
            return 0;
        }
        return +invoice.invoice_items?.reduce((val, item) => {
            let product = invoice.invoice_product_variants?.find((i) => +i.id === +item.invoice_product_variant_id) || {
                product: { price: 0 },
            };
            val = val + +item.qty * +product.product.price;
            return val;
        }, 0);
    };
    const navToEdit = () => {
        let local_state = {
            route_params: { filter: { id: invoice.id } },
            parent_id: invoice.id,
            parent_data: Object.assign({}, { back_state: ObjContainer.resolve('nav').getState() }),
        };
        ObjContainer.resolve('nav').navTo('invoices-Newinvoices', local_state);
    };
    const downloadPdf = async () => {
        await download({ params: { invoice_id: invoice.id } });
    };
    const sendPdf = async () => {
        await sendEmail({ invoice_id: invoice.id });
    };

    const companyName = invoice.customer?.customer_name && (
        <>
            {invoice.customer.customer_name}
            <br />
        </>
    );
    const customerName = (
        <>
            {invoice.customer?.last_name}
            {invoice.customer?.last_name && invoice.customer?.first_name && <>, </>}
            {invoice.customer?.first_name}
        </>
    );
    return (
        <div>
            <div key={1}>
                {invoice.status !== 'closed' && (
                    <Button
                        label='Edit'
                        icon='fa fa-edit'
                        onClick={() => navToEdit()}
                        style={{ marginBottom: '20px', marginLeft: '6px' }}
                    />
                )}
                {invoice.status !== 'closed' && (
                    <Button
                        label='Complete'
                        icon='fa fa-check'
                        disabled={isDisabled}
                        onClick={async () => await completeInvoice()}
                        style={{ marginBottom: '20px', marginLeft: '6px' }}
                    />
                )}
                <Button
                    label='Download'
                    icon='fa fa-download'
                    onClick={() => downloadPdf()}
                    style={{ marginBottom: '20px', marginLeft: '6px' }}
                />
                <Button
                    label='Send'
                    icon='fa fa-envelope'
                    onClick={() => sendPdf()}
                    style={{ marginBottom: '20px', marginLeft: '6px' }}
                />
            </div>
            <div id={'print-area'} className='p-grid invoice-body' key={2}>
                <div className='p-col-12'>
                    <div id='invoice-content'>
                        <div className='card invoice-wrapper'>
                            <div className='card invoice-table billto-table'>
                                <div>
                                    <h2>Invoice: #{invoice.invoice_no}</h2>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <label htmlFor=''>Client</label>
                                        <p style={{ border: '1px solid silver', padding: '10px', borderRadius: '3px' }}>
                                            <a
                                                className={'inline-nav-link'}
                                                onClick={() =>
                                                    navTo('customers-Viewcustomers', { id: invoice.customer_id })
                                                }
                                            >
                                                {companyName}
                                                {customerName}
                                            </a>
                                            <br />
                                            {invoice.customer?.email} - {invoice.customer?.phone}
                                            <br />
                                            {invoice.customer?.address_1}
                                            <br />
                                            {invoice.customer?.address_2}
                                            <br />
                                            {invoice.customer?.city}, {invoice.customer?.state}{' '}
                                            {invoice.customer?.postal_code}{' '}
                                        </p>
                                    </div>
                                    <div className='col-md-3 '>
                                        <label htmlFor=''>Status {invoice.status}</label>
                                        <p style={{ border: '1px solid silver', padding: '10px', borderRadius: '3px' }}>
                                            Due On: {invoice.date_due} <br />
                                            Amount Due: ${getTotalPrice().toFixed(2)}
                                            <br />
                                            Amount Paid: ${0.0}
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <table className={'table table-striped valerian-table'}>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Quantity</th>
                                        <th>Unit Price</th>
                                        <th>Line Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(invoice.invoice_product_variants || []).map((variant, v_index) => {
                                        return (
                                            <tr key={'invoice_item' + variant.id + '_item_' + v_index}>
                                                <td
                                                    className={
                                                        'p-grid p-nogutter table-content-row ' +
                                                        (v_index % 2 === 1 ? 'even' : 'odd')
                                                    }
                                                >
                                                    {variant.product.style_number} {variant.product.description} -{' '}
                                                    {(variant.product_variant || {}).variation_code}{' '}
                                                    {(variant.product_variant || {}).variation_name}
                                                </td>
                                                <td>
                                                    {invoice.invoice_items
                                                        ?.filter((i) => i.invoice_product_variant_id === variant.id)
                                                        ?.map((ii, ii_index) => {
                                                            return (
                                                                <span
                                                                    key={
                                                                        'invoice_item_' +
                                                                        variant.id +
                                                                        '_item_' +
                                                                        v_index +
                                                                        'ii_item' +
                                                                        ii_index
                                                                    }
                                                                >
                                                                    {ii.size}:{ii.qty} &nbsp;
                                                                </span>
                                                            );
                                                        })}
                                                </td>
                                                <td>${(+variant.product.price).toFixed(2)}</td>
                                                <td className='p-col-2 total text-right'>
                                                    ${getTotalVariantPrice(variant, variant.product).toFixed(2)}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    <tr>
                                        <td colSpan={2}></td>
                                        <td style={{ textAlign: 'right', borderBottom: '1px solid silver' }}>Total:</td>
                                        <td style={{ textAlign: 'right', borderBottom: '1px solid silver' }}>
                                            ${getTotalPrice().toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='p-col-5  text-left'>
                        <p>{invoice.notes}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
