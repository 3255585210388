import React, {useEffect, useState} from "react";

export const SortIndicator =({field, setSorts, sorts})=> {
    const [currSort, setCurrSort] = useState(sorts[field.field_code]);
    useEffect(()=>{
        setCurrSort(sorts[field.field_code]);
    },[sorts[field.field_code]]);
    const sortClick =()=>{
        let tmpSorts = {...sorts};
        if(tmpSorts && tmpSorts[field.field_code] && tmpSorts[field.field_code] === 'asc'){
            tmpSorts[field.field_code] = 'desc'
        }else if(tmpSorts && tmpSorts[field.field_code] && tmpSorts[field.field_code] === 'desc'){
            delete tmpSorts[field.field_code];
        }else{
            tmpSorts[field.field_code] = 'asc'
        }
        setSorts(tmpSorts);
        return false;
    }
    if(currSort === "asc"){
        return <a className={"va-table-sort"} onClick={()=>{return sortClick()}}><span className={"fas fa-sort-up va-sort"} /></a>
    }if(currSort ==="desc"){
        return <a className={"va-table-sort"} onClick={()=>{return sortClick()}}><span className={"fas fa-sort-down va-sort"} /></a>
    }
    return <a  className={"va-table-sort"} onClick={()=>{return sortClick()}}><span className={"fas fa-sort va-sort"} /></a>;
}