import React, {Component, useEffect, useState} from "react";
import {ObjContainer} from "../../services/object_container";
import ArrowUp from "react-icons/lib/fa/arrow-up";
import ArrowDown from "react-icons/lib/fa/arrow-down";
import FaSortAmountDesc from "react-icons/lib/fa/sort-amount-desc";
export const TableSortableMenu = ({field,definition,sort})=>{
    const [curSort, setCurSort] =useState(sort)
    useEffect(()=>{
        setCurSort(sort)
    },[sort])
    const _handleSort = (sort_param)=>{
        let sort = {};
        if(field.reference){
            sort[field.reference_field] = sort_param;
        }else{
            sort[field.field_code] = sort_param;
        }
        ObjContainer.resolve("service_manager").resolve(definition.table_name).emit("sort-updated",sort);
    }
    return (
        <div style={{display:'inline-block'}} className={"dropdown-popover"}>
            {curSort === 'asc' &&  <a onClick={()=>_handleSort('desc')}><i className={"fas fa-sort-up va-sort"} /></a>}
            {curSort === 'desc' && <a onClick={()=>_handleSort('')}><i className={"fas fa-sort-down va-sort"} /></a>}
            {!curSort &&           <a onClick={()=>_handleSort('asc')}><i className={"fas fa-sort va-sort"} /></a>}
        </div>);

}
export class TableHeaderMenu extends Component{
    constructor(){
        super();
        this.state = {show:false, sorted:undefined, filter:{}};
        this.service_manager = ObjContainer.resolve("service_manager");
    }
    _showMenu(){
        this.setState({show:!this.state.show});
    }
    _handleSort(sort_param){

        let sort = {};
        if(this.props.field.reference){
            sort[this.props.field.reference_field] = sort_param;
        }else{
            sort[this.props.field.field_code] = sort_param;
        }

        this.service_manager.resolve(this.props.definition.table_name).emit("sort-updated",sort);
        this.setState({show:false, sorted:sort_param});
    }
    render() {
        return (
            <>
                {this.state.sorted === 'asc' &&
                <a onClick={() => this._handleSort('desc')}><i className={"fas fa-sort-up va-sort"}/></a>}
                {this.state.sorted === 'desc' &&
                <a onClick={() => this._handleSort('')}><i className={"fas fa-sort-down va-sort"}/></a>}
                {!this.state.sorted &&
                <a onClick={() => this._handleSort('asc')}><i className={"fas fa-sort va-sort"}/></a>}
            </>);
    }
}
export const InlineFilterFields = ({field,filter})=>{
    return <div>
        {(field.data_type === 'string' || field.reference_field_type === "string") &&
        <input className={"form-control form-control-sm"} type={"text"} placeholder={"Search"}
               onChange={(e) => filter('eq', field, e.target.value, e)}/>}

        {(!field.reference && (field.data_type === 'int' || field.data_type === 'money')) &&
        <div>
            <input className={"form-control form-control-sm"} type={"text"}
                   placeholder={">="}
                   onChange={(e) => filter('gteq', field, e.target.value, e)}/>
            <input className={"form-control form-control-sm"} type={"text"}
                   placeholder={"<"}
                   onChange={(e) => filter('lt', field, e.target.value, e)}/>
        </div>
        }
        {(field.data_type === 'date') &&
        <div>
            <input style={{width: "160px"}} className={"form-control form-control-sm"} type={"date"}
                   placeholder={"After"}
                   onChange={(e) => filter('gteq', field, e.target.value, e)}/>
            <input style={{width: "160px"}} className={"form-control form-control-sm"} type={"date"}
                   placeholder={"Before"}
                   onChange={(e) => filter('lt', field, e.target.value, e)}/>
        </div>
        }
    </div>
}