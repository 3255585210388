import {TagList} from './TagList';
import {DataView} from '../Support/Import/DataView';
import React, {useState} from 'react';
import TabbedView from '../CommonUI/TabbedView/TabbedView';
import {TagPreviewer} from './TagPreviewer';
import {TagDesigner} from './TagDesigner';

export const TagBuilderContent = ({showImport, setShowImport}) =>{
    const [message, setMessage] = useState('');
    const tabs = () => {
        return [
            {
                title: 'Generator',
                view: (
                    <TagList showImport={showImport} setShowImport={setShowImport} />
                ),
                disabled: false,
            },
            {
                title: 'Tag Designer',
                view: (
                    <TagDesigner  />
                ),
                disabled: false,
            },
            {
                title: 'Tag Data',
                view: (
                    <TagPreviewer cols={[]} rows={[]} />
                ),
                disabled: false,
            }];
    };
    return <div className={'container-fluid import-container'}>
        { message && <><br/><div className="alert alert-success" role="alert">
            {message}
        </div></>}
        <div className="row">
            <div className="col-md-12">
                <div className={'import-container'}>
                    <TabbedView tabs={tabs()} />
                </div>
            </div>
        </div>
    </div>;
};