import React, {useEffect, useState} from 'react';
export const PieDef = ({currReport, updateReportDef,dataSource}) =>{
    const [newPhaseGroup, setNewPhaseGroup] = useState({});
    const [showAddNew, setShowAddNew] = useState(false);

    const updatePieSegment = (field, value) =>{
        let tmpPhaseGroup = {...newPhaseGroup};
        tmpPhaseGroup[field] = value;
        setNewPhaseGroup(tmpPhaseGroup);
    }
    const addPieSegment = () =>{
        let tmp = {...currReport};
        tmp.report_definition.pie_segments =tmp.report_definition?.pie_segments || [];
        tmp.report_definition.pie_segments.push({...newPhaseGroup});
        updateReportDef('pie_segments',tmp.report_definition?.pie_segments);
        setNewPhaseGroup({});
        setShowAddNew(false);
    }
    const deleteGroup = (group) =>{
        let tmp = {...currReport};
        tmp.report_definition.pie_segments =tmp.report_definition?.pie_segments || [];
        tmp.report_definition.pie_segments.splice(tmp.report_definition?.pie_segments.indexOf(group),1);
        updateReportDef('pie_segments',tmp.report_definition?.pie_segments);
        setNewPhaseGroup({});
        setShowAddNew(false);
        return false;
    }
    return <div className={"container-fluid"}>

        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">URL Data Source</label><br/>
                    <input type="checkbox" className={"form-control-sm"} checked={currReport.report_definition?.url_data_source} value={currReport.report_definition?.url_data_source}
                           onChange={(e)=>updateReportDef('url_data_source', e.target.checked)}/>
                </div>

            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Pie Data Source</label>
                    <select name="" id="" className={'form-control'} value={currReport.report_definition?.pie_data_source || ''} onChange={(e)=>updateReportDef('pie_data_source', e.target.value)}>
                        <option value="">...Choose One</option>
                        {(dataSource?.schema_definition?.tables  || []).map((option, opt_index)=>{
                            return <option key={'data-source-table-option-' + opt_index} value={option.table_name}>{option.table_name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
        <div>
            <label htmlFor="">Horizontal Line Groups</label>
        </div>
        <div className="row">
            <div className="col-md-12">
                <a className={"inline-nav-link"} onClick={()=>setShowAddNew(!showAddNew)}><i className={"fas fa-plus"} />Add</a>
                <table className={"table valerian-table"}>
                    <thead>
                        <tr><th>Data Key</th><th>Color</th><th>Order</th><th>Label</th><th></th></tr>
                    </thead>
                    <tbody>
                        {(currReport.report_definition?.pie_segments || []).map((pie_segment, ind)=>{
                            return <tr key={"added-comment_row_" + ind}>
                                <td>{pie_segment.data_key}</td>
                                <td>{pie_segment.color}</td>
                                <td>{pie_segment.order}</td>
                                <td>{pie_segment.label}</td>
                                <td><a className={"inline-nav-link"} onClick={()=>{return deleteGroup(pie_segment)}}><i className={"fas fa-trash"} /></a></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>

        {showAddNew && <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Data Key</label>
                        <input name="" id="" className={'form-control'} value={newPhaseGroup?.data_key || ''}
                               onChange={(e)=>updatePieSegment('data_key', e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Color</label>
                        <input name="" id="" className={'form-control'} value={newPhaseGroup?.color || ''}
                               onChange={(e)=>updatePieSegment('color', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Order</label>
                        <input name="" id="" className={'form-control'} value={newPhaseGroup?.order || ''}
                               onChange={(e)=>updatePieSegment('order', e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Label</label>
                        <input name="" id="" className={'form-control'} value={newPhaseGroup?.label || ''}
                               onChange={(e)=>updatePieSegment('label', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <button className={"btn btn-primary"} onClick={()=>addPieSegment()}>Commit</button>
                    </div>
                </div>
            </div>
        </div>}
    </div>;
}