import {useMutation, useQueryCache} from 'react-query';
import AxiosClient from './AxiosClient';

import { useAuth } from './authTokenContext';
import constants from './constants';

const { api } = constants;
const { basePath } = api;

export const Update = async (payload, context) => {
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity
    } = context;
    try {
        const pathname = `${basePath}${entity}/${payload.id}`;
        const results = await AxiosClient.put(
            pathname,
            payload,
            config
        );
        const { data } = results;
        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble adding ${entity}.`);
    }
};
export const Add = async (payload, context) => {
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity
    } = context;

    try {
        const pathname = `${basePath}${entity}`;
        const results = await AxiosClient.post(
            pathname,
            payload,
            config
        );
        const { data } = results;

        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble adding ${entity}.`);
    }
};

export const useUpdate = (context) => {
    const auth = useAuth();
    const queryCache = useQueryCache();
    const { entity = 'UNK', invalidateQuery } = context; 
    const key = `add-${entity}`;
    return useMutation(
        async (variables) => await Update(variables, { ...auth, ...context }),
        {
            mutationKey: key,
            onSuccess: (result, vars) => {
                if (invalidateQuery) {
                    queryCache.invalidateQueries(invalidateQuery);
                }
                queryCache.invalidateQueries(`search-${context.entity}`);
                if(context.onSuccess){
                    context.onSuccess(vars);
                }
            }
        }
    );
};

export default useUpdate;
