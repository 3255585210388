import * as ReactHighcharts from 'react-highcharts';
import React, {useEffect, useState} from 'react';
import {useReportConfig} from '../../../services/hooks/useReportConfig';


export const ReportPreview= ({report,schema}) =>{
    let [config,setConfig] = useState({});
    const [paramValues, setParamValues] = useState({});
    const getParams = (param_data)=>{
        return Object.keys(param_data).map((param)=>{
            return `${param}=${param_data[param]}`;
        }).join('&');
    };
    let {data:reportConfig} = useReportConfig({environment:report.environment, report_name:report.api_key,params:paramValues});
    useEffect(()=>{
        if(reportConfig){
            setConfig(reportConfig);
        }

    },[reportConfig]); 
    const updateLocalParam = async (field, value)=>{
        let tmpParams = {...paramValues};
        tmpParams[field] = value;
        setParamValues(tmpParams);
    };
    try{
        return <div className={'col-md-4'}>
            <div className={'container-fluid'} style={{clear:'both'}}>
                <div><label htmlFor="">Parameters</label></div>
                {schema.schema_definition?.paramaters?.map((param,index)=>{
                    return <div className="row" key={'param-data-' +index}>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">{param.parameter_name}</label>
                                <input type="text" className={'form-control'}
                                    onChange={async (e)=>{await updateLocalParam(param.parameter_name, e.target.value);}}/>
                            </div>
                        </div>
                    </div>;
                })}
            </div>
            <label>Preview</label>
            <ReactHighcharts config={config}  />
        </div>;
    }catch( e){
        return <div className={'col-md-4'}>
            <h4>Preview
                {e.toString()}
            </h4>
        </div>;
    }

};