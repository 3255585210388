import React, {useEffect, useState} from 'react';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import useNav from '../../../services/hooks/useNav';
import useSearch from '../../../services/hooks/useSearch';
import {NewEmbeddedReport} from './NewEmbeddedReport';
import {ReportEditHeader} from './ReportEditHeader';
import {ReportEdit} from './ReportEdit';
import {ReportPreview} from './ReportPreview';
import {ReportLayout} from './ReportLayout'; 
import useGetById from '../../../services/hooks/useGetById';
import {useUpsert} from '../../../services/hooks/useUpsert';
const embeddedReportHookContext = {
    entity: 'embedded_reports',
    expands:{'data_source_connections':'data_connection_id','data_source_items':'data_source_item_id'}
};
const dataSourceHookContext = {
    entity: 'data_source_items'
};
export const EmbeddedReports = () =>{
    const [currReport,setCurrReport] = useState({});
    const [reportItem,setReportItem] = useState({});
    const [currDataSetId,setCurrentDataSetId] = useState(-1);
    const [currDataSource,setCurrDataSource] = useState({});
    const {update} = useFieldUpdater({state:currReport,setState:setCurrReport});
    const {getState, navTo} = useNav();
    const {id:reportId} =getState();
    const [newReport,setNewReport] = useState(!reportId);
    const {data:incoming} = useSearch({...embeddedReportHookContext,filter:{id:{eq:reportId}}});
    const {data:dataSourceData} = useGetById({...dataSourceHookContext,id:currDataSetId});
    const [savedMsg, setSavedMsg] = useState('');
    const [upsertReport] = useUpsert({...embeddedReportHookContext,onSave:(result)=>{
        setNewReport(!result.id);
        //setCurrReport(result);
        setSavedMsg('Embedded Report Saved');
        setTimeout(()=>{
            setSavedMsg('');
            navTo('EmbeddedReports',{id:result.id});
        },2000);

    }});
    const updateReport  = (report) =>{
        setCurrReport({...report});
    };
    useEffect(()=>{
        setNewReport(!reportId);
    },[reportId]);

    useEffect(()=>{
        if(incoming && incoming.data && reportId){
            setNewReport(false);
            setCurrentDataSetId(incoming.data[0].data_source_item_id);
            setCurrReport(incoming.data[0]);
            setReportItem(incoming.data[0]);
        }
    },[incoming,reportId]);
    useEffect(()=>{
        if(dataSourceData && dataSourceData.data )
        {setCurrDataSource(dataSourceData.data);}
    },[dataSourceData]);
    const save = async ()=>{
        await upsertReport(currReport);
    };
    const copy = async () =>{
        let tmpReport = {...currReport};
        delete tmpReport.id;
        tmpReport.report_title = tmpReport.report_title + ' - Copy';
        setCurrReport(tmpReport);
    };
    return <div>
        <ReportEditHeader report={currReport} save={save} copy={copy}/>
        { savedMsg && <><br/><div className="alert alert-success" role="alert">
            {savedMsg}
        </div></>}
        <ReportLayout>
            <ReportEdit report={currReport} updateField={update} updateReport={updateReport} dataSource={currDataSource}/>
            <ReportPreview report={reportItem} schema={currDataSource}/>
        </ReportLayout>
        {newReport && <NewEmbeddedReport show={newReport} commit={async (report) => await upsertReport(report)} />}
    </div>;
};




