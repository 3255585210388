import React from 'react';
import './App.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'fullcalendar/dist/fullcalendar.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import {Home} from './components/HomePage/Home';
const queryCache = new QueryCache({
    defaultConfig: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
function  App({config}){
    return (
        <>
            <ReactQueryCacheProvider  queryCache={queryCache}>
                <Home config={config}/>
            </ReactQueryCacheProvider>
        </>);
}
export default App;
