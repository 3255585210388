import React, {useEffect, useState} from 'react';

export const FilterConfigurationItem = ({table,schema, filterItem,updateTable, all_tables})=>{
    const [curFilterTable, setCurFilterTable] = useState({column_data:[]});
    const [curFilter, setCurFilter] = useState(filterItem || {});
    const [currTable, setCurrTable] = useState(table);
    useEffect(()=>{
        setCurFilter(filterItem);
        setCurFilterTable(all_tables.find(i=>i.table_name === filterItem.dest_table) || {column_data:[]});
    },[filterItem]);
    useEffect(()=>{
        setCurrTable(table);
    },[table]);

    const saveObjects = ()=>{
        updateTable(currTable);
    };
    const update = (field, value)=>{
        let tmpCurTable = {...currTable};
        let tmpCurFilter = {...curFilter};
        tmpCurFilter[field] = value;
        setCurFilter(tmpCurFilter);
        tmpCurTable.filter_list = tmpCurTable.filter_list || [];
        let ind = tmpCurTable.filter_list.indexOf(filterItem);
        if(ind < 0)
        {tmpCurTable.filter_list.push(tmpCurFilter);}
        else
        {tmpCurTable.filter_list[ind] = tmpCurFilter;}
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    };
    const removeRow = ()=>{
        let tmpCurTable = {...currTable};
        tmpCurTable.filter_list = tmpCurTable.filter_list || [];
        tmpCurTable.filter_list.splice(tmpCurTable.filter_list.indexOf(filterItem),1);
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    };
    const addRow = ()=>{
        let tmpCurTable = {...currTable};
        tmpCurTable.filter_list = tmpCurTable.filter_list || [];
        tmpCurTable.filter_list.push({});
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    }; 
    const setSelJoinTable = (table_name)=>{
        setCurFilterTable(all_tables.find(i=>i.table_name === table_name) || {column_data:[]});
        let tmpCurFilter = {...curFilter};
        tmpCurFilter['dest_table'] = table_name;
        setCurFilter(tmpCurJoin);
    };
    return <>
        <div className="row">
            <div className="col-md-3">
                <select name="" id="" placeholder={'Source Field'} value={curFilter.source_field} onChange={(e)=>
                    update('source_field', e.target.value)} className={'form-control'}>
                    <option>--Choose Source Column</option>
                    {table.column_data.map((item,index)=>{
                        return <option key={'table_join_source_column_' + index} value={item.name}>{item.name}</option>;
                    })}
                </select>
            </div>
            <div className="col-md-2">
                <select name="" id="" className={'form-control'} value={curFilter.filter_equality} onChange={(e)=>
                    update('filter_equality', e.target.value)}>
                    <option value="eq">=</option>
                    <option value="lt">&lt;</option>
                    <option value="gt">&gt;</option>
                    <option value="gteq">&gt;=</option>
                    <option value="lteq">&lt;=</option>
                    <option value="neq">Not =</option>
                    <option value="nlt">Not &lt;</option>
                    <option value="ngt">Not &gt;</option>
                </select>
            </div>
            <div className="col-md-2">
                <select name="" id="" className={'form-control'} value={curFilter.filter_right} onChange={(e)=>
                    update('filter_right', e.target.value)}>
                    <option value="field">Field</option>
                    <option value="value">Value</option>
                    <option value="parameter">Parameter</option>
                </select>
            </div>
            {curFilter.filter_right === 'field' &&
                <div className="col-md-3">
                    <div className="form-group">
                        <select name="" id="" value={curFilter.dest_table} placeholder={'Dest Field'}
                            onChange={(e)=>setSelJoinTable(e.target.value)} className={'form-control'}>
                            <option>--Choose Dest Table</option>
                            {all_tables.map((item,index)=>{
                                return <option key={'table_join_column_' + index} value={item.table_name}>{item.table_name}</option>;
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <select name="" id="" value={curFilter.dest_column} onChange={(e)=>update('dest_column', e.target.value)} placeholder={'Dest Field'} className={'form-control'}>
                            <option>--Choose Dest Column</option>
                            {curFilterTable.column_data.map((item,index)=>{
                                return <option key={'table_join_column_' + index} value={item.name}>{item.name}</option>;
                            })}
                        </select>
                    </div>
                </div>
            }
            {curFilter.filter_right === 'value' &&
                <div className="col-md-3">
                    <div className="form-group">
                        <input type={'text'} name="" id="" value={curFilter.dest_value} onChange={(e)=>update('dest_value', e.target.value)} placeholder={'Value'} className={'form-control'} />
                    </div>
                </div>
            }
            {curFilter.filter_right === 'parameter' &&
                <div className="col-md-3">
                    <div className="form-group">
                        <select  value={curFilter.parameter_name} onChange={(e)=>update('parameter_name', e.target.value)}  className={'form-control'}>
                            <option>--Choose Parameter</option>
                            {schema.schema_definition?.paramaters?.map((item,index)=>{
                                return <option key={'table_parameter_' + index} value={item.parameter_name}>{item.parameter_name}</option>;
                            })}
                        </select>
                    </div>
                </div>
            }
            <div className="col-md-1">
                <a className={'inline-nav-link'}  onClick={()=>addRow()}><i className={'fa fa-plus'} /></a> &nbsp;
                <a className={'inline-nav-link'} onClick={()=>saveObjects()}><i className={'fa fa-save'} /></a> &nbsp;
                <a className={'inline-nav-link'} onClick={()=>removeRow()}><i className={'fa fa-trash'} /></a>
            </div>
        </div>
    </>;

};