const OrderDetails = {
    title: 'Order Details',
    query: `select c.customer_name, o.id, o.date, o.date_due, o.customer_po, o.amount, o.amount_freight, o.amount_tax_total, 
            o.amount_subtotal * (o.pct_discount / 100) as amount_discount, o.qty, o.qty_cxl, o.qty_open, o.qty_shipped 
                from orders o join customers c 
                on o.customer_id = c.id;`,
    defaultFilter: ['date', 'anyof', [new Date().getFullYear()]],
    columns: [
        {
            dataField: 'customer_name',
            dataType: 'string',
            groupIndex: 0,
            groupCellRender: (data) => <div>{data.value}</div>,
        },
        {
            dataField: 'id',
            caption: 'Order',
            dataType: 'number',
            cellRender: (data) => {
                return <a href={`/orders-Vieworders#${data.value}`}>{data.value}</a>;
            },
        },
        {
            dataField: 'date',
            caption: 'Date',
            dataType: 'date',
            allowHeaderFiltering: true,
        },
        {
            dataField: 'date_due',
            caption: 'Due Date',
            dataType: 'date',
            allowHeaderFiltering: true,
        },
        {
            dataField: 'customer_po',
            caption: 'Customer PO',
            dataType: 'string',
        },
        {
            dataField: 'amount_subtotal',
            caption: 'Merchandise',
            dataType: 'number',
            format: 'currency',
        },
        {
            dataField: 'amount_discount',
            caption: 'Discount',
            dataType: 'number',
            format: 'currency',
        },
        {
            dataField: 'amount_freight',
            caption: 'Freight',
            dataType: 'number',
            format: 'currency',
        },
        {
            dataField: 'amount_tax_total',
            caption: 'Tax',
            dataType: 'number',
            format: 'currency',
        },
        {
            dataField: 'amount',
            caption: 'Amount',
            dataType: 'number',
            format: 'currency',
        },
        {
            dataField: 'qty',
            caption: 'Unit',
            dataType: 'number',
        },
        {
            dataField: 'qty_shipped',
            caption: 'Shipped',
            dataType: 'number',
        },
        {
            dataField: 'qty_csl',
            caption: 'Cxl',
            dataType: 'number',
        },
        {
            dataField: 'qty_open',
            caption: 'Open',
            dataType: 'number',
        },
    ],
};

export default OrderDetails;
