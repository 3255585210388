import classes from "./Modal.module.scss";
import Button from "./Button";
import ActionRow from "./ActionRow";

const Modal = ({ header, content, buttonArray }) => {
  return (
    <>
      <div className={classes.blurredBackground}>&nbsp;</div>
      <div className={classes.modalDialogPopup}>
        <div className={classes.modalHeader}>{header}</div>
        <div className={classes.modalContent}>
          {content}
          <div className={classes.actionRowWrapper}>
            <ActionRow rightButtons={buttonArray} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
