import React, {useEffect, useState} from 'react';
import {useDownloadDocument, useDownloadPostDocument} from '../../services/hooks/useDownloadDocument';
import useSearch from '../../services/hooks/useSearch';
import {DataView} from '../Support/Import/DataView';
import {TagDataViewer} from "./TagDataViewer";
const getPdfContext = {
    entity: 'tag_operations',operation:'generate', file_name:'tag_list.pdf'
};
const hookTagDatabaseContext = {
    entity:'tag_manager_tag_lists'
};
const hookTagDesignContext = {
    entity:'tag_manager_tag_designs'
};
export const TagList= ({showImport, setShowImport})=>{
    const [designs, setDesigns] = useState([]);
    const [databaseData, setDatabaseData] = useState([]);
    const [databaseCols, setDatabaseCols] = useState([]);
    const [databases,setDatabases] = useState([]);
    const [curDatabase,setCurDatabase] = useState({});
    const [toGenerate, setToGenerate] = useState({tags:[]});
    const { data:tag_database_data } = useSearch({
        ...hookTagDatabaseContext
    });
    const { data:database_data } = useSearch(curDatabase);
    const { data:tag_design_data } = useSearch({
        ...hookTagDesignContext
    });
    useEffect(()=>{
        if(tag_design_data && tag_design_data.data){
            setDesigns(tag_design_data.data || []);

        }
        if(tag_database_data &&  tag_database_data.data ){
            setDatabases(tag_database_data.data || []);
        }
    },[tag_database_data,tag_design_data]);
    useEffect(()=>{
        if(database_data && database_data.data){
            setDatabaseData(database_data.data);
            setDatabaseCols(database_data.schema_def.schema_definition.fields);
        }
    },[database_data]);
    let {download} = useDownloadPostDocument(getPdfContext);
    const downloadTags = async ()=>{
        await download({body:toGenerate});
    };
    const updateTagDatabase = (value) =>{
        let database = databases.find(i=>i.id === +value);
        setCurDatabase({entity: database.tag_table_name});
    };
    const updateValue = (field, value)=>{
        let tmpToGenerate = {...toGenerate};
        tmpToGenerate[field] = value;
        setToGenerate(tmpToGenerate);
    }
    const updateDesign = (value)=>{
        let tmpToGenerate = {...toGenerate};
        tmpToGenerate.design = designs.find(i=>i.id === +value);
        setToGenerate(tmpToGenerate);
    }
    const onTagSelected = (tag, selected) =>{
        let tmpToGenerate = {...toGenerate};
        if(selected){
            tmpToGenerate.tags.push(tag);
        }else{
            tmpToGenerate.tags.splice(tmpToGenerate.tags.indexOf(tag), 1);
        }
        setToGenerate(tmpToGenerate);
    }
    return <div className={'tag-list-internal-container'}>
        <button className={'btn btn-primary'} onClick={()=>setShowImport(!showImport)}>Import</button> &nbsp;
        <button className={'btn btn-primary'} onClick={async ()=>await downloadTags()}>Generate</button><br/>
        <div className={'container-fluid'}>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="">Tag Database</label>
                        <select name="" id="" className={'form-control'} onChange={(e)=>updateTagDatabase(e.target.value)}>
                            <option value="">--Choose One</option>
                            {databases?.map((item, index)=>{
                                return <option key={'database-option-' + index} value={item.id}>{item.tag_list_name}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="">Tag Design</label>
                        <select name="" id="" className={'form-control'} onChange={(e)=>updateDesign(e.target.value)}>
                            <option value="">--Choose One</option>
                            {designs?.map((item, index)=>{
                                return <option key={'design-option-' + index} value={item.id}>{item.tag_design_name}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="">Quantity</label>
                        <input type="number" className={'form-control'} onChange={(e)=>updateValue('quantity', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <label htmlFor="">Tag Data Selector</label>
                    <TagDataViewer rows={databaseData} cols={databaseCols} onChange={onTagSelected} />
                </div>
            </div>
        </div>
    </div>;
};