import {useAuth} from './authTokenContext';
import {useQuery} from 'react-query';
import {ObjContainer} from '../object_container';

const useNav = (context) => {
    return {navTo:(url,params={})=>{
        let backward = {back_state:ObjContainer.resolve('nav').getState()};
        return ObjContainer.resolve('nav').navLocal(url, {...params,...backward});
    },getState:()=>{
        return ObjContainer.resolve('nav').getState();
    },getHash(){
        return window.location.hash?.replace('#','');
    }}; 
};

export default useNav;
