import React, {useEffect, useState} from 'react';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import TabbedView from '../../CommonUI/TabbedView/TabbedView';
import {ReportDesignContainer} from './ReportDesignContainer';
import './DashboardReport.scss';
import Drawer from 'devextreme-react/drawer';
import Toolbar from 'devextreme-react/toolbar';
import List from 'devextreme-react/list';
import {ConnectionInfo} from './ConnectionInfo';
import {FieldsList} from './FieldsList';
import {useUpsert} from '../../../services/hooks/useUpsert';
import useNav from '../../../services/hooks/useNav';
import useGetById from '../../../services/hooks/useGetById';
import {ReportDesignTab} from "./ReportDesignTab";
const embeddedReportHookContext = {
    entity: 'v_dashboard_reports',
    expands:{
        'data_source_connections':'data_connection_id',
        'data_source_items':'data_source_item_id'}
};
export const DashboardReportDesigner = ()=>{
    const [currReport,setCurrReport] = useState({});

    const [reportChart,setReportChart] = useState([]);
    const [activeTitle, setActiveTitle] = useState();
    const [showMenu, setShowMenu] = useState();
    const [currentEdit, setCurrentEdit] = useState(1);
    const [curDataSource, setCurDataSource] = useState({schema_definition:{}});
    const {getState,navTo} = useNav();
    const {id:datasetId} =getState();
    const {data:incoming} = useGetById({...embeddedReportHookContext,id:datasetId});
    const [upsertMutate] = useUpsert({...embeddedReportHookContext,onSave: (data)=>{
        navTo('DashboardReportDesigner',{id:data.id});
    }});
    const {update} = useFieldUpdater({state:currReport,setState:setCurrReport});

    useEffect(()=>{
        if(datasetId && incoming){
            setCurrReport({...incoming.data});
            setCurDataSource({...incoming.data.data_source_items});

        }
    },[incoming]);

    const updateReportChart = (reportChartItem) =>{
        let tmpCurReport= {...currReport};
        tmpCurReport.report_definition =tmpCurReport.report_definition || {};
        tmpCurReport.report_definition.reports  =tmpCurReport.report_definition.reports || [];
        let report = tmpCurReport.report_definition.reports.find(i=>i.report_key === reportChartItem.report_key);
        tmpCurReport.report_definition.reports[tmpCurReport.report_definition.reports.indexOf(report)] = reportChartItem;
        setCurrReport(tmpCurReport);
    }
    const getTabs = () =>{
        return [{
            title: 'View 1',
            view: (<ReportDesignContainer reportDef={currReport} setReportDef={setCurrReport} selected={reportChart} setSelected={setReportChart}   />),
            disabled: false,
        }]
    }
    const saveContent = async () =>{
        await upsertMutate({...currReport});
    };
   /* const addTab = () =>{
        let tmpTabs = [...otherTabs];
        tmpTabs.push({
            title: `View ${tmpTabs.length + 1}`,
            view: (<ReportDesignContainer reportDef={currReport} setReportDef={setCurrReport} selected={reportChart} setSelected={setReportChart} />),
            disabled: false,
        });
        setActiveTitle(`View ${tmpTabs.length}`);
        setOtherTabs([...tmpTabs]);
    };*/
    const addTab = () =>{

    }
    let tabs = [...getTabs(),{
            title: <a className={'inline-nav-link'} onClick={()=>addTab()}><i className="fas fa-plus" /></a>,
            view: (<></>),
            disabled: false,
        }
    ];
    const navigation = [
        { id: 1, text: 'Connection', icon: 'product' },
        { id: 2, text: 'Fields', icon: 'chart' },
        { id: 3, text: 'Design', icon: 'group' },
        { id: 4, text: 'Filters', icon: 'card' },
        { id: 5, text: 'Parameters', icon: 'group' },
        { id: 6, text: 'Sharing', icon: 'group' },
    ];
    const navList = ()=>{
        return  <div className="list" style={{ width: '200px', color:'white' }}>
            <List
                dataSource={navigation}
                hoverStateEnabled={true}
                activeStateEnabled={true}
                focusStateEnabled={true}
                onItemClick={(item)=>{
                    setShowMenu(false);
                    setCurrentEdit(item.itemData.id);
                }}
                className="panel-list dx-theme-accent-as-background-color" />
        </div>;
    };
    const toolbarItems =  [{
        widget: 'dxButton',
        location: 'before',
        options: {
            icon: 'menu',
            onClick: () => {
                setShowMenu(!showMenu);
            }
        }
    },{
        widget: 'dxButton',
        location: 'before',
        options: {
            icon: 'save',
            onClick: async () => {
                await saveContent();
            }
        }
    }];
    return <div >
        <Toolbar items={toolbarItems } />
        <Drawer
            opened={showMenu}
            openedStateMode={ 'overlap'}
            position={'left'}
            revealMode={'slide'}
            component={navList}
            closeOnOutsideClick={()=>{
                setShowMenu(false);
            }}>
            <div id="content" className="container-fluid dashboard-report-item-container">
                <div className={'row'}>
                    <div className="col md-3">
                        {currentEdit === 1 && <ConnectionInfo update={update} currReport={currReport} setCurDataSource={setCurDataSource} />}
                        {currentEdit === 2 && <FieldsList curDataSource={curDataSource} onDragStart={(item)=>{
                        }} />}
                        {currentEdit === 3 && <ReportDesignTab reportChart={reportChart} setReportChart={updateReportChart}/>}
                    </div>
                    <div className={'col-md-9 tab-container-full'}>
                        <TabbedView tabs={tabs} forcedActiveTabTitle={activeTitle} />
                    </div>
                </div>
            </div>
        </Drawer>
    </div>;
};