import React from 'react';
import {VariantEditRow} from './VariantEditRow';

export const InvoiceProductVariants = ({invoice_rows, invoice,deleteVariant, updateInvoice})=>{
    return <>
        {(invoice_rows || []).map((variant)=>{
            return(
                <VariantEditRow key={'variant_row_' + variant.product_id + '_' + variant.id} variant={variant} invoice={invoice} deleteVariant={deleteVariant} updateInvoice={updateInvoice}/>
            );
        })}
    </>;
};
