import React, {Component, PureComponent, useEffect, useState} from 'react';
import {Checkbox} from 'primereact/checkbox';
import useDataServices from '../../services/hooks/useDataServices';
import useNav from '../../services/hooks/useNav';
import {Popup} from '../FormDriver/FormBuilderSupportComponent';
import useSearch from '../../services/hooks/useSearch';
import useGetById from '../../services/hooks/useGetById';
import useFieldUpdater from '../../services/hooks/useFieldUpdater';

import {TableDetailView} from './SchemaBuilder/TableDetailsView';
import {TableDataViewer} from './SchemaBuilder/TableDataViewer';
import {TableConfiguration} from './SchemaBuilder/TableConfiguration';
import {ParameterManager} from './SchemaBuilder/ParameterManager';
import {QueryBuilder} from './SchemaBuilder/QueryBuilder';
import {AddDataSource} from './DataSourceBuilder';
import {useUpsert} from '../../services/hooks/useUpsert';
import {StoredProcBuilder} from "./SchemaBuilder/StoredProcBuilder";
const hookContext = {
    entity: 'data_source_items'
};
const connectionHookContext = {
    entity: 'data_source_connections'
};
export const DataConnectionSchemaImport = () =>{
    const [dataset,setDataset] = useState([]);
    const [paramValues, setParamValues] = useState({});
    const [currDataset,setCurrDataset] = useState({});
    const [testData,setTestData] = useState([]);
    const [curTable,setCurTable] = useState([]);
    const [selTables,setSelTables] = useState([]);
    const [connections,setConnections] = useState([]);
    const [savedMsg,setSavedMsg] = useState('');
    const [showImport,setShowImport] = useState(false); 
    const [showBuilder,setShowBuilder] = useState(false);
    const [showNewSP,setShowNewSP] = useState(false);

    const [connection,setConnection] = useState({});
    const {update} = useFieldUpdater({state:currDataset,setState:setCurrDataset});
    const {data:dataConnections} = useSearch({...connectionHookContext});
    const {getState,navTo} = useNav();
    const {id:datasetId} =getState();
    const {data:incoming} = useGetById({...hookContext,id:datasetId});
    const [updateMutate]  = useUpsert({...hookContext,onSuccess:(result)=>{
        if(datasetId !== result.id)
        {navTo('DataConnectionSchemaImport',{id:result.id});}
    }});
    useEffect(()=>{
        if(incoming && incoming.data){
            setCurrDataset(incoming.data);
            setConnection(incoming.data.data_source_connections);
        }
    },[incoming]);
    useEffect(()=>{
        if(dataConnections && dataConnections.data){
            setConnections(dataConnections.data);
        }
    },[dataConnections]);
    const {getSchema,getData:getReportData} = useDataServices({id:datasetId});
    const getData = async ()=> {
        try {
            const schema = await getSchema(currDataset);
            setDataset(schema.data || []);
        } catch (e) {
        }
    };
    const saveDataset = async () =>{
        await updateMutate({...currDataset});
        setSavedMsg('Saved!');
        setTimeout(()=>{
            setSavedMsg('');

        },2000);
    };
    const saveObjects = async () =>{
        setShowImport(false);
    };
    const onImport = async () =>{
        await getData();
        setShowImport(true);
    };
    const onCheckChange = (checked, item) =>{
        let dset = {...currDataset};
        dset.schema_definition = dset.schema_definition || {};
        dset.schema_definition.tables =dset.schema_definition.tables || [];
        if(checked){
            (item.column_data || []).forEach((col)=>{
                col.enabled = true;
            });
            dset.schema_definition.tables.push(item);
        }
        else
        {dset.schema_definition.tables.splice(dset.schema_definition.tables.indexOf(item), 1);}
        setCurrDataset(dset);
    };
    const removeTable = (table) =>{
        let dset = {...currDataset};
        let toRemoveTable = dset.schema_definition.tables.find(i=>i.table_name=== table.table_name);
        dset.schema_definition.tables.splice(dset.schema_definition.tables.indexOf( toRemoveTable), 1);
        setCurrDataset(dset);
    };
    const updateParams = async (params) =>{

    };
    const updateTable = (table)=>{
        let dset = {...currDataset};
        let src = dset.schema_definition.tables.find(i=>i.table_name === table.table_name);
        dset.schema_definition.tables[dset.schema_definition.tables.indexOf(src)] = table;
        setCurrDataset(dset);
    };
    const updateLocalParam = async (field, value)=>{
        let tmpParams = {...paramValues};
        tmpParams[field] = value;
        setParamValues(tmpParams);
        if(curTable.table_name){
            let res = await getReportData({params:tmpParams,data_set:{...currDataset,core_table_name: curTable.table_name}});
            setTestData(res.data);
        }

    };
    const copyDataset = async () =>{
        let tmpCurrDataset = {...currDataset};
        delete tmpCurrDataset.id;
        tmpCurrDataset.data_source_name = tmpCurrDataset.data_source_name + ' - Copy';
        setCurrDataset(tmpCurrDataset);

    };
    const selectTable = async (table) =>{
        let res = await getReportData({params:paramValues,data_set:{...currDataset,core_table_name: table.table_name}});
        setTestData(res.data);
        setCurTable(table);
    };
    const updateSchema = (schema)=>{
        setCurrDataset({...schema});
    };
    if(!datasetId)
    {return <><div>Add One</div></>;}
    return <div className={'container-fluid'}>
        <div className="row">
            <div className="col-md-6">
                <button className={'btn btn-primary'} onClick={()=>saveDataset()}>Save</button>&nbsp;&nbsp;
                <button className={'btn btn-primary'} onClick={()=>copyDataset()}>Copy</button>&nbsp;&nbsp;
                <button className={'btn btn-primary'} onClick={async ()=> await onImport()}>Import Objects</button>&nbsp;&nbsp;
                <button className={'btn btn-primary'} onClick={ ()=>setShowBuilder(true)}>Build Query</button>&nbsp;&nbsp;
                <button className={'btn btn-primary'} onClick={ ()=>setShowNewSP(true)}>Build Stored Proc</button>&nbsp;&nbsp;
                <button className={'btn btn-default'} onClick={()=>navTo('DataSources')}>Cancel</button>
            </div>
        </div>
        { savedMsg && <><br/><div className="alert alert-success" role="alert">
            {savedMsg}
        </div></>}

        <hr/>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Dataset Title</label>
                    <input value={currDataset.data_source_name} className={'form-control'} type="text" onChange={(e)=>update('data_source_name', e.target.value)}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">API Url</label>
                    <input value={currDataset.api_key} className={'form-control'} type="text" onChange={(e)=>update('api_key', e.target.value)}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Connection</label>
                    <select  value={currDataset.data_connection_id} className={'form-control'} onChange={(e)=>update('data_connection_id', e.target.value)}>
                        {connections.map(i=>{
                            return <option key={'connection_id_' + i.id} value={i.id}>{i.data_source_name}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
        <hr/>
        <div><label htmlFor="">Objects</label> | <ParameterManager updateSchema={updateSchema} schema={currDataset} /></div>
        <div className={'selected-tables-area'}>
            {((currDataset.schema_definition || {}).tables || []).map((table, ind)=>{
                return <TableDetailView key={'sel-table-item-'+ ind}
                    table={table}
                    schema={currDataset}
                    removeTable={removeTable}
                    selectTable={selectTable}
                    updateTable={updateTable}
                    updateSchema={updateSchema}
                />;
            })}
        </div>

        <div className={'container-fluid'} style={{clear:'both'}}>
            <hr/>
            <div><label htmlFor="">Parameters</label></div>
            {currDataset.schema_definition?.paramaters?.map((param,index)=>{
                return <div className="row" key={'param-data-' +index}>
                    <div className="col-md-5">
                        <div className="form-group">
                            <label htmlFor="">{param.parameter_name}</label>
                            <input type="text" className={'form-control'}
                                onChange={async (e)=>{await updateLocalParam(param.parameter_name, e.target.value);}}/>
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div >
            <TableDataViewer table={curTable} data={testData} schema={currDataset} updateParams={updateParams}/>
        </div>
        <TableConfiguration updateParams={updateParams} show={showImport} saveObjects={saveObjects} onCheckChange={onCheckChange} currDataset={currDataset} dataset={dataset}/>
        <QueryBuilder show={showBuilder} table={undefined} dataset={currDataset} setShow={setShowBuilder} updateDataset={updateSchema}/>
        <StoredProcBuilder show={showNewSP} table={undefined} dataset={currDataset} setShow={setShowNewSP} updateDataset={updateSchema}/>
    </div>;
};





