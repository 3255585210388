import React, {useEffect, useState} from 'react';
import useNav from '../../../services/hooks/useNav';
import useSearch from '../../../services/hooks/useSearch';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {MetaTable} from '../../TableDriver/MetaTable/MetaTable';


const datasetHookContext = {
    entity: 'customers'
};

export const CustomerList = () =>{
    const [customers,setCustomers] = useState([]);
    const {navTo} = useNav(datasetHookContext);
    const { data:customer_data } = useSearch({
        ...datasetHookContext
    });
    useEffect(() => {
        if (customer_data && customer_data.data) {
            setCustomers(customer_data.data);
        } else {
            setCustomers([]);
        }
    }, [customer_data]);
    const addCustomer = async()=>{
        navTo('customers-Newcustomers',{});
    };

    const nameBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => navTo('customers-Viewcustomers',rowData)}>{rowData.company_name}</a>
            </>
        );
    };
    const emailBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => navTo('customers-Viewcustomers',rowData)}>{rowData.email}</a>
            </>
        );
    };
    const actionsBodyTemplate =(rowData) =>{
        return (
            <>

            </>
        );
    };

    return (<div>
        <h2>Customers</h2>
        <div>
            <MetaTable table={{table_name:'customers'}} />
        </div>
    </div>);
};