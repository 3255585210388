import {DataTypeIcon} from '../SchemaBuilder/DataTypeIcon';
import React, {useState} from 'react';

export const FieldsList = ({curDataSource, onDragStart}) =>{
    const [currTable, setCurrTable] = useState({});
    const chooseTable = (table) =>{
        setCurrTable(curDataSource?.schema_definition?.tables.find(i=>i.table_name === table));
    };
    return <div className={'datasource-selector'}>
        <div className="row">
            <div className="col-md-12">
                <div>Field List</div>
                <select name="" id=""  className={'form-control'}  onChange={(e)=>{chooseTable(e.target.value);}}>
                    <option value="">--Select One</option>
                    {curDataSource?.schema_definition?.tables?.map((table, t_index)=>{
                        return <option key={'table_name_' + t_index} value={table.table_name}>{table.table_name}</option>;
                    })}
                </select>
                {currTable.column_data?.filter(j=>j.enabled).map((item, col_index)=> {
                    return <div key={'table_index__'+ col_index} className={'draggable'}
                        onDragStart={(event)=>{
                            event.dataTransfer.setData('itemData',JSON.stringify(item));
                            onDragStart(item);
                        }}><a href="" > <DataTypeIcon id={item.user_type_id} /> &nbsp;<span >{item.name}</span></a></div>;

                })}
            </div>
        </div>
    </div>;
};
