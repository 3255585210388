import useNav from '../../services/hooks/useNav';

import CustomReportEdit from './CustomReports/CustomReportEdit';
import Report from './Report';
import { getReport } from './ReportData';

const ReportsRouter = ({ category }) => {
    const { navTo, getState } = useNav();
    const reportName = getState().key;

    switch(category) {
        case '':
        case undefined:
            return navTo('Reports');
        case 'add':
            return <CustomReportEdit />
        case 'edit':
            const reportId = getState().path.split('/')[2];
            return <CustomReportEdit reportId={reportId} />
        case 'custom':
            return <Report customReportId={reportName} />
        default:
            return <Report reportData={getReport(reportName)} />
    }
};

export default ReportsRouter;
