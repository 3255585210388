import axios from "axios";
import {ObjContainer} from "../../../services/object_container";

export class ReportDataService{
    async getReports(){
        let data = await ObjContainer.resolve("service_manager").resolve("scoped_nav").scopedNavGet(`/data-api/reports`);
        return data.data.data;
    }
    async saveReport(report){
        await ObjContainer.resolve("service_manager").resolve("scoped_nav").scopedNavPost(`/data-api/reports`,report);
    }
    async runReport(type, report){
        if(!report.data_source){
            return {data:[],added_columns:[]};
        }
        return await ObjContainer.resolve("data_manager").postData("/analytics/test_dataset",{
            source_table: report.data_source.source_table,
            filter_values: report.data_source.filter_values,
            query:         report.data_source.query,
            columns:       report.data_source.columns,
            rows:          report.data_source.rows,
            values:        report.data_source.values,
            limit:         (report.report_limit || 10),
            top_bottom:    report.top_bottom
        });

    }
}
