import React, {useEffect, useState} from 'react';
import useSearch from '../../../services/hooks/useSearch';
import TabbedView from '../../CommonUI/TabbedView/TabbedView';
import {ReportBasicEdit} from './ReportBasicEdit';
import {ReportAdvancedEdit} from './ReportAdvancedEdit';
import './EmbeddedStyles.scss';
import {EditSeries} from "./EditSeries";
import {XAxis} from "./XAxis";
import {YAxis} from "./YAxis";
import {PieDef} from "./PieDef";
const connectionHookContext = {
    entity: 'data_source_connections'
};
const dataSourceHookContext = {
    entity: 'data_source_items'
};
export const ReportEdit = ({report,updateField, updateReport, dataSource}) =>{
    const [showNewSeries, setShowNewSeries] = useState(false);
    const [seriesItem, setSeriesItem] = useState({});
    const [currReport, setCurrReport] = useState(report);
    const [connections,setConnections] = useState([]);
    const [dataSources,setDataSources] = useState([]);
    const {data:dataConnections} = useSearch({...connectionHookContext});
    const {data:dataSourceData} = useSearch({...dataSourceHookContext,filter_eq:{data_connection_id:currReport.data_connection_id}});
    useEffect(()=>{
        if(dataConnections && dataConnections.data){
            setConnections(dataConnections.data);
        }
    },[dataConnections]);
    useEffect(()=>{
        if(dataSourceData && dataSourceData.data){
            setDataSources(dataSourceData.data);
        }
    },[dataSourceData]);
    useEffect(()=>{
        if(report)
        {setCurrReport(report);}
    },[report]);
    const selectSeries = (seriesIndex) =>{
        let tmpReport = {...currReport};
        tmpReport.report_definition =tmpReport.report_definition || {};
        tmpReport.report_definition['series'] =tmpReport.report_definition['series'] || [];
        let series = tmpReport.report_definition['series'].find(i=>+i.series_index === +seriesIndex);
        setSeriesItem(series);
        setShowNewSeries(true);
    };
    const updateSeriesItem = (updatedItem) =>{
        let tmpReport = {...currReport};
        tmpReport.report_definition =tmpReport.report_definition || {};
        tmpReport.report_definition['series'] =tmpReport.report_definition['series'] || [];
        let series = tmpReport.report_definition['series'].find(i=>+i.series_index === +updatedItem.series_index);
        tmpReport.report_definition['series'][tmpReport.report_definition['series'].indexOf(series)] = updatedItem;
        setCurrReport(tmpReport);
        updateReport(tmpReport);
    };
    const addNewSeries = () =>{
        let tmpReport = {...currReport};
        tmpReport.report_definition =tmpReport.report_definition || {};
        tmpReport.report_definition['series'] =tmpReport.report_definition['series'] || [];
        let newItem = {
            series_index:tmpReport.report_definition['series'].length
        };
        tmpReport.report_definition['series'].push(newItem);
        setSeriesItem(newItem); 
        setCurrReport(tmpReport);
        setShowNewSeries(true);
    };
    const updateReportDef = (field,value) =>{
        let tmpReport = {...currReport};
        tmpReport.report_definition =tmpReport.report_definition || {};
        tmpReport.report_definition[field] =value;
        setCurrReport(tmpReport);
        updateReport(tmpReport);
    };
    let tabs = [
        {
            title: 'Basic',
            view: (<ReportBasicEdit report={report} currReport={currReport} dataSource={dataSource} addNewSeries={addNewSeries}
                selectSeries={selectSeries} showNewSeries={showNewSeries} seriesItem={seriesItem}
                updateField={updateField} updateSeriesItem={updateSeriesItem} updateReportDef={updateReportDef}  />),
            disabled: false,
        },{
            title: 'Data Source',
            view: (<ReportAdvancedEdit updateField={updateField} currReport={currReport} connections={connections} dataSources={dataSources}/>),
            disabled: false
        }
    ];
    if(currReport.report_definition?.report_type === 'line'){
        tabs = tabs.concat([
            {
                title: 'Series',
                view: (<EditSeries currReport={currReport}
                                   selectSeries={selectSeries} updateSeriesItem={updateSeriesItem} seriesItem={seriesItem}
                                   showNewSeries={showNewSeries} report={report} addNewSeries={addNewSeries} dataSource={dataSource} />),
                disabled: false,
            },{
                title: 'X Axis',
                view: (<XAxis currReport={currReport} updateReportDef={updateReportDef} dataSource={dataSource} />),
                disabled: false
            },{
                title: 'Y Axis',
                view: (<YAxis  currReport={currReport} updateReportDef={updateReportDef} dataSource={dataSource}  />),
                disabled: false
            }]);
    }
    if(currReport.report_definition?.report_type === 'pie'){
        tabs = tabs.concat([
            {
                title: 'Pie Define',
                view: (<PieDef currReport={currReport} updateReportDef={updateReportDef}
                               dataSource={dataSource} />),
                disabled: false,
            }]);
    }
    return <div className={'col-md-8'} style={{overflow:'auto'}}>
        <TabbedView tabs={tabs} />
    </div>;
};