import React, {useEffect, useState} from "react";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";
import {Checkbox} from "primereact/checkbox";

export const TableConfiguration = ({show,saveObjects, currDataset, onCheckChange,dataset}) =>{
    const [showImport, setShowImport] =useState(false);
    useEffect(()=>{
        setShowImport(show);
    },[show])
    return <Popup show={showImport} showSave={true} save={() => saveObjects()} title={"Import Objects"} closeModal={() => setShowImport(false)}>
        <div className="row">
            <div className="col-md-12">
                <div style={{maxHeight:"400px",overflow:"auto"}}>
                    {dataset.map((item,index)=>{
                        return (<div key={"table_item_" + index} className={"form-group"}>
                            <Checkbox inputId="cb1" value={item.table_name} onChange={(e)=> onCheckChange(e.checked, item)} checked={((currDataset.schema_definition || {}).tables || []).includes(item)} />
                            <label htmlFor="cb1" className="p-checkbox-label">{item.table_name}</label>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    </Popup>;
}