import React, { Component } from 'react';
import {ObjContainer} from '../../services/object_container';
import {TableOperations} from '../../services/table_operations';
import {BasicTableDef} from './BasicTableDef';

export class TableMasterComponent extends Component{
    constructor(props){
        super();
        this.service_manager = ObjContainer.resolve('service_manager');
        let nav_state =        ObjContainer.resolve('nav').getState();

        this.table_operations = new TableOperations({
            rowClick:props.rowClick,
            routes: props.config.routes,
            parent_data:props.config.parent_data
        });

        this.table_operations.parent_id =      (props.parent_data ||{}).parent_id;
        this.table_operations.parent_collection =      (props.parent_data || {}).parent_collection;
        let filter = Object.assign({},((nav_state.route_params || {}).filter || {}),props.filter || {});
        this.state = {
            filter:            filter,
            rows:              (props.rows || []),
            mode:               props.mode,
            sort:               nav_state.route_params && nav_state.route_params.sort? nav_state.route_params.sort : (props.sort || {}),
            table_type:        (props.table_type || 'query'),
            config:             props.config,
            selected:           [],
            definition:        (props.table_def || {fields:[]}),
            parent_id:         (props.parent_data ||{}).parent_id,
            parent_collection:(props.parent_data || {}).parent_collection,
            ops: this.table_operations.operations(this)
        };
    }
    async componentDidMount(){
        this.service_manager.resolve(this.props.config.table_name).addListener('refresh_rows', (rows) => {
            this.setState({rows});
        });
        if(this.state.table_type === 'assign' ) {
            await this.setupAssign();
            return;
        }
        if(this.service_manager.hasType(this.props.config.table_name)) {
            await this.setupTableType();
        }
    }
    async setupTableType(){
        let filter = {filter:this.state.filter, sort: this.state.sort } || {};
        if(this.props.parent_data) {
            if(this.props.config.parent_field_name){
                filter.filter = {};
                filter.filter[this.props.config.parent_field_name] = this.props.parent_data[this.props.config.parent_field_name];
            }else{
                filter.filter = {parent_id: this.props.parent_data.parent_id};
            }
            this.table_operations.filter = filter.filter;
        }
        this.setupListeners();
        this.data_def = await this.service_manager.resolve(this.props.config.table_name).search(filter);
        if(this.props.onData)
        {this.props.onData(this.data_def.data.data);}

        this.table_operations.pageConfig = {count:this.data_def.data.count,page:this.data_def.data.page || 1, page_size: this.data_def.data.page_size};
        this.table_operations.schema = this.data_def.schema_def;
        //definition: this.data_def.schema_def,
        this.setState({
            rows: this.data_def.data.data,
            paging: {
                count:this.data_def.data.count,
                page:this.data_def.data.page || 1,
                page_size: this.data_def.data.page_size},filter:filter});
        if(!this.state.definition || !this.state.definition.fields){
            this.setState({definition:this.data_def.schema_def});
        } 
    }
    async setupAssign(){
        let data_def = await this.service_manager.resolve(this.props.config.table_name).schema({});
        this.table_operations.pageConfig = {count:(this.state.rows || []).length,page: 1, page_size: 20};
        this.table_operations.schema = data_def.data;

        this.setState({
            paging: {count:(this.state.rows || []).length,page: 1, page_size: 20}});
        if(!this.state.definition || !this.state.definition.fields){
            this.setState({definition:data_def.data});
        }
    }
    setupListeners(){
        this.service_manager.resolve(this.props.config.table_name).addListener('update-grid', async () => {
            this.data_def = await this.service_manager.resolve(this.props.config.table_name).search(this.state.filter);
            this.table_operations.filter = this.state.filter;
            this.setState({rows: this.data_def.data.data,
                paging: {count:this.data_def.data.count,page:this.data_def.data.page || 1, page_size: this.data_def.data.page_size}});
        });
        this.service_manager.resolve(this.props.config.table_name).addListener('filter-updated', async (filter) => {
            let newFilter =  Object.assign({}, (this.state.filter.filter || {}),filter.filter || {});
            let fullFilter = Object.assign({},this.state.filter, filter, {filter:newFilter});
            this.table_operations.filter = fullFilter;
            await this.executeFilter(fullFilter);
        });
        this.service_manager.resolve(this.props.config.table_name).addListener('sort-updated', async (sort) => {
            let fullFilter = Object.assign({}, this.state.filter);
            fullFilter.sort =  Object.assign({},fullFilter.sort, sort);
            this.table_operations.filter = fullFilter;
            await this.executeFilter(fullFilter);
        });
    }
    async executeFilter(fullFilter){
        this.data_def = await this.service_manager.resolve(this.props.config.table_name).search(fullFilter);
        this.setState({
            rows: (this.data_def.data.data),
            paging: {
                count:this.data_def.data.count,
                page:this.data_def.data.page || 1,
                page_size: this.data_def.data.page_size
            },
            filter:fullFilter
        });
        if(!this.state.definition || !this.state.definition.fields){
            this.setState({definition:this.data_def.schema_def});
        }
    }
    render(){
        return <BasicTableDef {...this.state} selected={this.state.selected}  disableDelete={this.props.disableDelete} config={this.props.config} />;
    }
    componentWillUnmount(){
        try{

            this.service_manager.resolve(this.props.config.table_name).removeAllListeners('refresh_rows');
            this.service_manager.resolve(this.props.config.table_name).removeAllListeners('update-grid');
            this.service_manager.resolve(this.props.config.table_name).removeAllListeners('filter-updated');
            this.service_manager.resolve(this.props.config.table_name).removeAllListeners('sort-updated');
        }catch(e){
            console.log('Table Missing from manager',this.props.config.table_name);
        }
    }
}






