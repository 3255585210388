import React, {useEffect, useState} from 'react';

export const ReportEditHeader = ({report,save,copy}) =>{
    return <div  className="container-fluid">
        <div className="row">
            <div className="col-md-6">
                <h3>{report.report_title} </h3>
                <h4>source: {report.data_source_connections?.data_source_name} - {report.data_source_items?.data_source_name}</h4>
            </div>
            <div className="col-md-6 align-right">
                <button className={'btn btn-primary'} onClick={async () =>await save()}>Save</button> <button className={'btn btn-primary'} onClick={async () =>await copy()}>Copy</button>  <button className={'btn btn-primary'}>Publish</button> <button className={'btn btn-primary'}>Embed Code</button>
                &nbsp; <a className={'inline-nav-link'}>Cancel</a>
            </div>
        </div> 
    </div>;
};