import OrderDetails from './SalesReports/OrderDetails';
import OrdersBySalesperson from './SalesReports/OrdersBySalesperson';
import ByCustomerAndProduct from './SalesReports/ByCustomerAndProduct';
import InvoicesBySource from './SalesReports/InvoicesBySource';

import CustomerInactivity from './CustomerReports/CustomerInactivity';
import ItemsByCustomer from './CustomerReports/ItemsByCustomer';
import {SalesForecasting} from "./ForcastingReports/SalesForcasting";

export const getReport = (reportName) => {
    switch (reportName) {
        // ***** SALES REPORTS *****
        case 'orderDetails':
            return { ...OrderDetails };
        case 'ordersBySalesperson':
            return { ...OrdersBySalesperson };
        case 'openOrdersReport':
            return { ...ByCustomerAndProduct('order') };
        case 'invoicesByCustomerAndProduct':
            return { ...ByCustomerAndProduct('invoice') };
        case 'invoicesBySource':
            return { ...InvoicesBySource };
        case 'salesForecasting':
            return { ...SalesForecasting };
        // ***** CUSTOMER REPORTS *****
        case 'orderItemsByCustomer':
            return { ...ItemsByCustomer('order') };
        case 'invoiceItemsByCustomer':
            return { ...ItemsByCustomer('invoice') };
        case 'customerInactivity':
            return { ...CustomerInactivity };
    }
};

const ReportData = {
    sales: {
        description: 'Sales reports.',
        reports: [
            {
                name: 'Invoice Summary',
                nav: 'InvoiceSummaryReport',
            },
            {
                name: 'Order Details',
                nav: 'reports/sales#orderDetails',
            },
            {
                name: 'Orders by Salesperson',
                nav: 'reports/sales#ordersBySalesperson',
            },
            {
                name: 'Open Orders by Customer and Product',
                nav: 'reports/sales#openOrdersReport',
            },
            {
                name: 'Invoices by Customer and Product',
                nav: 'reports/sales#invoicesByCustomerAndProduct',
            },
            {
                name: 'Invoices by Source',
                nav: 'reports/sales#invoicesBySource',
            },
        ],
    },
    inventory: {
        description: 'Current inventory counts and negative inventory reports.',
        reports: [
            {
                name: 'Inventory Count',
                nav: 'CurrentInventoryLevel',
            },
        ],
    },
    customers: {
        description: 'Current customer activity and inactivity.',
        reports: [
            {
                name: 'Order Items by Customer',
                nav: 'reports/customers#orderItemsByCustomer',
            },
            {
                name: 'Invoice Items by Customer',
                nav: 'reports/customers#invoiceItemsByCustomer',
            },
            {
                name: 'Customer Inactivity Report',
                nav: 'reports/customers#customerInactivity',
            },
        ],
    },
    forecasting: {
        description: 'Forecasting Report.',
        reports: [
            {
                name: 'Sales Forecasting',
                nav: 'reports/forecasting#salesForecasting',
            }
        ],
    },
};

export default ReportData;
