import React, {useEffect, useState} from "react";

export const JoinConfigurationItem = ({table,joinItem,updateTable, all_tables}) =>{
    const [curJoinTable, setCurJoinTable] = useState({column_data:[]});
    const [curJoin, setCurJoin] = useState(joinItem || {});
    const [currTable, setCurrTable] = useState(table);
    useEffect(()=>{
        setCurJoin(joinItem);
        setCurJoinTable(all_tables.find(i=>i.table_name === joinItem.dest_table) || {column_data:[]})
    },[joinItem]);
    useEffect(()=>{
        setCurrTable(table);
    },[table]);

    const saveObjects = ()=>{
        updateTable(currTable);

    }
    const update = (field, value)=>{
        let tmpCurTable = {...currTable};
        let tmpCurJoin = {...curJoin};
        tmpCurJoin[field] = value;
        setCurJoin(tmpCurJoin);
        tmpCurTable.join_list = tmpCurTable.join_list || [];
        let ind = tmpCurTable.join_list.indexOf(joinItem);
        if(ind < 0)
            tmpCurTable.join_list.push(tmpCurJoin);
        else
            tmpCurTable.join_list[ind] = tmpCurJoin;
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    }
    const removeRow = ()=>{
        let tmpCurTable = {...currTable};
        tmpCurTable.join_list = tmpCurTable.join_list || [];
        tmpCurTable.join_list.splice(tmpCurTable.join_list.indexOf(joinItem),1);
        updateTable(tmpCurTable);
        setCurrTable(tmpCurTable);
    }
    const addRow = ()=>{
        let tmpCurTable = {...currTable};
        tmpCurTable.join_list = tmpCurTable.join_list || [];
        tmpCurTable.join_list.push({});
        updateTable(tmpCurTable)
        setCurrTable(tmpCurTable);
    }
    const setSelJoinTable = (table_name)=>{
        setCurJoinTable(all_tables.find(i=>i.table_name === table_name) || {column_data:[]});
        let tmpCurJoin = {...curJoin};
        tmpCurJoin['dest_table'] = table_name;
        setCurJoin(tmpCurJoin);
    }
    return <>
        <div className="row">
            <div className="col-md-3">
                <select name="" id="" placeholder={"Join Type"} value={curJoin.join_type} onChange={(e)=>
                    update('join_type', e.target.value)} className={"form-control"}>
                    <option>--Choose Join Type</option>
                    <option value="inner">Inner</option>
                    <option value="left-outer">Left Outer</option>
                    <option value="right">Right Outer</option>
                    <option value="left">Left</option>
                    <option value="full">Full Outer</option>
                </select>
            </div>
            <div className="col-md-3">
                <select name="" id="" placeholder={"Source Field"} value={curJoin.source_field} onChange={(e)=>
                    update('source_field', e.target.value)} className={"form-control"}>
                    <option>--Choose Source Column</option>
                    {table.column_data.map((item,index)=>{
                        return <option key={"table_join_source_column_" + index} value={item.name}>{item.name}</option>;
                    })}
                </select>
            </div>
            <div className="col-md-2">
                <select name="" id="" className={"form-control"} value={curJoin.join_equality} onChange={(e)=>
                    update('join_equality', e.target.value)}>
                    <option value="eq">=</option>
                    <option value="lt">&lt;</option>
                    <option value="gt">&gt;</option>
                    <option value="gteq">&gt;=</option>
                    <option value="lteq">&lt;=</option>
                    <option value="neq">Not =</option>
                    <option value="nlt">Not &lt;</option>
                    <option value="ngt">Not &gt;</option>
                </select>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <select name="" id="" value={curJoin.dest_table} placeholder={"Dest Field"}
                            onChange={(e)=>setSelJoinTable(e.target.value)} className={"form-control"}>
                        <option>--Choose Dest Table</option>
                        {all_tables.map((item,index)=>{
                            return <option key={"table_join_column_" + index} value={item.table_name}>{item.table_name}</option>;
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <select name="" id="" value={curJoin.dest_column} onChange={(e)=>update('dest_column', e.target.value)} placeholder={"Dest Field"} className={"form-control"}>
                        <option>--Choose Dest Column</option>
                        {curJoinTable.column_data.map((item,index)=>{
                            return <option key={"table_join_column_" + index} value={item.name}>{item.name}</option>;
                        })}
                    </select>
                </div>
            </div>
            <div className="col-md-1">
                <a className={"inline-nav-link"}  onClick={()=>addRow()}><i className={"fa fa-plus"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={()=>saveObjects()}><i className={"fa fa-save"} /></a> &nbsp;
                <a className={"inline-nav-link"} onClick={()=>removeRow()}><i className={"fa fa-trash"} /></a>
            </div>
        </div>
    </>;
}
