import FaSearch from 'react-icons/lib/fa/search';
import TimesCircle from 'react-icons/lib/fa/times-circle';
import React, {Component} from 'react';
import {TableMasterComponent} from '../TableDriver/TableMasterComponent';
import {ObjContainer} from '../../services/object_container';
import {VDropdown} from './VDropdown';

export const FormInput =(props)=>{
    let datatype = props.field.form_field_type || props.field.data_type;
    if(datatype instanceof Object)
    {datatype = datatype.value;} 
    switch (datatype){
        case 'string':
            return (<TextInputField  {...props}/>);
        case 'pick_list':
            return (<DropDownFieldSelector {...props}/>);
        case 'derived_pick_list':
            return (<DerivedDropDownList {...props} />);
        case 'many_to_many':
            return (<ManyToManySelection {...props} />);
        case 'derived_search':
            return (<DerivedSearch {...props}/>);
        case 'text':
            return (<TextAreaInputField {...props}/>);
        case 'checkbox':
            return (<CheckboxField {...props}/>);
        case 'reference':
            return (<ReferenceSelector {...props}/>);
        case 'password':
            return (<PasswordInputField {...props}/>);
        default:
            return (<TextInputField {...props}/>);
    }
};
export class ManyToManySelection extends Component{
    constructor(params){
        super();
        let selected = (params.obj[params.field.field_code] || []).map((item)=>{
            return {value:item.integrationTag, display:item.integrationTag};
        });
        this.state = {selected: selected,options:[], params:params, schema:{}};
        this.service_manager = ObjContainer.resolve('service_manager');
        this.data_manager = ObjContainer.resolve('data_manager');
    }
    async componentDidMount() {

        if(this.state.params && this.state.params.field){
            let schema = this.service_manager.schema(this.state.params.field.through);
            this.setState({schema:schema});
        }
    }

    onChange(option, e){
        let checked = this.state.selected || [];
        let exists = checked.find((i) =>{
            return i.value === option.value;
        });
        if(e.target.checked && !exists){
            checked.push(option);
        }else{
            checked.splice(checked.indexOf(exists),1);
        }
        this.setState((s)=>{
            s.selected = checked;
            return s;
        });
        this.props.onChange(this.props.field.field_code,checked,e);
    }
    valueChecked(option){
        let found = (this.state.selected || []).find((item)=>{
            return item.value === option.value;
        });
        return found !== undefined;
    }
    render(){
        return (<div>
            {(this.state.schema.fields || []).filter(field=>field.display).map((field, field_index)=>{
                return <div key={'many_to_many_key_' + field_index}>
                    {(field.options || []).map((option, option_index)=>{
                        return (
                            <div  key={'relation_checkbox_option_value_' + option_index} className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" onChange={this.onChange.bind(this,option)}
                                        name={this.state.schema.field_code} checked={this.valueChecked(option)}/>{option.display}
                                </label>
                            </div>);
                    })}
                </div>;
            })}
        </div>);
    }
}
export const DerivedSearch = ({field, value,title, onChange,onClick,className, items})=>{
    const [open, setOpen] =React.useState(false);
    let def = field.derived_def || {display_field: 'display', value_field: 'value'};
    return (
        <div>
            <input type={'text'} className={className || 'form-control'}
                onClick={async (e)=>{
                    setOpen(true);
                    await onChange(field, e.target.value);
                }}
                onChange={async (e) => {
                    setOpen(true);
                    await onChange(field, e.target.value);
                }}
                onBlur={(e)=>setTimeout(() => setOpen(false),500)}
                placeholder={title}
                value={value || ''}
                onFocus={()=>setOpen(true)}
            />
            {open &&
                <div style={{position:'relative', zIndex:'5000', width:'100%'}}>
                    <div style={{display:'block',position:'absolute', backgroundColor:'white', height:'125px', width:'100%', overflow:'auto', zIndex:5000, border:'1px solid silver', padding:'3px', cornerRadius:'5px'}}>
                        {items.map((item,j)=>{
                            return (<div key={'mapping-options-' + j} style={{padding:'5px 5px 2px 2px'}}>
                                <a key={'menu-item-' + j} className={'nav-link'}
                                    onClick={async (e)=>{
                                        e.preventDefault();
                                        await onClick(field, item[def.value_field], item);
                                        setOpen(false);
                                    }}>{item[def.display_field]}</a>
                            </div>);
                        })}
                    </div>
                </div>
            }
        </div>);
};
export class DerivedDropDownList extends Component{
    constructor(){
        super();
        this.state = {options:[]};
        this.service_manager = ObjContainer.resolve('service_manager');
    }
    async componentDidMount(){
        await this.refreshData();
        this.service_manager.resolve('form_emitter').addListener('updated',async ()=>{
            await this.refreshData();
        });
    }
    componentWillUnmount(){
        this.service_manager.resolve('form_emitter').removeAllListeners('updated');
    }
    onChange(option, e){
        if(!(this.props.value instanceof Array)){
            this.props.onChange(this.props.field.field_code,[option],e);
            return;
        }
        let checked = this.props.value || [];
        let exists = checked.find((i) =>{
            return i.value === option.value;
        });
        if(e.target.checked && !exists){
            checked.push(option);
        }else{
            checked.splice(checked.indexOf(exists),1);
        }

        this.props.onChange(this.props.field.field_code,checked,e);
    }
    onSingleChange(option){
        if(this.props.field.derived_pick_list.value_field){
            this.props.onChange(this.props.field.field_code, option.value);
        }else{
            this.props.onChange(this.props.field.field_code, option);
        }
    }
    async refreshData(){
        if(this.props.field.derived_pick_list  && this.props.field.derived_pick_list.source_table && this.props.field.derived_pick_list.filter_by){
            let filter = {};
            filter['parent_id'] = (this.props.obj[this.props.field.derived_pick_list.filter_by] || {}).value;
            let res = await this.service_manager.resolve(this.props.field.derived_pick_list.source_table).get({filter:filter});
            let options = res.data.data.map((item, index)=>{
                return {value: (this.props.field.derived_pick_list.value_field ? item[this.props.field.derived_pick_list.value_field] : item._id),
                    display: (this.props.field.derived_pick_list.display_field ? item[this.props.field.derived_pick_list.display_field] : item.display )};
            });
            this.setState({options: options});
        }
        else if(this.props.field.derived_pick_list  && this.props.field.derived_pick_list.source_table){
            let res = await this.service_manager.resolve(this.props.field.derived_pick_list.source_table).get({});
            let options = res.data.data.map((item, index)=>{
                return {value: (this.props.field.derived_pick_list.value_field ? item[this.props.field.derived_pick_list.value_field] : item._id),
                    display: (this.props.field.derived_pick_list.display_field ? item[this.props.field.derived_pick_list.display_field] : item.display )};
            });
            this.setState({options: options});
        }
    }
    valueChecked(option){
        if(!(this.props.value instanceof Array)) {return false;}
        let found = (this.props.value || []).find((item)=>{
            return item.value === option.value;
        });
        return found !== undefined;
    }
    render(){
        let val = this.state.options.find(i=>i.value === this.props.value);
        return (
            <span >
                {!this.props.field.derived_pick_list.is_multi &&  <span  className={'dropdown'} >
                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        {val ?  val.display : 'Select One...'}
                        &nbsp;<span className="caret"/>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                        {(this.state.options|| []).map((option,index) =>{
                            return <li key={'relation_option_value_' + index}>
                                <a className="nav-link" onClick={() => this.onSingleChange(option)}  >{option.display}</a>
                            </li>;
                        })}
                    </ul>
                </span>}
                {this.props.field.derived_pick_list.is_multi && <span>
                    {(this.state.options|| []).map((option,index) =>{
                        return (<div  key={'relation_checkbox_option_value_' + index} className="form-check">
                            <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" onClick={this.onChange.bind(this,option)} name={this.props.field.field_code} checked={this.valueChecked(option)}/>{option.display}
                            </label>
                        </div>);
                    })}
                </span>}
            </span>
        );
    }
}
let SingleSelector = ({placeholder, value, onChange, showModal}) =>{
    return (
        <div className="input-group">
            <input type="text" className="form-control" placeholder={placeholder} value={value || ''} onChange={onChange ||( ()=>{})} aria-describedby="sizing-addon2" /> &nbsp;
            <span className="input-group-addon" id="sizing-addon2"><a className={'inline-nav-link'} onClick={(e) => showModal(e)}><FaSearch /></a></span>
        </div>);
};
let MultiSelector = (params) =>{
    return (<div>
        <div style={{height:'125px',border:'1px solid silver',borderRadius:'4px', overflowY:'scroll'}}>
            {(params.values || []).map((item, index)=>{
                return <div key={'reference_item-' + index}><a className={'inline-nav-link'} onClick={params.onDelete} ><TimesCircle/></a> {item.display}</div>;
            })}
        </div>
    </div>);
};
//{field.reference.is_multi && <MultiSelector values={value} onDelete={()=> onDelete()} />}
export const SelectorPopup = ({toTable,placeHolder, display,selected})=>{
    const [open, setOpen] = React.useState(false);
    const onSelect= (row,e) =>{
        e.preventDefault();
        setOpen(false);
        selected(row);
    };
    const onOpen = (e) =>{
        e.preventDefault();
        setOpen(true);
    };
    const renderBackdrop = () => {
        if (open ) {
            return (
                <div className={'modal-backdrop fade ' +  (open?  'show' :'')} role="presentation" />
            );
        }
        return null;
    };
    return (
        <span>
            <SingleSelector showModal={(e)=>onOpen(e)} placeholder={placeHolder} value={display}/>
            <div className={'modal ' + (open ? 'show' : '')}
                id="myModal"
                style={{ display: ((open) ? 'block' : 'none'),width:'55%',height:'55%',top: '35%',left: '35%',transform: 'translate(-35%, -35%)' }}
                tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden={!open}>
                <div className="" style={{}} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id="exampleModalLabel">Lookup
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setOpen(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body" style={{overflow:'scroll',height:'500px'}}>
                            <TableMasterComponent rowClick={(row,field, e) => onSelect(row,e)}
                                config={{fields:[],table_name:toTable} }
                                table_def={Object.assign({},ObjContainer.resolve('service_manager').schema(toTable),{} )}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>setOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {renderBackdrop()}
        </span>);
};


export class ReferenceSelector extends Component{
    constructor(params){
        super();
        if(params.field.reference.is_multi){
            let val = params.value;
            if(!(val instanceof Array))  {val = [];}

            this.state = {show:false,value: val || [] } ;
        }else{
            this.state = {show:false,value: params.value, display: params.display} ;
        }
        this.service_manager = ObjContainer.resolve('service_manager');
    }
    _showModal(){
        this.setState({show:true});
    }
    _closeModal(){
        this.setState({show:false});
    }
    _save(){

    }
    _delete(val, index){
        this.state.value.splice(index, 1);
        this.setState({value:this.state.value});
        if(this.props.onChange)
        {this.props.onChange(this.props.field.field_code, this.state.value, this.state.value);}

    }
    _onRowClick(row,field){
        let val = row[this.props.field.reference.field_code || 'id'];
        let display = row[this.props.field.reference.display_field_code || this.props.field.reference.field_code];
        if(this.props.field.reference.is_multi){
            this.state.value.push(val);
            this.props.onChange(this.props.field.field_code,  val,  val);
        }
        else{
            this.props.onChange(this.props.field.field_code,  val,  val);
        }
        this.setState({value:  val,display: display, show:false});
    }
    renderBackdrop = () => {
        if (this.state.show ) {
            return (
                <div className={'modal-backdrop fade ' +  (this.state.show ?  'show' :'')}
                    role="presentation" />
            );
        }
        return null;
    };
    render(){
        return (
            <span>
                <SingleSelector showModal={this._showModal.bind(this)}
                    placeholder={this.props.field.field_name} value={this.state.display || this.state.value}/>
                {this.props.field.reference.is_multi && <MultiSelector values={this.state.value} onDelete={this._delete.bind(this)} />}
                <div className={'modal ' + (this.state.show ? 'show' : '')}
                    id="myModal"
                    style={{ display: ((this.state.show) ? 'block' : 'none'),width:'55%',height:'55%',top: '35%',left: '35%',transform: 'translate(-35%, -35%)' }}
                    tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden={!this.state.show}>
                    <div className="" style={{}} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title" id="exampleModalLabel">Lookup
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this._closeModal.bind(this)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body" style={{overflow:'scroll',height:'500px'}}>
                                <TableMasterComponent
                                    rowClick={(row, field) => this._onRowClick(row, field)}
                                    mode={'single-select'}
                                    config={{fields:[],table_name:this.props.field.reference.relation_table} }
                                    table_def={Object.assign({},this.service_manager.schema(this.props.field.reference.relation_table),{})}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this._closeModal.bind(this)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={this._save.bind(this)}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderBackdrop()}
            </span>);
    }
}
export class PickListDefiner extends Component{
    constructor(params){
        super();
        this.state = {obj:{}, field: params.field, show:false,modal:null};
        this.service_manager = ObjContainer.resolve('service_manager');
    }
    async _delete(val, index){
        this.state.field.options.splice(index, 1);
        this.setState({obj:{display:'',value:'',dropin:''},field: this.state.field});
        await this.service_manager.resolve('fields').upsert(this.state.field._id,this.state.field);
        this.service_manager.resolve('fields').emit('field_update',this.state.field);
    }
    _appendItem(){
        this.state.field.options = this.state.field.options || [];
        this.state.field.options.push(this.state.obj);
        this.setState({obj:{'display':'','value':'',dropin:null},field: this.state.field});
    }
    _onChange(obj){
        this.state.obj[obj.target.name] = obj.target.value;
        this.setState({obj:this.state.obj});
    }
    async _saveOptions(){
        await this.service_manager.resolve('fields').upsert(this.state.field._id,this.state.field);
        this.service_manager.resolve('fields').emit('field_update',this.state.field);
        this.setState({show:false});
    }
    _showModal(){
        this.setState({show:true});
        return false;
    }
    _closeModal(){
        this.setState({show:false});
    }
    renderBackdrop = () => {
        if (this.state.show ) {
            return (
                <div className={'modal-backdrop fade ' +  (this.state.show ?  'show' :'')} role="presentation" />
            );
        }
        return null;
    };
    render(){
        return (
            <div className={'col-md-4'}>
                <a className="nav-link" href="#"  onClick={this._showModal.bind(this)}>define</a>
                <div className={'modal ' + (this.state.show ? 'show' : '')}
                    id="myModal"
                    style={{ display: ((this.state.show) ? 'block' : 'none') }}
                    tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden={!this.state.show}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title" id="exampleModalLabel">Define {this.state.field.field_name}</div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this._closeModal.bind(this)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            <label>Option Text</label>
                                        </div>
                                        <div className={'col-md-9'}>
                                            <input type="text" onChange={this._onChange.bind(this) } name={'display'} value={this.state.obj['display']}/>
                                        </div>

                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-md-3'}>
                                            <label>Option Value</label>
                                        </div>
                                        <div className={'col-md-9'}>
                                            <input type={'text'} name={'value'} onChange={this._onChange.bind(this) } value={this.state.obj['value']}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className={'btn btn-primary'} onClick={this._appendItem.bind(this)}>Add</button>
                                </div>
                                <table className="table table-responsive">
                                    <thead>
                                        <tr><th/><th>Option Text</th><th>Option Value</th></tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.field.options || []).map((option, index)=>{
                                            return (
                                                <tr key={'field_option_' + index}>
                                                    <td><a href={'#'} onClick={this._delete.bind(this, option, index)} ><TimesCircle/></a></td><td>{option.display}</td><td>{option.value}</td>
                                                </tr>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this._closeModal.bind(this)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={this._saveOptions.bind(this)}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderBackdrop()}
            </div>);
    }
}


let TextInputField = (params)=>{
    return (<input type="text" className="form-control" name={params.field.field_code} value={params.value} onChange={(e) => params.onChange(params.field.field_code, e.target.value)}/>);
};
let PasswordInputField = (params)=>{
    return (<input type="password" className="form-control" name={params.field.field_code} value={params.value} onChange={(e) => params.onChange(params.field.field_code, e.target.value)} />);
};
let TextAreaInputField = (params) =>{
    return (<textarea  className="form-control" name={params.field.field_code}  onChange={(e) => params.onChange(params.field.field_code, e.target.value)} value={params.value}/>);
};
let DropDownFieldSelector = (params)=>{
    let selected_option = params.field.options.find(i=>i.value === params.value) || {};
    return (
        <div className={'col-md-12'}>
            <div className={'row'}>
                <div className="dropdown">
                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        {selected_option.display || 'Selct One...'}
                        &nbsp;<span className="caret"/>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {(params.field.options || []).map((option,index) =>{
                            return <a className="dropdown-item" href="#" key={'option_value_' + index}
                                onClick={(e) => params.onChange(params.field.field_code,option.value)}>{option.display}</a> ;
                        })}
                    </div>
                </div>&nbsp;<PickListDefiner field={params.field} />
            </div>
        </div>

    );
};
let CheckboxField = (params) => {
    let _onChange = (val,e) =>{

        if(e.target.checked){
            params.onChange(params.field.field_code, {value: params.field.field_code,display:'true'});
        }else{
            params.onChange(params.field.field_code, {value: null,display:'false'},e);
        }
    };
    return (
        <input type={'checkbox'} name={params.field.field_code} onChange={(e)=>_onChange( params.field.field_code,e)}
            checked={params.value && params.value.value !== null && params.value.value !== ''} />
    );
};
