import {ObjContainer} from '../../services/object_container';
import {RechartBar, RechartLine, RechartsPie} from './MpCharts';
import React, { Component } from 'react';
import {Dropdown} from 'primereact/dropdown';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import {ReportDataService} from './shared/ReportDataService';
import {DataSourceService} from './shared/DataSourceService';
import {DataTable} from 'primereact/datatable';
import { Column } from 'primereact/column';

import './datatable.css';

const reportService = new ReportDataService();
const dataSourceService = new DataSourceService();

export function Reports({}){
    return (
        <div>
            <ReportList />
        </div>);
}
export function ReportList(){
    let {data:reports_data}  =  useQuery(['reports'],reportService.getReports);

    const [show, setShow] = React.useState(false);
    const [addedColumns, setAddedColumns] = React.useState([]);
    const [reports, setReports] = React.useState([]);
    const [report, setReport] = React.useState({});
    const [data, setData] = React.useState([]);
    const queryCache = useQueryCache();
    React.useEffect(() => {
        if (reports_data) {
            setReports(reports_data);
        } else {
            setReports([]);
        }
    }, [reports_data]);


    const  runReport = async(report) =>{
        setReport({...report});
        setShow(true);
    };
    const refresh= async (state)=>{

    };
    const deleteReport = async (report)=>{
        await ObjContainer.resolve('data_manager').deleteData('/data-api/reports/' + report._id);
        await queryCache.invalidateQueries(['reports']);
    };
    const nameBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await runReport(rowData)}>{rowData.report_name}</a>
            </>
        );
    };
    const actionsBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await deleteReport(rowData)}>Delete</a>
            </>
        ); 
    };
    return <div className={'report-builder-container'}>
        {!show &&
        <div>
            <div  className={'valerian-search'}>
                <button className={'btn btn-primary'} onClick={() => setShow(true)}>Add Report</button>
            </div>
            <DataTable  className="p-datatable-customers" value={reports}
                header={''}  dataKey="id" rowHover
                paginator rows={10} emptyMessage="No reports found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                <Column field="report_name" header="Actions"  body={actionsBodyTemplate} style={{width:'95px'}} />
                <Column field="report_name" header="Name"  body={nameBodyTemplate} sortable filter filterPlaceholder="Search by name" />
                <Column field="created_by" header="Created By"  sortable filter filterPlaceholder="Created By" />
                <Column field="created_on" header="Created On"  sortable filter filterPlaceholder="Created On" />
            </DataTable>
        </div>}
        <div className="row">
            {show &&
                <div className={'col-md-12'} style={{display:'flex'}}>
                    <ChooseReportTypes  report={report} added_columns={addedColumns} complete={()=>{{
                        setShow(false);

                    }}} />
                </div>
            }
        </div>
    </div>;
}
export function  ChooseReportTypes({ report, added_columns, complete}){
    const [reportItem, setReportItem] = React.useState(report || {});
    const [dataItem, setDataItem]= React.useState([]);
    const [addedColumns,setAddedColumns]= React.useState(added_columns || []);
    const [dataSources, setDataSources] = React.useState([]);
    const queryCache = useQueryCache();
    let {data:report_data}  =  useQuery(['report', report],reportService.runReport);
    let {data:data_sources}  =  useQuery(['data_sources'],dataSourceService.getDataSources);
    React.useEffect(() => {
        if (data_sources) {
            setDataSources(data_sources.data);
        } else {
            setDataSources([]);
        }
    }, [data_sources]);
    React.useEffect(() => {
        if (report_data) {
            setDataItem(report_data.data);
            setAddedColumns(report_data.added_columns);
            setReportItem({...reportItem});
        } else {
            setDataItem([]);
        }
    }, [report_data]);
    const [mutateSaveReport] = useMutation(reportService.saveReport,{
        onSuccess: async (salesOrder, mutationVariables) => {
            await queryCache.invalidateQueries(['report', report]);
            complete(reportItem);
        },
    });
    const update = async (name, value)=>{
        reportItem[name] = value;
        await runReport();
    };
    const changeDataSource = async (data_source)=>{
        reportItem.data_source = data_source;
        await runReport();
    };
    const runReport = async () =>{
        let dataResult = await reportService.runReport('', reportItem);
        setDataItem(dataResult.data);
        reportItem.added_columns = dataResult.added_columns;
        setReportItem(i=>{
            return {...reportItem};
        });
        setAddedColumns(dataResult.added_columns);
    };
    const saveReport = async () =>{
        //await mutateSaveReport(reportItem);
        complete(reportItem);
    };
    const close = ()=>{
        complete();
    };
    return (
        <div style={{display:'flex'}}>
            <div style={{width:'400px'}}>
                <h3>Report Setup</h3>
                <div className="row">
                    <div className="col-md-5">
                        <button className={'btn btn-primary'} onClick={async ()=> await saveReport()}>Save</button>&nbsp;
                        <button className={'btn btn-primary'} onClick={()=>close()}>Cancel</button>
                    </div>
                </div>
                <br/>
                <ReportSetup reportItem={reportItem} update={update}/>
                <br/>
                <DatasetDefinition reportItem={reportItem} dataSources={dataSources} changeDataSource={changeDataSource}/>
                <br/>
                <VisualDefinition  reportItem={reportItem} update={update}/>
                <br/>
                {reportItem.visual_type === 'pie' && <PieFields reportItem={reportItem} update={update}/>}
                {reportItem.visual_type === 'line' && <LineFields reportItem={reportItem} update={update}/>}
                {reportItem.visual_type === 'scatter' && <ScatterFields reportItem={reportItem} update={update}/>}
                {reportItem.visual_type === 'bar' && <BarFields reportItem={reportItem} update={update}/>}
                <hr/>
                <ReportFormatting reportItem={reportItem} update={update} />
                <br/>
            </div>
            <div style={{flexGrow:'1'}}>
                <div className="col-md-12" style={{height: '500px'}}>
                    <ManagedReportRunner report={reportItem} data={dataItem} added_columns={addedColumns} />
                </div>
            </div>
        </div>

    );

}
export const VisualDefinition = ({reportItem, update}) =>{
    return <div  className="row">
        <div className="col-md-4">Visual</div>
        <div className="col-md-8">
            <a onClick={()=>update('visual_type', 'pie')} style={{fontSize:'40px', margin:'3px'}}><i className={'fa fa-chart-pie'} /></a>
            <a onClick={()=>update('visual_type', 'line')}  style={{fontSize:'40px', margin:'3px'}}><i className={'fa fa-chart-line'} /></a>
            <a onClick={()=>update('visual_type', 'bar')}  style={{fontSize:'40px', margin:'3px'}}><i className={'fa fa-chart-bar'} /></a>
            <a onClick={()=>update('visual_type', 'scatter')}  style={{fontSize:'40px', margin:'3px'}}><i className={'fa fa-chart-scatter'} /></a>
            <a onClick={()=>update('visual_type', 'table')}  style={{fontSize:'40px', margin:'3px'}}><i className={'fa fa-table'} /></a>
            <select className={'form-control'} onChange={(e)=>update('visual_type', e.target.value)}   value={reportItem.visual_type}>
                <option>--Select one</option>
                <option value={'pie'}>Pie</option>
                <option value={'line'}>Line</option>
                <option value={'bar'}>Bar</option>
                <option value={'table'}>Table</option>
                <option value={'scatter'}>Scatter Plot</option>
            </select>
        </div>
    </div>;
};
export const DatasetDefinition = ({reportItem,dataSources,changeDataSource})=>{
    return <div className="row">
        <div className="col-md-4">
            <label>Dataset</label>
        </div>
        <div className="col-md-8">
            <select
                className={'form-control'}
                value={reportItem.data_source || ''}
                onChange={async (e) => { await changeDataSource(e.target.value);}}
                placeholder="Select Data source">
                <option value="">--Select One</option>
                {(dataSources || []).map((source, index)=>{
                    return <option key={'option-index-' + index} value={source.id}>{source.name}</option>;
                })}
            </select>
        </div>
    </div>;
};
export const ReportSetup = ({reportItem, update}) =>{
    return <div className="row">
        <div className="col-md-4">
            <label>Analyses Name</label>
        </div>
        <div className="col-md-8">
            <input type="text" className={'form-control'} onChange={(e)=>update('report_name', e.target.value)} value={reportItem.report_name}/>
        </div>
    </div>;
};
export const ReportFormatting = ({reportItem,update}) =>{
    return <>
        <div  className="row">
            <div className="col-md-4">
                Background Color
            </div>
            <div className="col-md-8">
                <select className={'form-control'} onChange={(e)=>update('background_color', e.target.value)}   value={reportItem.background_color}>
                    <option>--Select one</option>
                    <option value={'white'}>White</option>
                    <option value={'black'}>Black</option>
                    <option value={'grey'}>Grey</option>
                    <option value={'purple'}>Purple</option>
                    <option value={'red'}>Red</option>
                    <option value={'green'}>Green</option>
                    <option value={'blue'}>Blue</option>
                </select>
            </div>
        </div>
        <br/>
        <div  className="row">
            <div className="col-md-4">Label Color</div>
            <div className="col-md-8">
                <select className={'form-control'} onChange={(e)=>update('label_color', e.target.value)}   value={reportItem.label_color}>
                    <option>--Select one</option>
                    <option value={'white'}>White</option>
                    <option value={'black'}>Black</option>
                    <option value={'grey'}>Grey</option>
                    <option value={'purple'}>Purple</option>
                    <option value={'red'}>Red</option>
                    <option value={'green'}>Green</option>
                    <option value={'blue'}>Blue</option>
                </select>
            </div>
        </div>
    </>;
};
export const BarFields = ({reportItem, update})=>{
    return <div>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Labels (text)</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.name_field}
                    options={(reportItem.added_columns || [])}
                    onChange={async (e) => { await update('name_field', e.value);}}
                    placeholder="Select Y Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Values (numbers)</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.value_field}
                    options={reportItem.added_columns}
                    onChange={async (e) => { await update('value_field', e.value);}}
                    placeholder="Select X Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Limit</label>
            </div>
            <div className="col-md-8">
                <input type="number" className={'form-control'} onChange={(e)=>update('report_limit', e.target.value)} value={reportItem.report_limit}/>

            </div>
        </div>
    </div>;
};
export const ScatterFields = ({reportItem, update}) =>{
    return <div>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Y Axis</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.name_field}
                    options={(reportItem.added_columns || [])}
                    onChange={async (e) => { await update('name_field', e.value);}}
                    placeholder="Select Y Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">X Axis</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.value_field}
                    options={reportItem.added_columns}
                    onChange={async (e) => { await update('value_field', e.value);}}
                    placeholder="Select X Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Z Axis</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.z_axis_field}
                    options={reportItem.added_columns}
                    onChange={async (e) => { await update('z_axis_field', e.value);}}
                    placeholder="Select Z Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Limit</label>
            </div>
            <div className="col-md-8">
                <input type="number" className={'form-control'} onChange={(e)=>update('report_limit', e.target.value)} value={reportItem.report_limit}/>

            </div>
        </div>
    </div>;
};
export const LineFields = ({reportItem, update}) =>{
    return <div>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Horizontal Label</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.name_field}
                    options={(reportItem.added_columns || [])}
                    onChange={async (e) => { await update('name_field', e.value);}}
                    placeholder="Select Y Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Vertical Label</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.value_field}
                    options={reportItem.added_columns}
                    onChange={async (e) => { await update('value_field', e.value);}}
                    placeholder="Select X Axis"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Limit</label>
            </div>
            <div className="col-md-8">
                <input type="number" className={'form-control'} onChange={(e)=>update('report_limit', e.target.value)} value={reportItem.report_limit}/>

            </div>
        </div>
    </div>;
};
export const PieFields= ({update,reportItem})=>{
    return <div>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Label Field (text)</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.name_field}
                    options={(reportItem.added_columns || [])}
                    onChange={async (e) => { await update('name_field', e.value);}}
                    placeholder="Select Label Field"/>

            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Value Field (number)</label>
            </div>
            <div className="col-md-8">
                <Dropdown
                    className={'form-control'}
                    optionLabel="field_name"
                    value={reportItem.value_field}
                    options={reportItem.added_columns}
                    onChange={async (e) => { await update('value_field', e.value);}}
                    placeholder="Select Value Field"/>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-md-4">
                <label htmlFor="label_field">Limit</label>
            </div>
            <div className="col-md-8">
                <input type="number" className={'form-control'} onChange={(e)=>update('report_limit', e.target.value)} value={reportItem.report_limit}/>

            </div>
        </div>
    </div>;
};
export function ManagedReportRunner ({report,data,added_columns}){
    return (<div className={'dashboard-report-inner'}>
        <ReportView   report={report} data={data} added_columns={added_columns}/>
    </div>);
}
export function ReportRunner({report,data,added_columns}){
    return (<div className={'dashboard-report-inner'}>
        <ReportView   report={report} data={data} added_columns={added_columns}/>
    </div>);
}
export function ReportView({report,data,added_columns}){
    const chartClick =(data)=>{
        let filter = {};
        filter[report.name_field.field_code] = data.name;
        let local_state = {route_params:{filter:filter}};
        ObjContainer.resolve('nav').navTo(`${report.data_source.source_table}-${report.data_source.source_table}`, local_state);
    };
    return (
        <div className={'report-module'} >
            <div className={'report-wrapper'}>
                <div className={'report-container'}>
                    <div className={'report-header'}>
                        <div className={'report-title'}>
                            {report.report_name}
                        </div>
                    </div>
                    <div className="report-body" style={{padding:'0',width:'99%',backgroundColor: report.background_color || 'white'}} >
                        <div className="card-chart-wrapper"  style={{padding:'0',width:'99%',height:'300px'}}>

                            <RenderVisualReport report={report} data={data} chartClick={chartClick} added_columns={added_columns}/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export function RenderVisualReport({report, data,chartClick,added_columns}){
    if(data.length === 0) {return <div>Loading...</div>;}
    switch(report.visual_type){
        case 'pie':
            return <RechartsPie  type={report.value_field?.format_type}
                data={data}
                label_field={report.name_field}
                label_color={report.label_color}
                value_field={report.value_field}
                color={report.color_pallet}
                width={report.width}
                height={report.height}
                report_size={report.report_size}
                onClick={(data)=>{chartClick(data);}}/>;
        case 'line':
            return <RechartLine  type={report.value_field?.format_type}
                data={data}
                label_field={report.name_field}
                label_color={report.label_color}
                value_field={report.value_field}
                dom_min={report.dom_min}
                dom_max={report.dom_max}
                color={report.color_pallet}
                width={report.width}
                height={report.height}
                report_size={report.report_size}
                onClick={(data)=>{chartClick(data);}}/>;
        case 'bar':
            return <RechartBar type={(report.value_field || {}).format_type}
                data={data}
                label_color={report.label_color}
                label_field={report.name_field}
                value_field={report.value_field}
                dom_min={report.dom_min}
                dom_max={report.dom_max}
                color={report.color_pallet}
                width={report.width}
                height={report.height}
                report_size={report.report_size}
                onClick={(data)=>{chartClick(data);}}/>;

        default:
            return <ReportTable
                report={report}
                added_columns={added_columns}
                data={data }
            />;
    }
}
export function ReportTable({report,added_columns,data}){

    let formatter = (value, type_name) =>{
        if(isNaN(value) || (type_name !== 'string_money' && type_name !== 'decimal_money') )
        {return value;}
        return '$' + (value * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    };
    return (<div className={'table-report-container'}>
        {(report.filters || []).map((item, index)=>{
            return (<div className={'row'} key={'report_filter_'  + index}>
                <div className={'col-md-12'}>
                    <label key={'header-field-' + index}>{item.field_name}</label>
                    <input type={'text'} className={'form-control'} />
                </div>
            </div>);
        })}
        <table className={'table table-striped report-table'}>
            <thead>
                <tr>
                    {(added_columns || []).map((item, index)=>{
                        return <th key={'header-field-' + index}>{item.field_name}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {(data || []).slice(0,10).map((item, index)=>{
                    return (<tr key={'Report-table-header-' + index}>
                        {(added_columns || []).map((col, col_i)=>{
                            return <td key={'field_code_value_' + col_i}>{formatter(item[col.field_code], col.format_type)}</td>;
                        })}

                    </tr>);
                })}
            </tbody>
        </table>

    </div>);

}
