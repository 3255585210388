import { useState } from 'react';
import moment from 'moment';

const useBetweenDatesForm = (preText = 'Show data between dates') => {
    const [fromDate, setFromDate] = useState(moment().dayOfYear(1));
    const [toDate, setToDate] = useState(moment());
    const [presetValue, setPresetValue] = useState('This Year');

    const defaultValues = [
        {
            label: 'This Month',
            dates: () => {
                return {
                    from: moment().startOf('month'),
                    to: moment(),
                };
            },
        },
        {
            label: 'Last Month',
            dates: () => {
                return {
                    from: moment().subtract(1, 'months').startOf('month'),
                    to: moment().startOf('month').subtract(1, 'days'),
                };
            },
        },
        {
            label: 'This Year',
            dates: () => {
                return {
                    from: moment().startOf('year'),
                    to: moment(),
                };
            },
        },
        {
            label: 'Last Year',
            dates: () => {
                return {
                    from: moment().subtract(1, 'years').startOf('year'),
                    to: moment().startOf('year').subtract(1, 'days'),
                };
            },
        },
    ];

    const onUpdateFrom = (e) => {
        setFromDate(moment(e.target.value));
        setPresetValue('');
    };

    const onUpdateTo = (e) => {
        setToDate(moment(e.target.value));
        setPresetValue('');
    };

    const onChoosePreset = (e) => {
        setPresetValue(e.target.value);
        const dates = defaultValues.find((obj) => obj.label === e.target.value).dates();
        setFromDate(dates.from);
        setToDate(dates.to);
    };

    const form = (
        <form>
            <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <p>
                    {preText}{' '}
                    <input
                        type={'date'}
                        name={'fromDate'}
                        value={fromDate.format('YYYY-MM-DD')}
                        onChange={onUpdateFrom}
                    />{' '}
                    and{' '}
                    <input type={'date'} name={'toDate'} value={toDate.format('YYYY-MM-DD')} onChange={onUpdateTo} />.
                </p>
                <div>
                    <select
                        name={'presets'}
                        value={presetValue}
                        placeholder={'Choose a preset.'}
                        onChange={onChoosePreset}
                    >
                        <option key={'blank'} value={''} disabled={true}>
                            or choose a preset range...
                        </option>
                        {defaultValues.map((obj) => {
                            return (
                                <option key={obj.label} value={obj.label}>
                                    {obj.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </form>
    );

    return [
        form,
        {
            fromDate: fromDate,
            toDate: toDate,
        },
    ];
};

export default useBetweenDatesForm;
