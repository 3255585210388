import React,{useEffect,useState} from 'react';
import {PurchaseOrderProductTableItem} from './PurchaseOrderProductTableItem';
import useSearch from '../../../services/hooks/useSearch';
import useDelete from '../../../services/hooks/useDelete';
const hookContext = {
    entity:'purchase_order_items',
    expands:{'products':'product_id',product_variations:'product_variant_id'}
};

export function PurchaseOrderProductsTables({purchaseOrder}){
    const [curPurchaseOrderVariants, setCurPurchaseOrderVariants] = useState([]);
    const [curPurchaseOrder, setCurPurchaseOrder] = useState({});

    const {data:purchaseOrderVariants}  = useSearch({...hookContext,filter_eq:{purchase_order_id:curPurchaseOrder.id}});
    useEffect(()=>{
        if(purchaseOrderVariants && purchaseOrderVariants.data){
            let items  = purchaseOrderVariants.data.reduce((arr,item)=>{
                let src = arr.find(i=>i.product_id === item.product_id && i.product_variant_id === item.product_variant_id);
                if(!src){
                    arr.push({...item,sizes:[item]});
                }else{
                    src.sizes.push(item);
                }
                return arr;
            },[]);
            setCurPurchaseOrderVariants(items);
        }
        if(purchaseOrder){
            setCurPurchaseOrder(purchaseOrder);
        }
    },[purchaseOrderVariants,purchaseOrder]);
    const [deleteMutate] = useDelete({...hookContext});
    const deletePurchaseOrderProduct = async(variant) =>{
        await deleteMutate({product_id: variant.product_id, product_variant_id: variant.product_variant_id});
    };
    return (<div key={'product-row-'}>
        <table className={'table table-striped valerian-table'}>
            <thead>
                <tr>
                    <th style={{width:'50px'}}></th>
                    <th style={{width:'30%'}}>Print</th>
                    <th style={{width:'15%'}}>Ordered</th>
                    <th  style={{width:'35%'}}>Additional</th>
                    <th  style={{width:'15%'}}>Total</th>
                </tr>
            </thead>
            <tbody>
                {curPurchaseOrderVariants.map((variant)=> {
                    return (<PurchaseOrderProductTableItem
                        key={'purchase_order_product_' + variant.id}
                        purchaseOrder={curPurchaseOrder}
                        variant={variant}
                        delete={deletePurchaseOrderProduct}/>);
                })}
            </tbody>
        </table>
    </div>);

}