import {useMutation, useQueryCache} from 'react-query';
import AxiosClient from './AxiosClient';

import { useAuth } from './authTokenContext';
import constants from './constants';

const { api } = constants;
const { basePath } = api;

const Delete = async (payload, context) => {
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity
    } = context;

    try {
        const pathname = `${basePath}${entity}/${payload.id}`;
        const results = await AxiosClient.delete(
            pathname,
            config
        );
        const { data } = results;

        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble adding ${entity}.`);
    }
};

const useDelete = (context) => {
    const auth = useAuth();
    const queryCache = useQueryCache();
    const { entity = 'UNK', invalidateQuery } = context;
    const key = `delete-${entity}`;

    return useMutation(
        async (variables) => await Delete(variables, { ...auth, ...context }),
        {
            mutationKey: key,
            onSuccess: () => { 
                if (invalidateQuery) {
                    queryCache.invalidateQueries(invalidateQuery);
                }
                queryCache.invalidateQueries(`search-${context.entity}`);
            }
        }
    );
};

export default useDelete;
