import moment from 'moment';
import useBetweenDatesForm from '../../../services/hooks/useBetweenDatesForm';

import Report from '../Report';
import  {SalesForecastingSummary} from './SalesForecastingSummary';
import useSalesForecastingSearch from "../../../services/hooks/useSalesForecastingSearch";

const queryFn = ({
                     fromDate = moment().dayOfYear(1),
                     toDate = moment(),
                     soFromDate = moment().dayOfYear(1),
                     soToDate = moment(),
                     productCode='',
                     variantCode=''}) => {
    return `select 
            products.style_number, products.description,
            pv.variation_code, 
            pv.variation_name, 
            ii."size", 
            coalesce(sum(ii.qty),0) as invoice_count,
            coalesce((select qty_inventory 
                from inventory i2 where i2.product_id = products.id 
                and i2.product_variant_id = pv.id and ii."size" = i2."size" limit 1),0) as inventory_count,
            coalesce((select sum(os.qty) 
                from order_items os 
                join orders on os.order_id  = orders.id 
                where os.product_id = products.id 
                    and os.product_variant_id = pv.id and ii."size" = os."size" and orders."date_start" between 
                    '${soFromDate.format('YYYY/MM/DD')}' and 
                    '${soToDate.format('YYYY/MM/DD')}'),0) as so_count,
            coalesce((select sum(os.qty_open) 
                from order_items os 
                join orders on os.order_id  = orders.id 
                where os.product_id = products.id 
                    and os.product_variant_id = pv.id and ii."size" = os."size" and os.qty_open > 0),0) as open_orders
        from invoices i 
        join invoice_items ii 
        on i.id = ii.invoice_id 
        join products on ii.product_id  = products.id 
        left outer join sizes  on products.size_range = sizes.size_range and ii.size = sizes.code
        join product_variations pv on ii.product_variant_id = pv.id
        where i.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format('YYYY/MM/DD')}' and ('${productCode}'='' 
            or '${productCode}'=products.style_number) and ('${variantCode}'='' or '${variantCode}'=pv.variation_code)
        group by 
            products.id,
            pv.id,
            products.style_number, 
            products.description,
            pv.variation_code, 
            pv.variation_name, 
            ii."size",
            sizes.order_number
        order by products.id,pv.id, sizes.order_number limit 200;`
};

export const SalesForecasting = {
    title: 'Sales Forecasting',
    queryFormHook: useSalesForecastingSearch,
    queryFormText: 'Show data from invoices between',
    queryFn: queryFn,
    columns: [
        {
            dataField: 'style_number',
            caption: 'Style',
            dataType: 'string',
            groupIndex: 0,
        },
        {
            dataField: 'description',
            caption: 'Description',
            dataType: 'string'
        },
        {
            dataField: 'variation_code',
            caption: 'Variation',
            dataType: 'string'
        },
        {
            dataField: 'variation_name',
            caption: 'Variation Code',
            dataType: 'string',
        },
        {
            dataField: 'size',
            caption: 'Size',
            dataType: 'string',
        },
        {
            dataField: 'invoice_count',
            caption: 'Invoice Count',
            dataType: 'number',
        },
        {
            dataField: 'inventory_count',
            caption: 'Current Inventory Count',
            dataType: 'number',
        },
        {
            dataField: 'so_count',
            caption: 'Ordered Count',
            dataType: 'number',
        },
        {
            dataField: 'open_orders',
            caption: 'Open Order Count',
            dataType: 'number',
        },
    ],
    summaryItems: [
    ]
};