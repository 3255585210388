import {ObjContainer} from '../../services/object_container';
import React, {Component, useEffect, useState} from 'react';
import {useQuery} from 'react-query';

export const LeftNav = () => {
    let navTo = (link, e) => {
        return ObjContainer.resolve('nav').navTo(link, {}, e);
    };
    const [nav, setNav] = useState([]);
    const {
        status,
        data,
        error,
    } = useQuery(['nav_items', 1],async ()=>{
        return ObjContainer.resolve('service_manager').resolve('scoped_nav').scopedNavGet('/site_operations/nav');
    }, {});
    useEffect(() => {
        if(data && data.data)
        {setNav(data.data.data);}
    }, [data]);
    if (status === 'loading') {
        return <span>Loading...</span>;
    }
    if (status === 'error') {
        return <span>Error: {error.message}</span>;
    }
    return (<>
        <div  className={'menu-layout-static'} style={{}}>
            <div className="menu-scroll-content">
                <div className="layout-menu-container">
                    <ul className="layout-menu layout-main-menu clearfix">
                        {(nav || []).filter(i => i.in_menu && i.in_menu.display === 'true').map((item, index) => {
                            return (
                                <li key={'nav-item-' + index}>
                                    <a data-target={'#data_item' + index} className={'collapsed' }
                                        data-toggle="collapse" aria-expanded="true" aria-controls={'data_item' + index}>
                                        <i className={(item ? item.icon : '')}/>
                                        <span> {item.display}</span>
                                        <i className="fa fa-fw fa-angle-down menuitem-toggle-icon pull-right" />
                                    </a>
                                    <ul className="layout-submenu collapse" id={'data_item' + index}>
                                        {(item.children || []).map((child, child_index) => {
                                            return (
                                                <li key={'nav-item-' + index + '-child-' + child_index}>
                                                    <a className={'nav-link'} onClick={(e) => {
                                                        navTo(child.link, e);
                                                        return false;
                                                    }}><i className={(child ? child.icon : '')}/>{child.display}</a>
                                                </li>);
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    </>);
};
