import React, {Component} from "react";

export class VDropdown extends Component{
    constructor(props) {
        super(props);
        this.state={open:false}
    }

    render() {

        return ( <div>
            <button className={this.props.className || "btn btn-primary"} onClick={() => this.setState({open:!this.state.open})}>
                {this.props.title}
            </button>
            {this.state.open &&
            <div style={{position:"relative", zIndex:"5000"}}>
                <div style={{display:"block",position:"absolute", backgroundColor:"white", height:"125px", overflow:"auto", zIndex:5000, border:"1px solid silver", padding:"3px", cornerRadius:"5px"}}>
                    {this.props.items.map((item,j)=>{
                        return <div key={"mapping-options-" + j} style={{padding:"5px 5px 2px 2px"}}><a key={"menu-item-" + j} href={"#"} onClick={(e)=>{
                            this.props.onClick(item);
                            this.setState({open:false});
                        }}>{this.props.simple ? item : item[this.props.display_field]}</a></div>
                    })}
                </div>
            </div>
            }
        </div>);
    }
}
