import React, {useEffect, useState} from 'react';
import useSearch from '../../services/hooks/useSearch';
const hookTagDesignContext = {
    entity:'tag_manager_tag_designs'
};
export const TagDesignList = ({setShowDesigner,editDesign}) =>{
    const [designs, setDesigns] = useState([]);

    const { data:tag_design_data } = useSearch({
        ...hookTagDesignContext
    });
    useEffect(()=>{
        if(tag_design_data && tag_design_data.data){
            setDesigns(tag_design_data.data);
        }
    },[tag_design_data]);

    return <div>
        <button className={'btn btn-primary'} onClick={() =>setShowDesigner(true)}>Add</button>
        <table className={'table table-striped valerian-table'}>
            <thead>
                <tr><th>Design Name</th><th>Height</th><th>Width</th><th>Tags Per Row</th><th></th></tr>
            </thead>
            <tbody>
                {designs.map((item, index)=>{
                    return <tr key={'tag-design-' + index}>
                        <td><a className={"inline-nav-link"} onClick={()=>editDesign(item)}>{item.tag_design_name}</a></td>
                        <td>{item.tag_height}</td>
                        <td>{item.tag_width}</td>
                        <td>{item.tags_per_row}</td>
                        <td></td>
                    </tr>;
                })}
            </tbody>
        </table>
    </div>;
};