import {useAuth} from './authTokenContext';
import constants from './constants';
const { api } = constants;
const { basePath, options } = api;
const downloadDocument = async (context) => {
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity,
        operation,
        file_name,params,
    } = context;

    try {
        let paramStings = [];
        for(let param in params){
            paramStings.push(`${param}=${params[param]}`);
        }
        const pathname = `${basePath}${entity}/${operation}?${paramStings.join('&')}`;
        let oReq = new XMLHttpRequest();
        oReq.open('GET', pathname, true);
        oReq.responseType = 'arraybuffer';
        oReq.setRequestHeader('Authorization',config.headers['Authorization']);
        oReq.onload = function(oEvent) {
            let arrayBuffer = oReq.response;
            let byteArray = new Uint8Array(arrayBuffer);
            let blob = new Blob([arrayBuffer], {});
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download =file_name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        };
        oReq.send();

    } catch (err) {
        console.log(err.message);
        throw new Error('Trouble retrieving document.');
    }
};

const downloadPostDocument = async (context) => {
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity,
        operation,
        file_name,params,body,
    } = context;

    try {
        let paramStings = [];

        const pathname = `${basePath}${entity}/${operation}?${paramStings.join('&')}`;
        let oReq = new XMLHttpRequest();
        oReq.open('POST', pathname, true);
        oReq.responseType = 'arraybuffer';
        oReq.setRequestHeader('Authorization',config.headers['Authorization']);
        oReq.setRequestHeader('Content-Type','application/json');
        oReq.onload = function(oEvent) {
            let arrayBuffer = oReq.response;
            let byteArray = new Uint8Array(arrayBuffer);
            let blob = new Blob([arrayBuffer], {});
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download =file_name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        };
        oReq.send(JSON.stringify(body));

    } catch (err) {
        console.log(err.message);
        throw new Error('Trouble retrieving document.');
    }
};
export const useDownloadPostDocument = (context) =>{
    const auth = useAuth();
    return {download: async (args)=>{
            await downloadPostDocument({...context,...args,...auth});
        }};
};

export const useDownloadDocument = (context) =>{
    const auth = useAuth();
    return {download: async (args)=>{
        await downloadDocument({...context,...args,...auth});
    }};
};