import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import React,{useEffect, useState} from 'react';
import useSearch from '../../../services/hooks/useSearch';
import useNav from '../../../services/hooks/useNav';
const productHookContext = {
    entity: 'product_variations',
    expands:{'products':'product_id'}
};
 
export const ProductList = () =>{
    const { data:products } = useSearch({
        ...productHookContext
    });
    const {navTo} = useNav(productHookContext);
    const [curProducts, setCurProducts] = useState([]);
    useEffect(()=>{
        if(products && products.data){
            setCurProducts(products.data);
        }
    },[products]);
    const addProduct = ()=>{

    };
    const descriptionTemplate = (rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => navTo('product_variations-Viewproduct_variations',{id:rowData.product_id})}>{rowData.products.description}</a>
            </>
        );
    };
    const skuTemplate  = (rowData) =>{
        return (
            <>
                {rowData.sku}
            </>
        );
    };
    return <div>
        <h2>Products</h2>
        <div>
            <div  className={'valerian-search'}>
                <button className={'btn btn-primary'} onClick={() =>addProduct()}>Add Product</button>
            </div>
            <DataTable  className="p-datatable-customers" value={curProducts}
                header={''}  dataKey="id" rowHover
                paginator rows={10} emptyMessage="No reports found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                <Column field="style_number" header="Code"  sortable filter  />
                <Column field="description" header="Description"  body={descriptionTemplate} sortable filter  />
                <Column field="variation_name" header="Name"  sortable filter  />
                <Column field="variation_code" header="Variation Code"  sortable filter  />
                <Column field="sku" header="SKU" body={skuTemplate}   sortable filter filterPlaceholder="Search Connection" />
            </DataTable>

        </div>
    </div>;
};