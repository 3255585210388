import React, { Fragment, useState, useEffect } from 'react';
import useTableData from '../../../services/hooks/useTableData';
import classes from './InventoryManager.module.scss';

import PageTitle from '../../CommonUI/PageTitle';
import MasterDetailView from '../../CommonUI/MasterDetailView';
import TypeAheadSearch from '../../CommonUI/TypeAheadSearch';
import VariantTable from './VariantTable';
import TabbedView from '../../CommonUI/TabbedView/TabbedView';

import VariantInventoryTable from './VariantInventoryTable';
import VariantDetails from './VariantDetails';
import { VariantHistory } from './VariantHistory';
import { VariantInvoices } from './VariantInvoices';

export const InventoryManager = () => {
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedVariant, setSelectedVariant] = useState(null);

    const mapRowWithStringDescription = product => {
        return {
            ...product,
            key: product.id,
            stringDescription: `${product.style_number} - ${product.description}`,
        }
    }
    const onProductPicked = (selection) => {
        if (selection !== null) {
            setSelectedProduct(selection);
            setSelectedVariant(null);
        }
    };

    const mapVariant = (variant) => {
        return {
            id: variant.id,
            key: [variant.id, variant.variation_code].join('-'),
            archived: variant.archived,
            code: variant.variation_code,
            variant_description: variant.variation_name,
            sku: variant.sku,
        };
    };
    const [archived, setArchived] = useState('false');
    const [variants, setVariants] = useTableData({
        hookContext: {
            entity: 'product_variations',
            filter_eq: { product_id: selectedProduct.id },
            filter: { archived: { eq: archived } },
            paging: { pageSize: 1000 },
            sort: { sku: 'asc' },
        },
        dependencies: [selectedProduct],
        mapCallback: mapVariant,
    });
    const updateFilter = (event) => {
        if (event.target.name === 'showArchived') {
            setArchived(event.target.checked.toString());
        }
    };
    const [isInAddVariantMode, setAddVariantMode] = useState(false);
    const [forcedActiveTabTitle, setForcedActiveTabTitle] = useState(null);
    const onEnterAddVariantMode = () => {
        setForcedActiveTabTitle('Details');
        setSelectedVariant(null);
        setAddVariantMode(true);
    };

    const onVariantUpdated = (updatedVariant) => {
        setForcedActiveTabTitle(null);
        setSelectedProduct({ ...selectedProduct });
        if (updatedVariant) {setSelectedVariant(mapVariant(updatedVariant));}
        setAddVariantMode(false);

    };
    const updateSelectedVariant = (variant) =>{
        setSelectedVariant({...variant});
        setForcedActiveTabTitle(forcedActiveTabTitle);
    }
    const masterView = () => (
        <div className={classes.searchAndContentGrid}>
            <div className={classes.searchArea}>
                <TypeAheadSearch
                    label='Products'
                    tableName='products'
                    mapRowWithStringDescription={mapRowWithStringDescription}
                    searchColumnNames={['description', 'style_number']}
                    onItemPicked={onProductPicked}
                />
            </div>

            <div className={classes.contentArea}>
                <VariantTable
                    selectedProduct={selectedProduct}
                    variantArray={selectedProduct.id ? variants : []}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={updateSelectedVariant}
                    onEnterAddMode={onEnterAddVariantMode}
                    onFilter={updateFilter}
                />
            </div>
        </div>
    );

   let tabs = [
        {
            title: 'Details',
            view: (
                <VariantDetails
                    isInAddMode={isInAddVariantMode}
                    selectedProduct={selectedProduct}
                    selectedVariant={selectedVariant}
                    onUpdate={onVariantUpdated}
                />
            ),
            disabled: false,
        },
        {
            title: 'Inventory',
            view: selectedVariant && (
                <VariantInventoryTable selectedProduct={selectedProduct} selectedVariant={selectedVariant} />
            ),
            disabled: [null, undefined].includes(selectedVariant?.id),
        },
        {
            title: 'Adjustments',
            view: selectedVariant && <VariantHistory productVariantId={selectedVariant?.id} />,
            disabled: [null, undefined].includes(selectedVariant?.id),
        },
        {
            title: 'Invoices',
            view: selectedVariant && <VariantInvoices productVariantId={selectedVariant?.id} />,
            disabled: [null, undefined].includes(selectedVariant?.id),
        },
    ];

    const detailView = () => (
        <div className={classes.searchAndContentGrid}>
            <div className={classes.searchArea}></div>
            <div className={classes.tabbedView}>
                <TabbedView tabs={tabs} forcedActiveTabTitle={forcedActiveTabTitle} />
            </div>
        </div>
    );

    return (
        <div className={'h-100'}>
            <div className={classes.tableWrapper }>
                <div className={classes.titleArea}>
                    <PageTitle>Inventory Manager</PageTitle>
                </div>
                <div className={classes.mainArea}>
                    <MasterDetailView className={classes.stretchView} data={[selectedVariant, selectedProduct]} master={masterView()} detail={detailView()} />
                </div>
            </div>
        </div>

    );
};
