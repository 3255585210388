import * as ReactHighcharts from 'react-highcharts';
import React from 'react';

export const ChartViewer = ({reportDesign}) =>{
    console.log("report design", reportDesign);
    if(!reportDesign || !reportDesign.fields ||  reportDesign.fields?.length < 2)
        return <div>Select the dependent variable.</div>;
    let field_a = reportDesign.fields[0];
    let field_b =  reportDesign.fields[1];

    return <ReactHighcharts config={{
        chart: {
            type: 'line',
            width: reportDesign.size.width,
            height: reportDesign.size.height - 50,
        },
        title: {
            text: (reportDesign.title || 'Title')
        },
        yAxis: {
            title: {
                text: 'Score'
            }
        },
        xAxis: {
            accessibility: {
                rangeDescription: 'Range: 2021 School Year'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2021
            }
        },
        series: [{
            name: 'Performance',
            data: [['1/1/2021', 1],['1/1/2022', 10]]
        }]
    }}  />;
};