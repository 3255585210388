import React, {Component} from 'react';
import {Carousel} from "primereact/carousel";
import {ObjContainer} from "../../services/object_container";
import {DerivedDropDownList, DerivedSearch} from "../FormDriver/FormComponents";
import {useQuery} from "react-query";
import {InventoryServices} from "./InventoryServices";
const inventoryService = new InventoryServices();
export  const InventoryTable = ()=>{

    const {data:product_data}  =  useQuery(["products"],inventoryService.searchProducts);
    const [products, setProducts] = React.useState([]);
    const [searchProducts, setSearchProducts] = React.useState([]);
    const [productVariants, setProductVariants] = React.useState([]);
    const [product, setProduct] = React.useState({});
    const {data:inventory_data}  =  useQuery(["inventory",product.id],inventoryService.searchInventory);
    const {status,data:sizes_data,error} = useQuery(['sizes',product.size_range],inventoryService.getSizes, {});
    const [productVariant, setProductVariant] = React.useState({});
    const [inventory,setInventory] = React.useState([]);
    const [sizes,setSizes] = React.useState([]);
    React.useEffect(() => {
        if (product_data) {
            product_data.forEach((product)=>{
               product.display =  `${product.style_number} - ${product.description}`
            });
            setProducts(product_data);
            setSearchProducts(product_data);
        } else {
            setProducts([]);
        }
    }, [product_data]);
    React.useEffect(() => {
        if (sizes_data) {
            setSizes(sizes_data);
        } else {
            setSizes([]);
        }
    }, [sizes_data]);
    React.useEffect(() => {
        if (inventory_data) {
            setInventory(inventory_data);
        } else {
            setInventory([]);
        }
    }, [inventory_data]);
     const updateFilter = async (val)=>{
         let searchProducts = products.filter((product)=>{
             return (product.style_number || "").indexOf(val) > -1 || (product.description || "").indexOf(val) > -1;
         });
         product.display = val;
         setProduct({...product});
         setSearchProducts(searchProducts);
    };
    const  getInventory = async (variant) => {};

    const  getStyles = async (product) =>{
        setProduct(product);
        product.product_variations.sort((a,b)=>{
            if(a.variation_code < b.variation_code)
                return -1;
            if(a.variation_code > b.variation_code)
                return 1;
            return 0;
        }).forEach((variation)=>{
            variation.display = `${variation.variation_code} - ${variation.variation_name}`;
        });
        setProductVariants(product.product_variations);
    };
    const  updateStyleFilter = async (val)=>{
        if(product && product.product_variations)
            setProductVariants(product.product_variations);

    };
    const navTo = (route, params) =>{
        let local_state = {route_params:{filter:{id:params.id}},parent_id:params.id, parent_data:
                Object.assign({},params,{back_state:ObjContainer.resolve("nav").getState()})};
        ObjContainer.resolve("nav").navTo(route, local_state);
    };

    return (
        <div>
            <div>
                <h2 className={"page_title"}>Inventory</h2>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <label>Style</label>
                </div>
                <div className="col-md-10">
                    <DerivedSearch
                        onChange={async (field, val)=> await updateFilter(val) }
                        onClick={async (field, val, item)=> await getStyles(item) }
                        title={"Product Code"}
                        items={searchProducts}
                        value={product.display}
                        field={{reference: {relation_table: "products", field_code: "id", display_field_code:"display",is_multi:false }}}/>
                </div>
            </div>
            <div className="row">

                <div className="col-md-2">
                    <label>Print</label>
                </div>
                <div className="col-md-10">
                    <DerivedSearch
                        onChange={async (field, val)=> await updateStyleFilter(val) }
                        onClick={async (field, val, item)=> await getInventory(item.variant) }
                        title={"Style Number"}
                        items={productVariants || []}
                        value={productVariant.display}
                        field={{reference: {relation_table: "product_variations", field_code: "id", display_field_code:"display",is_multi:false }}}/>
                </div>
            </div>
            <div className={"container-fluid"}>
                <div className={"row"} style={{borderBottom:"2px solid #ccc", marginBottom:"10px"}}>
                    <div className="col-md-12">
                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Style</th>
                                    <th>Print</th>
                                    {sizes.map((size, index)=>{
                                        return <th key={"size-col-"+index}  style={{textAlign:"right"}}>{size.name}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {(productVariants.map((variant_item, index)=>{
                                    return  <tr key={"var_item_" + index} style={{borderBottom:"1px solid #dedede", marginBottom:"10px"}}>
                                        <td   >
                                            <a  className={"inline-nav-link"} onClick={()=> navTo("products-Viewproducts", product)}>{product.style_number}</a>
                                        </td>
                                        <td   >
                                            <a  className={"inline-nav-link"} onClick={()=> navTo("product_variations_manager", variant_item)}>{variant_item.variation_code} - {variant_item.variation_name}</a>
                                        </td>
                                        {sizes.map((size, index_f)=>{
                                            let i_item = inventory.find(it=>it.size === size.code && +it.product_variant_id === +variant_item.id);
                                            if(!i_item){
                                                return <td key={"var_item_" + index + "_field_" + index_f} style={{textAlign:"right"}} >{0}</td>
                                            }

                                            return <td key={"var_item_" + index + "_field_" + index_f} style={{textAlign:"right"}} >{i_item.qty_inventory || 0}</td>
                                        })}
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
