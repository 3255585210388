import {ViewLayer} from './ViewLayer';
import React from 'react';

export const ViewDetailsView = ({saveData, changeData, cancelEdit, onButtonPress, schema, obj}) =>{
    return (<div  className={'container-fluid'}>
        <div className="row">
            <div className={'col-md-12'} style={{overflow:'auto'}}>
                <ViewLayer schema_def={schema}
                    obj={obj}
                    ops={{onSave: async (group)=>{ return await saveData(group.group_title);},
                        onChange:     (field_code, value,data)=>{ changeData(field_code, value);},
                        onCancel:     (group)=>{ cancelEdit(group.group_title);},
                        onDefine:     ()=>{},
                        onButtonPress:(button) => onButtonPress(button) }}/>
            </div>

        </div>
    </div>);
};