import React, {useEffect, useRef, useState} from 'react';
import {useRoutePost} from '../../services/hooks/routes/useRoutePost';
import * as moment from 'moment';
import useNav from '../../services/hooks/useNav';
import * as ReactHighcharts from 'react-highcharts';

export const HomePageComponent = () =>{
    const [selectedRange, setSelectedRange] = useState('today');
    const [data, setData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [integrations, setIntegrations] = useState([]);
    const [totals, setTotals] = useState({});
    const [topSellers, setTopSellers] = useState([]);
    const [lowInventory, setLowInventory] = useState([]);
    const [realTrend, setRealTrend] = useState([]);
    const {navTo} = useNav();
    const {post} = useRoutePost({entity:'custom_reports',operation:'dashboard_data'});
    const getData = async (filter) =>{
        let res = await post({parameters:filter});
        if(res){
            setIntegrations(res.integrations);
            setTotals(res.totals);
            setData(res.dashboard_data);
            setLowInventory(res.low_inventory);
            setTopSellers(res.top_sellers);
            setSalesData(res.sales_trend);
            setRealTrend(res.real_trend);
        }
        return res;
    };
    const defaultFilter = () =>{
        return {start_date:{source:'date',exp:'gte',value:moment().add(-1,'days').format('YYYY-MM-DD')}};
    };
    useEffect(()=>{
        getData(defaultFilter()).then((res)=>{});
    },[]);

    let dataArray = salesData.map((item) => {
        return [ new Date(item.day).getTime(),+item.sales];
    });
    const selectRange = async (filter_type)=>{
        let filter = defaultFilter();
        const today = moment();

        switch(filter_type){
            case 'month':
                filter = {start_date:{source:'date',exp:'gte',value:today.startOf('month').format('YYYY-MM-DD')}};
                break;
            case 'p30':
                filter = {start_date:{source:'date',exp:'gte',value:today.add(-1,'month').format('YYYY-MM-DD')}};
                break;
            case 'week':
                filter = {start_date:{source:'date',exp:'gte',value:today.add(-1,'week').format('YYYY-MM-DD')}};
                break;
            case 'year':
                filter = {start_date:{source:'date',exp:'gte',value:today.startOf('year').format('YYYY-MM-DD')}};
                break;
            case 'pyear':
                filter = {start_date:{source:'date',exp:'gte',value:today.add(-1,'year').format('YYYY-MM-DD')}};
                break;
        }
        await getData(filter);
        setSelectedRange(filter_type);
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const dataRow = (int)=>{
        let item = data.find(i=>i.source===int.integration_type && i.location === int.integrationTag);
        if(item){
            return <div className={'data-view'}>{formatter.format(item.amount)} Sold: {item.qty}</div>;
        }
        return <div  className={'data-view'}>$0.00  Sold: 0</div>;
    };
    const config = {
        title: {
            text: 'Sales History'
        },
        subtitle: {
            text: 'Last 30 days Sales History'
        },
        yAxis: {
            title: {
                text: 'Items Sold'
            }
        },
        xAxis: {
            type:'datetime',
            accessibility: { rangeDescription: 'Previous 30 days' },
            labels: {
                staggerLines: 1,
                formatter: function() {
                    return moment(this.value).format('MM/DD/YYYY');
                }
            },
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'bottom'
        },
        plotOptions: {
            series: {label: { connectorAllowed: false}}
        },
        tooltip: {
            valueDecimals: 1,
            dateTimeLabelFormats:{
                day: '%m/%d/%y',
                month: '%m/%y',
                hour:'%m/%d/%y',
                millisecond: '%A, %b %e, %H:%M:%S.%L',
                minute:'%m/%d/%y',
                second:'%m/%d/%y',
                week: '%m/%d/%y',
                year: '%Y',
            }
        },
        dateFormat:'mm/dd/YYYY',
        series: [{
            name: 'Item Count',
            dateFormat:'mm/dd/YYYY',
            data: [...dataArray]
        }]
    };
    return (<div className={'container-fluid main-dashboard-content'}>
        <div className="row dashboard-header-row">
            <div className="col-md-6">
                <select name="" id="" className={'form-control'} onChange={(e)=>selectRange(e.target.value)}>
                    <option value="today">Today</option>
                    <option value="week">Week</option>
                    <option value="month">This Month</option>
                    <option value="year">This Year</option>
                    <option value="pyear">Rolling Year</option>
                    <option value="p30">Rolling Month</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="col-md-8">
                <div className="row">
                    {integrations.map((int, index)=>{
                        return <div className="dashboard-integration-area" key={'integration_area_' + index}>
                            <div className={'area-title'}>{int.integrationTag} - {int.integration_type}</div>
                            {dataRow(int)}
                        </div>;
                    })}
                    <div className="dashboard-integration-area">
                        <div className={'area-title'}>Total Sales</div>
                        <div  className={'data-view'}>{formatter.format(totals.amount) || '$0.00'} Sold: {totals.qty || 0}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col md-6 action-link-row" >
                        <a className={'inline-nav-link'} onClick={()=>navTo('invoices-invoices')}>Invoices</a> &nbsp;
                        <a className={'inline-nav-link'} onClick={()=>navTo('inventory_manager-inventory_manager')}>Inventory Manager</a> &nbsp;
                        <a className={'inline-nav-link'} onClick={()=>navTo('InvoiceSummaryReport')}>Sales Report</a> &nbsp;
                        <a className={'inline-nav-link'} onClick={()=>navTo('CurrentInventoryLevel')}>Inventory Report</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 detail-panel">
                        <div className={'container-header'}>Top Sellers</div>
                        <table  className={'table'}><thead><tr><th>Product</th><th>Slope</th><th>Intercept</th></tr></thead>
                            <tbody>
                                {realTrend.map((i, index)=>{
                                    return <tr key={'top_sellers_' + index}>
                                        <td>{i.style_number} {i.description}</td>
                                        <td></td>
                                        <td><Canvas slope={i.slope} intercept={i.intercept} width={50} height={15}/></td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-5 detail-panel">
                        <div className={'container-header'}>Low Inventory</div>
                        <table className={'table'}><thead><tr><th>Product</th><th>Size</th><th>Sold</th><th>Current Inventory</th></tr></thead>
                            <tbody>
                                {lowInventory.map((i, index)=>{
                                    return <tr key={'low_inventory_' + index}>
                                        <td>{i.style_number} - {i.variation_code} {i.variation_name}</td>
                                        <td>{i.size}</td>
                                        <td>{i.sold_qty}</td>
                                        <td>{i.qty_inventory}</td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className={'dashboard-right-col'}>

                    <ReactHighcharts config={config}  />
                </div>
            </div>
        </div>

    </div>);

};
const Canvas = (props) => {
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.fillStyle = '#EFEFEF'
        //context.fillRect(0, 0, context.canvas.width, context.canvas.height)
        //Our first draw


        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(props.width, props.height);
        context.stroke();

    }, [])
    return <canvas ref={canvasRef} {...props}/>
}