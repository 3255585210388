import React, {useEffect, useState} from 'react';
import {useGetOne} from '../../services/hooks/useGetOne';
import {useUpsert} from '../../services/hooks/useUpsert';
const systemDataHookContext = {
    entity: 'system_config'
};
export const SystemConfigComponent = ()=>{

    const {data:systemInfo_data} = useGetOne({...systemDataHookContext,key:'edit'});
    const [systemInfo, setSystemInfo]= useState({});
    const [updateMutate] = useUpsert({...systemDataHookContext,key:'edit',id:systemInfo.id});
    useEffect(()=>{
        if(systemInfo_data){
            setSystemInfo(systemInfo_data);
        }
    },[systemInfo_data]);

    const update = (attr, value)=>{
        let tmpInfo = {...systemInfo};
        tmpInfo[attr] = value;
        setSystemInfo(tmpInfo);
    };
    const save= async ()=>{
        await updateMutate(systemInfo);
    };
    return <div><h1>System Config</h1>
        <div className={'container-fluid'}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Company Name</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('company_name', e.target.value)} value={systemInfo.company_name || ''}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Logo Url</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('logo_url', e.target.value)} value={systemInfo.logo_url || ''}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Address 1</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('company_address_1', e.target.value)} value={systemInfo.company_address_1 || ''}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Address 2</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('company_address_2', e.target.value)} value={systemInfo.company_address_2 || ''}/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">City</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('city', e.target.value)} value={systemInfo.city || ''}/>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label htmlFor="">State (2 Letter):</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('state', e.target.value)} value={systemInfo.state || ''}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="">Zip</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('zip', e.target.value)} value={systemInfo.zip || ''}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Phone</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('phone', e.target.value)} value={systemInfo.phone || ''}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input className={'form-control'} type={'text'} onChange={(e)=>update('email', e.target.value)} value={systemInfo.email || ''}/>
                    </div>
                </div>
            </div>
            <button className={'btn btn-primary'} onClick={async ()=>await save()}>Save</button>
        </div>
    </div>;
};
