import React, { Component } from 'react';
import moment from 'moment';

export class CalendarMasterComponent extends Component{
    constructor(){
        super();
        this.state = {source_month: 1, source_day: 1, source_year: 1};
    }
    render(){
        let a_day = moment('01/01/2017');
        let week_items = [];
        let a_month = a_day.format('M');
        let has_weeks = true;
        while(has_weeks){
            week_items.push(<div style={{borderRight:"1px solid #f6f6f6",width:"841px"}}>
                {[0,1,2,3,4,5,6].map((day, index)=>{
                    if(+a_month !== +a_day.format('M')){
                        has_weeks = false;
                        return <div style={{float:"left",width:"120px", height:"90px",borderLeft:"1px solid #f6f6f6",borderBottom:"1px solid #f6f6f6"}} />;
                    } else if(+a_day.format('d') === day){
                        let display = a_day.format('D');
                        a_day = a_day.add(1, 'day');
                        return (<div  style={{float:"left",width:"120px", height:"90px",borderLeft:"1px solid #f6f6f6",borderBottom:"1px solid #f6f6f6"}} >{display}</div>);
                    }else{
                        return <div  style={{float:"left",width:"120px", height:"90px",borderLeft:"1px solid #f6f6f6",borderBottom:"1px solid #f6f6f6"}} />;
                    }
                })}
                <div style={{clear:"both"}}/>
            </div>);
        }

        return (
            <div>
                <div>
                    <div style={{}}>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Sunday</div>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Monday</div>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Tuesday</div>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Wednesday</div>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Thursday</div>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Friday</div>
                        <div style={{float:"left",width:"120px",borderBottom: "1px solid #f6f6f6"}}>Saturday</div>
                        <div style={{clear:"both"}}/>
                    </div>

                    {week_items}

                </div>
            </div>
        );
    }


}