import {ObjContainer} from "../../services/object_container";
import {FilterService} from "../../services/filter_service";

export class InventoryServices{
    async searchProducts(filter){
        let filter_svc = new FilterService(ObjContainer.resolve("service_manager").resolve("products"));
        let data = await filter_svc.all().includeMulti({"product_variations":"product_id"}).toList();
        return data.data;

    }
    async searchInventory(key, product_id){
        if(!product_id) return [];
        let filter_svc = new FilterService(ObjContainer.resolve("service_manager").resolve("inventory"));
        let data = await filter_svc.context({product_id: product_id})
            .andWhere(i => i.product_id === product_id).include({"product_variations":"product_variant_id"}).all().toList();
        return data.data;

    }
    async getSizes(key, size_range){
        let filter_svc = new FilterService(ObjContainer.resolve("service_manager").resolve("sizes"));
        let data = await filter_svc.context({size_range:size_range}).andWhere(i=> i.size_range === size_range).orderBy({order_number:"asc"}).all().toList();
        return data.data;
    }
}
