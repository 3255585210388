import classes from './Button.module.scss';
import ButtonType from './ButtonType';

const Button = ({ label, action, type = ButtonType.button, customStyle={}}) => {
    return (
        <button className={getButtonClass(type)} onClick={action} style={customStyle}>
            {label}
        </button>
    );
};

const getButtonClass = (type) => {
    switch (type) {
        case ButtonType.link:
            return classes.link;
        case ButtonType.linkInverted:
            return [classes.link, classes.linkInverted].join(' ');
        case ButtonType.cancelButton:
            return [classes.button, classes.cancelButton].join(' ');
        case ButtonType.miniActionButton:
            return [classes.button, classes.miniActionButton].join(' ');
        case ButtonType.symbol:
            return [classes.button, classes.symbolButton].join(' ');
        default:
            return classes.button;
    }
};

export default Button;
