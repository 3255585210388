import React, {useEffect, useState} from 'react';
import useNav from '../../../services/hooks/useNav';
import useGetById from '../../../services/hooks/useGetById';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import './permissions.scss';
import {useUpsert} from '../../../services/hooks/useUpsert';
import {useRoutePost} from '../../../services/hooks/routes/useRoutePost';
import {Permissions} from './Permissions';
const hookContext = {
    entity: 'users'
};
const managementHookContext = {
    entity: 'user_management',
    operation: 'send_invite'
};
export const EditUser = () =>{
    const {getState,navTo} = useNav(); 
    const {id:userId} =      getState();
    const [user, setUser] = useState({});
    const {data:incoming} = useGetById({...hookContext,id:userId});
    const [updateMutate]  = useUpsert({...hookContext,id:userId});
    const {update} = useFieldUpdater({state:user,setState: setUser});
    const {post} = useRoutePost({...managementHookContext});
    useEffect(()=>{
        if(userId && incoming){
            setUser(incoming.data);
        }
    },[userId,incoming]);

    const saveUser = async () =>{
        await updateMutate(user);
        await navTo('System-Users',{});
    };
    const resetInvite  = async ()=>{
        await post(user);
    };
    return (
        <div> <h4>{userId && <>Edit User</>} {!userId && <>New User</>}</h4>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10">
                        <div className="form-group">

                            {!userId && <button className={'btn btn-primary'} onClick={()=>saveUser()}>Invite</button>}
                            {userId && <>
                                <button className={'btn btn-primary'} onClick={()=>saveUser()}>Update</button> &nbsp;
                                <button className={'btn btn-primary'} onClick={()=>resetInvite()}>Resend</button>
                            </>}&nbsp;
                            <button className={'btn btn-default'} onClick={()=>navTo('System-Users')}>Cancel</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className={'form-control'}
                                value={user.username}
                                onChange={(e)=>update('username',e.target.value)}/>
                            <label htmlFor="">Username/Email</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className={'form-control'}
                                value={user.first_name}
                                onChange={(e)=>update('first_name',e.target.value)}
                            />
                            <label htmlFor="">First Name</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className={'form-control'}
                                value={user.last_name}
                                onChange={(e)=>update('last_name',e.target.value)}
                            />
                            <label htmlFor="">Last Name</label>
                        </div>
                    </div>
                </div>
                <hr/>
                <Permissions user={user} setUser={setUser} />
            </div>
        </div>);
};
