import useBetweenDatesForm from '../../../services/hooks/useBetweenDatesForm';

const OrdersBySalesperson = {
    title: 'Orders by Salesperson',
    queryFormHook: useBetweenDatesForm,
    queryFormText: 'Show data from orders between',
    queryFn: ({
        fromDate,
        toDate,
    }) => `select o.sales_person_id, s.first_name || ' ' || s.last_name sales_person_name, o.apm_order_id, o.date, o.customer_po, o.amount, s.commission_rate, o.amount * (s.commission_rate / 100) as commission 
                from orders o 
                join sales_people s on o.sales_person_id = s.id 
                where sales_person_id is not null 
                    and o.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format('YYYY/MM/DD')}'
                order by sales_person_name, o.date, o.apm_order_id;`,
    columns: [
        {
            dataField: 'sales_person_name',
            caption: 'Salesperson',
            dataType: 'string',
            groupIndex: 0,
            groupCellRender: (data) => <div>{data.value}</div>,
        },
        {
            dataField: 'apm_order_id',
            caption: 'Order Id',
            dataType: 'number',
            cellRender: (data) => <a href={`4/orders-Vieworders#${data.value}`}>{data.value}</a>,
        },
        {
            dataField: 'date',
            caption: 'Date',
            dataType: 'date',
        },
        {
            dataField: 'customer_po',
            caption: 'Customer PO',
            dataType: 'string',
        },
        {
            dataField: 'amount',
            caption: 'Transaction Amt.',
            dataType: 'number',
            format: 'currency',
        },
        {
            dataField: 'commision_rate',
            caption: 'Rate',
            dataType: 'number',
            format: 'percent',
        },
        {
            dataField: 'commission',
            caption: 'Commission',
            dataType: 'number',
            format: 'currency',
        },
    ],
    summaryItems: [
        {
            column: 'amount',
            summaryType: 'sum',
            valueFormat: 'currency',
            displayFormat: 'Net: {0}',
            alignByColumn: true,
            showInGroupFooter: true,
        },
        {
            column: 'commission',
            summaryType: 'sum',
            valueFormat: 'currency',
            displayFormat: 'Balance: {0}',
            alignByColumn: true,
            showInGroupFooter: true,
        },
    ],
};

export default OrdersBySalesperson;
