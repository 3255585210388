export const customDateSearches = (columnName) => {
    const currDate = new Date();
    const firstForThisWeek = currDate.getDate() - currDate.getDay();
    const firstOfThisWeek = new Date(currDate.setDate(firstForThisWeek)).toLocaleDateString('en-ZA');
    const firstOfLastWeek = new Date(currDate.setDate(firstForThisWeek - 7)).toLocaleDateString('en-ZA');

    const dateOfFirstOfMonth = new Date(currDate.setDate(1));
    const firstOfThisMonth = dateOfFirstOfMonth.toLocaleDateString('en-ZA');
    const firstOfLastMonth = new Date(
        dateOfFirstOfMonth.setMonth(dateOfFirstOfMonth.getMonth() - 1)
    ).toLocaleDateString('en-ZA');

    return [
        {
            text: 'This Week',
            value: [columnName, '>=', firstOfThisWeek],
        },
        {
            text: 'Last Week',
            value: [
                [columnName, '>=', firstOfLastWeek],
                [columnName, '<', firstOfThisWeek],
            ],
        },
        {
            text: 'This Month',
            value: [columnName, '>=', firstOfThisMonth],
        },
        {
            text: 'Last Month',
            value: [
                [columnName, '>=', firstOfLastMonth],
                [columnName, '<', firstOfThisMonth],
            ],
        },
        {
            text: 'This Year',
            value: new Date().getFullYear(),
        },
        {
            text: 'Last Year',
            value: new Date().getFullYear() - 1,
        },
    ];
};
