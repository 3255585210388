import React, {useEffect, useState} from 'react';
import classes from './MasterDetailView.module.scss';

const MasterDetailView = ({master, detail,data}) => {
    const [curMaster, setCurMaster] = useState(null);
    const [curDetail, setCurDetail] = useState(null);
    useEffect(()=>{
        setCurMaster(master);
        setCurDetail(detail);
    },[data,master,detail]);
    return <div className={classes.viewWrapper}>
        <div className={classes.master}>{curMaster}</div>
        <div className={classes.detail}>{curDetail}</div>
    </div>;
};
 
export default MasterDetailView;