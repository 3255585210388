import { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';

import useTableData from '../../services/hooks/useTableData';
import useRawQuery from '../../services/hooks/useRawQuery';
import useCustomReport from '../../services/hooks/useCustomReport';

import classes from './Report.module.scss';

import PageTitle from '../CommonUI/PageTitle';
import Loader from '../CommonUI/Loader';
import DataGrid, {
    Column,
    Export,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    LoadPanel,
    MasterDetail,
    Scrolling,
    SearchPanel,
    Summary,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { customDateSearches } from './ReportHeaderFilters';

const Report = ({ reportData = {}, customReportId = null }) => {
    const [ report, setReport ] = useState({ ...reportData });

    const { structure: customReport, data: customData } = useCustomReport({ customReportId })
    useEffect(() => {
        if(!_.isEmpty(customReport) && customData) {
            setReport(customReport)
        }
    }, [customReport, customData])

    
    const [contextData] = report.hookContext
        ? useTableData({
              hookContext: report.hookContext,
              mapCallback: report.mapCallback,
          })
        : [];

    const [queryForm, formResultObject] = report.queryFormHook ? report.queryFormHook(report.queryFormText) : [];
    const { rows: queryData, status: queryStatus } =
        (report.query || report.queryFn) ? useRawQuery(report.query ?? report.queryFn(formResultObject)) : {};

    useEffect(() => {
        switch (queryStatus) {
            case 'loading':
                dataGridRef.current?.instance.beginCustomLoading();
                return;
            case 'success':
            default:
                dataGridRef.current?.instance.endCustomLoading();
                return;
        }
    }, [queryStatus]);

    const wrapperRef = useRef();
    const titleRef = useRef();
    const dataGridRef = useRef();
    const [gridHeight, setGridHeight] = useState(window.innerHeight);
    useEffect(() => {
        if (wrapperRef.current && titleRef.current) {
            const isDetail = report.isDetail ?? false;
            if (isDetail && queryData.length > 0) {
                const heightDeps = ['dx-header-row', 'dx-data-row'];
                (report.isGroupable || report.isSearchable || report.isExportable) &&
                    heightDeps.push('dx-datagrid-header-panel');
                report.isFilterableByRow && heightDeps.push('dx-datagrid-filter-row');
                report.isFilterableByPanel && heightDeps.push('dx-datagrid-filter-panel');
                const height =
                    heightDeps
                        .map((className) => {
                            const height = document.getElementsByClassName(className)[0].offsetHeight;
                            return height * (className === 'dx-data-row' ? queryData.length : 1);
                        })
                        .reduce((prev, curr) => prev + curr, 0) + 4;

                if (height !== gridHeight) setGridHeight(height);
            } else {
                const height = wrapperRef.current.clientHeight - titleRef.current.clientHeight;
                if (height !== gridHeight) setGridHeight(height);
            }
        }
    }, [wrapperRef, titleRef, queryData]);

    const customHeaderFilter = useCallback((data, customSearches) => {
        data.dataSource.postProcess = (results) => {
            customSearches.push(...results);
            return customSearches;
        };
    }, []);

    const onExporting = useCallback((e) => {
        e.component.beginUpdate();

        report.columns?.map((column) => {
            const isVisibleOnExport = column.visibleOnExport ?? true;
            e.component.columnOption(column.dataField, 'visible', isVisibleOnExport);
        });

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        const customExportColumns = report.columns?.filter((column) => column.customizeOnExport);
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ excelCell, gridCell }) => {
                if (gridCell.column.dataType === 'date') {
                    excelCell.style.numFmt = '[$-9]M-d-yyyy';
                }
                customExportColumns.map((column) => column.customizeOnExport({ excelCell, gridCell }));
            },
        })
            .then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
            })
            .then(() => {
                report.columns?.map((column) => {
                    const isVisible = column.visible ?? true;
                    e.component.columnOption(column.dataField, 'visible', isVisible);
                });
                e.component.endUpdate();
            });
        e.cancel = true;
    }, []);

    const [showHeaderFilter, setShowHeaderFilter] = useState(false);
    const columns = report.columns?.map((column) => {
        return (
            <Column
                key={column.dataField}
                dataField={column.dataField}
                caption={column.caption}
                dataType={column.dataType}
                format={column.format}
                alignment={column.alignment}
                width={column.width}
                visible={column.visible ?? true}
                cellRender={column.cellRender}
                allowFiltering={column.allowFiltering ?? true}
                allowHeaderFiltering={column.allowHeaderFiltering ?? false}
                groupIndex={column.groupIndex}
                groupCellRender={column.groupCellRender}
                headerFilter={{
                    dataSource: column.customHeaderSearches
                        ? (data) => customHeaderFilter(data, column.customHeaderSearches)
                        : column.dataType === 'date' &&
                          ((data) => customHeaderFilter(data, customDateSearches(column.dataField))),
                }}
            />
        );
    });

    // This is a workaround for a bug where detail datagrid headers would
    // disappear when a second detail datagrid was rendered. The support ticket
    // suggesting the workaround is here: https://supportcenter.devexpress.com/ticket/details/t375072/column-headers-disappear-when-using-dynamic-columns-customstore-and-calling-grid-refresh
    // However, there is most likely a more elegant solution for a future refactor.
    useEffect(() => {
        setTimeout(() => {
            report.isDetail && dataGridRef.current.instance.repaint();
        }, 0);
    }, [columns]);

    const summaryItems = report.summaryItems?.map((item) => {
        return (
            <GroupItem
                key={`${item.summaryType}-${item.column}`}
                column={item.column}
                summaryType={item.summaryType}
                valueFormat={item.valueFormat}
                displayFormat={item.displayFormat}
                showInGroupFooter={!!item.showInGroupFooter}
                alignByColumn={!!item.alignByColumn}
            />
        );
    });

    return _.isEmpty(report) 
        ? <Loader />
        : <>
            <div ref={wrapperRef} className={classes.reportWrapper} style={{ height: '100%' }}>
                <div ref={titleRef}>
                    {report.title && <PageTitle titleNote={report.titleNote}>{report.title}</PageTitle>}
                    {queryForm}
                </div>
                <DataGrid
                    ref={dataGridRef}
                    dataSource={customReportId ? customData : (report.hookContext ? contextData : queryData)}
                    keyExpr={report.masterDetailKeyExpr}
                    columnAutoWidth={true}
                    showColumnHeaders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode={'widget'}
                    showBorders={true}
                    height={() => gridHeight}
                    filterSyncEnabled={true}
                    defaultFilterValue={columns?.length > 0 && report.defaultFilter}
                    headerFilter={{ visible: showHeaderFilter }}
                    onContentReady={(e) => {
                        setShowHeaderFilter(true);
                    }}
                    onExporting={onExporting}
                >
                    <LoadPanel enabled />
                    <Scrolling mode={'virtual'} columnRenderingMode={'virtual'} />

                    <GroupPanel visible={report.isGroupable ?? true} />
                    <Grouping autoExpandAll={true} />

                    <SearchPanel visible={report.isSearchable ?? true} placeholder={'Search...'} />

                    <FilterRow visible={report.isFilterableByRow ?? true} applyFilter={'auto'} />
                    <FilterPanel visible={report.isFilterableByPanel ?? true} />

                    <Export enabled={report.isExportable ?? true} />

                    {columns}
                    {report.summaryItems && <Summary>{summaryItems}</Summary>}

                    {report.masterDetailComponent && (
                        <MasterDetail enabled={true} component={report.masterDetailComponent} autoExpandAll={false} />
                    )}
                </DataGrid>
            </div>
        </>
};

export default Report;
