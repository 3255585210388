import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from './authTokenContext';
import AxiosClient from './AxiosClient';

const PostRawQuery = async (auth, query) => {
    const token = auth.token;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };
    try {
        const pathname = '/reports/execute_query';
        const results = await AxiosClient.post(
            pathname,
            {
                query: query,
            },
            config
        );
        const { data } = results;
        return data.data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble executing raw query: ${query}`);
    }
};

const useRawQuery = (query) => {
    const auth = useAuth();

    const [rows, setRows] = useState([]);
    const { data: rowData, status } = useQuery([auth, query], (auth, query) => PostRawQuery(auth, query));
    useEffect(() => {
        if (rowData) {
            setRows(rowData);
        } else if (rows.length > 0) {
            setRows([]);
        }
    }, [rowData]);

    return { rows, status };
};

export default useRawQuery;
