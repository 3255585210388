import React, {Component, useState} from 'react';
import * as moment from 'moment';
import {VDropdown} from '../FormDriver/VDropdown';
import FaEye from 'react-icons/lib/fa/eye';
import {InlineFilterFields, TableHeaderMenu, TableSortableMenu} from './TableHeaderMenu';
import {TableFeatures} from './TableFeatures';
import {getColumns} from '../../services/table_ops';
import {Pager} from './Pager';
import {ObjContainer} from '../../services/object_container';


export const BasicTableDef =(props)=>{
    //PagerItem = ({total,curPager,setCurPager})
    return( <div>
        <h2 ><i className={props.definition?.icon + ' header-icon'} />  {props.config.title}</h2>
        <div className={'button-bar  valerian-search'}>
            <TableNav {...props} />
        </div> 
        <div>
            <table className="table table-striped valerian-table" >
                <TableContent definition={props.definition} disableDelete={props.disableDelete} >
                    {(props.rows || []).map((row,index)=>{
                        return (
                            <tr key={'table-row-' + index} >
                                {props.mode === 'single-select' && <td>
                                    <div className="form-check">
                                        <a className={'inline-nav-link'} onClick={(e)=>props.ops.rowClick(row,'id',e)}>Select</a>
                                    </div>
                                </td>}
                                {props.mode !== 'single-select' && <td>
                                    <div className="form-check">
                                        <input type={'checkbox'}
                                            name="checked-row"
                                            checked={props.selected.indexOf(row.id) > -1}
                                            className={'form-check-input'}
                                            onChange={(e)=>props.ops.onSelect(e.target.checked,row)} />
                                    </div>
                                </td>}
                                {getColumns(row,index,props.definition,props.ops)}
                            </tr>);
                    })}
                </TableContent>
            </table>
        </div>
        <Pager  paging={props.paging || {}} onPage={props.ops.onPage}/>
    </div>);
};
export const TableBasicTemplate = ({definition, sort,children}) =>{
    return (<>
        <BasicColumnHeaderGroup definition={definition} sort={sort} />
        <TableBody children={children}/>
    </>);
};
export const TableContent = (params) =>{
    return <>
        <ColumnHeaderGroup key={'column-headers'} {...params}/>
        <TableBody {...params}/>
    </>;
};
export const TableBody = ({children}) =>{
    return (
        <tbody key={'table-body'} style={{overflowY:'auto',overflowX:'hidden'}}>
            {children}
        </tbody>);
};
export const TableNav = (params)=>{
    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <button className="btn btn-primary" onClick={params.ops.onAdd}>New</button>&nbsp;<button className="btn btn-primary" onClick={params.ops.onExport}>Export</button>
                <TableFeatures definition={params.definition} parent_id={params.parent_id} parent_collection={params.parent_collection} onDelete={async ()=> await params.ops.onDelete()}/>
                {params.children}
            </div>
        </div>
    );
};
export class DefaultActions extends Component{
    constructor(props){
        super();
        this.state = {checked:false, id:props.id};
    }
    onSelect(checked){
        this.setState({checked:checked});
        this.props.ops.onSelect(checked);
    }
    render(){
        return <td>
            <div className="form-check">
                <input type={'checkbox'} id={'checkbox-' + this.props.id} name="checked-row" checked={this.props.checked} className={'form-check-input position-static'} onChange={(e)=>this.onSelect(e.target.checked)}/>
            </div>
        </td>;
    }
}

export class MultiRowDisplay extends Component{
    constructor(){
        super();
        this.state = {show:false};
    }
    showMulti(){
        this.setState({show:!this.state.show});
    }
    hideMulti(){
        this.setState({show:!this.state.show});
    }
    render(){
        return (<div><a className={'link'} onClick={this.hideMulti.bind(this)}><FaEye /></a>
            <div style={{display: (this.state.show ? 'block' :'none'),overflowX:'scroll', position: 'absolute',width:'250px', height: '125px', border:'1px solid silver', padding:'5px',borderRadius:'4px', backgroundColor: 'white'  }}>
                {(this.props.values || []).map((item, index) =>{
                    return <div key={'PermissionVal-' + index}>{item.display}</div>;
                })}
            </div></div>);
    }
}
export const BasicColumnHeaderGroup = ({definition,sort})=>{
    return (
        <thead >
            <tr>{(definition.fields|| []).filter(i=>i.display_class === definition.layout_class || !i.display_class).map((field, index)=>{
                if((field.show && !field.show.value)|| field.hide_list)
                {return;}
                return <BasicHeaderColumn key={'table-header-' + field.field_name + '-' + index} index={index}
                    sort={sort[field.reference ?  field.reference.display_field_code : field.field_code]} field={field}  definition={definition} />;})}
            </tr>
        </thead>
    );
};
export const ColumnHeaderGroup = (params)=>{
    return (
        <thead >
            <tr>{!params.disableDelete && <th style={{width:'50px', textAlign:'center',marginLeft:'10px',position:'sticky'}}/>}
                {(params.definition.fields|| []).filter(i=>i.display_class === params.definition.layout_class || !i.display_class).map((field, index)=>{
                    if((field.show && !field.show.value)|| field.hide_list)
                    {return;}
                    return <FormattedHeaderColumn key={'table-header-' + field.field_name + '-' + index} field={field} index={index} definition={params.definition} />;})}
            </tr>
        </thead>
    );
};
export const FormattedHeaderColumn = (params) =>{
    const [filter, setFilter] = useState({});
    const [showFilter,setShowFilter] = useState(false);
    const _handleRangeFilter = (range_key, field, value,e)=>{
        let tmpFilter = {...filter};
        if(field.reference){
            tmpFilter[field.reference_field] = tmpFilter[field.reference_field] || {} ;
            tmpFilter[field.reference_field][range_key] = value;
        }else{
            tmpFilter[field.field_code] = tmpFilter[field.field_code] || {} ;
            tmpFilter[field.field_code][range_key] = value;
        }
        ObjContainer.resolve('service_manager').resolve(this.props.definition.table_name).emit('filter-updated',{filter: tmpFilter});
        setFilter(tmpFilter);
    };
    let display = params.field.reference ? params.field.reference_display :  params.field.field_name;
    return (
        <th  style={{whiteSpace: 'nowrap'}}>

            <a onClick={()=>{setShowFilter(!showFilter);}} ><i className={'fas fa-filter'} /></a>
            {display} &nbsp;<TableHeaderMenu field={params.field} definition={params.definition} />
            {showFilter && <div className={'dropdown-popover'}>
                <InlineFilterFields field={params.field} filter={_handleRangeFilter}/>
            </div>}
        </th>);
};
export const ColumnFooterGroup = (params) =>{
    return (
        <tfoot  >
            <tr>{!params.disableDelete && <th style={{whiteSpace:'nowrap', width:'50px'}}/>}
                {(params.definition.fields || []).filter(i=>i.display_class === params.definition.layout_class || !i.display_class).map((field, index)=>{
                    if((field.show && !field.show.value)|| field.hide_list)
                    {return;}
                    return <th key={'table-footer-' + field.field_name + '-' + index} style={{whiteSpace:'nowrap'}}>{field.field_name}</th>;})}</tr>
        </tfoot>
    );
};
export const BasicHeaderColumn = (params) =>{
    return (
        <th  style={{whiteSpace: 'nowrap'}}>
            {params.field.reference ? params.field.reference_display : params.field.field_name} &nbsp;<TableSortableMenu {...params}/>
        </th>
    );
};
