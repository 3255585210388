import React,{useState} from 'react';
import {TagImporter} from './TagImporter';
import {TagBuilderContent} from './TagBuilderContent';

export const TagGenerator = () =>{
    const [showImport , setShowImport] = useState(false);
    return <div>
        {!showImport && <>
            <TagBuilderContent showImport={showImport} setShowImport={setShowImport} />
        </>}
        {showImport && <TagImporter cancel={()=>setShowImport(!showImport)} />}

    </div>;
};