import React, {Component} from 'react';
import {ObjContainer} from '../../services/object_container';
import PlayCircle from 'react-icons/lib/fa/play-circle';



export class AnalyticsManager extends Component{
    constructor(){
        super();
        this.data_manager = ObjContainer.resolve('data_manager');
        this.service_manager = ObjContainer.resolve('service_manager');
        this.state = {data:[]};
    }
    async componentDidMount(){
        await this.getData();
 
    }
    async runUpdates(){
        await this.data_manager.postData('/analytics/execute_table_analytics',{parent_table_id: this.props.table._id});
    }
    async getData(){
        this.data_def = await this.service_manager.resolve('aggregates').get({filter:{parent_id:this.props.table._id}});
        this.setState({data:(this.data_def.data.data || [])});
    }
    async runUpdate(item){
        await this.data_manager.postData('/analytics/execute_table_analytics',{_id: item._id});
    }
    render(){
        return (<div>
            <h4>Analytics {this.props.table.system_label}</h4>

            <button className={'btn btn-primary'} onClick={async ()=> await this.runUpdates()}>Run All</button>

            <table className={'table'}>
                <thead>
                    <tr>
                        <th></th><th>Table</th><th>Field Count</th><th>Destination</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data.map((item)=>{
                        return <tr><td><a href={'#'} onClick={async ()=> await this.runUpdate(item)}><PlayCircle /></a></td>
                            <td>{item.table_name}</td>
                            <td>{Object.keys(item.analytics_fields || {}).length}</td>
                            <td>{item.destination}</td></tr>;
                    })}
                </tbody>
            </table>
        </div>);
    }
}