import axios from 'axios';
export class AuthenticationService{
    constructor(config={root_url:''}){
        this.config = config;
        this.config.root_url = this.config.root_url || "";
    }
    async authenticate(user_data){
        return axios.post(`${this.config.root_url}/api/auth/authenticate`,user_data)
    }
    async resetPassword(user_data){
        return axios.post(`${this.config.root_url}/api/auth/reset_password`,user_data)
    }
    async sendLink(user_data){
        return axios.post(`${this.config.root_url}/api/auth/send_link`,user_data)
    }
}
