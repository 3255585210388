import React, {Component, PureComponent, useEffect} from 'react';
import useNav from '../../../services/hooks/useNav';
import useSearch from '../../../services/hooks/useSearch';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import useDelete from '../../../services/hooks/useDelete';

const embeddedReportHookContext = {
    entity: 'v_dashboard_reports',
    expands:{'data_source_connections':'data_connection_id','data_source_items':'data_source_item_id'}
};

export const DashboardReportList = () =>{
    const [datasets, setDatasets] = React.useState([]);
    const {navTo} = useNav(embeddedReportHookContext);
    const [deleteReport] = useDelete(embeddedReportHookContext);
    const { data:dataset_data } = useSearch({
        ...embeddedReportHookContext
    });

    useEffect(() => {
        if (dataset_data && dataset_data.data) {
            setDatasets(dataset_data.data);
        } else {
            setDatasets([]);
        }
    }, [dataset_data]);
    const addDashboard = async()=>{
        navTo('DashboardReportDesigner',{});
    };
    const deleteReportItem = async (report) =>{
        await deleteReport(report);
    };
    const nameBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => navTo('DashboardReportDesigner',rowData)}>{rowData.report_name}</a>
            </>
        );
    };
    const actionsBodyTemplate =(rowData) =>{
        return (
            <>
                <a className="inline-nav-link" onClick={async () => await deleteReportItem(rowData)}>Delete</a>
            </>
        );
    };
    const connectionBodyTemplate =(rowData) =>{
        return (
            <>
                {rowData.data_source_connections?.data_source_name}
            </>
        );
    };
    const dataSourceBodyTemplate =(rowData) =>{
        return (
            <>
                {rowData.data_source_items?.data_source_name}
            </>
        );
    };
    return (<div>
        <h2>Dashboard Reports</h2>
        <div>
            <div  className={'valerian-search'}>
                <button className={'btn btn-primary'} onClick={() =>addDashboard()}>Add Report</button>
            </div>
            <DataTable  className="p-datatable-customers" value={datasets}
                header={''}  dataKey="id" rowHover
                paginator rows={10} emptyMessage="No reports found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                <Column field="report_name" header="Name"  body={nameBodyTemplate} sortable filter  />
                <Column field="connection" header="connection" body={connectionBodyTemplate}   sortable filter filterPlaceholder="Search Connection" />
                <Column field="data_source_name" header="data source" body={dataSourceBodyTemplate}   sortable filter filterPlaceholder="Search Data Source" />
                <Column field="id" header="Actions"  body={actionsBodyTemplate}  />
            </DataTable>
        </div>
    </div>);
};