import { useState, useEffect } from 'react';
import classes from './TabbedView.module.scss';

import TabRow from './TabRow';
import Tab from './Tab';

const TabbedView = ({ tabs, forcedActiveTabTitle }) => {
    const [selectedTabTitle, setSelectedTabTitle] = useState(tabs[0].title);
    const tabClickHandler = (tabTitle) => {
        const selectedTab = tabs.find((tab) => tab.title === tabTitle && !tab.disabled);
        if (selectedTab !== undefined) {
            setSelectedTabTitle(selectedTab.title);
        }
    };

    useEffect(() => {
        if (forcedActiveTabTitle) {setSelectedTabTitle(forcedActiveTabTitle);}
    }, [tabs,forcedActiveTabTitle]);

    const selectedTabView = tabs.find((tab) => tab.title === selectedTabTitle)?.view;

    return (
        <div className={classes.tabbedView}>
            <div className={classes.tabArea}>
                <TabRow>
                    {tabs.map((tab) => {
                        return (
                            <Tab
                                key={tab.title}
                                title={tab.title}
                                isSelected={tab.title === selectedTabTitle}
                                isDisabled={tab.disabled}
                                clickHandler={() => tabClickHandler(tab.title)}
                            />
                        );
                    })}
                </TabRow> 
            </div>
            <div className={classes.tabDetailArea}>
                <div>{selectedTabView ? selectedTabView : <h5>{selectedTabTitle} tab view coming soon.</h5>}</div>
            </div>
        </div>
    );
};

export default TabbedView;
