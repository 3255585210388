import axios from "axios";
import {UserManagementService} from "./user_management_service";

export class ImportService {
    constructor(config={}){
        this.config = config;
        this.user_manager = new UserManagementService();
    }
    async import_file(files, params, data){
        let formData = new FormData();
        //formData.append("data", data);
        files.forEach((file)=>{
            formData.append(data.import_table_name,file, file.name);
        });

        let ret = await axios.post(`${this.config.root_url}/import_file/upload/?${this._prepareParams(params)}`,formData, this.getUploadHeaders());
        return ret.data.data;
    }
    _prepareParams(params){
        return (Object.keys(params).map((item)=>{
            return `${item}=${params[item]}`
        }) || []).join('&');
    }
    _prepareFilter(filter){
        let ret_array = [];
        if(filter.filter && Object.getPrototypeOf(filter.filter) === String.prototype){
            return "filter=" + filter.filter.toString();
        }
        Object.keys(filter.filter || {}).forEach((key)=>{
            if(filter.filter[key] !== null && filter.filter[key] !== '')
                ret_array.push(`filter[${key}]=${filter.filter[key]}`);
        });

        if(filter.filter_eq && Object.getPrototypeOf(filter.filter_eq) === String.prototype){
            return "filter_eq=" + filter.filter_eq.toString();
        }
        Object.keys(filter.filter_eq || {}).forEach((key)=>{
            if(filter.filter_eq[key] !== null && filter.filter_eq[key] !== '')
                ret_array.push(`filter_eq[${key}]=${filter.filter_eq[key]}`);
        });


        if(filter.filter_or && Object.getPrototypeOf(filter.filter_or) === String.prototype){
            return "filter_or=" + filter.filter_or.toString();
        }
        Object.keys(filter.filter_or || {}).forEach((key)=>{
            if(filter.filter_or[key] !== null && filter.filter_or[key] !== '')
                ret_array.push(`filter_or[${key}]=${filter.filter_or[key]}`);
        });
        if(filter.expand){
            let exp_str = Object.keys(filter.expand || {}).map(key => filter.expand[key]).join(',');
            ret_array.push(`expand=${exp_str}`);
        }
        if(filter.sort){
            Object.keys(filter.sort).forEach((item)=>{
                ret_array.push(`sort[${item}]=${filter.sort[item]}`);
            })
        }

        if(filter.page){
            ret_array.push(`page=${filter.page}`)
        }
        if(filter.page_size){
            ret_array.push(`page_size=${filter.page_size}`)
        }
        return encodeURI(ret_array.join('&'));
    }
    getUploadHeaders(){
        return {headers :{Authorization:"Bearer " + (this.user_manager.loginData() || {}).jwt},
            'Content-Type': 'multipart/form-data'};
    }
    getHeaders(){
        return {headers :{Authorization:"Bearer " + (this.user_manager.loginData() || {}).jwt}};
    }
}
