import React, {Component, useEffect, useState} from 'react';
import {Popup} from "../FormDriver/FormBuilderSupportComponent";;
import useSearch from "../../services/hooks/useSearch";
import useAdd from "../../services/hooks/useAdd";
import useUpdate from "../../services/hooks/useUpdate";
import useUpdateBulk from "../../services/hooks/useBulkUpdate";
import useDelete from "../../services/hooks/useDelete";

let editor = undefined;
let localHtml = [];
const hookContext = {
    entity: 'schema_item_tables',
    expand_multi:{'schema_item_columns':'schema_table_item_id'}
};
const columnHookContext = {
    entity: 'schema_item_columns'
};

export const Diagram = () =>{

    const [currentData,setCurrentData] =useState([]);
    const { data, isLoading, error: apiError } = useSearch({
        ...hookContext
    });
    const [addMutate]  = useAdd(hookContext);
    const [editMutate]  = useUpdate(hookContext);
    const [editMultiSchemaMutate]  =  useUpdateBulk(hookContext);
    const [deleteMutate]  =  useDelete(hookContext);

    const [editMultiColumnMutate]  =  useUpdateBulk(columnHookContext);
    const [deleteColumnMutate]  =  useDelete(columnHookContext);


    let htmlData2 = [
        {
            id: "s1",
            type: "template",
            table_name: "Shape",
            textDef: [{columnName:"column1",columnPrecision:"",columnType:"text"}, {columnName:"column2",columnPrecision:"",columnType:"text"}, {columnName:"column3",columnPrecision:"",columnType:"text"}],

            x: 200, y: 0, width: 140, height: 140,
        },
        {
            id: "s2",
            type: "template",
            table_name: "Triangle",
            textDef: [{columnName:"column1",columnPrecision:"",columnType:"text"}, {columnName:"column2",columnPrecision:"",columnType:"text"}, {columnName:"column3",columnPrecision:"",columnType:"text"}],

            x: 200, y: 220, width: 140, height: 90,
        },
        {
            id: "s3",
            type: "template",
            table_name: "Rectangle",
            textDef: [{columnName:"column1",columnPrecision:"",columnType:"text"}, {columnName:"column2",columnPrecision:"",columnType:"text"}, {columnName:"column3",columnPrecision:"",columnType:"text"}],

            x: 400, y: 220, width: 140, height: 140,
        },
        {
            id: "s4",
            type: "template",
            table_name: "Circle",
            textDef: [{columnName:"column1",columnPrecision:"",columnType:"text"}, {columnName:"column2",columnPrecision:"",columnType:"text"}, {columnName:"column3",columnPrecision:"",columnType:"text"}],

            x: 0, y: 220, width: 140, height: 140,
        },
        {
            type: "line",
            stroke: "#0AB169",
            from: "s1",
            to: "s2",
            fromSide: "bottom",
            toSide: "top",
            forwardArrow: "filled"
        },
        {
            type: "line",
            stroke: "#0AB169",
            connectType: "straight",
            from: "s1",
            to: "s4",
            fromSide: "bottom",
            toSide: "top",
            forwardArrow: "filled"
        },
        {
            type: "line",
            stroke: "#0AB169",
            connectType: "straight",
            from: "s1",
            to: "s3",
            fromSide: "bottom",
            toSide: "top",
            forwardArrow: "filled"
        }
    ];
    const [showEdit, setShowEdit] = useState(false);
    const [currentShape, setCurrentShape] = useState({});
    const template = config => (
        `<section class='template' 
					style='background:${config.fill};border:${config.strokeWidth}px solid ${config.stroke}'>
					<h3 style='border-bottom:${config.strokeWidth}px solid ${config.stroke}'>
						${config.table_name}
					</h3>
					${config.textDef.map(i=>`<div>${i.field_name} </div><div> ${i.field_code} (${i.data_type}) </div>`).join('')}
				</section>`
    );

    useEffect(() => {

        if(data && data.data.data){
            setCurrentData([...data.data.data]);
            localHtml = data.data.data.map(i=> {
                return {
                    id: i.id,
                    type: "template",
                    table_name: i.table_name,
                    x: i.x || 0,
                    y: i.y || 0,
                    height: i.height || 0,
                    width: i.width || 0,
                    textDef: i.schema_item_columns,
                };
            });
        }
        if(!editor){
            editor = new dhx.DiagramEditor('diagram', {
                shapeSections: {
                    "custom shapes": ["template"]
                },
                controls: {
                    import: false,
                    export: false,
                    gridStep: false,
                    autoLayout: false,
                    apply: false,
                    reset: false
                },
            });
            editor.events.on("beforeShapeIconClick", async (iconId, shape) =>{
                console.log("Clicked...", iconId, shape);
                if(iconId === "remove"){
                    await deleteMutate(shape);
                }
            });
            editor.diagram.events.on("shapedblclick", (shape, evt) =>{
                let item = localHtml.find(i=>i.id === shape);
                if(item){
                    setCurrentShape(localHtml.find(i=>i.id === shape));
                }else{
                    setCurrentShape({
                        table_name: "Table Name",
                        textDef: [{field_name:"colName",field_code:"code", columnPrecision:"",data_type:"text"}],
                        height: 90,
                        fill: "#CEEFE1",
                        stroke: "#0AB169",
                        strokeWidth: 2,
                        x:10,y:10,width:140
                    });
                }
                setShowEdit(true);
            });
            editor.diagram.addShape("template", {
                template,
                defaults: {
                    table_name: "Table Name",
                    textDef: [{field_name:"colName",field_code:"code", columnPrecision:"",data_type:"text"}],
                    height: 90,
                    fill: "#CEEFE1",
                    stroke: "#0AB169",
                    x:10,y:10,
                    strokeWidth: 2
                }
            });
            editor.parse(localHtml);
        }else{
            editor.parse(localHtml);
        }
    },[data]);


    const onChangeTable= (field, value)=>{
        currentShape[field] = value;
        setCurrentShape({...currentShape});
    };
    const onSave = async ()=>{
        let obj = {
            fill:currentShape.fill,
            height:currentShape.height,
            id:isNaN(currentShape.id) ? undefined : currentShape.id,
            stroke:currentShape.stroke,
            strokeWidth:currentShape.strokeWidth,
            table_name:currentShape.table_name,
            textDef:currentShape.textDef,
            width:currentShape.width,
            x:currentShape.x,
            y:currentShape.y
        };
      await editMultiColumnMutate(currentShape.textDef);
      if(obj.id){
          await editMutate(obj);
      }else{
          await addMutate(obj);
      }

        //textDef
      setShowEdit(false);
    };
    const saveDiagram= async ()=>{
        let items = editor.serialize().map(item=>{
            return {
                fill:item.fill,
                height:item.height,
                id:isNaN(item.id) ? undefined : item.id,
                stroke:item.stroke,
                strokeWidth:item.strokeWidth,
                table_name:item.table_name,
                textDef:item.textDef,
                width:item.width,
                x:item.x,
                y:item.y
            }
        });
        await editMultiSchemaMutate(items);
    };
    const updateColumns = (columns) =>{
        currentShape.textDef = columns;
        setCurrentShape({...currentShape});
    };
    return <section style={{height:"100%"}}>
            <button className={"btn btn-primary"} onClick={async ()=>await saveDiagram()}>Save</button>
            <div style={{height:"100%"}} id="diagram" />
                {showEdit && <Popup closeModal={()=>setShowEdit(false)} show={showEdit} title={currentShape.table_name}>

                    <button className={"btn btn-primary"} onClick={async ()=>await onSave()}><i className={"fa fa-save"} /> &nbsp;Save</button>
                    <div>
                        <div>
                            <label>Table Name:</label>
                            <input type="text" className={"form-control"} value={currentShape.table_name} onChange={(e) => onChangeTable('table_name',e.target.value)} />
                        </div>
                    </div>
                    <ColumnsRef referenceTable={currentShape} columnsUpdated={(columns)=>updateColumns(columns)}/>
                </Popup>}
        </section>;
}
export const ColumnsRef = ({referenceTable,columnsUpdated})=>{
    const [currentData, setCurrentData] = useState([]);
    const { data, isLoading, error: apiError } = useSearch({
        ...columnHookContext,filter:{schema_table_item_id:referenceTable.id}
    });
    useEffect(()=>{
        if(data)
            setCurrentData(data.data.data);
    },[data]);
    const onChange= (field, value, obj)=>{
        obj[field] = value;
        let item = currentData.find(i=>i.id===obj.id);
        item[field] = value;
        setCurrentData([...currentData]);
        columnsUpdated(currentData);
    };
    const addRow = ()=>{
        currentData.push({schema_table_item_id:referenceTable.id,field_name:"",data_type:"",field_code:"",columnPrecision:""});
        setCurrentData([...currentData]);
        columnsUpdated(currentData);
    };
    return <>
        <button className={"btn btn-primary"} onClick={()=>addRow()}><i className={"fa fa-plus"} /> &nbsp;Add</button> &nbsp;
        <table className={"table table-striped"}>
        <thead><tr><th>Display</th><th>Type</th><th>Column Name</th></tr></thead>
        <tbody>
        {currentData.map((i,index)=>{
            return <ColumnRow key={"column-index-"+index}
                              field_name={i.field_name} data_type={i.data_type} field_code={i.field_code} columnPrecision={i.columnPrecision}
                              onChange={(field, value)=>onChange(field, value, i) }/>
        })}
        </tbody>
    </table></>
};
export const ColumnRow = ({field_name, data_type, field_code,columnPrecision,onChange})=>{

  return <tr>
      <td>
          <input type="text"  className={"form-control"} value={field_name || ""} onChange={(e)=>onChange('field_name',e.target.value)}/>
      </td>
      <td>
          <select value={data_type  || ""} className={"form-control"} onChange={(e)=>onChange('data_type',e.target.value)}>
              <option value="0">--Select One</option>
              <option value="varchar">VarChar</option>
              <option value="int">Int</option>
              <option value="decimal">Decimal</option>
              <option value="money">Money</option>
          </select>
      </td>
      <td>
          <input type="text"  className={"form-control"} value={field_code || ""} onChange={(e)=>onChange('field_code',e.target.value)}/>
      </td>
  </tr>;
};
