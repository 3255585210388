import EventEmitter from 'events';

export class SocketService  extends EventEmitter{
    constructor(config={}){
        super();
        this.config = config;
        this.config.root_url = this.config.root_url || "";
    }
    connect(){
        return;
        window.WebSocket = window.WebSocket || window.MozWebSocket;
        if (!window.WebSocket) {
            console.log("Websocket does not exit");
            return;
        }
        // open connection
        this.connection = new WebSocket(`ws://${window.location.hostname + (window.location.port != 80 && window.location.port != 443 ? ':3000' : "")}/ws-support/messages`);
        this.connection.onopen = () => {
            console.log("Connection open")
        };
        this.connection.onerror = (error) => {
            console.log(error);
        };
        // most important part - incoming messages
        this.connection.onmessage = (message) => {
            try {
                console.log("Received message", message);
                this.emit("message",message.data);
            } catch (e) {
                console.log('Invalid JSON: ', message.data);
            }
        };
        this.connection.onclose = () =>{

        };
    }
    sendStr(message){
        this.connection.send(message);
    }
    sendJson(message){
        this.connection.send(JSON.stringify(message));
    }
}
