import React, {Component, PureComponent, useEffect,useState,useRef} from 'react';
import {ReportDesignItem} from './ReportDesignItem';
import { ButtonGroup } from 'devextreme-react/button-group';
const selectedItemKeys = ['left'];
import notify from 'devextreme/ui/notify';
export const ReportDesignContainer = ({reportDef, setReportDef,selected, setSelected}) =>{

    const alignments = [
        {
            icon: 'add',
            alignment: 'left',
            hint: 'Add Report',
            button_name:'add'
        },
        {
            icon: 'trash',
            alignment: 'center',
            hint: 'Delete Report'
        }
    ];

    const fontStyles = [

    ];
    const itemClick = (e) =>{
        if(e.itemData.button_name === 'add'){
            let tmpReport = {...reportDef};
            tmpReport.report_definition =tmpReport.report_definition || {};
            tmpReport.report_definition.reports  =tmpReport.report_definition.reports || [];
            let newReport = {report_key:`report_index_${tmpReport.report_definition.reports.length}`};
            tmpReport.report_definition.reports.push(newReport);
            setReportDef(tmpReport);
            setSelected(newReport);
        }
        //notify({ message: `The "${ e.itemData.hint }" button was clicked`, width: 320 }, 'success', 1000);
    };
    const setCurSelected = (report)=>{
        let tmpReport = {...reportDef};
        tmpReport.report_definition =tmpReport.report_definition || {};
        tmpReport.report_definition.reports  =tmpReport.report_definition.reports || [];
        let areport = tmpReport.report_definition.reports.find(i=>i.report_key === report.report_key);
        console.log("updating", areport, report);
        tmpReport.report_definition.reports[tmpReport.report_definition.reports.indexOf(areport)] = report;
        setReportDef(tmpReport);
        setSelected(report);
    }
    return <div className={'report-item-container'} >
        <div>
            <div className="buttongroups-container">
                <ButtonGroup
                    items={alignments}
                    keyExpr="alignment"
                    stylingMode="outlined"
                    selectedItemKeys={selectedItemKeys}
                    onItemClick={itemClick}
                />
                <ButtonGroup
                    items={fontStyles}
                    keyExpr="style"
                    stylingMode="outlined"
                    selectionMode="multiple"
                    onItemClick={itemClick}
                />
            </div>
        </div>
        {(reportDef?.report_definition?.reports || []).map((report, report_index)=>{
            return <ReportDesignItem key={"report_item_" + report_index} report={report} selected={selected} setSelected={setCurSelected} />;
        })}
    </div>;
};