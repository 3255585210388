import React, { Component, useState } from 'react';
import { ObjContainer } from '../../services/object_container';
import { AuthenticationService } from '../../services/authentication_service';
import { Button } from 'primereact/button';

export const LoginPage = () => {
    const [loginData, setLoginData] = useState({ user_obj: {}, message: '' });
    const authenticator = ObjContainer.resolve('authentication_service');
    const _onChange = (e) => {
        let tmpLoginData = { ...loginData };
        tmpLoginData.user_obj[e.target.name] = e.target.value;
        setLoginData(tmpLoginData);
    };
    const login = async () => {
        let result = (await authenticator.authenticate(loginData.user_obj)).data;
        if (result.authenticated) {
            ObjContainer.resolve('user_manager').login(result);
            await ObjContainer.resolve('nav').emit('try_login', result);
        } else {
            let tmpLoginData = { ...loginData };
            tmpLoginData.message = 'Authentication Failed';
            setLoginData(tmpLoginData);
        }
    };
    return (
        <div className='login-body'>
            <div className='card login-panel p-fluid'>
                <div className='login-panel-content'>
                    <div className='p-grid'>
                        <div className='p-col-12' style={{ textAlign: 'center' }}>
                            <img src={'/valerian-logo.png'} style={{ width: '150px' }} />
                        </div>
                        <div className='p-col-12' style={{ textAlign: 'left' }}>
                            <label className='login-label'>Username</label>
                            <div className='login-input'>
                                <input
                                    className={'form-control'}
                                    type={'text'}
                                    placeholder={'User Name/Email'}
                                    name={'username'}
                                    onChange={(e) => _onChange(e)}
                                />
                            </div>
                        </div>
                        <div className='p-col-12' style={{ textAlign: 'left' }}>
                            <label className='login-label'>Password</label>
                            <div className='login-input'>
                                <input
                                    className={'form-control'}
                                    type={'password'}
                                    placeholder={'Password'}
                                    name={'password'}
                                    onChange={(e) => _onChange(e)}
                                />
                            </div>
                        </div>
                        <div className='p-col-12 button-pane'>
                            <Button label='Sign In' onClick={async () => await login()} />
                            &nbsp;
                        </div>
                        <div className='p-col-12 link-pane' style={{ textAlign: 'center' }}>
                            <a href='/ResetPassword'>Forget Password?</a>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
