import React, {useEffect, useState} from "react";
import {Popup} from "../../FormDriver/FormBuilderSupportComponent";
import {JoinConfigurationItem} from "./JoinConfigurationItem";

export const JoinConfiguration = ({show,setShow,table,updateTable, all_tables,dataset}) =>{
    const [showImport, setShowImport] =useState(false);
    const [currTable, setCurrTable] =useState(table || {});
    useEffect(()=>{
        setShowImport(show);
    },[show]);
    useEffect(()=>{
        if(table)
            setCurrTable(table);
    },[table]);
    const saveJoins = ()=>{

        setShow(false);
    }
    return <Popup show={showImport} showSave={true} save={() => saveJoins()} title={currTable.table_name + " Joins"} closeModal={() => setShow(false)}>
        <div className="row">
            <div className="col-md-12">
                <div className="p-col-12">
                    <div style={{height:"400px",overflow:"auto"}}>
                        {(!currTable.join_list || currTable.join_list.length === 0)&& <>
                            <JoinConfigurationItem table={currTable} joinItem={{}} all_tables={all_tables} updateTable={updateTable} />
                        </>}
                        {(currTable.join_list && currTable.join_list.length > 0) && (
                            <>
                                {currTable.join_list.map((i, ind)=>{
                                    return <JoinConfigurationItem key={"join_item_" + ind} joinItem={i} table={currTable} all_tables={all_tables} updateTable={updateTable} />;
                                })}
                            </>)}
                    </div>
                </div>
            </div>
        </div>
    </Popup>;
}
