import React, { useEffect, useState } from "react";
import { MetaTable } from "../../TableDriver/MetaTable/MetaTable";

// expands:{'data_source_connections':'data_connection_id','data_source_items':'data_source_item_id'}
export const VariantInvoices = ({ productVariantId }) => {
  return (
    <div>
      <MetaTable
        table={{ table_name: "invoices" }}
        filter_eq={{
          "invoice_product_variants.product_variant_id": productVariantId,
        }}
        added_joins={{ invoice_product_variants: "invoice_id" }}
        column_list={"customer_view"}
      />
    </div>
  );
};
