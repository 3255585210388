import React, {useEffect, useState} from 'react';
import useUpdate from "../../../services/hooks/useUpdate";
import useFieldUpdater from "../../../services/hooks/useFieldUpdater";
export const ReportDesignTab = ({reportChart,setReportChart}) =>{
    const [curReport, setCurReport] = useState({});
    useEffect(()=>{
        setCurReport(reportChart);
    },[reportChart]);
    const {update} = useFieldUpdater({state:curReport,setState:setCurReport});

    if(!reportChart.report_key)
        return <div>Select a chart or add new.</div>

    const updateData = (field, data) =>{
        let areport = update(field, data);
        console.log("report", areport)
        setReportChart(areport);
    }
    return <div className={"container-fluid"}>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Title</label>
                    <input type="text"  className={"form-control"} value={curReport['title'] || ''} onChange={(e)=>updateData('title',e.target.value )}/>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="">Sub Title</label>
                    <input type="text" className={"form-control"} value={curReport['sub_title'] || ''} onChange={(e)=>updateData('sub_title',e.target.value )}/>
                </div>
            </div>
        </div>
    </div>
}