import {useAuth} from './authTokenContext';
import {useMutation, useQueryCache} from 'react-query';
import {Add, Update} from './useUpdate';

export const useUpsert = (context) => {
    const auth = useAuth();
    const queryCache = useQueryCache();
    const { entity = 'UNK', invalidateQuery } = context;
    const key = `add-${entity}`;
    return useMutation(
        async (variables) => {
            if(variables.id)
            {return await Update(variables, { ...auth, ...context });}
            return await Add(variables, { ...auth, ...context });
        },
        {
            mutationKey: key,
            onSuccess: async (data, obj) => {
                if (invalidateQuery) {
                    await queryCache.invalidateQueries(invalidateQuery);
                }
                await queryCache.invalidateQueries(`search-${context.entity}`);
                if(context.onSave)
                {context.onSave(data.result[0]);}
            }
        }
    );
};