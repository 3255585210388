import {VDropdown} from '../FormDriver/VDropdown';
import React from 'react';

export const Pager = ({paging={count:0,page:0,page_size:20},onPage}) =>{
    let page_size = (paging.page_size || 20);
    let total_pages = Math.ceil(paging.count / page_size);
    const getPages = () =>{
        let pageCount = (total_pages / page_size) || 1;
        let pages = [];
        for(let i = 0;i < pageCount;i++){
            pages.push(i);
        }if(paging.page > 5)
        {return  pages.slice(paging.page - 2 ,5);}
        return  pages.slice(0,5);
    };

    let nav_page = (page_params)=>{
        onPage(page_params);
    }; 
    return (
        <div className={'container-fluid  valerian-pager'}>
            <div className="form-row">
                <div className="col-md-1">
                    Count: {total_pages}
                </div>
                <div className="col-md-1">
                    <select className={'form-control form-control-sm'} value={paging.page_size} onChange={(e)=>nav_page({...paging,pageSize:e.target.value})}>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="60">60</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className={'col-md-10'}>
                    {paging.page === 0 &&
                    <>
                        <span className={'disabled'}><i className={'fas fa-angle-double-left'} /></span> &nbsp;
                        <span className={'disabled'}><i className={'fas fa-angle-left'} /></span> &nbsp; &nbsp;
                    </>}
                    {paging.page > 0 &&
                    <>
                        <a onClick={()=>{nav_page({...paging,page:0});}}><i className={'fas fa-angle-double-left'} /></a> &nbsp;
                        <a onClick={()=>{nav_page({...paging,page:paging.page - 1});}}><i className={'fas fa-angle-left'} /></a> &nbsp;
                    </>}
                    {getPages().map((page, index)=>{
                        if(page === paging.page){
                            return <span key={'page_item_'+index} className={'disabled'}>{+page + 1}&nbsp;</span>;
                        }
                        return <span  key={'page_item_'+index}><a className={'inline-nav-link'} onClick={()=>nav_page({...paging,page:page})}>{+page + 1}</a>&nbsp;</span>;
                    })}&nbsp;&nbsp;
                    {((paging.page + 1) * page_size)  >= total_pages &&
                    <>
                        <span className={'disabled'}><i className={'fas fa-angle-double-right'} /></span> &nbsp;
                        <span className={'disabled'}><i className={'fas fa-angle-right'} /></span> &nbsp;
                    </>}
                    {((paging.page + 1)* page_size )  < total_pages &&
                    <>
                        <a onClick={()=>{nav_page({...paging,page:paging.page + 1});}}><i className={'fas fa-angle-right'} /></a> &nbsp;
                        <a onClick={()=>{nav_page({...paging,page:(total_pages/page_size)});}}><i className={'fas fa-angle-double-right'} /></a> &nbsp;
                    </>}
                </div>
            </div>
        </div>
    );
};