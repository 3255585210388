import React from 'react';

const DragAndDrop =({onDrop,children}) => {
    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = event => {
        onDrop(JSON.parse(event.dataTransfer.getData('itemData')));
    };
    return (
        <div className={'drag-drop-zone'}
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
        >
            {children}
        </div>
    );
};
export default DragAndDrop;