import {FilterService} from "../../../services/filter_service";
import {ObjContainer} from "../../../services/object_container";
import axios from "axios";

export class DashboardDataService{
    async getDashboards(){
       // await ObjContainer.resolve("service_manager").resolve("scoped_nav").scopedNavGet(`/data-api/reports`);

        let data = await axios.get(`/api/v_dashboards`,ObjContainer.resolve("service_manager").resolve("generic").getHeaders());
        return data.data.data;
    }
    async deleteDashboard(dashboard){
        await axios.delete(`/api/v_dashboards/${dashboard._id}`,ObjContainer.resolve("service_manager").resolve("generic").getHeaders())
    }
    async saveDashboard(dashboard){
        if(dashboard._id)
            await axios.put(`/api/v_dashboards/${dashboard._id}`,dashboard,ObjContainer.resolve("service_manager").resolve("generic").getHeaders());
        else
            await axios.post(`/api/v_dashboards`,dashboard,ObjContainer.resolve("service_manager").resolve("generic").getHeaders());
    }
}
