import moment from 'moment';

const customSearches = () => {
    const dateFormat = 'YYYY/MM/DD';
    const net7Days = moment().subtract(7, 'days').format(dateFormat);
    const net1Month = moment().subtract(1, 'months').format(dateFormat);
    const net6Months = moment().subtract(6, 'months').format(dateFormat);
    const net1Year = moment().subtract(1, 'years').format(dateFormat);

    return [
        {
            text: '> 1 Week',
            value: ['last_order', '<', net7Days],
        },
        {
            text: '> 1 Month',
            value: ['last_order', '<', net1Month],
        },
        {
            text: '> 6 months',
            value: ['last_order', '<', net6Months],
        },
        {
            text: '> 1 Year',
            value: ['last_order', '<', net1Year],
        },
    ];
};

const CustomerInactivity = {
    title: 'Customer Inactivity Report',
    query: `select c.id, c.customer_name, c.phone, c.email, c.city, c.state, c.country, max(o.date) last_order
                from orders o 
                    join customers c on c.id = o.customer_id 
                group by c.id
                order by last_order desc, customer_name asc;`,
    defaultFilter: ['last_order', 'anyof', [['last_order', '<', moment().subtract(1, 'months').format('YYYY/MM/DD')]]],
    columns: [
        {
            dataField: 'last_order',
            caption: 'Last Order',
            dataType: 'date',
            allowHeaderFiltering: true,
            customHeaderSearches: customSearches(),
        },
        {
            dataField: 'customer_name',
            caption: 'Customer',
            dataType: 'string',
            cellRender: (data) => {
                return <a href={`/customers-Viewcustomers#${data.data.id}`}>{data.value}</a>;
            },
        },
        {
            dataField: 'phone',
            caption: 'Phone',
            dataType: 'string',
        },
        {
            dataField: 'email',
            caption: 'Email',
            dataType: 'string',
        },
        {
            dataField: 'city',
            caption: 'City',
            dataType: 'string',
        },
        {
            dataField: 'state',
            caption: 'State',
            dataType: 'string',
        },
        {
            dataField: 'country',
            caption: 'Country',
            dataType: 'string',
        },
    ],
};

export default CustomerInactivity;
