import React, {useState,useEffect} from 'react';
import {Button} from 'primereact/button';
import {ObjContainer} from '../../../services/object_container';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import {InvoiceDataService} from './InvoiceDataService';
import {CustomerSelectDisplay} from '../SalesOrders/CustomerSelectDisplay';
import './InvoiceManager.scss';
import {InvoiceProducts} from './InvoiceProducts';

const invoiceDataService = new InvoiceDataService(); 
export const  InvoiceManager =()=>{
    const [invoice, setInvoice] =          React.useState({});
    const [showSaved,setShowSaved] =          React.useState(false);
    const requestState  =  ObjContainer.resolve('nav').getState();
    let {data:invoice_data}  =  useQuery(['invoice_data',requestState.parent_id],invoiceDataService.getInvoiceData);
    const [mutateInvoiceBase] = useMutation(invoiceDataService.saveInvoice,{
        onSuccess: async (res,params) => {
            if(!invoice.id)
            {navTo(res.result[0].id);}
        },
    });
    const [mutateInvoice] = useMutation(invoiceDataService.saveInvoiceItems,{
        onSuccess: async (res,params) => {
            setShowSaved(true);
            setTimeout(()=>{
                setShowSaved(false);
            },2000);
        },
    });
    useEffect(() => {
        if (invoice_data) {
            setInvoice(invoice_data.data.invoice || {});
        } else {
            setInvoice({date:'11/01/2020'});
        }
    }, [invoice_data]);
    const updateInvoice = (newInvoice)=>{
        setInvoice(newInvoice);
    };
    const navTo = (id)=> {
        let local_state = {route_params:{filter:{id:id}},parent_id:id, parent_data: Object.assign({},{back_state:ObjContainer.resolve('nav').getState()})};
        ObjContainer.resolve('nav').navTo('invoices-Newinvoices', local_state);
    };
    const navToView = (id)=> {
        let local_state = {route_params:{filter:{id:id}},parent_id:id, parent_data: Object.assign({},{back_state:ObjContainer.resolve('nav').getState()})};
        ObjContainer.resolve('nav').navTo('invoices-Viewinvoices', local_state);
    };
    const setCustomerUpdate = (customer) =>{
        let tmp = {...invoice};
        tmp.customer_id = customer.id;
        tmp.customer = customer;
        setInvoice(tmp);
    };
    const updateValue = (value, field)=>{
        let tmp = {...invoice};
        tmp[field] = value;
        setInvoice(tmp);
    };
    const saveInvoice = async () =>{
        invoice.qty = getTotalCount();
        invoice.amount = getTotalPrice();
        invoice.balance = getTotalPrice();
        if(invoice.id)
        {
            await mutateInvoiceBase(invoice);
            await mutateInvoice(invoice.invoice_items);
        }
        else{
            await mutateInvoiceBase(invoice);
        }
    };
    const valueChanged = (item)=>{
        setInvoice((invoice)=>{
            return {...invoice};
        });
    };
    const getTotalCount = () =>{
        if(!invoice || !invoice.invoice_items)
        {return 0;}
        return invoice.invoice_items.reduce((val, item)=>{
            val  = val + (+item.qty);
            return val;
        },0);
    };
    const getTotalPrice = ()=>{
        if(!invoice || !invoice.invoice_items)
        {return 0;}
        return invoice.invoice_items.reduce((val, item)=>{
            let product = invoice.invoice_product_variants.find(i=>+i.id === +item.invoice_product_variant_id) ||{product:{price:0}};
            val  = val + (+item.qty * +product.product.price);
            return val;
        },0);
    };
    return <div>
        <div>
            <Button label="Save" icon="fa fa-save" onClick={saveInvoice} style={{ marginBottom: '20px', marginLeft: '6px'}} key={1}/>
            {invoice.id && <Button label="View" icon="fa fa-eye" onClick={()=>navToView(invoice.id)} style={{marginBottom: '20px', marginLeft: '6px'}} key={2}/>}
        </div>
        {showSaved && <><div className="alert alert-success" role="alert">
            Saved
        </div></>}
        <div>
            <h2>Invoice: #{invoice.invoice_no}</h2>
        </div>
        <div className="p-grid invoice-body" key={3}>
            <div className="p-col-12">
                <div id="invoice-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="">Client</label>
                                            <CustomerSelectDisplay customer={invoice.customer} setCustomer={setCustomerUpdate}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Date</label>
                                            <input type="date" value={invoice.date} onChange={(e)=>updateValue(e.target.value, 'date')} className={'form-control'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Due Date</label>
                                            <input type="date" value={invoice.date_due} onChange={(e)=>updateValue(e.target.value, 'date_due')} className={'form-control'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="">Status</label>
                                            <select value={invoice.status} onChange={(e)=>updateValue(e.target.value, 'status')} className={'form-control'}>
                                                <option value="">--CHOOSE</option>
                                                <option value="open">OPEN</option>
                                                <option value="closed">CLOSED</option>
                                                <option value="canceled">CANCELED</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="">Notes</label>
                                            <textarea name="" id="" cols="30" rows="4" value={invoice.notes} className={'form-control'} onChange={(e)=>updateValue(e.target.value,'notes')}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="">Private Notes</label>
                                            <textarea name="" id="" cols="30" rows="4" value={invoice.private_notes} className={'form-control'} onChange={(e)=>updateValue(e.target.value,'private_notes')}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className={'table table-striped valerian-table'}>
                        <thead><tr>
                            <th style={{width:'100px'}}></th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Line Total</th>
                        </tr>
                        </thead>
                        <tbody>
                            <InvoiceProducts invoice={invoice} updateInvoice={updateInvoice} valueChanged={valueChanged} parent_id={requestState.parent_id}/>
                            <tr>
                                <td colSpan={3}></td>
                                <td  className={'text-right'}>Total</td>
                                <td className={'text-right'}>${getTotalPrice().toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
};


