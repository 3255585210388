import EventEmitter from 'events';
import axios from 'axios';
import {UserManagementService} from './user_management_service';

export class GenericTableService extends EventEmitter{
    constructor(props={root_url:''}){
        super(); 
        this.props = props;
        this.props.root_url = this.props.root_url || '';
        this.user_manager = new UserManagementService();
    }
    async get(filter={}){
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}?${this._prepareFilter(filter)}`, this.getHeaders());
        return ret.data;
    }
    async getOneById(id, filter={}){
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}/${id}?${this._prepareFilter(filter)}`, this.getHeaders());
        return ret.data;
    }
    async getOne(filter={}){
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}?${this._prepareFilter(filter)}`, this.getHeaders());
        return ret.data.data[0];
    }
    async getOneData(filter={}){
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}?${this._prepareFilter(filter)}`, this.getHeaders());
        return ret.data.data.data[0];
    }
    async search(filter={}){
        let ret = await axios.post(`${this.props.root_url}/api/${this.props.table_name}/search`,filter, this.getHeaders());
        return ret.data;
    }
    async upload(data, params){
        let formData = new FormData();
        formData.append('import_file', data);
        let ret = await axios.post(`${this.props.root_url}/api/${this.props.table_name}/upload/upload_batch?${this._prepareParams(params)}`,formData, this.getUploadHeaders());
        return ret.data.data;
    }
    async upload_upsert(data, params, schema){
        let formData = new FormData();
        formData.append('data', data);
        formData.append('schema', schema);
        let ret = await axios.post(`${this.props.root_url}/api/${this.props.table_name}/upload/upload_upsert?${this._prepareParams(params)}`,formData, this.getUploadHeaders());
        return ret.data.data;
    }
    async upload_attachments(files, params, data){
        let formData = new FormData();
        files.forEach((file)=>{
            formData.append('image_file',file, file.name);
        });
        formData.append('data', data);
        let ret = await axios.post(`${this.props.root_url}/api/${this.props.table_name}/upload/upload_attachments?${this._prepareParams(params)}`,formData, this.getUploadHeaders());
        return ret.data.data;
    }
    async upsert(id, data){
        let ret ={};
        if(id)
        {ret = await axios.patch(`${this.props.root_url}/api/${this.props.table_name}/${id}`,data, this.getHeaders());}
        else
        {ret = await axios.post(`${this.props.root_url}/api/${this.props.table_name}`,data, this.getHeaders());}
        return ret.data;
    }
    async postMulti(data){
        await axios.post(`${this.props.root_url}/api/${this.props.table_name}/save_multi`,data, this.getHeaders());
    }
    async getById(filter, field='_id'){
        if(!filter.filter[field]) {return {};}
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}/${filter.filter[field]}?${this._prepareFilter(filter)}`, this.getHeaders());
        return ret.data.data;
    }
    async delete(id){
        let ret = await axios.delete(`${this.props.root_url}/api/${this.props.table_name}/${id}`, this.getHeaders());
        return ret.data;
    }
    async deleteMulti(obj){
        await axios.post(`${this.props.root_url}/api/${this.props.table_name}/delete_multi`, obj, this.getHeaders());
    }
    async schema(){
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}/_schema`, this.getHeaders());
        return ret.data;
    }
    async history(id){
        let ret = await axios.get(`${this.props.root_url}/api/${this.props.table_name}/${id}/_history`, this.getHeaders());
        return ret.data;
    }
    _prepareParams(params){
        return (Object.keys(params).map((item)=>{
            return `${item}=${params[item]}`;
        }) || []).join('&');
    }
    _prepareFilter(filter){
        let ret_array = [];
        if(filter.filter && Object.getPrototypeOf(filter.filter) === String.prototype){
            return 'filter=' + filter.filter.toString();
        }
        Object.keys(filter.filter || {}).forEach((key)=>{
            if(filter.filter[key] !== null && filter.filter[key] !== '')
            {ret_array.push(`filter[${key}]=${filter.filter[key]}`);}
        });

        if(filter.filter_eq && Object.getPrototypeOf(filter.filter_eq) === String.prototype){
            return 'filter_eq=' + filter.filter_eq.toString();
        }
        Object.keys(filter.filter_eq || {}).forEach((key)=>{
            if(filter.filter_eq[key] !== null && filter.filter_eq[key] !== '')
            {ret_array.push(`filter_eq[${key}]=${filter.filter_eq[key]}`);}
        });
        if(filter.filter_or && Object.getPrototypeOf(filter.filter_or) === String.prototype){
            return 'filter_or=' + filter.filter_or.toString();
        }
        Object.keys(filter.filter_or || {}).forEach((key)=>{
            if(filter.filter_or[key] !== null && filter.filter_or[key] !== '')
            {ret_array.push(`filter_or[${key}]=${filter.filter_or[key]}`);}
        });
        if(filter.expand){
            let exp_str = Object.keys(filter.expand || {}).map(key => filter.expand[key]).join(',');
            ret_array.push(`expand=${exp_str}`);
        }
        if(filter.sort){
            Object.keys(filter.sort).forEach((item)=>{
                ret_array.push(`sort[${item}]=${filter.sort[item]}`);
            });
        }

        if(filter.page){
            ret_array.push(`page=${filter.page}`);
        }
        if(filter.pageSize){
            ret_array.push(`pageSize=${filter.pageSize}`);
        }
        return encodeURI(ret_array.join('&'));
    }
    getUploadHeaders(){
        return {headers :{Authorization:'Bearer ' + (this.user_manager.loginData() || {}).jwt},
            'Content-Type': 'multipart/form-data'};
    }
    getHeaders(){
        return {headers :{Authorization:'Bearer ' + (this.user_manager.loginData() || {}).jwt}};
    }
}
