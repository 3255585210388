import { useEffect } from 'react';
import useTableData from '../../../services/hooks/useTableData';

import moment from 'moment';

import FormattedTable from '../../CommonUI/TitledTable/FormattedTable';

export const VariantHistory = ({ productVariantId }) => {
    const [inventoryIds, , inventoryDataReceived] = useTableData({
        hookContext: {
            entity: 'inventory',
            filter_eq: { product_variant_id: productVariantId },
        },
        mapCallback: (el) => el.id,
    });

    const [users, , userDataReceived] = useTableData({
        hookContext: {
            entity: 'users',
        },
        mapCallback: (el) => {
            return {
                id: el.id,
                name: [el.first_name, el.last_name].join(' '),
                username: el.username,
            };
        },
    });
 
    const [adjustments, , adjustmentDataReceived] = useTableData({
        hookContext: {
            entity: 'adjustment_items',
            filter_in: { inventory_id: inventoryIds ? inventoryIds : [] },
            sort: { created_at: 'desc' },
        },
        effectCondition: inventoryIds?.length > 0 && users?.length > 0,
        mapCallback: (el) => {
            return {
                key: el.id,
                size: el.inventory.size,
                sku: el.inventory.sku_id,
                amountAdjusted: el.qty_inventory,
                adjustmentReason: el.adjustments?.reason,
                authorizedPerson: users?.find((person) => person.id == el.adjustments?.authorized_person)?.name,
                dateOfAdjustment: moment(el.created_at).format('MMM D, YYYY • h:mm a'),
            };
        },
        dependencies: [inventoryDataReceived, userDataReceived],
    });

    const headers = [
        { key: 1, colTitle: 'Sku', colKey: 'sku' },
        { key: 2, colTitle: 'Size', colKey: 'size' },
        { key: 3, colTitle: 'Amount Adjusted', colKey: 'amountAdjusted' },
        { key: 4, colTitle: 'Adjustment Reason', colKey: 'adjustmentReason' },
        { key: 5, colTitle: 'Date of Adjustment', colKey: 'dateOfAdjustment' },
        { key: 6, colTitle: 'Authorized Person', colKey: 'authorizedPerson' },
    ];

    return (
        <>
            <FormattedTable headers={headers} data={adjustments ?? []} />
        </>
    );
};
