import App from './App';
import React from 'react';
import { render } from 'react-dom';
import {ObjContainer} from './services/object_container';
 
function BaseLine({config}){
    return (
        <>
            <App config={config}/>
        </>
    );
}
window.bootstrap_valerian = (config)=> {
    ObjContainer.initializeContainer(config);
    if ((ObjContainer.resolve('user_manager')).isLoggedIn()) {
        ObjContainer.initializeTables().then(() => {
            render(
                <BaseLine config={config}/>,
                document.getElementById(config.element_id)
            );
        });
    } else {
        render(
            <BaseLine config={config}/>,
            document.getElementById(config.element_id)
        );
    }
    if (module.hot) {
        module.hot.accept();
    }
};

export default bootstrap_valerian;
