import {UserManagementService} from "../user_management_service";
import axios from "axios";

export class NavWrapper{
    constructor(config={}){
        this.config = config;
        this.root_url = this.config.root_url || "";
        this.user_manager = new UserManagementService();
    }
    async scopedNavGet(url){
        return  axios.get(`${this.root_url|| ""}${url}`, this.getHeaders());
    }
    async scopedNavPost(url, params){
        return axios.post(`${this.root_url|| ""}${url}`,params, this.getHeaders());
    }
    async scopedNavPut(url, params){
        return axios.post(`${this.root_url|| ""}${url}`,params, this.getHeaders());
    }
    getHeaders(){
        return {headers :{Authorization:"Bearer " + (this.user_manager.loginData() || {}).jwt}};
    }

}
