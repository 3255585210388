import { useState } from 'react';

import classes from './TypeAheadSearch.module.scss';

import Downshift from 'downshift';
import useTableData from '../../services/hooks/useTableData';

const TypeAheadSearch = ({ 
    label, 
    tableName, 
    excludeIdsArray = [],
    mapRowWithStringDescription, 
    searchColumnNames,
    onItemPicked, 
}) => {
    const [ searchFilter, setSearchFilter ] = useState({})
    const [ items ] = useTableData({
        hookContext: {
            entity: tableName,
            filter_not_in: {id: excludeIdsArray},
            filter_or: searchFilter,
            paging: { pageSize: 1000 }
        },
        mapCallback: mapRowWithStringDescription
    })

    const inputValueChanged = (value) => {
        const tmpFilter = { ...searchFilter }
        searchColumnNames.forEach(col => {
            tmpFilter[col] = { eq: value }
        })
        setSearchFilter(tmpFilter)
    }
    
    return ( 
        <Downshift
            onChange={onItemPicked}
            onInputValueChange={inputValueChanged}
            itemToString={(item) => (item ? item.stringDescription : '')}
        >
            {({
                clearSelection,
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                openMenu,
                isOpen,
                inputValue,
                getRootProps,
                selectedItem,
            }) => {
                const openAndClear = () => {
                    clearSelection();
                    openMenu();
                };

                const selectAll = (event) => {
                    if (
                        selectedItem &&
            event.target.value === selectedItem.stringDescription
                    ) {
                        event.target.select();
                    }
                };

                const inputClasses =
          classes.input + (isOpen ? ' ' + classes.inputWithDropdown : '');

                return (
                    <div className={classes.typeaheadWrapper}>
                        <label {...getLabelProps()}>{label}:</label>
                        <div
                            className={classes.inputWrapper}
                            {...getRootProps({}, { suppressRefError: true })}
                        >
                            <input
                                className={inputClasses}
                                {...getInputProps()}
                                onFocus={openAndClear}
                                onClick={selectAll}
                            />
                            <i className={classes.icon + ' fas fa-search'}></i>
                            {isOpen ? (
                                <ul
                                    {...getMenuProps()}
                                    className={isOpen ? classes.listWrapper : ''}
                                >
                                    {items
                                        .filter(
                                            (item) =>
                                                !inputValue ||
                        item.stringDescription
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                                        )
                                        .map((item, index) => (
                                            <li
                                                className={isOpen ? classes.listItemWrapper : ''}
                                                {...getItemProps({
                                                    key: item.stringDescription,
                                                    index,
                                                    item,
                                                })}
                                            >
                                                {item.stringDescription}
                                            </li>
                                        ))}
                                </ul>
                            ) : null}
                        </div>
                    </div>
                );
            }}
        </Downshift>
    );
};

export default TypeAheadSearch;
