import {ObjContainer} from '../../../services/object_container';
import {FilterService} from '../../../services/filter_service';
import axios from 'axios';
export class PurchaseOrderDataService{

    getSalesOrderItemData(method){
        return axios.get(`/inventory_operations/get_sales_order_data`,
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }
    async savePurchaseOrder(purchaseOrder){
        return ObjContainer.resolve('service_manager').resolve('purchase_orders').upsert(purchaseOrder.id, purchaseOrder);
    }
    async savePurchaseOrderProduct(purchaseOrderProduct){
        return ObjContainer.resolve('service_manager').resolve('purchase_order_products').upsert(purchaseOrderProduct.id, purchaseOrderProduct);
    }
    async completePurchaseOrderItems(purchaseOrderId){
        return axios.post('/inventory_operations/complete_purchase_order',{purchase_order_id:purchaseOrderId},
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }
    async copySalesOrderToPurchaseOrder(copyOperation){
        return axios.post('/inventory_operations/copy_sales_order_to_po',copyOperation,
            ObjContainer.resolve('service_manager').resolve('purchase_order_products').getHeaders());
    }
    async assignSalesOrderItem(salesOrderItem){
        return axios.post('/inventory_operations/assign_sales_order_items',salesOrderItem,
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }
    async removeSalesOrderItem(salesOrderItem){
        return axios.post('/inventory_operations/remove_sales_order_items',salesOrderItem,
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }
    async removePurchaseOrderItems(salesOrderItem){
        return axios.post('/inventory_operations/remove_purchase_order_items',salesOrderItem,
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }

    async deletePurchaseOrderProduct(key){ 
        return ObjContainer.resolve('service_manager').resolve('purchase_order_products').delete(key);
    }
    async savePurchaseOrderVariant(purchaseOrderVariant){
        return ObjContainer.resolve('service_manager').resolve('purchase_order_product_variant').upsert(purchaseOrderVariant.id, purchaseOrderVariant);
    }
    async deletePurchaseOrderVariant(key){
        return ObjContainer.resolve('service_manager').resolve('purchase_order_product_variant').delete(key);
    }
    async savePurchaseOrderItem(purchaseOrderItem){
        return ObjContainer.resolve('service_manager').resolve('purchase_order_items').upsert(purchaseOrderItem.id, purchaseOrderItem);
    }
    async savePurchaseOrderItems(purchaseOrderItems){
        return ObjContainer.resolve('service_manager').resolve('purchase_order_items').postMulti(purchaseOrderItems);
    }
    async getPurchaseOrder(key,purchaseOrderId){
        if(purchaseOrderId){
            let lprod = purchaseOrderId.toString();
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('purchase_orders'));
            let data = await filter_svc.context({lprod:lprod}).andWhere(i=> i.id === lprod).first();
            return data;
        }
        return {};
    }
    async getPurchaseOrderProducts(key, purchaseOrderId){
        if(purchaseOrderId) {

            let lprod = purchaseOrderId.toString();
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('purchase_order_products'));
            let data = await filter_svc.context({lprod: lprod})
                .andWhere(i => i.purchase_order_id === lprod).include({'products':'product_id'}).toList();
            return data.data;
        }
        return {};
    }
    async getProductOrderItems(key, purchaseOrderProductId){
        if(purchaseOrderProductId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('purchase_order_items'));
            let data = await filter_svc.context({purchaseOrderProductId:purchaseOrderProductId})
                .andWhere(i => i.purchase_order_product_id === purchaseOrderProductId).includeMulti({'order_items':'purchase_order_item_id'}).toList();
            return data.data;
        }
        return [];
    }
    async getOrderProductVariations(key,orderProductId){
        if(orderProductId) {
            let filter_svc = new FilterService(ObjContainer.resolve('service_manager').resolve('purchase_order_product_variant'));
            let data = await filter_svc.context({orderProductId: orderProductId})
                .andWhere(i => i.purchase_order_product_id === orderProductId).include({'product_variations':'product_variant_id'}).toList();
            return data.data;
        }
        return [];
    }
}
