import {ObjContainer} from '../../../services/object_container';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import * as moment from 'moment';
import React, {useEffect, useState} from 'react';
import {PurchaseOrderDataService} from './PurchaseOrderDataService';
import {PurchaseOrderProductsTables} from './PurchaseOrderProductsTables';
import {ProductDropdown} from '../Invoices/ProductDropdown';
import {useUpsert} from '../../../services/hooks/useUpsert';
import './PurchaseOrders.scss';
const purchaseOrderDataService = new PurchaseOrderDataService();
const hookContext = {
    entity:'purchase_order_items',
    expands:{'products':'product_id','product_variations':'product_variant_id'}
};
export function PurchaseOrderTab({purchaseOrder,setPurchaseOrder}){
    const requestState  =  ObjContainer.resolve('nav').getState();
    const queryCache = useQueryCache();
    const [showSelect, setShowSelect] =  useState(false);
    const [updateMutate] = useUpsert({...hookContext});
    const [curPurchaseOrder, setCurPurchaseOrder] = useState({});
    useEffect(()=>{
        if(purchaseOrder){
            setCurPurchaseOrder(purchaseOrder);
        }
    },[purchaseOrder]);
    const [mutate] = useMutation(purchaseOrderDataService.savePurchaseOrder,{
        onSuccess: async (purchaseOrder, mutationVariables) => {
            navTo(purchaseOrder.result[0].id);
        },
    });
    const [mutatePurchaseItems] = useMutation(purchaseOrderDataService.savePurchaseOrderItems,{
        onSuccess: async (res,params) => {
            queryCache.setQueryData('PurchaseOrderItems',()=>{
                return [];
            });
            await queryCache.invalidateQueries(['purchase_order', requestState.parent_id]);
        },
    });
    const [mutateCompletePurchaseItems] = useMutation(purchaseOrderDataService.completePurchaseOrderItems,{
        onSuccess: async (res,params) => {
            queryCache.setQueryData('PurchaseOrderItems',()=>{
                return [];
            });
            await queryCache.invalidateQueries(['purchase_order', requestState.parent_id]);
        },
    });
    queryCache.setQueryData('PurchaseOrderItems',()=>{
        return [];
    });
    const saveAll = async()=>{
        await mutatePurchaseItems(queryCache.getQueryData('PurchaseOrderItems'));
    };
    const navTo = (id)=> {
        let local_state = {route_params:{filter:{id:id}},parent_id:id, parent_data:
                Object.assign({},{back_state:ObjContainer.resolve('nav').getState()})};
        ObjContainer.resolve('nav').navTo('purchase_orders-Viewpurchase_orders', local_state);
    };
    const savePurchaseOrder = async (e) =>{
        await mutate(purchaseOrder);
    };
    const completePo = async (e)=>{
        await mutateCompletePurchaseItems(curPurchaseOrder.id);
    };
    const addVariant = async (variant)=>{
        await updateMutate({...variant,purchase_order_id: curPurchaseOrder.id});
        setShowSelect(false);
    };
    return <div className={"purchase-order-container"}>
        {curPurchaseOrder.status !== 'complete' &&  <>
            <button className={'btn btn-primary'} onClick={saveAll}>Save</button> &nbsp;
            <button className={'btn btn-primary'}>Review & Submit</button> &nbsp;
            <button className={'btn btn-primary'} onClick={completePo}>Complete</button>
        </>}
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">Due Date:</div>
                <div className="col-md-3">{curPurchaseOrder.status !== 'complete' && <input className={'form-control'} type={'date'}
                                                                                            onChange={e => setPurchaseOrder({...curPurchaseOrder, date_due:e.target.value}) }
                                                                                            value={curPurchaseOrder.date_due ? moment(curPurchaseOrder.date_due).format('YYYY-MM-DD') : ''}/>}
                    {curPurchaseOrder.status === 'complete' && <span>{moment(curPurchaseOrder.date_due).format('YYYY-MM-DD')}</span>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {!curPurchaseOrder.id && <button className={'btn btn-primary'} onClick={savePurchaseOrder}>Save</button>}
                </div>
            </div>
        </div>

        <hr/>
        {curPurchaseOrder.id &&
            <div>
                <PurchaseOrderProductsTables purchaseOrder={curPurchaseOrder}/>
                <a className={'inline-nav-link'} onClick={()=>setShowSelect(true)}>Add</a>
                <ProductDropdown showSelect={showSelect} selectVariant={async (variant)=>await addVariant( variant)} />
            </div>
        }
    </div>;
}