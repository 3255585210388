import {useMutation, useQueryCache} from 'react-query'
import AxiosClient from './AxiosClient'

import { useAuth } from './authTokenContext'
import constants from './constants'

const { api } = constants;
const { basePath } = api;

const UpdateBulk = async (payload, context) => {
    const {
        config ={headers:{"Authorization":`Bearer ${context.token}`,"Content-Type":'application/json' }},
        entity
    } = context;

    try {
        const pathname = `${basePath}${entity}/save_multi`;
        const results = await AxiosClient.post(
            pathname,
            payload,
            config
        );
        const { data } = results;

        return data
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble adding ${entity}.`)
    }
};

const useUpdateBulk = (context) => {
    const auth = useAuth();
    const queryCache = useQueryCache();
    const { entity = 'UNK', invalidateQuery } = context;
    const key = `add-bulk-${entity}`;

    return useMutation(
        async (variables) => await UpdateBulk(variables, { ...auth, ...context }),
        {
            mutationKey: key,
            onSuccess: () => {
                if (invalidateQuery) {
                    queryCache.invalidateQueries(invalidateQuery);
                }
                queryCache.invalidateQueries(`search-${context.entity}`)
            }
        }
    )
};

export default useUpdateBulk
