import {FormInput} from './FormComponents';
import React, {Component} from 'react';
import * as  acorn from 'acorn';
import bwipjs from 'bwip-js';
import {ObjContainer} from '../../services/object_container';
import * as moment from 'moment';
export const GroupedFormDefinition = ({obj,edit_mode, ops, buttons, definition})=>{

    return (
        <div>
            {edit_mode && <ButtonGroup ops={ops} buttons={buttons}/>}
            {definition.map((group_field_item, row_i)=>{
                return (<div key={'layout_edit_group_' + row_i} className={'row'}>{
                    (group_field_item.cols || []).filter(i=>!i.field.automatic_field).map((field, field_i)=>{
                        if(edit_mode){
                            if(field.field.no_editable){
                                return (
                                    <div key={'field_edit_item_group_' + row_i + '_' + field_i} className={'form-group col-md-' + (field.width || 4)} style={{marginBottom:'0px'}}>
                                        <label className={'label'} style={{fontWeight:'600'}}>{field.field.field_name}</label><br/>
                                        <FieldDisplay data={obj} field={field.field}/>
                                    </div>);
                            }
                            return (
                                <div key={'field_edit_item_group_' + row_i + '_' + field_i} className={'form-group col-md-' + (field.width || 4)} style={{marginBottom:'0px'}}>
                                    <StackColumn show={true}
                                        field={field.field}
                                        ops={ops}
                                        obj={obj}
                                        value={obj[field.field.field_code] || ''}
                                        display={field.field.reference ? (obj[field.field.reference.relation_table] || {})[field.field.reference_field] : ''}
                                    />
                                </div>
                            );
                        }
                        return (<div key={'field_item_group_' + row_i + '_' + field_i} className={'form-group col-md-' + (field.width || 4)} style={{marginBottom:'0px'}}>
                            <label className={'label'} style={{fontWeight:'600'}}>{field.field.reference_display || field.field.field_name}</label><br/>
                            <FieldDisplay data={obj} field={field.field}/>
                        </div>);
                            

                    })
                }</div>);
            })}
        </div>);
};
export const FieldDisplay = ({field, data}) =>{
    let canvas = undefined;
    try {
        // The return value is the canvas element
        canvas = bwipjs.toCanvas('field_canvas_' + this.state.field.field_code, {
            bcid:        'code128',       // Barcode type
            text:        this.state.data[this.state.field.field_code],    // Text to encode
            scale:       2,               // 3x scaling factor
            height:      10,              // Bar height, in millimeters
            includetext: true,            // Show human-readable text
            textxalign:  'center',        // Always good to set this
        });
    } catch (e) {
        // `e` may be a string or Error object
    }

    let formatter = (value, type_name) =>{
        if(!isNaN(value) && (type_name === 'money' ) ){
            return '$' + (value * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }else if(type_name==='date'){
            return moment(value).format('MM/DD/YYYY');
        }else if(type_name==='url'){
            return <a href={value} target={'_blank'} rel="noreferrer">Click Here</a>;
        }
            
        return value;
            
    };
    let navTo = (e)=>{
        let local_state = {route_params:{filter:{}},parent_data: Object.assign({},{back_state:ObjContainer.resolve('nav').getState()})};
        local_state.route_params.filter['id'] = data[field.field_code];
        return ObjContainer.resolve('nav').navTo(field.go_to.link, local_state,e);
    };
    if(field.data_type === 'barcode'){
        return (<span><canvas style={{height:'50px'}} id={'field_canvas_' + field.field_code} /></span>);
    }
    if(field.data_type === 'many_to_many'){
        return (<div>
            {data[field.reference_field || field.field_code].map((val)=>{
                return <div>{val['integrationTag']}</div>;
            })}
        </div>);
    }
    if(field.go_to){
        return <a href={'#' + data[field.field_code]}
            onClick={(e)=> {return navTo(e);}}>{ formatter(data[field.reference_field || field.field_code],
                (field.reference_field_type || field.data_type))}</a>;
    }
    return (<span>{ formatter(data[field.reference_field || field.field_code],
        (field.reference_field_type || field.data_type))}</span>);
};

export const FormDefinition = ({definition,ops, buttons,obj}) =>{
    return (
        <div className={'container-fluid'}>
            {(chunk_array((definition.fields || []).filter(i=>!i.automatic_field),2)).map((field_array, index)=>{
                let shows = [token_array(field_array[0],{definition,ops, buttons}),token_array(field_array[1],{definition,ops, buttons})];
                return (<div className={'row'} style={{paddingBottom:'4px'}}>
                    <div className={'col-md-6'}>
                        <BlockColumn key={'column-field-key-' + index} show={shows[0]} field={field_array[0]} ops={ops} obj={obj} value={obj[field_array[0].field_code] || ''}/>
                    </div>
                    {field_array.length > 1 &&
                            <div className={'col-md-6'}>
                                <BlockColumn key={'column-field-key-' + index} show={shows[1]} field={field_array[1]} ops={ops} obj={obj} value={obj[field_array[1].field_code] || ''}/>
                            </div>
                    }
                </div>);
            })}
            <ButtonGroup ops={ops} buttons={buttons}/>
        </div>);
};
let token_array = (field, vals)=>{
    if(field === undefined) {return false;}
    let tokens = [...acorn.tokenizer(field.show_if)];
    let token_map = tokens.map((token, index)=>{
        if(token.type.label === 'name'){
            return '\'' + ((vals.obj[token.value] && vals.obj[token.value].display) ? vals.obj[token.value].display : vals.obj[token.value]) +'\'';
        }else if(token.type.label === 'string' ){
            return '\'' + token.value + '\'';
        }
        return token.value;
        
    });
    try{
        return eval(token_map.join(' '));
    }catch(e){ }
};
let chunk_array = (array, chunkSize) => {
    return [].concat.apply([],
        array.map((elem,i) => {
            return i%chunkSize ? [] : [array.slice(i,i+chunkSize)];
        })
    );
};
const BlockTextInput = (params) =>{
    return (
        <div className={'form-group'}>
            <FormInput obj={params.obj} field={params.field} value={params.value} onChange={params.ops.onChange} onDefine={params.ops.onDefine} />
            {params.field.error_state && <div style={{color: 'red'}}>{params.field.error_message}</div>}
        </div>
    );
};
const StackColumn = (params) =>{
    return (<div className={'form-group'}>
        <BlockLabel field={params.field}/>
        <BlockGroupTextInput obj={params.obj} field={params.field} ops={params.ops} value={params.value} display={params.display}/>
    </div>
    );
};
const BlockGroupTextInput = (params) =>{
    return (
        <div>
            <FormInput obj={params.obj} field={params.field}
                value={params.value}
                display={params.display}
                onChange={params.ops.onChange}
                onDefine={params.ops.onDefine} />
            {params.field.error_state && <div style={{color: 'red'}}>{params.field.error_message}</div>}
        </div>
    );
};
const BlockColumn = (params) =>{
    return (
        <div className="row" style={{display:params.show ? '' : 'none'}}>
            <div className={'col-md-4'}>
                <BlockLabel field={params.field}/>
            </div>
            <div className={'col-md-8'}>
                <BlockTextInput obj={params.obj} field={params.field} ops={params.ops} value={params.value}/>
            </div>

        </div>);
};
let BlockLabel = (params) =>{
    let is_required = (params.field.validations || []).find((valid) => valid.validation_type === 'required');
    return (
        <div><label style={{fontWeight:'600'}}>{params.field.field_name}</label>{is_required && <span style={{color:'red'}}>&nbsp;*</span>}</div>
    );
};
let ButtonGroup = (params) =>{
    return (<div className={'btn-group'}>
        <button className="btn btn-primary btn-sm" onClick={params.ops.onSave}>Save</button>&nbsp;
        <button onClick={params.ops.onCancel}  className="btn btn-primary btn-sm">Cancel</button>
        {(params.buttons || []).map(i=><span>&nbsp;<button className={'btn btn-primary btn-sm'} onClick={()=>params.ops.onButtonPress(i)}>{i.name}</button></span>)}
    </div>);
};
let FormClear = () =>{
    return <div className="clearfix"/>;
};
