import {useQuery} from 'react-query';
import React,{useEffect,useState} from 'react';
import {PurchaseOrderDataService} from './PurchaseOrderDataService';
import {PurchaseOrderAdjustmentItemInput} from './PurchaseOrderAdjustmentItemInput';

export function PurchaseOrderVariants({variant,purchaseOrder,sizes, product,deleteVariant}){
    const [curVariant, setCurVariant] =useState({});
    const [curPurcahseOrder,setCurPurchaseOrder] =useState({});
    useEffect(() => {
        setCurVariant(variant);
        setCurPurchaseOrder(purchaseOrder);
    }, [variant,purchaseOrder]);
    const defaultItem = (size)=>{
        return {purchase_order_product_variant_id:variant.id,
            size: size,
            qty:0,
            so_qty:0,
            adjusted_qty: 0,
            purchase_order_id: purchaseOrder.id, product_variant_id:variant.product_variant_id, product_id:variant.product_id};
    };
    const updateCounts = (purchase_order_item)=>{
        let tmpPO = {...curPurcahseOrder};
        tmpPO.purchase_order_items = tmpPO.purchase_order_items || [];
        let item = tmpPO.purchase_order_items.find(i=>{
            return i.purchase_order_product_variant_id === variant.id && i.size === purchase_order_item.size;
        });
        if(!item){
            item = defaultItem(purchase_order_item.size);
            purchaseOrder.purchase_order_items.push(item);
        }else{
            item.adjusted_qty = purchase_order_item.adjusted_qty;
        }
        item.qty = item.adjusted_qty + item.so_qty;
        setCurPurchaseOrder(tmpPO);
    };
    const  getPurchaseOrderItem = (size, variant)=>{

        let item = variant?.sizes?.find(i=>{
            return i.size === size.code;
        });
        return item ||defaultItem(size.code);
    };
    return <>
        <tr key={'variant_record_' + curVariant.id}>
            <td>
                <a className={'inline-nav-link'} onClick={()=>deleteVariant(curVariant)}><i className={'fa fa-trash'}/></a>
            </td>
            <td>
                {product.style_number} {product.description} - {curVariant.product_variations?.variation_code} {curVariant.product_variations?.variation_name}
            </td>
            <td>

                {sizes.map((size,i)=>{
                    return (<span key={'product_current_size_so_qty_' +curVariant.id+ '_' + i}>
                        <label htmlFor="">{size.code}:</label> <label htmlFor="">{getPurchaseOrderItem(size,curVariant).so_qty}</label>&nbsp;&nbsp;
                    </span>);
                })}
            </td>
            <td>
                {sizes.map((size,i)=>{
                    return (
                        <span  key={'product_current_size_value_adjusted_' +curVariant.id+ '_' + i}>
                            <label htmlFor="">{size.code}:</label>&nbsp;
                            <PurchaseOrderAdjustmentItemInput size={size}
                                variant={curVariant}
                                updateCounts={updateCounts}
                                purchase_order_item={getPurchaseOrderItem(size,variant)}/>&nbsp;&nbsp;
                        </span>);
                })}
            </td>
            <td>
                {sizes.map((size,i)=>{
                    return (<span key={'product_current_size_qty_' +curVariant.id+ '_' + i}>
                        <label htmlFor="">{size.code}:</label> <label htmlFor="">{getPurchaseOrderItem(size,curVariant).qty}</label>&nbsp;&nbsp;
                    </span>);
                })}
            </td>
        </tr>
    </>;
}