import axios from 'axios';
import {ObjContainer} from '../../../services/object_container';

export class InvoiceDataService{
    buildInvoiceFromSalesOrder(salesOrder){
        return axios.post('/inventory_operations/copy_sales_order_to_invoice',salesOrder,
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }
    getInvoiceData(method,invoice_id){
        return axios.get(`/inventory_operations/get_invoice_data?invoice_id=${invoice_id}`,
            ObjContainer.resolve('service_manager').resolve('purchase_orders').getHeaders());
    }
    async saveInvoice(invoice){
        return await ObjContainer.resolve('service_manager').resolve('invoices').upsert(invoice.id,invoice);
    } 
    async saveInvoiceItems(invoiceItem){
        await ObjContainer.resolve('service_manager').resolve('invoice_items').postMulti(invoiceItem);
    }
    async saveInvoiceProduct(invoiceProduct){
        await ObjContainer.resolve('service_manager').resolve('invoice_products').upsert(invoiceProduct.id, invoiceProduct);
    }
    async deleteInvoiceProduct(key){
        await ObjContainer.resolve('service_manager').resolve('invoice_products').delete(key);
    }
    async saveInvoiceProductVariant(invoiceProductVariant){
        await ObjContainer.resolve('service_manager').resolve('invoice_product_variants').upsert(invoiceProductVariant.id, invoiceProductVariant);
    }
    async deleteInvoiceProductVariant(key){
        await ObjContainer.resolve('service_manager').resolve('invoice_product_variants').delete(key);
    }
}
