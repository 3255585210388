import {useAuth} from '../authTokenContext';
import {useQuery} from 'react-query';
import AxiosClient from '../AxiosClient';
import constants from '../constants';
const { api } = constants;
const { basePath, options } = api;

const SearchData = async (queryKey,context, params) =>{
    const {
        config ={headers:{'Authorization':`Bearer ${context.token}`,'Content-Type':'application/json' }},
        entity,
        operation,
        tenantId
    } = context;
    try {
        const pathname = `${basePath}${entity}/${operation}`;
        const results = await AxiosClient.get(
            pathname,
            config
        );
        const { data } = results;
        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble searching ${entity}.`);
    }
};
export const useRouteData = (context) =>{
    const auth = useAuth();
    return useQuery(
        [
            `search-${context.entity}`,
            {
                ...auth,
                ...context
            }
        ],
        (args, item) => SearchData(args,item, context)
    );
};