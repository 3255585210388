import React from 'react';

export const DataTypeIcon = ({id})=>{
    if (id === 36 || id === 56 || id===62) {return <span className={'data-type-identifier-int'}>123</span>;}
    if(id=== 231) {return <span className={'data-type-identifier-str'}>ABC</span>;}
    if(id=== 104) {return  <span className={'data-type-identifier-io'}>I/O</span>;}
    if(id === 42){return  <i className={'data-type-identifier-dt fas fa-calendar'}></i>;}
    if(id === 61){return  <i className={'data-type-identifier-dt'}>OTHER</i>;}
    console.log('cannot find', id);
    return <span></span>;
};