import React, {useEffect, useState} from 'react';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import useFieldUpdater from '../../services/hooks/useFieldUpdater';
import {useUpsert} from '../../services/hooks/useUpsert';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];

export const TagDesignSurface = ({setShowDesigner,curDesignItem, setCurDesignItem, save}) => {
    const {update} = useFieldUpdater({state:curDesignItem, setState:setCurDesignItem});
    return <div>
        <button className={'btn btn-primary'} onClick={()=>save()}>Save</button> &nbsp;
        <button className={'btn btn-primary'} onClick={()=>setShowDesigner(false)}>Cancel</button>
        <div className={'container-fluid'}>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="">Design Name</label>
                        <input type="text" className={'form-control'}
                               onChange={(e)=>update('tag_design_name', e.target.value)}
                               value={curDesignItem['tag_design_name'] || ''}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="">Height</label>
                        <input type="number" className={'form-control'} onChange={(e)=>update('tag_height', e.target.value)}  value={+curDesignItem['tag_height'] || 0}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="">Width</label>
                        <input type="number" className={'form-control'} onChange={(e)=>update('tag_width', e.target.value)}  value={+curDesignItem['tag_width'] || 0} />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="">Tags Per Row</label>
                        <input type="number" className={'form-control'} onChange={(e)=>update('tags_per_row', e.target.value)} value={+curDesignItem['tags_per_row'] || 0}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 tag-design-surface">
                    <label htmlFor="">Design</label>
                    <HtmlEditor
                        height="300px"
                        style={{backgroundColor:'white', color:'#333'}}
                        value={curDesignItem['tag_design_template'] || ''}
                        defaultValue={''}
                        onValueChanged={(e)=>update('tag_design_template', e.value)}
                    >
                        <MediaResizing enabled={true} />
                        <Toolbar multiline={true}>
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item
                                name="size"
                                acceptedValues={sizeValues}
                            />
                            <Item
                                name="font"
                                acceptedValues={fontValues}
                            />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            <Item
                                name="header"
                                acceptedValues={headerValues}
                            />
                            <Item name="separator" />
                            <Item name="color" />
                            <Item name="background" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="image" />
                            <Item name="separator" />
                            <Item name="clear" />
                            <Item name="codeBlock" />
                            <Item name="blockquote" />
                            <Item name="separator" />
                            <Item name="insertTable" />
                            <Item name="deleteTable" />
                            <Item name="insertRowAbove" />
                            <Item name="insertRowBelow" />
                            <Item name="deleteRow" />
                            <Item name="insertColumnLeft" />
                            <Item name="insertColumnRight" />
                            <Item name="deleteColumn" />
                        </Toolbar>
                    </HtmlEditor>
                </div>
            </div>
        </div>
    </div>;
};
