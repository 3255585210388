import * as moment from 'moment';
import React from 'react';
import {MultiRowDisplay} from '../components/TableDriver/BasicTableDef';

export const getColumns = (row, index, definition,ops) =>{
    return (definition.fields || []).filter(i=>i.display_class === definition.layout_class || !i.display_class).map((field, col_index)=>{
        return <ColumnItem key={'table-row-col-item-' + index + '-' + col_index}  row={row} field={field} rowClick={ops.rowClick}/>;
    });
};
const formatter = (value, type_name) =>{
    if(isNaN(value) || (type_name !== 'money' ) )
    {return value;}
    return '$' + (value * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
export const ColumnItem = ({row, field, rowClick}) =>{
    if((field.show && !field.show.value) || field.hide_list)
    {return null;} 

    if(field.reference_field){
        return (<td style={{textAlign:'right',whiteSpace:'nowrap'}}>{ row[field.reference_field]}</td>);
    }
    if(field.reference && field.reference.is_multi){
        let val = row[field.field_code] || [];
        if(!(val instanceof Array))
        {val = [];}
        return (
            <td style={{whiteSpace:'nowrap'}}><MultiRowDisplay values={val}/></td>);
    }
    if(field.data_type === 'html'){
        return (
            <td style={{whiteSpace:'nowrap'}}><div dangerouslySetInnerHTML={{ __html: row[field.field_code] }} /></td>);
    }
    if(field.data_type === 'money' && !field.link){
        return (
            <td style={{textAlign:'right',whiteSpace:'nowrap'}}>{formatter(row[field.field_code],'money')}</td>);
    }
    if(field.data_type === 'int' && !field.link){
        return (
            <td style={{textAlign:'right',whiteSpace:'nowrap'}}>{formatter(row[field.field_code],'int')}</td>);
    }
    if(field.data_type === 'bool'){
        return (
            <td style={{textAlign:'left',whiteSpace:'nowrap'}}>{(row[field.field_code] !== undefined && ( row[field.field_code] === '1')      ? 'Yes' : 'No') }</td>);
    }
    if(field.data_type === 'url'){
        return (
            <td style={{textAlign:'left',whiteSpace:'nowrap'}}>
                <a target={'_blank'} href={row[field.field_code]} rel="noreferrer">Click</a>
            </td>);
    }
    if(field.data_type === 'date'){
        return (<td style={{textAlign:'right',whiteSpace:'nowrap'}}>{ row[field.field_code] ? moment(row[field.field_code]).utcOffset(4).format('MM/DD/YYYY') : ''}</td>);

    }
    if(field.data_type === 'timestamp'){
        return (<td style={{textAlign:'right',whiteSpace:'nowrap'}}>{ row[field.field_code] ? moment(row[field.field_code]).utcOffset(4).format('MM/DD/YYYY h:mm') : ''}</td>);
    }
    if(row[field.field_code] instanceof Object){
        if(field.link)
        {return (
            <td style={{whiteSpace:'nowrap'}}>
                <a className={'inline-nav-link'}
                    onClick={(e)=>rowClick(row,field,e)}>{(row[field.field_code].display ? row[field.field_code].display.toString() : row[field.field_code].display) || 'empty'}</a>
            </td>);}
        return (<td style={{whiteSpace:'nowrap'}}>{row[field.field_code].display ? row[field.field_code].display.toString() :''}</td>);
    }
    if(field.link_type === 'replace')
    {return (
        <td style={{whiteSpace:'nowrap'}}>
            <a href={getReplaced(field, row)}  >{field.field_name}</a>
        </td>);}
    else if(field.link)
    {return (
        <td style={{whiteSpace:'nowrap'}}>
            <a className={'inline-nav-link'} onClick={(e)=>rowClick(row,field,e)} >{(row[field.field_code] ? row[field.field_code].toString() : row['id'])  || 'empty'}</a>
        </td>);}
    return (<FormattedFieldRow row={row} field={field}/>);
    
};
export function getReplaced(field, row){
    return field.link.replace(
        /{(\w*)}/g,
        ( m, key )=> {
            return row.hasOwnProperty( key ) ? row[ key ] : '';
        });
}
export const FormattedFieldRow = (params) =>{
    let formatData = (data, data_type) =>{
        switch((data_type || {}).value){
            case 'money':
                return '$' + (data * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            default:
                return data.toString();
        }
    };
    return <td style={{whiteSpace:'nowrap'}}>{formatData(((params.row[params.field.field_code] === undefined ||  params.row[params.field.field_code] === null) ? '' : params.row[params.field.field_code]),params.field.data_type)}</td>;
};
