import React, {Component} from 'react';
import {TableMasterComponent} from './TableMasterComponent';
import {ObjContainer} from '../../services/object_container';
import {TableOperations} from '../../services/table_operations';
import {CustomTableDef} from './CustomTableDef';
import {MetaTable} from './MetaTable/MetaTable';
// This function takes a component...

class DataSource{
    registrations = [];
    service_manager = ObjContainer.resolve('service_manager');
    data = [];
    constructor(){
    }
    addChangeListener(defMethod){
        this.registrations.push(defMethod);
    }
    removeChangeListener(defMethod){
        this.registrations.splice(this.registrations.indexOf(defMethod),1);
    }
    async updateData(filter){
        let res = await this.service_manager.resolve('invoices').search(filter);
        this.data= res.data;
        this.onChange(this.data);
    }
    getCurrentData(){
        return this.data;
    }
    onChange(){
        this.registrations.forEach((handle)=>{handle();});
    }
}
function withSubscription(WrappedComponent, dataSource,tableOperations, selectData,definition,filter,ops) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.handleChange = this.handleChange.bind(this);
            let data = selectData();
            this.state = {data:data.data, paging:{count:data.count, page_size: data.page_size}};
        }

        async componentDidMount() {
            dataSource.addChangeListener(this.handleChange);
        }

        componentWillUnmount() {
            dataSource.removeChangeListener(this.handleChange);
        }

        handleChange() {
            let res = selectData();
            tableOperations.pageConfig = {count:+res.count, page_size: res.page_size,page:(res.page || 1)};
            this.setState({
                data: res.data,
                paging: {count:+res.count, page_size: res.page_size}
            });
        }
        render() {
            return <CustomTableDef sort={filter.sort}
                definition={definition}
                {...this.state}
                ops={ops}
                rows={this.state.data}
                paging={this.state.paging}/>;

        }
    };
}
export const InvoiceSearch = ()=>{
    return (
        <div>
            <h3>Invoice Search</h3>
            <MetaTable table={{table_name:'invoices'}} column_list={'invoice_simplified'} />
        </div>);
};
