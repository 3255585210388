import React, {useState} from 'react';
import {ObjContainer} from '../../../services/object_container';

import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import {useSearchClear} from '../../../services/hooks/useSearch';

import {ImportColumns} from './ImportColumns';

import classes from '../../Products/InventoryManager/InventoryManager.module.scss';
import TabbedView from '../../CommonUI/TabbedView/TabbedView';
import {ImportGeneral} from './ImportGeneral';
import {DataView} from './DataView';
const hookContext = {
    entity: 'import_files'
};
export const ImportManager= ()=>{
    const [definition, setDefinition ] = useState({import_table_name:'', lines_to_skip:0});
    const [message, setMessage] = useState('');
    const [cols, setCols] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [schemaDisabled, setSchemaDisabled] = useState(true);
    const [csvRows, setCsvRows] = useState([]);
    const [file, setFile] = useState('');
    const [ext, setExt] = useState('');
    const {update} = useFieldUpdater({state:definition, setState:setDefinition});
    const { clear } = useSearchClear({
        ...hookContext
    });
    const handleFiles = (e)=>{
        let newFile = e.target.files[0];

        let lastDot = newFile.name.lastIndexOf('.');
        let fileName = newFile.name.substring(0, lastDot);
        let cExt = newFile.name.substring(lastDot + 1);
        setExt(cExt);
        setFile(newFile);
        showSchema(newFile,cExt);
        setSchemaDisabled(false);
    };
    const upload = async ()=>{
        setUploadDisabled(true);
        await ObjContainer.resolve('import_service').import_file([file],definition,definition);
        await clear();
        setMessage('Upload Finished Successfully');

    };
    const showSchema = (newFile,cExt)=>{
        if(cExt === 'csv'){
            let reader = new FileReader();
            reader.onload = (e)=> {
                let data = reader.result.split('\n');
                let headers = data[+definition.lines_to_skip].split(',');
                setCsvRows(data.slice(1, 11).map((item)=>{
                    let itemObj = {};
                    let varItems = item.split(',');
                    headers.map((head, headIndex)=>{
                        itemObj[head] =  varItems[headIndex];
                    });
                    return itemObj;
                }));
                setCols(headers);
                let updated  = update('file_name',newFile.name);
                updated.fields =  headers;
                setDefinition(updated);
            };
            reader.readAsText(newFile);
        }
    };
    const tabs = () => {
        return [
            {
                title: 'General',
                view: (
                    <ImportGeneral handleFiles={handleFiles} uploadDisabled={uploadDisabled} update={update} upload={upload} definition={definition} />
                ),
                disabled: false,
            },
            {
                title: 'Preview',
                view: (
                    <DataView cols={cols} rows={csvRows} />
                ),
                disabled: schemaDisabled,
            },
            {
                title: 'Mapping',
                view: (
                    <ImportColumns cols={cols} />
                ),
                disabled: schemaDisabled,
            }];
    };
    return (<div className={'container-fluid'}>
        { message && <><br/><div className="alert alert-success" role="alert">
            {message}
        </div></>}
        <div className="row">
            <div className="col-md-12">
                <div className={classes.tabbedView}>
                    <TabbedView tabs={tabs()} />
                </div>
            </div>
        </div>
    </div>);

};

