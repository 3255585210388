import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import useElementRect, { useWindowSize } from '../../../services/hooks/useElementRect';
import classes from './FormattedTable.module.scss';

import FormattedTableCell from './FormattedTableCell_FormEdit';

const FormattedTable = forwardRef(
    (
        {
            headers,
            data,
            selectedItem = null,
            onItemSelected = null,
            isFormEditing = false,
            isFormCommitted = false,
            wrapperEl,
        },
        ref
    ) => {
        const [lastScroll, setLastScroll] = useState(null);
        useImperativeHandle(ref, () => ({
            onScroll: (event) => {
                setLastScroll(event.timeStamp);
            },
        }));

        const windowSize = useWindowSize();
        const selectedRowEl = useElementRect([selectedItem, windowSize.width, windowSize.height, lastScroll]);
        const iconEl = useElementRect([selectedItem]);
        const headerEl = useElementRect([windowSize.width, windowSize.height]);

        const [iconPosition, setIconPosition] = useState({ x: 0, y: 0 });
        const [iconRotation, setIconRotation] = useState(90);

        const processPositionChange = () => {
            if (selectedRowEl.rect !== null && iconEl.rect !== null) {
                const selectedRowRect = selectedRowEl.rect;
                const wrapperRect = wrapperEl.rect;
                const headerRect = headerEl.rect;
                const iconRect = iconEl.rect;

                const iconPosInRow = (selectedRowRect.height - iconRect.height) / 2;
                const halfRowHeight = selectedRowRect.height / 2;

                const pixelsAbove = wrapperRect.y + headerRect.height - selectedRowRect.y;
                const pixelsBelow = selectedRowRect.y + selectedRowRect.height - (wrapperRect.y + wrapperRect.height);
                const yPos =
                    pixelsAbove > halfRowHeight - iconRect.height / 2
                        ? wrapperRect.x + headerRect.height
                        : pixelsBelow > halfRowHeight - iconRect.height / 2
                        ? wrapperRect.x + wrapperRect.height - iconRect.height
                        : selectedRowRect.y + iconPosInRow;

                setIconPosition({
                    x: wrapperEl.rect.x - iconRect.width - 1,
                    y: yPos,
                });

                const rotation =
                    pixelsAbove > halfRowHeight
                        ? 90 - 90 * Math.min(...[(pixelsAbove - halfRowHeight) / halfRowHeight, 1])
                        : pixelsBelow > halfRowHeight
                        ? 90 + 90 * Math.min(...[(pixelsBelow - halfRowHeight) / halfRowHeight, 1])
                        : 90;
                setIconRotation(rotation);
            }
        };

        useEffect(() => {
            processPositionChange();
        }, [selectedRowEl.rect, iconEl.rect]);

        return (
            <table className={classes.fullWidthTable}>
                <thead ref={headerEl.ref}>
                    <tr>
                        {headers.map((header) => {
                            return (
                                <th key={header.key} style={header.colStyles}>
                                    {header.colTitle}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {(data || []).map((row) => {
                        const isSelected = selectedItem && row.key === selectedItem.key;
                        const rowClasses = [
                            isSelected ? classes.selectedRow : '',
                            onItemSelected ? '' : classes.nonSelectable,
                        ].join(' ');
                        return (
                            <tr
                                key={row.key}
                                ref={isSelected ? selectedRowEl.ref : null}
                                className={rowClasses}
                                onClick={
                                    onItemSelected
                                        ? () => {
                                              onItemSelected(row.key);
                                          }
                                        : null
                                }
                            >
                                {headers
                                    .sort((a, b) => a.key > b.key)
                                    .map((header) => {
                                        return (
                                            <FormattedTableCell
                                                key={[header.key, row.key].join('-')}
                                                header={header}
                                                dataFields={row}
                                                isSelected={isSelected}
                                                iconElRef={iconEl.ref}
                                                iconPosition={iconPosition}
                                                iconRotation={iconRotation}
                                                isFormEditing={isFormEditing}
                                                isFormCommitted={isFormCommitted}
                                            />
                                        );
                                    })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
);

export default FormattedTable;
