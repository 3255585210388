import React, {useState,useEffect} from 'react';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import {Popup} from '../../FormDriver/FormBuilderSupportComponent';
import {ProductDataService} from './ProductDataService';
import {PrintSelect} from './PrintSelect';
import {ProductVariants} from './ProductVariants';
import {ObjContainer} from '../../../services/object_container';
import {ProductDropdown} from '../Invoices/ProductDropdown';
const productDataService = new ProductDataService();
export function ProductTableDisplay({salesOrder}){
    const [variants, setVariants] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [curSalesOrder, setCurSalesOrder] = useState(false);
    const {data:order_products_variations}  =  useQuery(['order_product_variants',curSalesOrder.id],productDataService.getOrderProductVariations);
    const queryCache = useQueryCache();
    useEffect(()=>{
        setCurSalesOrder(salesOrder);
    },[salesOrder]);

    const [addMutate] = useMutation(productDataService.saveProductOrderVariant,{
        onSuccess: async (res,params) => {
            await queryCache.invalidateQueries(['order_product_variants',salesOrder.id]);
        },
    });
    React.useEffect(() => {
        if (order_products_variations) {
            setVariants(order_products_variations);
        } else {
            setVariants([]);
        }
    }, [order_products_variations ]);


    const addVariant = async (variant)=>{
        console.log('variant', variant);
        setVariants([...variants,variant]);
        setShowSelect(false);
        await addMutate({product_variant_id:variant.product_variant_id,product_id:variant.product_id, order_id: curSalesOrder.id});
    };
    const updateVariants =(variants)=> {
        let v_variants = variants;
        setVariants((v)=>[...v_variants]);
    };
    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    if (status === 'error') {
        return <span>Error: {''}</span>;
    }
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <table className={'table table-striped valerian-table'} >
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th style={{width:'65%'}}>Print</th>
                                <th  style={{width:'25%'}}>Quantity</th>
                            </tr> 
                        </thead>
                        <tbody>
                            <ProductVariants variants={variants} setVariants={updateVariants}/>
                            <tr>
                                <td colSpan={3}>
                                    <a className={'nav-link'} onClick={()=>setShowSelect(!showSelect)}><i className={'fa fa-plus'} /> Add</a>
                                    <ProductDropdown showSelect={showSelect} selectVariant={async (variant)=>await addVariant( variant)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>);
}
