import React from "react";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    async logErrorToMyService(error, errorInfo){

    }
    componentDidCatch(error, errorInfo) {
        this.logErrorToMyService(error, errorInfo).then((err)=>{
        }).catch(err=>{
            console.log("error ")
        });
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}