import { useState } from 'react'

import classes from './CustomReportsEditUsers.module.scss'

import TypeAheadSearch from "../../CommonUI/TypeAheadSearch"
import Button from '../../CommonUI/Button'
import ButtonType from '../../CommonUI/ButtonType'
import TitledTable from '../../CommonUI/TitledTable/TitledTable'

const CustomReportsEditUsers = ({ currentUsers=[], setCurrentUsers }) => {
    const [ selectedNewUser, setSelectedNewUser ] = useState(null);
    const [ selectedCurrentUser, setSelectedCurrentUser ] = useState(null);

    const mapRowWithStringDescription = user => {
        return {
            id: user.id,
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            stringDescription: `${user.username}: ${user.first_name} ${user.last_name}`,
        }
    }

    const onNewUserPicked = (selection) => {
        setSelectedNewUser(selection)
    }

    const onNewUserAdded = () => {
        setCurrentUsers(prevArray => {
            return [
                ...prevArray,
                {
                    key: selectedNewUser.id,
                    userId: selectedNewUser.id,
                    username: selectedNewUser.username,
                    firstName: selectedNewUser.firstName,
                    lastName: selectedNewUser.lastName
                }
            ]
        })
    }

    const onCurrentUserPicked = (currentUserKey) => {
        setSelectedCurrentUser(currentUsers.find(user => user.key === currentUserKey))
    }

    const onCurrentUserRemoved = () => {
        setCurrentUsers(prevArray => {
            const userIdToRemove = selectedCurrentUser.userId
            return [
                ...prevArray.filter(user => user.userId !== userIdToRemove)
            ]
        })
        setSelectedCurrentUser(null)
    }

    return <div>
        <p>Choose a user from the typeahead search below and then click "Allow User +" to add the user to the Allowed Users to view this report. To remove a user, click the user's name in the table on the right and then click the "Remove Allowed User" button.</p>
        <div className={classes.typeAheadWrapper}>
            <TypeAheadSearch
                label='Select&nbsp;User'
                tableName='users'
                excludeIdsArray={currentUsers?.map(user => user.userId)}
                mapRowWithStringDescription={mapRowWithStringDescription}
                searchColumnNames={['username', 'first_name', 'last_name']}
                onItemPicked={onNewUserPicked}
            />
            <Button
                label={
                    <div className={selectedNewUser ? '' : classes.disabledButton}>
                        <i className={'fa fa-plus'} style={{ paddingRight: '6px' }}></i>
                        <b>Add</b>
                    </div>
                }
                action={onNewUserAdded}
                type={ButtonType.link}
            />
        </div>
        <div className={classes.allowedTableWrapper}>
            <TitledTable
                title={<div className={classes.tableTitleBarWrapper}>
                    <h4>Allowed Users</h4>
                    <Button
                        label={<div className={[classes.removeButtonWrapper, (selectedCurrentUser ? '' : classes.disabledButton)].join(' ')}>
                            <i className={["fas fa-minus-circle", classes.removeIcon].join(' ')} />
                            Remove User Access
                        </div>}
                        action={onCurrentUserRemoved}
                        type={ButtonType.link}
                    />
                </div>}
                headers={[
                    { key: '1-username', colTitle: 'Username', colKey: 'username' },
                    { key: '2-firstName', colTitle: 'First Name', colKey: 'firstName' },
                    { key: '3-lastName', colTitle: 'Last Name', colKey: 'lastName' }
                ]}
                data={currentUsers}
                selectedItem={selectedCurrentUser}
                onItemSelected={onCurrentUserPicked}
            />
        </div>
    </div>
}

export default CustomReportsEditUsers