import React, {useEffect, useState} from 'react';
import useSearch from '../../../services/hooks/useSearch';
import {AutoComplete} from 'primereact/autocomplete';
const productHookContext = {
    entity: 'product_variations',
    expands:{'products':'product_id'}
};
export const ProductDropdown = ({showSelect, selectVariant}) =>{
    const [show, setShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();
    const [curProducts, setCurProducts] = useState([]);
    const [filter, setFilter] = useState({});
    const { data:products } = useSearch({
        ...productHookContext,
        filter_or:filter
    });
    useEffect(()=>{
        setShow(showSelect);
    },[showSelect]);
    useEffect(()=>{
        if(products && products.data)
        {
            setCurProducts(products.data.map((product_variant)=>{
                return { label: `${product_variant.products.style_number}  ${product_variant.products.description} - ${product_variant.variation_code}  ${product_variant.variation_name}`,
                    value:{product_variant_id:product_variant.id,product_id:product_variant.product_id}  };
            }));}
    },[products]);
    const searchProducts = (event) => {
        let tmpFilter = {...filter};
        tmpFilter['products.description']={eq:event.query};
        tmpFilter['products.style_number']={eq:event.query};
        tmpFilter['variation_code']={eq:event.query};
        tmpFilter['variation_name']={eq:event.query};
        setFilter(tmpFilter);
    };
    const setSelectItem = (val)=>{
        setSelectedProduct(val);
    };
    const onSelect = (e)=>{
        selectVariant(e.value.value);
    };
    if(!show)
    {return <></>;}
    return <div>
        <AutoComplete value={selectedProduct} forceSelection={true}
            inputClassName={'form-control'} className={'valerian-ta'}
            suggestions={curProducts}
            completeMethod={searchProducts} field={'label'}
            onChange={(e) => setSelectItem(e.value)} onSelect={onSelect}/>
    </div>;
};