import { useMutation, useQueryCache } from 'react-query';
import AxiosClient from './AxiosClient';

import { useAuth } from './authTokenContext';
import constants from './constants';

const { api } = constants;
const { basePath } = api;

const Add = async (payload, context) => {
    const {
        config = { headers: { Authorization: `Bearer ${context.token}`, 'Content-Type': 'application/json' } },
        entity,
    } = context;

    try {
        const pathname = `${basePath}${entity}`;
        const results = await AxiosClient.post(pathname, payload, config);
        const { data } = results;

        return data;
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble adding ${entity}.`);
    }
};

const useAdd = (context) => {
    const auth = useAuth();
    const queryCache = useQueryCache();
    const { entity = 'UNK', invalidateQuery } = context;
    const key = `add-${entity}`;

    return useMutation(async (variables) => await Add(variables, { ...auth, ...context }), {
        mutationKey: key,
        onSuccess: async (data, obj) => {
            if (invalidateQuery) {
                await queryCache.invalidateQueries(invalidateQuery);
            }
            await queryCache.invalidateQueries(`search-${context.entity}`);
            if (context.onSave) context.onSave(data.result[0]);
        },
    });
};

export default useAdd;
