import React from "react";
import {SelectorPopup} from "../../FormDriver/FormComponents";

export function CustomerSelectDisplay({customer, setCustomer}){

    return <>
        <SelectorPopup display={customer ? customer.customer_name :null}
                       placeHolder={"Select Customer"}
                       toTable={"customers"}
                       selected={(customer)=>setCustomer(customer)} />
    </>
}
