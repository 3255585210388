import {ObjContainer} from '../../services/object_container';
import React from 'react';
import useNav from '../../services/hooks/useNav';

export const Header = ({logo_url,onTopbarMenuButtonClick}) => {
    const {navTo} = useNav();
    let onClick = () => {
        ObjContainer.resolve('user_manager').logout();
    };
    let onTopbarItemClick=(e,val)=>{ 

    };
    return (
        <>{(ObjContainer.resolve('user_manager') || {isLoggedIn: () => false}).isLoggedIn() &&
        <div className="topbar clearfix">
            <div className="topbar-left" style={{backgroundColor:'white', backgroundImage:'none', textAlign:'center'}} >
                <a className={'inline-nav-link'} onClick={()=>navTo('')}><img style={{height: '75px',padding:'10px'}} src={logo_url}/></a>
            </div>

            <div className="topbar-right">

                <button className="p-link" id="topbar-menu-button" onClick={onTopbarMenuButtonClick}>
                    <i className="fa fa-bars"></i>
                </button>

                <ul className={'topbar-items fadeInDown'}>

                    <li className={'search-item active-top-menu'}
                        onClick={(e) => onTopbarItemClick(e, 'search')}>
                        <div className="topbar-search">
                            <input type="text" placeholder="Search" />
                            <i className="fa fa-search"></i>
                        </div>
                    </li>
                    <li>
                        <a href={'#'} style={{color:'white'}} onClick={(e) => onClick()}>Logout</a>
                    </li>
                </ul>
            </div>
        </div>}
        </>
    );
};