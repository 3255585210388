import React from 'react';
import { LeftNav } from './LeftNav';
import { Header } from './Header';
import { ErrorBoundary } from './ErrorBoundary';
import { MainSimplifiedContent } from './MainSimplifiedContent';

export const SimplifiedSiteBody = ({ config, logo_url, children }) => {
    return (
        <div className={'site-body'}>
            {!config.hide_header && <Header logo_url={logo_url || '/valerian-logo.png'} />}
            <LeftNav />
            <ErrorBoundary>
                <MainSimplifiedContent config={config}>{children}</MainSimplifiedContent>
            </ErrorBoundary>
        </div>
    );
};
