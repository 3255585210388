import {useState} from "react";
import moment from "moment";

const useSalesForecastingSearch = (preText = 'Show data between dates') => {

    const [searchData, setSearchData] = useState({
        fromDate:moment().dayOfYear(1),
        toDate: moment(),
        soFromDate: moment().dayOfYear(1),
        soToDate:moment(),
        productCode:'',variantCode:''});

    const [presetValue, setPresetValue] = useState('This Year');

    const defaultValues = [
        {
            label: 'This Month',
            dates: () => {
                return {
                    from: moment().startOf('month'),
                    to: moment(),
                };
            },
        },
        {
            label: 'Last Month',
            dates: () => {
                return {
                    from: moment().subtract(1, 'months').startOf('month'),
                    to: moment().startOf('month').subtract(1, 'days'),
                };
            },
        },
        {
            label: 'This Year',
            dates: () => {
                return {
                    from: moment().startOf('year'),
                    to: moment(),
                };
            },
        },
        {
            label: 'Last Year',
            dates: () => {
                return {
                    from: moment().subtract(1, 'years').startOf('year'),
                    to: moment().startOf('year').subtract(1, 'days'),
                };
            },
        },
    ];

    const updateDate = (field, value) => {

        let tmp = {...searchData};
        tmp[field] = moment(value);
        if(!tmp[field].isValid() )
            return;
        setSearchData(tmp);
        setPresetValue('');
    };
    const updateValue = (field, value) =>{
        let tmp = {...searchData};
        tmp[field] =value;
        setSearchData(tmp);
    }
    const onChoosePreset = (e) => {
        setPresetValue(e.target.value);
        const dates = defaultValues.find((obj) => obj.label === e.target.value).dates();
        let tmp = {...searchData};
        tmp.fromDate = dates.from;
        tmp.soFromDate = dates.from;
        tmp.toDate = dates.to;
        tmp.soToDate = dates.to;
        setSearchData(tmp);
    };

    const form = (
        <form>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className="col-md-2">
                        <label htmlFor="">Invoices:</label>
                    </div>
                    <div className="col-md-3">
                        <input
                            type={'date'}
                            name={'fromDate'}
                            placeholder={"From Date"}
                            value={searchData.fromDate.format('YYYY-MM-DD')}
                            onChange={(e)=>updateDate('fromDate', e.target.value)}
                        />
                    </div>
                    <div className="col-md-3">
                        <input type={'date'} name={'toDate'} placeholder={"To Date"} value={searchData.toDate.format('YYYY-MM-DD')}
                               onChange={(e)=>updateDate('toDate', e.target.value)}/>
                    </div>

                </div>
                <div className={"row"}>
                    <div className="col-md-2">
                        Order Start Date:
                    </div>
                    <div className="col-md-3">
                        <input
                            type={'date'}
                            name={'salesFromDate'}
                            value={searchData.soFromDate.format('YYYY-MM-DD')}
                            onChange={(e)=>updateDate('soFromDate', e.target.value)}
                        />
                    </div>
                    <div className="col-md-3">
                        <input type={'date'} name={'toDate'} value={searchData.soToDate.format('YYYY-MM-DD')}
                               onChange={(e)=>updateDate('soToDate', e.target.value)}/>
                    </div>

                </div>
                <div className={"row"}>
                    <div className="col-md-2">
                        Product:
                    </div>
                    <div className="col-md-3">
                        <input
                            type={'text'}
                            placeholder={"Style Number"}
                            value={searchData.productCode}
                            onChange={(e)=>updateValue('productCode', e.target.value)}
                        />
                    </div>
                    <div className="col-md-3">
                        <input
                            type={'text'}
                            placeholder={"Variant Code"}
                            value={searchData.variantCode}
                            onChange={(e)=>updateValue('variantCode', e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <select
                        name={'presets'}
                        value={presetValue}
                        placeholder={'Choose a preset.'}
                        onChange={onChoosePreset}
                    >
                        <option key={'blank'} value={''} disabled={true}>
                            or choose a preset range...
                        </option>
                        {defaultValues.map((obj) => {
                            return (
                                <option key={obj.label} value={obj.label}>
                                    {obj.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </form>
    );

    return [
        form,
        {...searchData},
    ];
};

export default useSalesForecastingSearch;