import { useState, useEffect } from 'react';
import useTableData from '../../../services/hooks/useTableData';
import TitledTable from '../../CommonUI/TitledTable/TitledTable';
import Button from '../../CommonUI/Button';
import ButtonType from '../../CommonUI/ButtonType';

const VariantTable = ({
    selectedProduct: { style_number, description },
    variantArray,
    selectedVariant,
    setSelectedVariant,
    onEnterAddMode,
    onFilter,
}) => {
    const onVariantRowSelected = (selectedKey) => {
        if (selectedKey !== null) {
            const selectedVariation = variantArray.find((v) => v.key === selectedKey);
            setSelectedVariant({
                ...selectedVariation,
                key: selectedVariation.key,
            });
        }
    };

    const tableTitle = (
        <>
            <div>
                <h4>{style_number ? `${style_number} - ${description}` : 'Choose a Product...'}</h4>
                {style_number && (
                    <Button
                        label={
                            <>
                                <i className={'fa fa-plus'} style={{ paddingRight: '6px' }}></i>
                                <b>Add</b>
                            </>
                        }
                        action={onEnterAddMode}
                        type={ButtonType.link}
                    />
                )}
            </div>
            <div style={{ visibility: style_number ? 'visible' : 'hidden' }}>
                <div style={{ paddingTop: '4px' }}>
                    <input type='checkbox' name='showArchived' onChange={(e) => onFilter(e)} />
                    <span style={{ paddingLeft: '8px' }}>Show Archived</span>
                </div>
            </div>
        </>
    );

    return (
        <TitledTable
            title={tableTitle}
            headers={[
                { key: '1-Code', colTitle: 'Code', colKey: 'code' },
                {
                    key: '2-Description',
                    colTitle: 'Variant Description',
                    colKey: 'variant_description',
                },
                { key: '3-Sku', colTitle: 'Sku', colKey: 'sku' },
            ]}
            data={variantArray}
            selectedItem={selectedVariant}
            onItemSelected={onVariantRowSelected}
        />
    );
};

export default VariantTable;
