import { useState } from 'react';
import useOuterClick from '../../services/hooks/useOuterClick';

import classes from './BackgroundBlocker.module.scss';

import Modal from './Modal';
import Button from './Button';

const blurredBackgroundId = 'blurredBackground';

export const BackgroundBlocker = ({ children, foregroundRef }) => {
    return (
        <>
            <div className={classes.foreground} ref={foregroundRef}>
                {children}
            </div>
            <div id={blurredBackgroundId} className={classes.blurredBackground}></div>
        </>
    );
};

const ConditionalBackgroundBlocker = ({
    condition,
    children,
    modalProps: { header, content } = {
        header: 'Data Entry in Progress',
        content: 'Please finish editing or cancel the open content before continuing to a different part of the app.',
    },
}) => {
    const [showModal, setShowModal] = useState(false);
    const foregroundRef = useOuterClick((e) => {
        if (e.target.id === blurredBackgroundId) {
            setShowModal(true);
        }
    });

    return (
        <>
            {showModal && (
                <Modal
                    header={header}
                    content={content}
                    buttonArray={[<Button label={'OK'} action={() => setShowModal(false)}/>]}
                />
            )}
            {condition ? <BackgroundBlocker foregroundRef={foregroundRef}>{children}</BackgroundBlocker> : children}
        </>
    );
};

export default ConditionalBackgroundBlocker;
