import {ServiceManager} from './service_manager';
import {NavRouter} from './core/nav_router';
import {UserManagementService} from './user_management_service';
import {DataManager} from './data_manager';
import {SocketService} from './socket_service';
import {AuthenticationService} from './authentication_service';
import {ImportService} from './import_service';
import {DataConnectionService} from './data_connection_service';

const container = {};
export class ObjContainer {

    static async initializeTables(){
        await ObjContainer.resolve('service_manager').initialize();
        await ObjContainer.resolve('socket_service').connect();
    }

    static initializeContainer(config){
        ObjContainer.register('nav',             new NavRouter(config));
        ObjContainer.register('service_manager', new ServiceManager(config));
        ObjContainer.register('data_manager',    new DataManager(config));
        ObjContainer.register('user_manager',    new UserManagementService(config));
        ObjContainer.register('socket_service',  new SocketService(config));
        ObjContainer.register('authentication_service',  new AuthenticationService(config));
        ObjContainer.register('import_service',  new ImportService(config));
        ObjContainer.register('data_connection_service',  new DataConnectionService(config));

    } 

    static register(type_name, type_def){
        container[type_name] = type_def;
    }

    static resolve(type_name){
        return container[type_name];
    }
}

export class ScopedObjContainer {
    constructor(config={}){
        this.config = config;
        this.container = {};
    }
    initialize(){
        return this;
    }
    register(type_name, type_def){
        this.container[type_name] = type_def;
        return this;
    }
    resolve(type_name){
        return this.container[type_name];
    }
}
