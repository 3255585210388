import classes from './FormBuilderInputComponents.module.scss';

import Button from '../Button';
import ButtonType from '../ButtonType';

export const InputTypes = {
    text: 'text',
    checkbox: 'checkbox',
    number: 'number',
    select: 'select',
    selectWithAdd: 'selectWithAdd',
    checkboxGroup: 'checkbox_group',
    customComponent: 'custom_component'
}
const convertToBoolean = (value)=>{
  if(typeof value === 'string' || value instanceof String){
      return String(value).toLowerCase() === "true";
  }
  return value;

}
const FormBuilderInput = ({props: {
        inputType, fieldName, value, placeholder, onChange, onAdd, options = [{}],
        customComponent = null} }) => {
    const commonProps = {
        name: fieldName,
        type: inputType,
        value: value,
        checked: inputType === 'checkbox' ? convertToBoolean(value) : value,
        placeholder: placeholder,
    }
    
    switch(inputType) {
        case InputTypes.text:
        case InputTypes.checkbox:
        case InputTypes.number:
            return <input 
                {...commonProps}
                onChange={(e)=>{
                    onChange(e.target.name, e.target.value);
                }}
                className={inputType === 'checkbox' ? classes.checkbox : classes.detailInput}
            />
        case InputTypes.select:
        case InputTypes.selectWithAdd:
            const isAdd = inputType === InputTypes.selectWithAdd
            return <div className={isAdd ? classes.selectWithAddWrapper : []}>
                    <div className={classes.selectInput}>
                        <select
                        {...commonProps}
                        onChange={(e)=>{
                            onChange(commonProps.name, e.target.value)
                        }}>
                            <option value={''}>{placeholder ?? ''}</option>
                        {(options || []).map(option => {
                            return <option key={option.value} value={option.value}>{option.label ?? option.value}</option>
                            })}
                        </select>
                        <span className={classes.selectFocus}></span>
                    </div>
                    {isAdd && <Button 
                        label={'+'}
                        action={onAdd}
                        type={ButtonType.symbol}
                    />}
                </div>
        case InputTypes.checkboxGroup:
            return <div className={classes.checkboxGroup}>
                {options.map(option => {
                    console.log("rerendering",value)
                    return <div key={option.value} className={classes.checkboxContainer}>
                        <div className="col-md-3">
                            <label onClick={()=>{
                                onChange(option.value, !value.includes(option.value));
                            }}>
                                <input key={option.value} type={'checkbox'} value={option.value} name={fieldName}
                                       checked={(value || []).includes(option.value)} onChange={(e)=>{
                                    onChange(e.target.name,option.value);
                                }} />
                                <span className={classes.checkmark}></span>
                            </label>

                        </div>
                        <div className="col-md-9">

                            {option.sublabel
                                ?
                                <div className={classes.checkboxGroupLabelWrapper}>
                                    <span className={classes.checkboxGroupLabel}>{option.label ?? option.value}</span>
                                    <span className={classes.checkboxGroupSubLabel}>{option.sublabel}</span>
                                </div>
                                :
                                option.label ?? option.value
                            }
                        </div>
                    </div>
                })}
            </div>
        case InputTypes.customComponent:
            return customComponent
        default:
            return null;
    }
}

export default FormBuilderInput;