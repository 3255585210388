import React, {useEffect, useState} from 'react';
import useSearch from '../../../services/hooks/useSearch';
import {ItemEntry} from './ItemEntry';
const sizesHookContext = {
    entity: 'sizes'
};
export const VariantEditRow = ({variant, invoice,deleteVariant, updateInvoice}) =>{
    const [showSelect, setShowSelect] =          React.useState(false);
    const [curSizes, setCurSizes] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [curInvoice, setCurInvoice] = useState(invoice || {});
    useEffect(()=>{
        if(invoice)
        {setCurInvoice(invoice);}
    },[invoice]);
    const { data:sizes } = useSearch({
        ...sizesHookContext,
        filter:{name:variant.size_range}
    });
    useEffect(()=>{
        if(sizes && sizes.data){
            setCurSizes(sizes.data.sort((i,j)=>i.order_number < j.order_number));
        }
    },[sizes]);
    const getTotalVariantPrice = (variant, product)=>{
        return (curInvoice.invoice_items || []).reduce((val, item)=>{
            if(+variant.id === +item.invoice_product_variant_id){
                val = val + (+item.amount);
            }
            return val;
        },0);
    };
    const setupVariant = (invoice)=>{
        updateInvoice(invoice);
    };

    return (<tr  onClick={()=>{setShowEdit(true);}}>
        <td>
            {!showEdit &&  <a className={'inline-nav-link'} onClick={(e)=>{deleteVariant(variant.id);e.stopPropagation();}}><i className={'fas fa-trash'}/></a>}
            {showEdit &&  <a className={'inline-nav-link'} onClick={(e)=>{setShowEdit(false);e.stopPropagation();}} style={{color:'green'}}><i className={'fas fa-check'}/></a>}
        </td>
        <td className="row-title text-left">
            {variant.product.style_number}  {variant.product.description} - {variant.product_variant.variation_code}  {variant.product_variant.variation_name}
        </td>
        <td className="text-left">
            {!showEdit && curSizes.map((size)=>{
                let ii = invoice.invoice_items?.find(j=>j.invoice_product_variant_id === variant.id && j.size === size.code);
                if(!ii || ii.qty === 0)
                {return <></>;}
                return <span key={'invoice_item_'+variant.product_id+'_item_' + variant.id + 'ii_item' + size.code}>{size.code}:{ii.qty} &nbsp;</span>;
            })}
            {showEdit && curSizes.map((size)=>{
                return <ItemEntry key={'invoice_entry_' + variant.id +'_size_' + size.code} size={size} variant={variant} invoice={invoice}
                    updateInvoice={(val)=>setupVariant(val)} />;
            })}
        </td>
        <td className="text-right">
            ${(+variant.product.price).toFixed(2)}
        </td>
        <td className="text-right">
            ${getTotalVariantPrice(variant,variant.product).toFixed(2)}
        </td>
    </tr>);
};
