import moment from 'moment';
import useBetweenDatesForm from '../../../services/hooks/useBetweenDatesForm';

import Report from '../Report';
import InvoiceSummaryDetails from './InvoiceSummaryDetails';

const queryFn = ({ fromDate = moment().dayOfYear(1), toDate = moment() }) => {
    return `select i.customer_id, c.customer_name, i.id id, i.source, i.location,i.amount as line_total, 
                i.amount_discount,
                (i.amount - i.amount_discount) total_with_discounts,
                COALESCE(i.invoice_no, CAST(i.apm_invoice_id AS VARCHAR), 
                i.shopify_order_display, i.square_order_id) source_id,
                i.date, i.customer_po, sum(ii.qty) qty, sum(ii.amount) amount
                    from invoices i
                        join invoice_items ii on i.id = ii.invoice_id
                        left join customers c on i.customer_id = c.id
                    where ii.qty > 0 and i.date between '${fromDate.format('YYYY/MM/DD')}' and '${toDate.format(
        'YYYY/MM/DD'
    )}'
                    group by i.customer_id, c.customer_name, i.id, i.date, i.customer_po,i.amount,i.amount_discount
                    order by i.source, i.location, c.customer_name asc, i.id asc;`;
};

const InvoicesBySource = {
    title: 'Invoices by Source',
    queryFormHook: useBetweenDatesForm,
    queryFormText: 'Show data from invoices between',
    queryFn: ({ fromDate, toDate }) => queryFn({ fromDate, toDate }),
    columns: [
        {
            dataField: 'source',
            caption: 'Source',
            dataType: 'string',
            groupIndex: 0,
        },
        {
            dataField: 'source_id',
            caption: 'Source Invoice No.',
            dataType: 'string',
            cellRender: (data) => {
                return <a href={`/invoices-Viewinvoices#${data.data.id}`}>{data.value}</a>;
            },
        },
        {
            dataField: 'customer_name',
            caption: 'Customer',
            dataType: 'string',
            cellRender: (data) => {
                const rowData = data.data;
                return data.value ? (
                    <a href={`/customers-Viewcustomers#${rowData.customer_id}`}>{data.value}</a>
                ) : (
                    <>Customer details unavailable.</>
                );
            },
        },
        {
            dataField: 'date',
            caption: 'Date',
            dataType: 'date',
        },
        {
            dataField: 'customer_po',
            caption: 'Customer P.O.',
            dataType: 'string',
        },
        {
            dataField: 'qty',
            caption: 'Num. Items',
            dataType: 'number',
        },
        {
            dataField: 'amount',
            caption: 'Amount',
            dataType: 'number',
            format: {
                type: 'currency',
                precision: 2,
            },
            alignment: 'right',
        },
    ],
    summaryItems: [
        {
            column: 'qty',
            summaryType: 'sum',
            displayFormat: 'Total Items: {0}',
            alignByColumn: true,
            showInGroupFooter: false,
        },
        {
            column: 'amount',
            summaryType: 'sum',
            valueFormat: {
                type: 'currency',
                precision: 2,
            },
            displayFormat: 'Line Total: {0}',
            alignByColumn: true,
            showInGroupFooter: false,
        },
        {
            column: 'amount_discount',
            summaryType: 'sum',
            valueFormat: {
                type: 'currency',
                precision: 2,
            },
            displayFormat: 'Discounts: {0}',
            alignByColumn: true,
            showInGroupFooter: false,
        },
        {
            column: 'total_with_discounts',
            summaryType: 'sum',
            valueFormat: {
                type: 'currency',
                precision: 2,
            },
            displayFormat: 'Total: {0}',
            alignByColumn: true,
            showInGroupFooter: false,
        },
    ],
    masterDetailKeyExpr: 'id',
    masterDetailComponent: ({ data }) => {
        return <Report report={InvoiceSummaryDetails({ invoiceId: data.data.id })} />;
    },
};

export default InvoicesBySource;
