import {exact} from 'prop-types';

export class FilterService{
    service = null;
    filter = undefined;
    and_filter = undefined;
    sort = {};
    pager = {};
    expand = {};
    context_obj = {};
    constructor(service){
        this.service = service;
    }
    context(obj){
        this.context_obj = obj;
        return this;
    }
    orderBy(obj){
        this.sort = obj;
        return this;
    }
    include(expand){
        this.expand = expand;
        return this;
    }
    includeMulti(expand){
        this.expand_multi = expand;
        return this;
    }
    all(){
        this.pager = {pageSize:2000};
        return this;
    }
    where(filter){
        this.parseFunction (filter).forEach(i=>{
            this.filter = Object.assign({},i, (this.filter || {}));
        });
        return this;
    }
    andWhere(filter){
        this.parseFunction (filter).forEach(i=>{
            this.and_filter = Object.assign({},i,( this.and_filter || {}));
        });
        return this;
    }
    async toList(){ 
        if(this.and_filter){
            let result = await this.service.search(Object.assign({},{pageSize:this.pager.pageSize, filter:this.and_filter}, {sort:this.sort}, {pager:this.pager}, {expands:this.expand},{expand_multi:this.expand_multi}));
            return result.data;
        }
        let result = await this.service.search(Object.assign({},{pageSize:this.pager.pageSize, filter_or:this.filter}, {sort:this.sort}, {pager:this.pager}, {expands:this.expand},{expand_multi:this.expand_multi}));
        return result.data;
    }
    async first(){
        return (await this.toList()).data[0];
    }
    parseFunction (filter) {
        let str = filter.toString();
        let fn_body_idx = str.indexOf('{');
        let fn_body = str.substring(fn_body_idx+1, str.lastIndexOf('}')).replace(/[\n\r]+/g,'');
        let fn_declare = str.substring(0, fn_body_idx);
        let fn_params = fn_declare.substring(fn_declare.indexOf('(')+1, fn_declare.lastIndexOf(')'));
        let fn_body_parsed = fn_body.trim().substring(7);
        fn_body_parsed = fn_body_parsed.substring(0, fn_body_parsed.length - 1).split(' ');
        let left = fn_body_parsed[0];
        let filter_arr = [];
        if(left.indexOf('.') > -1) {
            let exp = left.split('.')[1];
            let op = fn_body_parsed[1];
            let subFilter = {};
            let right = this.evalInContext(fn_body_parsed[2]); //.replace(/'|"/g,"");
            switch (op) {
                case '===':

                    subFilter[exp] = {eq: right};
                    filter_arr.push(subFilter);
                    break;
            }
        }
        return filter_arr;
    }
    evalInContext(exp) {
        let func =new ExecFunction('return (this.' + exp + ')').bind(this.context_obj);
        return func();
        //eval(exp);
    }


}
class ExecFunction extends Function{
    constructor(args){
        super(args);
    }
    getCallee(test){
        return this;
    }
}
