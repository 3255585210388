import { useState, useEffect } from 'react';
import useSearch from './useSearch';

const useTableData = ({
    hookContext: context,
    effectCondition = true,
    dependencies = [],
    mapCallback = (el) => el,
}) => {
    const [rows, setRows] = useState(null);
    const [dataReturned, setDataReturned] = useState(false);
    const { isFetchedAfterMount, data: rowData } = useSearch({ ...context });
    useEffect(() => {
        if (effectCondition && rowData) {
            setRows(rowData.data.map(mapCallback));
            setDataReturned(true);
        } else {
            setRows([]);
            setDataReturned(false);
        }
    }, [...dependencies, rowData]);

    return [rows, setRows, dataReturned, isFetchedAfterMount]; 
};

export default useTableData;
