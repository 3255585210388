import React, { Component } from 'react';
import {ObjContainer} from "../../services/object_container";
import TimesCircle from 'react-icons/lib/fa/times-circle';
import FaEye from 'react-icons/lib/fa/eye';
import moment from 'moment';
import {Dialog} from "primereact/dialog";

export function  Popup({children,show, title,showSave,save,closeModal}){
    const renderBackdrop = ()=>{
        if (show ) {
            return (
                <div className={'modal-backdrop fade ' +  (show ?  "show" :"")} role="presentation" />
            );
        }
        return null;
    };
    return (
        <span>
            <div className={"modal-dialog-popup " + (show ? "show" : "")}
                 id="myModal"
                 style={{display: ((show) ? 'block' : 'none')}}
                 tabIndex="-1" role="dialog"
                 aria-hidden={!show}>
                <div className="" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" >
                                <div className={"row"}>
                                    <div className={"col-md-10"}>
                                        <h4>{title}</h4>
                                    </div>
                                    <div className={"col-md-2 pull-right align-right"}>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body" >
                            {children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                            {showSave && <button type="button" className="btn btn-primary" onClick={save}>Save changes</button>}

                        </div>
                    </div>
                </div>
            </div>
            {renderBackdrop()}
    </span>);
}
export class FlexPopup extends Component{
    renderBackdrop(){
        if (this.props.show ) {
            return (
                <div
                    className={'modal-backdrop fade ' +  (this.props.show ?  "show" :"")}
                    role="presentation"
                />
            );
        }
        return null;
    }
    render(){
        return (
            <span>
            <div className={" " + (this.props.show ? "show" : "")}
                 id="myModal"
                 style={{  display: ((this.props.show) ? 'block' : 'none'),backgroundColor: "#fefefe",
                     border: "1px solid #888",
                     zIndex:"1600",
                     position:"fixed",
                     width: "50%",
                     height: "50vh",
                     top: "50%",
                     transform: "translate(-125%,-78%)"}}
                 tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden={!this.props.show}>
                <div className="" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id="exampleModalLabel">
                                <div className={"row"}>
                                    <div className={"col-md-10"}>
                                        <h4>{this.props.title}</h4>
                                    </div>
                                    <div className={"col-md-2"}>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body" >
                            {this.props.children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.closeModal}>Close</button>
                            {this.props.showSave && <button type="button" className="btn btn-primary" onClick={this.props.save.bind(this)}>Save changes</button>}
                        </div>
                    </div>
                </div>
            </div>
            {this.renderBackdrop()}
        </span>);
    }

}
class SettingsArea extends Component{
    constructor(){
        super();
        this.state = {show:false,history:[],vis_items:{}};
        this.service_manager = ObjContainer.resolve("service_manager");
    }
    async _showModal(){
        let tables_data = await this.service_manager.resolve(this.props.table_name).history(this.props._id);
        this.setState({history:tables_data.data,show:true});
    }
    _closeModal(){
        this.setState({show:false});
    }
    _save(){
        this.setState({show:true});
    }
    showDetails(index, e){
        this.state.vis_items["show_history"+index] = !this.state.vis_items["show_history"+index];
        this.setState({vis_items:this.state.vis_items});
    }
    render(){
        //{ op: 'replace', path: '/iata_code', value: 'LAX' }
        return (<div>
            <h1>Settings</h1>
            <div>
                <a className={"nav-link"} onClick={this._showModal.bind(this)}>History</a>
                <Popup title={"History"} closeModal={this._closeModal.bind(this)} showSave={true} save={this._save.bind(this)} show={this.state.show}>
                    <table className={"table"}><thead><tr><th>Date</th><th>Change</th></tr></thead>
                        <tbody>
                        {((this.state.history || {ops:[]}).ops || []).map((item, index)=>{
                            return (<tr key={"history_item_row_" + index}><td>{moment(item.op_on).format('MMMM Do YYYY, h:mm:ss a')}</td><td>{item.operation}</td><td>
                                <a className={"nav-link"} onMouseEnter={this.showDetails.bind(this,index)} ><FaEye /></a>
                                <div style={{display:this.state.vis_items["show_history"+index] ? "block" : "none",position:"absolute"}}>
                                    <div style={{border: "1px solid silver", borderRadius:"5px", padding: "5px",backgroundColor: "white",width:"200px"}}>
                                        <h4>List of Changes</h4>
                                        {item.changes.map((change, c_index)=>{
                                            return (
                                                <div>
                                                    <span style={{fontWeight:"bold"}}>{change.op}:</span><span>{change.path}</span> -> <span>
                                                        {change.value instanceof Object ? (change.value.display || JSON.stringify(change.value)) : change.value}</span>
                                                </div>
                                            )
                                        })}

                                    </div></div>
                            </td></tr>)
                        })}
                        </tbody></table>
                </Popup>
            </div>
        </div>);
    }
}
export class AnalyticsDefine extends Component{
    constructor(props){
        super();
        this.service_manager = ObjContainer.resolve("service_manager");
        this.state = {data:[],show_analytics:false, obj:{parent_id: props.obj._id,parent_table:"tables", source_table: props.obj.table_name}};
    }
    async componentDidMount(){
        await this.getData();

    }
    async getData(){
        this.data_def = await this.service_manager.resolve("aggregates").get({filter:{parent_id:this.state.obj.parent_id}});
        this.setState({data:(this.data_def.data.data || [])});
    }
    addAnalytics(){
        this.setState((s)=>s.show_analytics = true);
    }
    closeModal(){
        this.setState({show_analytics:false});
    }

    updateVal(update_name, update_value){
        this.setState((s)=>{
            s.obj[update_name]  = update_value;
            return s;
        });
    }
    updateFieldVal(field_name, update_name, update_value){
        this.setState((s)=>{
            s.obj.analytics_fields = s.obj.analytics_fields || {};
            s.obj.analytics_fields[field_name] =  s.obj.analytics_fields[field_name] || {};
            s.obj.analytics_fields[field_name][update_name]  = update_value;
            return s;
        });
    }
    showFields(field_name, show){
        this.setState((s)=>{
            s.obj.analytics_fields = s.analytics_fields || {};
            s.obj.analytics_fields[field_name]  = {show:true};
            return s;
        });
    }
    addRemoveField(field, checked){
        this.setState((s)=>{
            s.obj.analytics_fields = s.obj.analytics_fields || {};
            if(checked)
                s.obj.analytics_fields[field.field_code]  = {field:field,show:true} ;
            else
                delete s.obj.analytics_fields[field.field_code];
            return s;
        });
    }
    async save(){
        this.data_def = await this.service_manager.resolve("aggregates").upsert(this.state.obj._id, this.state.obj);
        await this.getData();
        this.setState({show_analytics:false});
    }
    async deleteItem(item){
        await this.service_manager.resolve("aggregates").delete(item._id);
        await this.getData();
    }
    render(){
        return (<div >
            <button className={"btn btn-primary"} onClick={(e) => this.addAnalytics()}>Add</button>
            <table className={"table"}>
                <thead>
                <tr>
                    <th></th><th>Table</th><th>Field Count</th><th>Destination</th>
                </tr>
                </thead>
                <tbody>
                {this.state.data.map((item)=>{
                    return <tr><td><a href={"#"} onClick={async ()=> await this.deleteItem(item)}><TimesCircle /></a></td>
                        <td>{item.table_name}</td>
                        <td>{Object.keys(item.analytics_fields || {}).length}</td>
                        <td>{item.destination}</td></tr>
                })}
                </tbody>
            </table>
            <Dialog header="Add Member" visible={this.state.show_analytics} style={{width: '50vw'}} modal={true} onHide={() => this.setState({show_analytics: false})}>
                <div style={{height:"500px", overflowY:"auto"}}>
                    <div>Define Aggregate</div>
                    <div className="row">
                        <div className="col-md-3">
                            <label>Output Name</label>
                        </div>
                        <div className="col-md-6">
                            <input type={"text"} name={"table_name"} onChange={(e)=> this.updateVal('table_name', e.target.value)} className={"form-control"}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label>Distinct</label>
                        </div>
                        <div className="col-md-6">
                            <input type={"checkbox"} name={"distinct"} onChange={(e)=> this.updateVal('distinct', e.target.checked)} /><br/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label>Destination</label>
                        </div>
                        <div className="col-md-6">
                            <select name={"destination"} className={"form-control"} onChange={(e)=> this.updateVal('destination', e.target.value)} >
                                <option>--Choose One</option>
                                <option value={"postgres"}>Postgres</option>
                                <option value={"mongodb"}>Mongo</option>
                            </select>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <button className={"btn btn-primary"} onClick={()=>this.save()}>Save</button>
                        </div>
                    </div>
                    <table className={"table"}>
                        <thead>
                        <tr><th>Inc</th><th>Field</th><th>Type</th><th>Aggregate</th></tr>
                        </thead>
                        <tbody>
                        {(this.props.obj.child_fields || []).map((field, index)=>{
                            return (
                                <tr key={"field-analytics-" + index}>
                                    <td><input  type={"checkbox"} onChange={() => this.addRemoveField(field)}/></td>
                                    <td>{field.field_name}</td>
                                    <td><select className={"form-control"} value={((this.state.obj.analytics_fields || {})[field.field_code] || {})['agg_type']} onChange={(e)=> this.updateFieldVal(field.field_code, 'agg_type',e.target.value)}>
                                        <option>--Choose One</option>
                                        <option value={"value"}>Value</option>
                                        <option value={"value_by_quarter"}>Value By Quarter</option>
                                        <option value={"value_by_month"}>Value By Month</option>
                                        <option value={"value_by_year"}>Value By Year</option>
                                        <option value={"value_by_day"}>Value By Day</option>
                                        <option value={"sum"}>Sum</option>
                                        <option value={"avg"}>Average</option>
                                        <option value={"max"}>Max</option>
                                        <option value={"min"}>Min</option>
                                    </select></td>
                                    <td>
                                        <input type={"text"}   className={"form-control"} value={((this.state.obj.analytics_fields || {})[field.field_code] || {})['agg_def']} onChange={(e)=> this.updateFieldVal(field.field_code, 'agg_def', e.target.value)}/>
                                    </td>
                                </tr>);
                        })}
                        </tbody>
                    </table>
                </div>

            </Dialog>

        </div>);
    }
}

export class ChildCollectionDefine extends Component{
    constructor(props){
        super();
        let temp =props.value;
        if(!(props.value instanceof Array))
            temp = [];
        this.state = {relations:[], child_collection: temp || [],obj:{} ,display_options:[
                {display:"Parent Child",value:"parent_child"},
                {display:"Lookup Search",value:"lookup_search"},
                {display:"Attached Item",value:"attached_item"}
            ] };
        this.service_manager = ObjContainer.resolve("service_manager");
    }
    async componentDidMount(){
        let tables_data = await this.service_manager.resolve("tables").get({});
        this.setState({relations:tables_data.data.data});
    }
    onChange(option,e){
        this.state.obj[e.target.name] = option.value;
        this.setState({child_collection: this.state.child_collection});

    }
    onCheckChange(e){
        this.state.obj[e.target.name] = e.target.checked || undefined;
        this.setState(this.state);
    }
    onTextChange(e){
        this.state.obj[e.target.name] = e.target.value;
        this.setState({obj: this.state.obj});

    }
    _saveRoute(e){
        this.state.child_collection = this.state.child_collection || [];
        this.state.child_collection.push(this.state.obj);
        this.setState({obj:{route_key:'',route_dest:''}, child_collection: this.state.child_collection});
        if(this.props.onChange)
            this.props.onChange(this.props.name,this.props.field_code, this.state.child_collection,e);
    }

    _delete(index, e){
        this.state.child_collection.splice(index, 1);
        this.setState({child_collection: this.state.child_collection});
        if(this.props.onChange)
            this.props.onChange(this.props.name,this.props.field_code, this.state.child_collection,e);
    }
    render(){
        return (<div>
            <h1>Child Define</h1>
            <div className={"row"} style={{marginTop: "10px"}}>

                <div className="col-md-3">
                    <label>Child Table:</label>
                </div>
                <div className={"col-md-3"}>
                    <DropDownList value={{display: this.state.obj["table_name"],value:this.state.obj["table_name"]}}
                                  onChange={this.onChange.bind(this)}
                                  name={"table_name"}
                                  options={this.state.relations.map(relation => {
                                      return { display: relation.table_name, value: relation.table_name};
                                  })} />
                </div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-3"}><label>Relation Type:</label></div>
                <div className={"col-md-3"}>
                    <DropDownList value={{display: this.state.obj["relation_type"],value:this.state.obj["relation_type"]}}
                                  onChange={this.onChange.bind(this)}
                                  name={"relation_type"}
                                  options={this.state.display_options} />
                </div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-3"}><label>Title:</label></div>
                <div className={"col-md-3"}><input type={"text"}  className="form-control" onChange={this.onTextChange.bind(this)}
                                                   name={"title"} value={this.state.child_collection["title"]}/></div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-12"}>
                    <button className={"btn btn-primary"} onClick={this._saveRoute.bind(this)}>Save</button>
                </div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-12"}>
                    <table className={"table"}>
                        <thead><tr><th></th><th>Table Name</th><th>Title</th><th>Relation Type</th><th>Ordered</th></tr></thead>
                        <tbody>
                        {(this.state.child_collection || []).map((child, index)=>{
                            return (<tr key={"child-collection-" + index}>
                                <td><a className="nav-link" onClick={this._delete.bind(this, index)} ><TimesCircle/></a></td>
                                <td>{child.table_name}</td>
                                <td>{child.title}</td>
                                <td>{child.relation_type}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>);
    }
}

export class SurveyResultsDefine extends Component{
    constructor(){
        super();
        this.data_manager = ObjContainer.resolve("data_manager");
        this.state = {surveyResults:{}};
    }
    async componentDidMount(){
        //let result = await this.data_manager.getData(`/data-api/survey_data/`,{survey_member_id:(this.props.obj || {})._id});
        //this.setState({surveyResults:(result.data[0] || [])});
    }
    render(){
        return (
            <div>{Object.keys((this.state.surveyResults || {}).survey_data || {}).map((item,index)=>{
                let sitem = this.state.surveyResults.survey_data[item];
                if(sitem && sitem instanceof Object){

                    return (
                        <div key={"survey_item_" + index}>
                            <label style={{fontWeight:"800"}}>{sitem.Question}</label>
                            <br/> {sitem.Result.response_text}  {sitem.Result.response_value}
                        </div>);
                }
                return null;
            })}</div>);
    }
}
export class NotesDefine extends Component{
    render(){
        return <div>Notes</div>;
    }
}
export class ReferenceDefine extends Component{
    constructor(props){
        super();
        this.state = {relations:[], reference_relation: props.value || {} };
        this.service_manager = ObjContainer.resolve("service_manager");
    }
    async componentDidMount(){
        let tables_data = await this.service_manager.resolve("tables").get({});
        let data = tables_data.data.data;
        data.push({table_name:'users'});
        this.setState({relations:data});
    }
    onChange(option,e){
        this.state.reference_relation[e.target.name] = option.value;
        this.setState({reference_relation: this.state.reference_relation});
        if(this.props.onChange)
            this.props.onChange(this.props.name,this.props.field_code, this.state.reference_relation,e);
    }
    onTextChange(e){
        this.state.reference_relation[e.target.name] = e.target.value;
        this.setState({reference_relation: this.state.reference_relation});
        if(this.props.onChange)
            this.props.onChange(this.props.name,this.props.field_code, this.state.reference_relation,e);
    }
    onCheckChange(e){
        this.state.reference_relation[e.target.name] = e.target.checked || undefined;
        this.setState(this.state);
        if(this.props.onChange)
            this.props.onChange(this.props.name,this.props.field_code, this.state.reference_relation,e);
    }
    render() {
        return (<div>
            <div className={"row"} style={{marginTop: "10px"}}>

                <div className="col-md-3">
                    <label>Relation:</label>
                </div>
                <div className={"col-md-3"}>
                    <DropDownList value={{display: this.state.reference_relation["relation_table"]}}
                                  onChange={this.onChange.bind(this)}
                                  name={"relation_table"}
                                  options={this.state.relations.map(relation => {
                                      return { display: relation.table_name, value: relation.table_name};
                                  })} /></div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-3"}><label>Relation Filter:</label></div>
                <div className={"col-md-3"}><input type={"text"}  className="form-control" onChange={this.onTextChange.bind(this)}
                                                   name={"relation_filter"} value={this.state.reference_relation["relation_filter"]}/></div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-3"}><label>Multi:</label></div>
                <div className={"col-md-3"}><input type={"checkbox"}  onChange={this.onCheckChange.bind(this)}
                                                   name={"is_multi"} checked={this.state.reference_relation["is_multi"]}/></div>
            </div>
            <div className={"row"}  style={{marginTop: "5px"}}>
                <div className={"col-md-3"}><label>Display Field Code:</label></div>
                <div className={"col-md-3"}><input type={"text"}  className="form-control" onChange={this.onTextChange.bind(this)}
                                                   name={"field_code"} value={this.state.reference_relation["field_code"]}/></div>
            </div>
        </div>);
    }
}
export class DerivedDropdownDefine extends Component{

    render(){
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-3"}><label>Derivation Type:</label></div>
                    <div className={"col-md-3"}><DropDownList options={[{value:"table_lookup",display:"Table List"}]}/></div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-3"}><label>Table Name:</label></div>
                    <div className={"col-md-3"}><DropDownList options={[{value:"table1",display:"Table 1"}]}/></div>
                </div>
            </div>);
    }
}
