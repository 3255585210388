import classes from './DetailsTable.module.scss';
import { useState, useEffect, useRef } from 'react';

import ConditionalBackgroundBlocker from './BackgroundBlocker';
import Button from './Button';
import ButtonType from './ButtonType';

const DetailsTable = ({ title, dataObjectArray, onSave = null, onCancel = null, isEditing = false }) => {
    const [changeableDataObjectArray, setChangeableDataObjectArray] = useState(dataObjectArray);
    const [isEditable, setIsEditable] = useState(false);
    const [isInEditMode, setEditMode] = useState(isEditing);

    const calculatedFields = changeableDataObjectArray.filter((obj) => Object.keys(obj).includes('calculateValue'));
    const calculatedFieldKeys = calculatedFields.map((field) => field.key);

    useEffect(() => {
        setChangeableDataObjectArray(dataObjectArray);
        setIsEditable(dataObjectArray.some((obj) => obj.isEditable ?? true));
    }, [dataObjectArray]);

    const [focusedField, setFocusedField] = useState(null);
    const firstEl = useRef(null);
    const lastEl = useRef(null);

    useEffect(() => {
        setEditMode(isEditing);
        if (isEditing) {
            firstEl.current?.focus();
            lastEl.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isEditing]);

    const onFieldChange = (event) => {
        let newArray = changeableDataObjectArray.map((obj) => {
            if (obj.inputType === 'checkbox') {
                if (obj.key === event.target.name) {
                    obj.value = event.target.checked;
                }
            } else {
                if (obj.key === event.target.name) {
                    obj.value = event.target.value;
                }
            }

            return obj;
        });
        if (calculatedFieldKeys.length > 0) {
            newArray = newArray.map((obj) => {
                if (calculatedFieldKeys.includes(obj.key)) {
                    obj.value = obj.calculateValue.elements
                        .map((el) => {
                            switch (el.type) {
                                case 'lookup':
                                    return newArray.find((row) => row.key === el.key).value;
                                case 'exact':
                                default:
                                    return el.value;
                            }
                        })
                        .join(obj.calculateValue.delimiter);
                }
                return obj;
            });
        }
        setChangeableDataObjectArray(newArray);
        setFocusedField(event.target.name);
    };

    const tableBody = changeableDataObjectArray
        ?.filter((obj) => obj.key !== 'key')
        .map((obj, idx, arr) => {
            const isEditing = obj.isEditable && isInEditMode;
            const isCheckbox = obj.inputType === 'checkbox';
            return (
                <tr key={obj.key}>
                    <th className={classes.tableHeader}>{obj.key}</th>
                    <td className={isEditing ? classes.tableDataEditMode : classes.tableData}>
                        {isEditing ? (
                            <input
                                ref={idx === 0 ? firstEl : idx === arr.length - 1 ? lastEl : null}
                                className={isCheckbox ? classes.checkbox : classes.detailInput}
                                key={'input-' + obj.key}
                                type={obj.inputType ?? 'text'}
                                name={obj.key}
                                value={obj.value}
                                checked={obj.value}
                                onChange={onFieldChange}
                                autoFocus={obj.key === focusedField}
                            />
                        ) : isCheckbox ? (
                            obj.value ? (
                                <i className={['fas fa-check-circle', classes.check].join(' ')}></i>
                            ) : (
                                <i className={['fas fa-times-circle', classes.times].join(' ')}></i>
                            )
                        ) : (
                            obj.value
                        )}
                    </td>
                </tr>
            );
        });

    const onSaveChanges = async () => {
        setEditMode(false);
        const newObject = changeableDataObjectArray.reduce((prev, curr) => {
            prev[curr.fieldName] = curr.value;
            return prev;
        }, {});

        await onSave(newObject);
    };

    const onCancelChanges = () => {
        setEditMode(false);
        onCancel();
    };

    return (
        <ConditionalBackgroundBlocker condition={isInEditMode}>
            <div className={classes.titleWrapper}>
                <h4 className={classes.title}>{title}</h4>
                {isEditable && !isInEditMode && (
                    <Button
                        label={'Edit Variant'}
                        action={() => {
                            setEditMode(true);
                        }}
                        type={ButtonType.linkInverted}
                    />
                )}
                {isInEditMode && (
                    <div className={classes.buttonsWrapper}>
                        <Button label={'Cancel'} action={onCancelChanges} type={ButtonType.linkInverted} />
                        <Button label={'Save Changes'} action={onSaveChanges} type={ButtonType.linkInverted} />
                    </div>
                )}
            </div>
            <table className={classes.detailTable}>
                <tbody>{tableBody}</tbody>
            </table>
        </ConditionalBackgroundBlocker>
    );
};

export default DetailsTable;
