import React,{Component} from 'react';
import {ObjContainer} from '../../services/object_container';
import {useMutation, useQuery, useQueryCache} from 'react-query';
import {ProductDataService} from './SalesOrders/ProductDataService';
import {CustomerSelectDisplay} from './SalesOrders/CustomerSelectDisplay';
import {ProductTableDisplay} from './SalesOrders/ProductTableDisplay';
const productDataService = new ProductDataService();
export function SalesOrderManager(){
    const requestState  =  ObjContainer.resolve('nav').getState();
    const {status,data:sales_order,error}  =  useQuery(['order', requestState.parent_id],productDataService.getOrder);
    const [salesOrder, setSalesOrder] = React.useState(sales_order || {});
    const queryCache = useQueryCache();

    const [mutate] = useMutation(productDataService.saveSalesOrder,{
        onSuccess: async (res,params) => {
            navTo(res.result[0].id);
            setSalesOrder(res);
        },
    });
    queryCache.setQueryData('OrderItems',()=>{
        return [];
    });
    const [mutateSalesItems] = useMutation(productDataService.saveSalesOrderItems,{
        onSuccess: async (res,params) => {
            queryCache.setQueryData('OrderItems',()=>{
                return [];
            });
            await queryCache.invalidateQueries(['order', requestState.parent_id]);
        },
    });
    const saveAllItems = async ()=>{
        let items =queryCache.getQueryData('OrderItems');
        await mutateSalesItems(items);
    };
    const navTo = (id)=> {
        let local_state = {route_params:{filter:{id:id}},parent_id:id, parent_data: Object.assign({},{back_state:ObjContainer.resolve('nav').getState()})};
        ObjContainer.resolve('nav').navTo('orders-Vieworders', local_state);
    };
    React.useEffect(() => {
        if (requestState.parent_id !== null && sales_order) {
            setSalesOrder(sales_order);
        } else {
            setSalesOrder({});
        }

    }, [sales_order, requestState.parent_id]);
    if (status === 'loading') {
        return <span>Loading...</span>;
    }
    if (status === 'error') {
        return <span>Error: {error.message}</span>;
    }
    const onSave = async e => {
        e.preventDefault();
        try {
            await mutate(salesOrder);
        } catch (error) {}
    };
    const setCustomerUpdate =(customer)=>{
        setSalesOrder(salesOrder=>Object.assign({},salesOrder,{customer_id:customer.id,customers:customer}));
    };
    return ( <div className={'container-fluid'}>
        <div className="row">
            <div className="col-md-12"><h2>Sales Order</h2></div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <a>Products</a> > <a>Add and Adjust Counts</a>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-2">Customer</div>
                    <div className={'col-md-3'}>
                        <CustomerSelectDisplay customer={salesOrder.customers} setCustomer={setCustomerUpdate}/>
                    </div>
                </div>
                {!salesOrder?.id && <>
                    <button onClick={(e)=>onSave(e)} className={'btn btn-primary'} >Create</button>
                </>}
            </div>
        </div>

        {salesOrder?.id && <>
            <hr/>
            <div className="row">
                <div className="col-md-12">
                    <ProductTableDisplay salesOrder={salesOrder} />
                    <button className={'btn btn-primary'} onClick={()=>saveAllItems()}>Save</button> &nbsp; <button className={'btn btn-primary'}>Review & Submit</button>
                </div>
            </div>
        </>}
    </div>);
}
