import React, { useEffect, useState } from 'react';
import { useSchema } from '../../../services/hooks/useSchema';
import useSearch from '../../../services/hooks/useSearch';
import { ColumnItem } from '../../../services/table_ops';
import useNav from '../../../services/hooks/useNav';
import './meta-table.scss';
import { PagerItem } from './PagerItem';
import { SearchBlock } from './SearchBlock';
import { DisplayCol } from './DisplayCol';

export const MetaTable = ({
    table,
    entity = {},
    column_list,
    filter,
    expand_multi,
    added_joins,
    filter_eq,
}) => {
    const { data: schema } = useSchema({ table_name: table.table_name });
    const [curSchema, setCurSchema] = useState({});
    const [sorts, setSorts] = useState({});
    const [curSearch, setCurSearch] = useState('');
    const [curColList, setCurColList] = useState('');
    const [curPager, setCurPager] = useState({ page: 0, pageSize: 20 });
    const [curFilter, setCurFilter] = useState({});
    const [curAndFilter, setCurAndFilter] = useState(filter || {});
    useEffect(() => {
        setCurAndFilter(filter);
    }, [filter]);
    useEffect(() => {
        if (schema) {
            setCurSchema(schema);
        }
        setCurColList(column_list);
    }, [schema, table,column_list]);
    const { data: results } = useSearch({
        entity: table.table_name,
        ...entity,
        sort: sorts,
        expand_multi,
        added_joins,
        filter_eq,
        filter_or: curFilter,
        filter: curAndFilter,
        paging: curPager, 
    });
    const { navTo } = useNav();

    const setupSearch = (search) => {
        let tmpFilter = { ...curFilter };
        schema.fields
            .filter((i) => i.data_type === 'string')
            .forEach((field) => {
                if (search) {
                    tmpFilter[field.field_code] = { eq: search };
                } else {
                    delete tmpFilter[field.field_code];
                }
            });
        setCurFilter(tmpFilter);
        setCurSearch(search);
    };
    const getFields = () => {
        if (!curSchema || !curSchema.fields) {
            return [];
        }
        if (curColList) {
            return curSchema.fields.filter((field) =>
                curSchema.column_lists[curColList].find(
                    (col) => col === field.field_code
                )
            );
        }
        return curSchema.fields.filter((j) => (j.show ? j.show.value : true));
    };
    if (!curSchema) {
        return <div>...Loading</div>;
    }
    return (
        <div>
            {table.system_label !== undefined ? <h2>{table.system_label}</h2> : ''}
            <SearchBlock
                table={curSchema}
                search={curSearch}
                setSearch={setupSearch}
            />
            <table className={'table table-striped valerian-table'}>
                <thead>
                    <tr>
                        {curSchema.enable_delete && <th style={{ width: '50px' }} />}
                        {getFields().map((field) => {
                            return (
                                <DisplayCol
                                    key={field.field_code}
                                    field={field}
                                    filter={curAndFilter}
                                    setFilter={setCurAndFilter}
                                    sorts={sorts}
                                    setSorts={setSorts}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {results?.data?.map((row, row_index) => {
                        return (
                            <tr key={'result_row_' + row.id}>
                                {curSchema.enable_delete && (
                                    <td>
                                        <a className={'inline-nav-link'}>
                                            <i className={'fa fa-trash'} />
                                        </a>
                                    </td>
                                )}
                                {getFields().map((col, col_index) => {
                                    return (
                                        <ColumnItem
                                            key={'row_val' + row.id + 'col_val' + col_index}
                                            row={row}
                                            field={col}
                                            index={row_index}
                                            col_index={col_index}
                                            rowClick={() => {
                                                return navTo(col.link, {
                                                    id: row[col.link_field || 'id'],
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <PagerItem
                curPager={curPager}
                total={results?.count}
                setCurPager={setCurPager}
            />
        </div>
    );
};
